import {
  GET_SUBSCRIBER,
  GET_SUBSCRIBER_SUCCESS,
  GET_SUBSCRIBER_FAIL

} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: {},
}

const Subscriber = (state = INIT_STATE, action) => {
  // console.log(action.payload);
  // console.log(action.type);
  switch (action.type) {
    case GET_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_SUBSCRIBER_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    default:
      return state
  }
}

export default Subscriber
