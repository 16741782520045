import {
  API_BLOG_SUCCESS,
  API_BLOG_FAIL,
  ADD_BLOG,
  GET_ALL_BLOG,
} from "./actionTypes"

const INIT_STATE = {
  blog: [],
  blogg: [],
  blogError: [],
}

const Blog = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_BLOG_SUCCESS:
      switch (action?.payload?.actionType) {
        case ADD_BLOG:
          return {
            ...state,
            blog: action?.payload?.data,
          }
        case GET_ALL_BLOG:
          return {
            ...state,
            blogg: action?.payload?.data,
          }

        default:
          return state
      }
    case API_BLOG_FAIL:
      switch (action.payload.actionType) {
        case ADD_BLOG:
          return {
            ...state,
            blogError: action.payload.error,
          }
        case GET_ALL_BLOG:
          return {
            ...state,
            blogError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}

export default Blog
