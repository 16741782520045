import {
  ADD_DEAL,
  UPDATE_DEAL,
  DELETE_DEAL,
  GET_DEAL,
  GET_DEAL_BY_ID,
  DEAL_SUCCESS,
  DEAL_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  deals: [],
};

const DealsReducer = (state = INIT_STATE, action) => {

  switch (action.type) {

    case ADD_DEAL:
      return {
        ...state,
        deals: action.payload,
      };

    case UPDATE_DEAL:
      return {
        ...state,
        deals: action.payload,
      };

    case DELETE_DEAL:
      return {
        ...state,
        deals: action.payload,
      };

    case GET_DEAL:
      return {
        ...state,
        deals: action.payload,
      };

    case GET_DEAL_BY_ID:
      return {
        ...state,
        deals: action.payload,
      };

    case DEAL_SUCCESS:
      return {
        ...state,
        deals: action.payload,
      };

    case DEAL_FAIL:
      return {
        ...state,
        deals: action.payload,
      };

    default:
      return state;
  }
};

export default DealsReducer;
