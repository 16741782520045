
import { SketchOutlined } from "@ant-design/icons";
import { element } from "prop-types";
import React, { useEffect, useState } from "react"
import {
  Card,
  Container,
} from "reactstrap"

import ApexRadial from "./ApexRadial";
import WelcomeComp from "./WelcomeComp";

import aaa from "../../assets/images/bg-auth-overlay.png"
import bbb from "../../assets/images/logo-dark.png"


const Dashboard = () => {
  document.title = "Dashboard | FeatureBeeAi";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4></h4>
          <h2 className="main-heading ">
            <span>
              <span>
                Dashboard
              </span>
            </span>
          </h2>

        <WelcomeComp />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
