import React, { useEffect } from "react"
import {
    Container,
} from "reactstrap"

import BlogGrid from "./BlogGrid/BlogGrid"



const BlogList = () => {


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <h2 className="mx-5 my-2">Blog List </h2>
                    < BlogGrid />
                </Container>
            </div>
        </React.Fragment>
    );
}

export default BlogList;
