import { GET_FREELANCER_DATA, GET_FREELANCER_FAIL, GET_FREELANCER_SUCCESS } from "./actionTypes";

const INIT_STATE = {
    data: [],
    error: {},
}


const freelancerReducer = (state = INIT_STATE, action) => {
    // console.log(action.payload);
    // console.log(action.type);
    switch (action.type) {
        case GET_FREELANCER_SUCCESS:
            return {
                ...state,
                data: action.response,
            }

        case GET_FREELANCER_FAIL:
            return {
                ...state,
                error: action.response,
            }


        default:
            return state
    }
}


export default freelancerReducer