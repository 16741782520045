import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  Row,
  Col,
  Label,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Spinner,
  FormGroup,
} from "reactstrap";
import { Input as SearchInput, InputGroup, SelectPicker } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';
import SearchIcon from '@rsuite/icons/Search';

import { message as AntdMessage, Modal as AntdModal } from "antd";
const { confirm } = AntdModal;
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";

import classnames from "classnames";
import "./crm.css";
import { useDispatch, useSelector } from "react-redux";
import { addDeal, getDeal, updateDeal } from "store/actions";
import { get, map } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import { addDaysToDateWithDayName, formatDDMMYYYY, addFollowUpConfirmationModal, closeDealModal, moveDealConfirmationModal, getBorderColor, LoseModalContent, undoStageConfirmationModal } from "components/Common/UtilitiesFunctions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { languages } from "common/data/language";
import { initialLifeCycle } from "pages/Dataset Request/DatasetRequest";
import Select from 'react-select';
import { getCurrentStatusIndexFromLifecycle } from "./ViewDeal";

const columnsMapping = {
  1: "Initiated",
  2: "Contacted",
  3: "FollowUp",
  4: "Not Responding",
  5: "In Conversation",
  6: "Close",
  7: "Lose"
};

const dealsKeyList = {
  "name": "Client Name",
  "company": "Company Name",
  "email": "Email",
  "ownerName": "Owner Name",
  "clientType": "Client Type",
  "notes": "Notes",
  "service": "Service",
  "dataForm": "Dataset Type",
  "datasetSubType": "Dataset Sub Type",
  "datasetName": "Dataset Title",
  "language": "Language",
  "industry": "Industry",
  "demography": "Demography",
  "volume": "Volume",
  "purpose": "Purpose",
  "budget": "Budget",
  "type": "Type",
  "pageLink": "Page Link",
  "sampleLink": "Sample Link",
  "message": "Message",
  "fromWhere": "Inbound/Outbound Place",
};

export const demographyOptions = [
  { label: "Asia", value: "Asia" },
  { label: "Africa", value: "Africa" },
  { label: "North America", value: "North America" },
  { label: "Europe", value: "Europe" },
  { label: "Latin America", value: "Latin America" }
];

export const typeOptions = [
  { label: "2D Bounding Box", value: "2D Bounding Box" },
  { label: "Polygone", value: "Polygone" },
  { label: "3D Bounding Box", value: "3D Bounding Box" },
  { label: "Lidar", value: "Lidar" },
  { label: "Latin America", value: "Latin America" }
];

export const getDatasetTypeOptions = (service) => {
  if (['OTS Data', 'Custom Collection'].includes(service)) {
    return ['Speech', 'Text', 'Image', 'Video', 'OCR'];
  } else if (service === 'Data Annotation') {
    return ['Speech', 'Image', 'Video', 'Text'];
  } else if (service === 'Transcription') {
    return ['Speech', 'OCR'];
  } else if (service === 'RLHF') {
    return ['Text'];
  } else if (service === 'Yugo (SAAS)') {
    return ['Speech'];
  } else {
    return [];
  }
};

export const getDatasetSubTypeOptions = (datasetType) => {
  if (datasetType === 'Speech') {
    return ['General Conversation', 'Call Center Conversation', 'Prompt'];
  } else if (datasetType === 'Image') {
    return ['Facial'];
  } else if (datasetType === 'Video') {
    return ['Facial'];
  } else if (datasetType === 'Text') {
    return ['Chat', 'Prompt & Response', 'Parallel Corpora'];
  } else if (datasetType === 'OCR') {
    return ['Handwritten', 'Printed'];
  } else {
    return [];
  }
};

const sortLifeCycle = (lifecycle) => {
  lifecycle.sort((a, b) => {
    let A, B;
    if (a?.complete_date && b?.complete_date) {
      A = new Date(a.complete_date.split('/').reverse().join('-'));
      B = new Date(b.complete_date.split('/').reverse().join('-'));
    } else {
      A = new Date(a.date.date.split('/').reverse().join('-'));
      B = new Date(b.date.date.split('/').reverse().join('-'));
    }
    const date1 = new Date(B.getFullYear(), B.getMonth(), B.getDate()).getTime();

    const date2 = new Date(A.getFullYear(), A.getMonth(), A.getDate()).getTime();

    if (a.complete_date && b.complete_date && (date1 == date2)) {
      return a.status > b.status ? 1 : a.status < b.status ? -1 : 0;
    }

    return (date1 < date2) ? 1 : (date1 > date2) ? -1 : (date1 == date2) ? 1 : 1;
  });
};

const Deals = () => {
  document.title = "CRM-Dashboard | FeatureBeeAi";

  const dispatch = useDispatch();
  const router = useHistory();
  const [columns, setColumns] = useState([]);
  const [modal, setModal] = useState(false);
  const [ownerFilter, setOwnerFilter] = useState({ isSet: false, data: [], filter: '' });
  const [searchFilter, setSearchFilter] = useState({ isSet: false, data: [], filter: '' });
  const [dealData, setDealData] = useState({});

  // step1
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [clientType, setClientType] = useState('');
  const [notes, setNotes] = useState('');

  // step2
  const [service, setService] = useState('');
  const [dataForm, setDataForm] = useState('');
  const [datasetSubType, setDatasetSubType] = useState('');
  const [language, setLanguage] = useState([]);
  const [industry, setIndustry] = useState('');
  const [demography, setDemography] = useState([]);
  const [type, setType] = useState([]);

  // Step 3
  const [datasetName, setDatasetName] = useState('');
  const [volume, setVolume] = useState('');
  const [purpose, setPurpose] = useState('');
  const [budget, setBudget] = useState('');
  const [pageLink, setPageLink] = useState('');
  const [sampleLink, setSampleLink] = useState('');
  const [message, setMessage] = useState('');
  const [fromWhere, setFromWhere] = useState('');

  const togglemodal = () => {
    setModal(!modal);
    setactiveTab(1);
    // Reset All Fields When Modal Closed
    setName('');
    setCompany('');
    setEmail('');
    setOwnerName('');
    setClientType('');
    setNotes('');
    setService('');
    setDataForm('');
    setDatasetSubType('');
    setDatasetName('');
    setLanguage([]);
    setIndustry('');
    setDemography([]);
    setType([]);
    setVolume('');
    setPurpose('');
    setBudget('');
    setPageLink('');
    setSampleLink('');
    setMessage('');
    setFromWhere('');
  };

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  function toggleTab (tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
        if (tab === 4) {
          setDealData({ name, company, email, ownerName, clientType, notes, service, dataForm, datasetSubType, datasetName, language, industry, demography, type, volume, purpose, budget, pageLink, sampleLink, message, fromWhere });
        }
      }
    }
  }

  const { deals, messageText } = useSelector((state) => {
    return {
      deals: state.DealsReducer.deals?.result,
      messageText: state.DealsReducer.deals?.message,
    };
  });

  // Fetch Deals Data
  useEffect(() => {
    dispatch(getDeal());
  }, []);

  // Adjust Deals Data
  useEffect(() => {
    if (["Deals added successfully", "Records updated successfully."].includes(messageText)) {
      if (messageText === "Deals added successfully") {
        AntdMessage.success(messageText);
      }
      dispatch(getDeal());
    }
    else if (["Records are available.", "Records are not available."].includes(messageText)) {
      if (!ownerFilter?.isSet && !searchFilter?.isSet) {
        adjustDealsToColumns(deals ? deals : []);
      }
      else {
        setOwnerFilter({ isSet: ownerFilter?.isSet, data: deals?.filter(elm => elm?.owner?.toLowerCase() === ownerFilter?.filter?.toLowerCase()), filter: ownerFilter?.filter });
        setSearchFilter({ isSet: searchFilter?.isSet, data: deals?.filter(elm => elm?.owner?.toLowerCase() === searchFilter?.filter?.toLowerCase()), filter: searchFilter?.filter });
      }
    }
  }, [deals]);

  // Handle Owner Filter
  useEffect(() => {
    if (ownerFilter?.isSet) {
      adjustDealsToColumns(ownerFilter?.data);
    }
    else if (searchFilter?.isSet) {
      const { filteredData } = handleSearchFilter(searchFilter?.filter);
      setSearchFilter(prev => ({ ...prev, data: filteredData }));
    }
    else {
      adjustDealsToColumns(deals ? deals : []);
    }
  }, [ownerFilter]);

  // Handle Search Filter
  useEffect(() => {
    if (searchFilter?.isSet) {
      adjustDealsToColumns(searchFilter?.data);
    }
    else if (ownerFilter?.isSet) {
      const data = deals?.filter(elm => elm?.owner?.toLowerCase() === ownerFilter?.filter?.toLowerCase());
      setOwnerFilter(prev => ({ ...prev, data }));
    }
    else {
      adjustDealsToColumns(deals ? deals : []);
    }

  }, [searchFilter]);

  // Set Deals Data to Columns
  const adjustDealsToColumns = (data) => {
    const initialColumns = [
      {
        id: "1",
        name: "Initiated",
        cards: data?.filter(elm => elm.deal_status == "1"),
      },
      {
        id: "2",
        name: "Contacted",
        cards: data?.filter(elm => elm.deal_status == "2"),
      },
      {
        id: "3",
        name: "FollowUp",
        cards: data?.filter(elm => elm.deal_status?.startsWith("3")),
      },
      {
        id: "4",
        name: "Not Responding",
        cards: data?.filter(elm => elm.deal_status?.startsWith("4")),
      },
      {
        id: "5",
        name: "In Conversation",
        cards: data?.filter(elm => elm.deal_status?.startsWith("5")),
      },
      {
        id: "6",
        name: "Close",
        cards: data?.filter(elm => elm.deal_status == "6"),
      },
      {
        id: "7",
        name: "Lose",
        cards: data?.filter(elm => elm.deal_status == "7"),
      },
    ];

    sortTasks(initialColumns);
  };

  // Sort Tasks in Columns
  const sortTasks = (initialColumns) => {
    initialColumns.forEach(elm => {
      elm.cards.sort((a, b) => {
        return (new Date(b?.update_date?.split('/')?.reverse())?.getTime() < new Date(a?.update_date?.split('/')?.reverse())?.getTime()) ? -1 : 1;
      });
    });

    setColumns(initialColumns);
  };

  // Handle Drag and Drop
  const handleDragEnd = async (result) => {
    if (!result.destination) return; // If dropped outside a valid drop area, do nothing

    const { source, destination } = result;
    // Reorder cards within the same card line
    if (source.droppableId === destination.droppableId) {
      return;
      // const line = cards.find(line => line.id === source.droppableId);
      // const reorderedCards = Array.from(line.cards);
      // const [movedCard] = reorderedCards.splice(source.index, 1);
      // reorderedCards.splice(destination.index, 0, movedCard);

      // const updatedLines = cards.map(line => {
      //   if (line.id === source.droppableId) {
      //     return { ...line, cards: reorderedCards };
      //   }
      //   return line;
      // });

      // setCards(updatedLines);
    } else {
      // Move card between different card lines
      const sourceLine = columns.find(line => line.id === source.droppableId);
      const destinationLine = columns.find(
        line => line.id === destination.droppableId
      );
      const sourceCards = Array.from(sourceLine.cards);
      const destinationCards = Array.from(destinationLine.cards);
      const [movedCard] = sourceCards.splice(source.index, 1);
      destinationCards.splice(destination.index, 0, movedCard);

      const updatedLines = columns.map(line => {
        if (line.id === source.droppableId) {
          return { ...line, cards: sourceCards };
        } else if (line.id === destination.droppableId) {
          return { ...line, cards: destinationCards };
        }
        return line;
      });

      // Not Resp., In Conv. Close, Lose  => Followup
      if (sourceLine.id > 3 && destinationLine.id == '3') {
        if (sourceLine.id == '4') {
          if (movedCard.deal_status == '4' && !movedCard.lifecycle.filter(elm => elm.status == '4')[0].notes && movedCard.last_activity.startsWith('3')) {
            // TODO: Delete the not responding and keep the life cycle upto followup -> update status
            const lifecycle = movedCard.lifecycle.filter(elm => !elm.status.startsWith('4'));
            lifecycle[lifecycle.length - 1].date = addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 3);
            setColumns(updatedLines);
            console.log(lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: movedCard.last_activity, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
          }
          else {
            const { isConfirmed } = await addFollowUpConfirmationModal();
            if (isConfirmed) {
              // keep the deal under not responding keep deal status as it is but add a new followup under not responding
              let status = movedCard.lifecycle[movedCard.lifecycle.length - 2].status.split('.')[1];
              status = Number(status ? status : 0) + 1;
              movedCard.lifecycle.splice(-1, 1, {
                name: `FollowUp-${status}`,
                date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 2),
                status: '4.' + status,
                notes: ''
              }, {
                name: `Update Stage`,
                date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 12),
                status: '',
                notes: ''
              });
              sortLifeCycle(movedCard.lifecycle);
              console.log(movedCard);
              dispatch(updateDeal(movedCard._id, { lifecycle: movedCard.lifecycle }));
              AntdMessage.success("FollowUp Created successfully");
            }
          }
        }
        if (sourceLine.id == '5') {
          if (movedCard.deal_status == '5' && !movedCard.lifecycle.filter(elm => elm.status.startsWith('5.')).length && !movedCard.lifecycle.filter(elm => elm.status == '5')[0].notes) {
            const { isConfirmed, isDismissed } = await addFollowUpConfirmationModal();
            if (isConfirmed) {
              // keep the deal under In_Conversation keep deal status as it is but add a new followup under In_Conversation
              movedCard.lifecycle.splice(-1, 1, {
                name: `FollowUp-1`,
                date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 2),
                status: '5.1',
                notes: ''
              }, {
                name: `Update Stage`,
                date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 12),
                status: '',
                notes: ''
              });
              sortLifeCycle(movedCard.lifecycle);
              dispatch(updateDeal(movedCard._id, { lifecycle: movedCard.lifecycle }));
              AntdMessage.success("FollowUp Created successfully");
            }
            else if (isDismissed) {
              // TODO: Delete the in conversation and keep the life cycle upto followup -> update status
              if (movedCard.last_activity.startsWith('3')) {
                movedCard.lifecycle.splice(-2, 1);
                movedCard.lifecycle[movedCard.lifecycle.length - 1].date = addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3);
                setColumns(updatedLines);
                console.log(movedCard.lifecycle);
                dispatch(updateDeal(movedCard._id, { deal_status: movedCard.last_activity, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
              }
            }
          }
          else {
            const { isConfirmed } = await addFollowUpConfirmationModal();
            if (isConfirmed) {
              // keep the deal under In_Conversation keep deal status as it is but add a new followup under In_Conversation
              let status = movedCard.lifecycle[movedCard.lifecycle.length - 2].status.split('.')[1];
              status = Number(status ? status : 0) + 1;
              movedCard.lifecycle.splice(-1, 1, {
                name: `FollowUp-${status}`,
                date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 2),
                status: '5.' + status,
                notes: ''
              }, {
                name: `Update Stage`,
                date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 12),
                status: '',
                notes: ''
              });
              sortLifeCycle(movedCard.lifecycle);
              dispatch(updateDeal(movedCard._id, { lifecycle: movedCard.lifecycle }));
              AntdMessage.success("FollowUp Created successfully");
            }
          }
        }
        if (sourceLine.id == '6') {
          const { isConfirmed } = await addFollowUpConfirmationModal();
          if (isConfirmed) {
            // add followup for previous stage at last of cycle wheather it is followup, not resp. or in conv. and remove close from lifecycle
            movedCard.lifecycle.splice(-2, 1);
            let [lastStage, status] = movedCard.lifecycle[movedCard.lifecycle.length - 2].status.split('.');
            status = Number(status ? status : 0) + 1;
            movedCard.lifecycle.splice(-1, 1, {
              name: `FollowUp-${status}`,
              date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 2),
              status: lastStage + '.' + status,
              notes: ''
            }, {
              name: `Update Stage`,
              date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, Number(lastStage) == 3 ? 5 : 12),
              status: '',
              notes: ''
            });
            sortLifeCycle(movedCard.lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: movedCard.last_activity, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            AntdMessage.success("FollowUp Created successfully");
          }
        }
        if (sourceLine.id == '7') {
          const { isConfirmed } = await addFollowUpConfirmationModal();
          if (isConfirmed) {
            // add followup for previous stage at last of cycle wheather it is followup, not resp. or in conv. and remove lose from lifecycle
            movedCard.lifecycle.splice(-2, 1);
            let [lastStage, status] = movedCard.lifecycle[movedCard.lifecycle.length - 2].status.split('.');
            status = Number(status ? status : 0) + 1;
            movedCard.lifecycle.splice(-1, 1, {
              name: `FollowUp-${status}`,
              date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 2),
              status: lastStage + '.' + status,
              notes: ''
            }, {
              name: `Update Stage`,
              date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, Number(lastStage) == 3 ? 5 : 12),
              status: '',
              notes: ''
            });
            sortLifeCycle(movedCard.lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: movedCard.last_activity, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            AntdMessage.success("FollowUp Created successfully");
          }
        }
      }
      // Inititated, contacted => followup
      else if (destinationLine.id == '3') {
        if (sourceLine == '1') {
          Swal.fire({
            title: "Can not move from Initiated to FollowUp.",
            icon: "error",
          });
        } else {
          movedCard.lifecycle.forEach((elm, index) => {
            if (elm.status == '3.1') {
              movedCard.lifecycle[index].complete_date = formatDDMMYYYY(new Date());
            }
          });
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: '3.1', lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
      }
      // can not move back to initiated or contacted
      else if (sourceLine.id > 1 && destinationLine.id < 3) {
        Swal.fire({
          title: "Can not move back",
          icon: "error",
        });
      }
      // Initiated => Contacted, Not Responding, In Conversation, Close, Lose
      else if (sourceLine.id == '1' && ['2', '4', '5', '6', '7'].includes(destinationLine.id)) {
        if (destinationLine.id == '2') {
          movedCard.lifecycle.forEach((elm, index) => {
            if (elm.status == '2') {
              movedCard.lifecycle[index].complete_date = formatDDMMYYYY(new Date());
            }
          });
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
        else {
          Swal.fire({
            title: `Can not move from Initiated to ${columnsMapping[destinationLine.id]}.`,
            icon: "error",
          });
        }
      }
      // Contacted => Not Responding, In Conversation, Close, Lose
      else if (sourceLine.id == '2' && ['4', '5', '6', '7'].includes(destinationLine.id)) {
        const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

        if (destinationLine.id == '4') {
          movedCard.lifecycle.splice(-1, 1, {
            name: `Not Responding`,
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '4',
            notes: ''
          }, {
            name: `FollowUp-1`,
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
            status: '4.1',
            notes: ''
          }, {
            name: `Update Stage`,
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 20),
            status: '',
            notes: ''
          });
          const destinationStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, destinationLine.id);
          movedCard.lifecycle.forEach((elm, idx) => {
            if (idx > currentStatusIndex && idx < destinationStatusIndex) {
              movedCard.lifecycle[idx].skipped = true;
            }
          });
          sortLifeCycle(movedCard.lifecycle);
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
        else if (destinationLine.id == '5') {
          movedCard.lifecycle.splice(-1, 1, {
            name: `In Conversation`,
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '5',
            notes: ''
          }, {
            name: `Update Stage`,
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 13),
            status: '',
            notes: ''
          });
          const destinationStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, destinationLine.id);
          movedCard.lifecycle.forEach((elm, idx) => {
            if (idx > currentStatusIndex && idx < destinationStatusIndex) {
              movedCard.lifecycle[idx].skipped = true;
            }
          });
          sortLifeCycle(movedCard.lifecycle);
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
        else if (['6', '7'].includes(destinationLine.id)) {
          const statusName = destinationLine.id == '6' ? 'Close' : 'Lose';
          movedCard.lifecycle.splice(-1, 1, {
            name: statusName,
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
            complete_date: formatDDMMYYYY(new Date()),
            status: destinationLine.id,
            notes: ''
          });
          const destinationStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, destinationLine.id);
          movedCard.lifecycle.forEach((elm, idx) => {
            if (idx > currentStatusIndex && idx < destinationStatusIndex) {
              movedCard.lifecycle[idx].skipped = true;
            }
          });

          sortLifeCycle(movedCard.lifecycle);

          if (destinationLine.id == '6') {
            const { isConfirmed, value } = await closeDealModal();

            if (isConfirmed) {
              console.log(movedCard.lifecycle);
              setColumns(updatedLines);
              dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), is_potential_client: value ? true : false, lifecycle: movedCard.lifecycle }));
            }
          }
          else if (destinationLine.id == '7') {
            toggleLoseModal(movedCard._id, sourceLine.id, updatedLines, movedCard.lifecycle);
          }
        }
      }
      // Not Responding => In Conversation, Close, Lose
      else if (sourceLine.id == '4' && ['5', '6', '7'].includes(destinationLine.id)) {
        let lifecycle = movedCard.lifecycle.slice();
        let count = 0;
        let index;

        if (movedCard.deal_status == '4') {
          //remove not resp. and its followup which is not done and add inConvo. tabs in lifecycle
          index = lifecycle.reverse().findIndex(elm => elm.notes && elm.status.startsWith('4'));
          if (index !== -1) {
            lifecycle.splice(0, index);
            lifecycle.reverse();
            lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('4.')) {
                lifecycle[idx].skipped = true;
              }
            });
          } else {
            lifecycle = lifecycle.filter(elm => !elm.status.startsWith('4'));
            lifecycle.reverse();
            count = 1;
          }
        }
        else if (movedCard.deal_status.startsWith('4.')) {
          // add inconvo tab under not resp. tab and its followup in life cycle
          index = lifecycle.reverse().findIndex(elm => (elm.status == movedCard.deal_status) || (elm.notes && elm.status.startsWith('4')));
          if (index !== -1) {
            lifecycle.splice(0, index);
            lifecycle.reverse();
            lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('4.') && elm.status !== movedCard.deal_status) {
                lifecycle[idx].skipped = true;
              }
            });
          } else {
            lifecycle.reverse();
            count = 1;
          }
        }

        // Go back to prev stage by undoing the current stage
        const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

        if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLine.id)) {
          const { isConfirmed } = await undoStageConfirmationModal();
          if (isConfirmed) {
            const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLine.id) && elm.complete_date)));
            lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('5.') && elm.skipped && idx > prevIndexOfNewStatus) {
                delete elm.skipped;
                delete elm.complete_date;
              }
              if (idx > prevIndexOfNewStatus) {
                elm.date = addDaysToDateWithDayName(lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, lifecycle[idx - 1].status));
              }
            });
            sortLifeCycle(lifecycle);
            console.log(lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
            return;
          }
          return;
        }

        if (destinationLine.id == '5') {
          lifecycle.splice(-1, count, {
            name: `In Conversation`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '5',
            notes: ''
          }, {
            name: `Update Stage`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 13),
            status: '',
            notes: ''
          });
          sortLifeCycle(lifecycle);
          console.log(lifecycle);
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
        }
        else if (['6', '7'].includes(destinationLine.id)) {
          const statusName = destinationLine.id == '6' ? 'Close' : 'Lose';
          lifecycle.splice(-1, count, {
            name: statusName,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
            complete_date: formatDDMMYYYY(new Date()),
            status: destinationLine.id,
            notes: ''
          });
          sortLifeCycle(lifecycle);

          if (destinationLine.id == '6') {
            const { isConfirmed, value } = await closeDealModal();

            if (isConfirmed) {
              console.log(lifecycle);
              setColumns(updatedLines);
              dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle, is_potential_client: value ? true : false }));
            }
          }
          else if (destinationLine.id == '7') {
            toggleLoseModal(movedCard._id, sourceLine.id, updatedLines, lifecycle);
          }
        }
      }
      // In Conversation => Not Responding, Close, Lose
      else if (sourceLine.id == '5' && ['4', '6', '7'].includes(destinationLine.id)) {
        let lifecycle = movedCard.lifecycle.slice();
        let count = 0;
        let index;

        if (movedCard.deal_status == '5') {
          //remove in Conv. and add not resp. tabs in lifecycle
          index = lifecycle.reverse().findIndex(elm => elm.notes && elm.status.startsWith('5'));
          if (index !== -1) {
            lifecycle.splice(0, index);
            lifecycle.reverse();
            lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('5.')) {
                lifecycle[idx].skipped = true;
              }
            });
          } else {
            lifecycle = lifecycle.filter(elm => !elm.status.startsWith('5'));
            lifecycle.reverse();
            count = 1;
          }
        }
        else if (movedCard.deal_status.startsWith('5.')) {
          // add not resp. tab under in conv. tab and its followup in life cycle
          index = lifecycle.reverse().findIndex(elm => (elm.status == movedCard.deal_status) || (elm.notes && elm.status.startsWith('5')));
          if (index !== -1) {
            lifecycle.splice(0, index);
            lifecycle.reverse();
            lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('5.') && elm.status !== movedCard.deal_status) {
                lifecycle[idx].skipped = true;
              }
            });
          } else {
            lifecycle.reverse();
            count = 1;
          }
        }

        // Go back to prev stage by undoing the current stage
        const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

        if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLine.id)) {
          const { isConfirmed } = await undoStageConfirmationModal();
          if (isConfirmed) {
            const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLine.id) && elm.complete_date)));
            lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('4.') && elm.skipped && idx > prevIndexOfNewStatus) {
                delete elm.skipped;
                delete elm.complete_date;
              }
              if (idx > prevIndexOfNewStatus) {
                elm.date = addDaysToDateWithDayName(lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, lifecycle[idx - 1].status));
              }
            });
            sortLifeCycle(lifecycle);
            console.log(lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
            return;
          }
          return;
        }

        if (destinationLine.id == '4') {
          lifecycle.splice(-1, count, {
            name: `Not Responding`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '4',
            notes: ''
          }, {
            name: `FollowUp-1`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
            status: '4.1',
            notes: ''
          }, {
            name: `Update Stage`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 20),
            status: '',
            notes: ''
          });

          sortLifeCycle(lifecycle);
          console.log(lifecycle);
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
        }
        else if (['6', '7'].includes(destinationLine.id)) {
          const statusName = destinationLine.id == '6' ? 'Close' : 'Lose';
          lifecycle.splice(-1, count, {
            name: statusName,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
            complete_date: formatDDMMYYYY(new Date()),
            status: destinationLine.id,
            notes: ''
          });
          sortLifeCycle(movedCard.lifecycle);

          if (destinationLine.id == '6') {
            const { isConfirmed, value } = await closeDealModal();

            if (isConfirmed) {
              console.log(lifecycle);
              setColumns(updatedLines);
              dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle, is_potential_client: value ? true : false }));
            }
          }
          else if (destinationLine.id == '7') {
            toggleLoseModal(movedCard._id, sourceLine.id, updatedLines, lifecycle);
          }
        }
      }
      // Close => Lose
      else if (sourceLine.id == '6' && destinationLine.id == '7') {
        // replace close to lose in lifecycle and add lose reasone modal
        movedCard.lifecycle.splice(-1, 1, {
          name: "Lose",
          date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
          complete_date: formatDDMMYYYY(new Date()),
          status: '7',
          notes: ''
        });
        sortLifeCycle(movedCard.lifecycle);
        toggleLoseModal(movedCard._id, sourceLine.id, updatedLines, movedCard.lifecycle, setColumns);
      }
      // Lose => Close
      else if (sourceLine.id == '7' && destinationLine.id == '6') {
        // replace lose to close in lifecycle and open close confirm modal
        movedCard.lifecycle.splice(-1, 1, {
          name: "Close",
          date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
          complete_date: formatDDMMYYYY(new Date()),
          status: '6',
          notes: ''
        });
        sortLifeCycle(movedCard.lifecycle);

        const { isConfirmed, value } = await closeDealModal();

        if (isConfirmed) {
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), is_potential_client: value ? true : false, lifecycle: movedCard.lifecycle }));
        }
      }
      // Close => Not Responding
      else if (sourceLine.id == '6' && destinationLine.id == '4') {
        const { isConfirmed } = await undoStageConfirmationModal();

        if (isConfirmed) {
          // Go back to prev stage by undoing the current stage
          const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

          if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLine.id)) {
            const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLine.id) && elm.complete_date)));
            movedCard.lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('4.') && elm.skipped && idx > prevIndexOfNewStatus) {
                delete elm.skipped;
                delete elm.complete_date;
              }
              if (idx > prevIndexOfNewStatus) {
                elm.date = addDaysToDateWithDayName(movedCard.lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, movedCard.lifecycle[idx - 1].status));
              }
            });
            movedCard.lifecycle.splice(-1, 1, {
              name: 'Update Stage',
              date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
              status: '',
              notes: ''
            });
            sortLifeCycle(movedCard.lifecycle);
            console.log(movedCard.lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: movedCard.lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            return;
          }
          // remove "close" and add "not responding" in place of that and add auto followup in it.
          movedCard.lifecycle.splice(-1, 1, {
            name: 'Not Responding',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '4',
            notes: ''
          }, {
            name: 'FollowUp-1',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
            status: '4.1',
            notes: ''
          }, {
            name: 'Update Stage',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 20),
            status: '',
            notes: ''
          });
          sortLifeCycle(movedCard.lifecycle);
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
      }
      // Close => In Conversation
      else if (sourceLine.id == '6' && destinationLine.id == '5') {
        const { isConfirmed } = await undoStageConfirmationModal();

        if (isConfirmed) {
          // Go back to prev stage by undoing the current stage
          const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

          if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLine.id)) {
            const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLine.id) && elm.complete_date)));
            movedCard.lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('5.') && elm.skipped && idx > prevIndexOfNewStatus) {
                delete elm.skipped;
                delete elm.complete_date;
              }
              if (idx > prevIndexOfNewStatus) {
                elm.date = addDaysToDateWithDayName(movedCard.lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, movedCard.lifecycle[idx - 1].status));
              }
            });
            movedCard.lifecycle.splice(-1, 1, {
              name: 'Update Stage',
              date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
              status: '',
              notes: ''
            });
            sortLifeCycle(movedCard.lifecycle);
            console.log(movedCard.lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: movedCard.lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            return;
          }
          // remove "close" and add "In Conversation".
          movedCard.lifecycle.splice(-1, 1, {
            name: 'In Conversation',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '5',
            notes: ''
          }, {
            name: 'Update Stage',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 13),
            status: '',
            notes: ''
          });
          sortLifeCycle(movedCard.lifecycle);
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
      }
      // Lose => Not Responding
      else if (sourceLine.id == '7' && destinationLine.id == '4') {
        const { isConfirmed } = await undoStageConfirmationModal();

        if (isConfirmed) {
          // Go back to prev stage by undoing the current stage
          const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

          if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLine.id)) {
            const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLine.id) && elm.complete_date)));
            movedCard.lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('4.') && elm.skipped && idx > prevIndexOfNewStatus) {
                delete elm.skipped;
                delete elm.complete_date;
              }
              if (idx > prevIndexOfNewStatus) {
                elm.date = addDaysToDateWithDayName(movedCard.lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, movedCard.lifecycle[idx - 1].status));
              }
            });
            movedCard.lifecycle.splice(-1, 1, {
              name: 'Update Stage',
              date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
              status: '',
              notes: ''
            });
            sortLifeCycle(movedCard.lifecycle);
            console.log(movedCard.lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: movedCard.lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            return;
          }
          // remove "lose" and add "not responding" in place of that and add auto followup in it.
          movedCard.lifecycle.splice(-1, 1, {
            name: 'Not Responding',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '4',
            notes: ''
          }, {
            name: 'FollowUp-1',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
            status: '4.1',
            notes: ''
          }, {
            name: 'Update Stage',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 20),
            status: '',
            notes: ''
          });
          sortLifeCycle(movedCard.lifecycle);
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
      }
      // Lose => In Conversation
      else if (sourceLine.id == '7' && destinationLine.id == '5') {
        const { isConfirmed } = await undoStageConfirmationModal();

        if (isConfirmed) {
          // Go back to prev stage by undoing the current stage
          const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

          if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLine.id)) {
            const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLine.id) && elm.complete_date)));
            movedCard.lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('5.') && elm.skipped && idx > prevIndexOfNewStatus) {
                delete elm.skipped;
                delete elm.complete_date;
              }
              if (idx > prevIndexOfNewStatus) {
                elm.date = addDaysToDateWithDayName(movedCard.lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, movedCard.lifecycle[idx - 1].status));
              }
            });
            movedCard.lifecycle.splice(-1, 1, {
              name: 'Update Stage',
              date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
              status: '',
              notes: ''
            });
            sortLifeCycle(movedCard.lifecycle);
            console.log(movedCard.lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: movedCard.lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            return;
          }
          // remove "lose" and add "In Conversation" in place of that and add auto followup in it.
          movedCard.lifecycle.splice(-1, 1, {
            name: 'In Conversation',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '5',
            notes: ''
          }, {
            name: 'Update Stage',
            date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 13),
            status: '',
            notes: ''
          });
          sortLifeCycle(movedCard.lifecycle);
          setColumns(updatedLines);
          dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
      }
      // Followup => Not Responding, In Conversation, Close, Lose
      else if (sourceLine.id == '3' && ['4', '5', '6', '7']) {
        let lifecycle = movedCard.lifecycle.slice();
        let count = 0;
        let index;

        index = lifecycle.reverse().findIndex(elm => (elm.status == movedCard.deal_status) || (elm.notes && elm.status.startsWith('3')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('3') && elm.status !== movedCard.deal_status) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle.reverse();
          count = 1;
        }

        if (destinationLine.id == '4') {
          lifecycle.splice(-1, count, {
            name: `Not Responding`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '4',
            notes: ''
          }, {
            name: `FollowUp-1`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
            status: '4.1',
            notes: ''
          }, {
            name: `Update Stage`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 20),
            status: '',
            notes: ''
          });
        }
        else if (destinationLine.id == '5') {
          lifecycle.splice(-1, count, {
            name: `In Conversation`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 3),
            complete_date: formatDDMMYYYY(new Date()),
            status: '5',
            notes: ''
          }, {
            name: `Update Stage`,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 13),
            status: '',
            notes: ''
          });
        }
        else if (['6', '7'].includes(destinationLine.id)) {
          const statusName = destinationLine.id == '6' ? 'Close' : 'Lose';
          lifecycle.splice(-1, count, {
            name: statusName,
            date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
            complete_date: formatDDMMYYYY(new Date()),
            status: destinationLine.id,
            notes: ''
          });
        }
        sortLifeCycle(lifecycle);
        console.log(lifecycle);
        setColumns(updatedLines);
        dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
      }
      // Otherwise
      else {
        setColumns(updatedLines);
        dispatch(updateDeal(movedCard._id, { deal_status: destinationLine.id, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()) }));
      }
    }
  };

  // Create New Deal
  const createNewDeal = () => {
    let dealData = {
      client_name: name,
      company_name: company,
      email,
      owner: ownerName,
      client_type: clientType,
      request_date: formatDDMMYYYY(new Date()),
      notes,
      service,
      dataset_type: dataForm,
      dataset_sub_type: datasetSubType,
      dataset_name: datasetName,
      language: language.map(elm => elm.value),
      industry,
      demography: demography.map(elm => elm.value),
      type: type.map(elm => elm.value),
      volume,
      purpose,
      budget,
      page_link: pageLink,
      sample_link: sampleLink,
      message,
      inbound_outbound_place: fromWhere,
      deal_status: '1',
      lose_reason: '',
      last_activity: '',
      lifecycle: initialLifeCycle,
      self_created: true,
      update_date: formatDDMMYYYY(new Date()),
    };

    const deals = {
      deals: [
        dealData
      ]
    };
    console.log(deals);
    dispatch(addDeal(deals));
    togglemodal();
  };

  // Filter Deals by Owner
  const handleOwnerFilter = (value) => {
    if (value) {
      const data = searchFilter?.isSet ? searchFilter?.data : deals;
      const filteredDealsByOwner = data?.filter(elm => elm?.owner?.toLowerCase() === value?.toLowerCase());
      setOwnerFilter({ isSet: true, data: filteredDealsByOwner, filter: value });
    } else {
      setOwnerFilter({ isSet: false, data: [], filter: '' });
    }
  };

  // Filter Deals by Search Params
  const handleSearchFilter = (value) => {
    const searchValue = value?.trim()?.toLowerCase();
    if (!searchValue) {
      return { filteredData: [], value: '' };
    }

    const reg = new RegExp(searchValue, "gi");

    const data = ownerFilter?.isSet ? ownerFilter?.data : deals;

    let filteredData = map(data, record => {
      const owner = record.owner ? get(record, 'owner').match(reg) : null;
      const clientName = record.client_name ? get(record, 'client_name').match(reg) : null;
      const email = record.email ? get(record, "email").match(reg) : null;
      const requestDate = record.request_date ? get(record, "request_date").match(reg) : null;
      const fromWhere = record.from_where ? get(record, "from_where").match(reg) : null;
      const datasetType = record.dataset_type ? get(record, "dataset_type").match(reg) : null;
      const datasetName = record.dataset_name ? get(record, "dataset_name").match(reg) : null;
      const companyName = record.company_name ? get(record, "company_name").match(reg) : null;
      const service = record.service ? get(record, "service").match(reg) : null;
      const dealStatus = record.deal_status ? columnsMapping[get(record, "deal_status")]?.match(reg) : null;
      const lastActivity = record.last_activity ? columnsMapping[get(record, "last_activity")]?.match(reg) : null;
      const clientType = record.client_type ? get(record, "client_type").match(reg) : null;
      const notes = record.notes ? get(record, "notes").match(reg) : null;

      if (!owner && !clientName && !email && !requestDate && !fromWhere && !datasetType && !datasetName && !companyName && !service && !dealStatus && !lastActivity && !clientType && !notes) {
        return null;
      }
      return record;
    }).filter(record => !!record);

    return { filteredData: filteredData?.length ? filteredData : [], value };
  };

  // Clear all Filter
  const clearAllFilter = () => {
    setOwnerFilter({ isSet: false, data: [], filter: '' });
    setSearchFilter({ isSet: false, data: [], filter: '' });
    if (document.getElementById('search-input')) {
      document.getElementById('search-input').value = '';
    }
  };

  // Toggle is_important Flag for Deal
  const activeBtn = (e, cardId) => {
    e.stopPropagation();
    const ele = e.target;
    if (ele.closest("i").classList.contains("active")) {
      ele.closest("i").classList.remove("active");
      dispatch(updateDeal(cardId, { is_important: false }));
    } else {
      ele.closest("i").classList.add("active");
      dispatch(updateDeal(cardId, { is_important: true }));
    }
  };

  // Validate Each Stage for Compulsory Field
  const validateStage = (activeTab) => {
    if (activeTab === 1) {
      if (!name || !company || !email || !ownerName || !clientType) return false;
      return true;
    }
    else if (activeTab === 2) {
      if (!service || !dataForm || !datasetSubType) return false;
      else if (['Speech', 'Text', 'OCR'].includes(dataForm) && !language.length) return false;
      else if (['Call Center Conversation', 'Prompt', 'Chat', 'Prompt & Response'].includes(datasetSubType) && !industry) return false;
      else if (['Image', 'Video'].includes(dataForm) && !demography.length) return false;
      else if (['Facial', 'Prompt & Response', 'Parallel Corpora', 'Handwritten', 'Printed', '2D Bounding Box', 'Polygone', '3D Bounding Box', 'Lidar'].includes(datasetSubType) && !type.length) return false;
      return true;
    }
    return true;
  };

  const toggleLoseModal = (draggingTaskId, prevColumn, updatedColumns, lifecycle) => {
    confirm({
      title: 'Describe a Reason:',
      icon: <ExclamationCircleFilled />,
      content: <LoseModalContent />,
      okText: 'Submit',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk () {
        const loseReasonDropdown = document.querySelector('#lose-reason');
        const loseReasonTextArea = document.querySelector('#lose-reason-custom');
        const potClient = document.querySelector('#pot_client');

        const reason = loseReasonDropdown?.value === "Other" ? loseReasonTextArea?.value : loseReasonDropdown?.value;

        if (prevColumn) {
          dispatch(updateDeal(draggingTaskId, { deal_status: '7', lose_reason: reason, last_activity: prevColumn, update_date: formatDDMMYYYY(new Date()), is_potential_client: potClient.checked ? true : false, lifecycle }));
        } else {
          dispatch(updateDeal(draggingTaskId, { is_potential_client: potClient.checked ? true : false, lifecycle }));
        }
        setColumns(updatedColumns);
      },
    });
  };

  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <Card>
            <CardBody className="py-2">
              <div className="page-title-box pb-0 d-flex align-items-center justify-content-between">
                <h4 className="d-flex gap-2 align-items-center mb-0 font-size-18">Deals <span className="font-size-14 fw-normal">({columns?.map(elm => elm.cards).flat(1)?.length})</span></h4>
                <div>
                  <SelectPicker value={ownerFilter?.filter} onChange={handleOwnerFilter} data={[... new Set(deals?.length ? deals?.map(elm => elm.owner) : [])].filter(elm => elm).map((owner) => ({ label: owner, value: owner }))} style={{ width: 224 }} placeholder="Filter By Owner" loading={false} />
                </div>
                <div>
                  <InputGroup inside style={{ width: '300px' }}>
                    <SearchInput className="rounded-end-0" id="search-input" type="search" placeholder="Filter By Search" onPressEnter={(e) => {
                      const data = handleSearchFilter(e?.target?.value);
                      setSearchFilter({ isSet: !data?.value ? false : true, data: data?.filteredData, filter: data?.value });
                    }} />
                    <InputGroup.Addon>
                      {searchFilter.isSet ?
                        <CloseIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const inputEl = document.querySelector('#search-input');
                            if (inputEl) {
                              inputEl.value = '';
                              const data = handleSearchFilter('');
                              setSearchFilter({ isSet: !data?.value ? false : true, data: data?.filteredData, filter: data?.value });
                            }
                          }}
                        />
                        :
                        <SearchIcon
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            const inputEl = document.querySelector('#search-input');
                            const data = handleSearchFilter(inputEl?.value);
                            setSearchFilter({ isSet: !data?.value ? false : true, data: data?.filteredData, filter: data?.value });
                          }}
                        />
                      }
                    </InputGroup.Addon>
                  </InputGroup>
                </div>
                <div className="d-flex gap-3">
                  <Button
                    id="filter"
                    type="button"
                    color="danger"
                    onClick={() => clearAllFilter()}
                  >
                    Clear Filter
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => togglemodal()}
                  >
                    Create Deal
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
          <Row className="flex-nowrap overflow-x-auto overflow-y-hidden">
            <DragDropContext onDragEnd={handleDragEnd}>
              {(columns || []).map(line => (
                <Col lg={4} key={line.id} className="pb-3 flex-shrink-0" style={{ height: 'calc(100vh - 142px)' }}>
                  <Card className="h-100">
                    <div className="position-sticky top-0 bg-white z-2 d-flex justify-content-between gap-3 align-items-center border-bottom" style={{ padding: '8px 20px' }}>
                      <h4 className="card-title mb-0">{line.name}</h4>
                      <span className="deal-count">{line.cards.length}</span>
                    </div>
                    <Droppable droppableId={line.id}>
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={!deals?.length ? "opacity-25 h-100" : 'h-100'}
                        >
                          <CardBody className="overflow-y-auto overflow-x-hidden pb-1" style={{ height: 'calc(100% - 51px)' }}>
                            {line.cards.map((card, index) => {
                              const borderColor = getBorderColor(card.lifecycle, card.deal_status);
                              return (
                                <Draggable
                                  key={card._id}
                                  draggableId={card._id}
                                  index={index}
                                >
                                  {provided => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      // className="card task-list"
                                      className="pb-1 task-list"
                                      id={line.name + "-task"}
                                    >
                                      <div className="card mb-2 task-box" id="uptask-1" style={{ borderLeft: `3px solid ${borderColor}` }} onClick={() => router.push(`/crm/view-deal/${card._id}`)}>
                                        <CardBody>
                                          <div className="float-end ms-2">
                                            <i className={`bx bx-star font-size-20 ${card.is_important ? 'active' : ''}`} onClick={(e) => activeBtn(e, card._id)} style={{ cursor: 'pointer' }}></i>
                                          </div>
                                          {/^(3|4|5)/.test(card?.deal_status) ?
                                            <div className="float-end ms-2">
                                              <span
                                                className={`badge rounded-pill badge-soft-danger font-size-15`}
                                                id="task-status"
                                              >
                                                {/^(3|4|5)/.test(card?.deal_status) ? `${card.deal_status.slice(2,)}` : '1'}
                                              </span>
                                            </div>
                                            : null}
                                          <div className="d-flex gap-3">
                                            <div>
                                              <span
                                                title={card?.owner || "Unknown"}
                                                className={"rounded-circle header-profile-user d-flex justify-content-center align-items-center bg-primary-subtle text-primary font-size-16"}>
                                                {card.owner ? card.owner.charAt(0).toUpperCase() : <i className="bx bx-user"></i>}
                                              </span>
                                            </div>
                                            <div className="flex-grow-1">
                                              <h5 className="font-size-15 mb-0 text-capitalize">
                                                <Link
                                                  to="#"
                                                  className="text-dark"
                                                  id="task-name"
                                                >
                                                  {card.client_name}
                                                </Link>
                                              </h5>
                                              <p className="text-muted mb-0 text-capitalize">
                                                {card.company_name}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex justify-content-between gap-3 mt-3 flex-wrap">
                                            <span
                                              className={`badge rounded badge-soft-info font-size-12`}
                                              id="task-create-date"
                                            >
                                              Create: {card.request_date ? `${card.request_date}` : '-'}
                                            </span>
                                            <span
                                              className={`badge rounded badge-soft-success font-size-12`}
                                              id="task-update-date"
                                            >
                                              Update: {card?.update_date ? card?.update_date : '-'}
                                            </span>
                                          </div>
                                        </CardBody>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}

                            {!line.cards.length && (
                              <div className="text-center d-grid h-100">
                                <h5
                                  className="d-flex justify-content-center align-items-center font-size-20 text-muted p-2"
                                >
                                  No Deals To Show
                                </h5>
                              </div>
                            )}
                          </CardBody>
                        </div>
                      )}
                    </Droppable>
                    {/* {(!deals?.length && !columns.map(elm => elm.cards).flat(1).length) && (<Spinner color="primary" className='position-absolute top-50 start-50' />)} */}
                  </Card>
                </Col>
              ))}
            </DragDropContext>
          </Row>
        </Container>
        <Modal
          isOpen={modal}
          role="dialog"
          size="xl"
          autoFocus={true}
          backdrop="static"
          id="createDealModal"
          toggle={() => togglemodal()}
        >
          <div className="modal-content">
            <ModalHeader toggle={() => togglemodal()}>
              Create New Deal
            </ModalHeader>
            <ModalBody>
              <div className="wizard clearfix">
                <div className="steps clearfix">
                  <ul>
                    <NavItem
                      className={classnames({ current: activeTab === 1 })}
                    >
                      <NavLink
                        className={classnames({ current: activeTab === 1 })}
                        onClick={() => {
                          setactiveTab(1);
                        }}
                        disabled={!(passedSteps || []).includes(1)}
                      >
                        <span className="number">01</span> Client Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 2 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 2 })}
                        onClick={() => {
                          setactiveTab(2);
                        }}
                        disabled={!(passedSteps || []).includes(2)}
                      >
                        <span className="number">02</span> Type of Data
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 3 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 3 })}
                        onClick={() => {
                          setactiveTab(3);
                        }}
                        disabled={!(passedSteps || []).includes(3)}
                      >
                        <span className="number">03</span> Deal Details
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={classnames({ current: activeTab === 4 })}
                    >
                      <NavLink
                        className={classnames({ active: activeTab === 4 })}
                        onClick={() => {
                          setactiveTab(4);
                        }}
                        disabled={!(passedSteps || []).includes(4)}
                      >
                        <span className="number">04</span> Review Details
                      </NavLink>
                    </NavItem>
                  </ul>
                </div>
                <div className="content clearfix">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>
                      <Form>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="name">
                                Name<span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="form-control"
                                id="name"
                                placeholder="Enter Name"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="company">
                                Company<span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                className="form-control"
                                id="company"
                                placeholder="Enter Company Name"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="email">
                                Email<span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="form-control"
                                id="email"
                                placeholder="Enter Email"
                              />
                            </div>
                          </Col>
                          <Col lg="6" className="mx-auto">
                            <div className="mb-3">
                              <Label for="deal-owner">
                                Deal Owner<span className="text-danger">*</span>
                              </Label>
                              <select value={ownerName} id="deal-owner" className="form-select" onChange={(e) => setOwnerName(e.target.value)}>
                                <option value=''>
                                  Select Deal Owner
                                </option>
                                <option value="Jesal">
                                  Jesal
                                </option>
                                <option value="Praveen">Praveen</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="clientType">
                                Client Type<span className="text-danger">*</span>
                              </Label>
                              <select value={clientType} id="clientType" className="form-select" onChange={(e) => setClientType(e.target.value)}>
                                <option value=''>
                                  Select Client Type
                                </option>
                                <option value="New">
                                  New
                                </option>
                                <option value="Recurring">Recurring</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="notes">
                                Notes
                              </Label>
                              <textarea
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                                className="form-control"
                                id="notes"
                                placeholder="Enter Notes"
                                style={{ resize: 'none' }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={2}>
                      <Form>
                        <Row>
                          <Col lg="6" className="mx-auto">
                            <div className="mb-3">
                              <Label for="service">
                                Service<span className="text-danger">*</span>
                              </Label>
                              <select value={service} id="service" className="form-select" onChange={(e) => {
                                setService(e.target.value); setDataForm(''); setDatasetSubType(''); setLanguage([]); setIndustry(''); setDemography([]); setType([]);
                              }}>
                                <option value=''>Select Service</option>
                                <option value="OTS Data">OTS Data</option>
                                <option value="Custom Collection">Custom Collection</option>
                                <option value="Transcription">Transcription</option>
                                <option value="Data Annotation">Data Annotation</option>
                                <option value="RLHF">RLHF</option>
                                <option value="Yugo (SAAS)">Yugo (SAAS)</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" className="mx-auto">
                            <div className="mb-3">
                              <Label for="data-form">
                                Dataset Type<span className="text-danger">*</span>
                              </Label>
                              <select value={dataForm} id="data-form" className="form-select" onChange={(e) => setDataForm(e.target.value)}>
                                <option value=''>Select Dataset Type</option>
                                {getDatasetTypeOptions(service).map(elm => (<option value={elm} key={elm}>{elm}</option>))}
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" className="mx-auto">
                            <div className="mb-3">
                              <Label for="datasetSubType">
                                Dataset Sub Type<span className="text-danger">*</span>
                              </Label>
                              <select value={datasetSubType} id="datasetSubType" className="form-select" onChange={(e) => setDatasetSubType(e.target.value)}>
                                <option value=''>Select Dataset Sub Type</option>
                                {getDatasetSubTypeOptions(dataForm).map(elm => (<option value={elm} key={elm}>{elm}</option>))}
                              </select>
                            </div>
                          </Col>
                        </Row>
                        {['Speech', 'Text', 'OCR'].includes(dataForm) &&
                          (<Row>
                            <Col lg="6" className="mx-auto">
                              <div className="mb-3">
                                <Label for='language'>
                                  Language<span className="text-danger">*</span>
                                </Label>
                                <Select
                                  value={language}
                                  isMulti={true}
                                  onChange={setLanguage}
                                  options={languages}
                                  className="select2-selection"
                                  classNamePrefix="crm"
                                />
                              </div>
                            </Col>
                          </Row>)}
                        {['Call Center Conversation', 'Prompt', 'Chat', 'Prompt & Response'].includes(datasetSubType) &&
                          (<Row>
                            <Col lg="6" className="mx-auto">
                              <div className="mb-3">
                                <Label for="industry">
                                  Industry<span className="text-danger">*</span>
                                </Label>
                                <select value={industry} id="industry" className="form-select" onChange={(e) => setIndustry(e.target.value)}>
                                  <option value=''>Select Industry</option>
                                  <option value="General">General</option>
                                  <option value="Healthcare">Healthcare</option>
                                  <option value="Retain and E-Commerce">Retain and E-Commerce</option>
                                  <option value="Manufacturing">Manufacturing</option>
                                  <option value="BFSI">BFSI</option>
                                  <option value="Automobile">Automobile</option>
                                  <option value="Telecom">Telecom</option>
                                  <option value="Real-Estate">Real-Estate</option>
                                </select>
                              </div>
                            </Col>
                          </Row>)}
                        {['Image', 'Video'].includes(dataForm) &&
                          (<Row>
                            <Col lg="6" className="mx-auto">
                              <div className="mb-3">
                                <Label for="demography">
                                  Demography<span className="text-danger">*</span>
                                </Label>
                                <Select
                                  value={demography}
                                  id="demography"
                                  isMulti={true}
                                  onChange={setDemography}
                                  options={demographyOptions}
                                  className="select2-selection"
                                  classNamePrefix="crm"
                                />
                              </div>
                            </Col>
                          </Row>)}
                        {['Facial', 'Prompt & Response', 'Parallel Corpora', 'Handwritten', 'Printed', '2D Bounding Box', 'Polygone', '3D Bounding Box', 'Lidar'].includes(datasetSubType) &&
                          (<Row>
                            <Col lg="6" className="mx-auto">
                              <div className="mb-3">
                                <Label for="type">
                                  Type<span className="text-danger">*</span>
                                </Label>
                                <Select
                                  value={type}
                                  id="type"
                                  isMulti={true}
                                  onChange={setType}
                                  options={typeOptions}
                                  className="select2-selection"
                                  classNamePrefix="crm"
                                />
                              </div>
                            </Col>
                          </Row>)}
                      </Form>
                    </TabPane>
                    <TabPane tabId={3}>
                      <Form>
                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="datasetName">
                                Dataset Title
                              </Label>
                              <Input
                                type="text"
                                value={datasetName}
                                onChange={(e) => setDatasetName(e.target.value)}
                                className="form-control"
                                id="datasetName"
                                placeholder="Enter Dataset Name"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="volume">
                                Volume
                              </Label>
                              <Input
                                type="text"
                                value={volume}
                                onChange={(e) => setVolume(e.target.value)}
                                className="form-control"
                                id="volume"
                                placeholder="Enter Volume"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="purpose">
                                Purpose
                              </Label>
                              <select value={purpose} id="purpose" className="form-select" onChange={(e) => setPurpose(e.target.value)}>
                                <option value=''>Select Purpose</option>
                                <option value="Training">Training</option>
                                <option value="Testing">Testing</option>
                                <option value="Fine Tuning">Fine Tuning</option>
                                <option value="POC">POC</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="budget">
                                Budget
                              </Label>
                              <select value={budget} id="budget" className="form-select" onChange={(e) => setBudget(e.target.value)}>
                                <option value=''>Select Budget</option>
                                <option value="Upto $10k">Upto $10k</option>
                                <option value="$10k - $50k">$10k - $50k</option>
                                <option value="Above $50k">Above $50k</option>
                                <option value="Not sure">Not sure</option>
                              </select>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="pageLink">
                                Page Link
                              </Label>
                              <Input
                                type="text"
                                value={pageLink}
                                onChange={(e) => setPageLink(e.target.value)}
                                className="form-control"
                                id="pageLink"
                                placeholder="Enter Page Link"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="sampleLink">
                                Sample Link
                              </Label>
                              <Input
                                type="text"
                                value={sampleLink}
                                onChange={(e) => setSampleLink(e.target.value)}
                                className="form-control"
                                id="sampleLink"
                                placeholder="Enter Sample Link"
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="message">
                                Message
                              </Label>
                              <Input
                                type="textarea"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="form-control"
                                id="message"
                                placeholder="Enter Message"
                                style={{ resize: 'none' }}
                              />
                            </div>
                          </Col>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="fromWhere">
                                Inbound/Outbound Place
                              </Label>
                              <select value={fromWhere} id="fromWhere" className="form-select" onChange={(e) => setFromWhere(e.target.value)}>
                                <option value=''>Select Inbound/Outbound Place</option>
                                <option value="Dataset Request">Dataset Request</option>
                                <option value="Contact Us">Contact Us</option>
                                <option value="Get a Call">Get a Call</option>
                                <option value="Community Request">Community Request</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                    <TabPane tabId={4}>
                      <p className="card-title mb-3 text-danger font-size-12">**Please review all the details before submit.</p>
                      <Row>
                        {Object.keys(dealData).map((elm, index) =>
                          <Col lg="4" key={index}>
                            <div className="mb-3">
                              <Label for={elm}>
                                {dealsKeyList[elm]}
                              </Label>
                              <Input
                                type="text"
                                value={['language', 'demography', 'type'].includes(elm) ? dealData[elm]?.map(elm => elm.value).join(', ') : dealData[elm]}
                                className="form-control"
                                id={elm}
                                readOnly
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        (activeTab === 1) ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab - 1);
                        }}
                      >
                        Previous
                      </Link>
                    </li>
                    <li
                      className={validateStage(activeTab) ? "next" : "next disabled"}
                    >
                      <Link
                        to="#"
                        onClick={(e) => {
                          validateStage(activeTab) ?
                            (activeTab === 4) ? createNewDeal() : toggleTab(activeTab + 1)
                            :
                            e.preventDefault();
                        }}
                      >
                        {(activeTab === 4) ? 'Create' : 'Next'}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </div >
    </React.Fragment >
  );
};

export default Deals;
