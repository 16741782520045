import { deleteFreelancerVendors, getFreelancerVendors, sendCustomMailToFreelancer } from "../../helpers/backend_helper";
import { fork, takeEvery, all, put, call } from "redux-saga/effects";
import { DELETE_FREELANCER_DATA, GET_FREELANCER_DATA, GET_FREELANCER_FAIL, GET_FREELANCER_SUCCESS, SEND_MAIL_TO_FREELANCER } from "./actionTypes";
import Swal from "sweetalert2";

function* freelancerUpdate () {
    try {
        const response = yield call(getFreelancerVendors);
        // console.log(response);
        yield put({ type: GET_FREELANCER_SUCCESS, response });
    } catch (error) {
        yield put({ type: GET_FREELANCER_FAIL, response });
    }
}

function* freelancerDelete ({ payload: { id } }) {
    try {
        const response = yield call(deleteFreelancerVendors, id);
        // console.log(response);
        if (response.success) {
            Swal.fire({
                title: "Deleted!",
                text: "Data has been deleted.",
                icon: "success"
            });
            yield put({ type: GET_FREELANCER_SUCCESS, response });
        } else {
            Swal.fire({
                title: "Oops!",
                text: response?.message || "Something went wrong.",
                icon: "error"
            });
            yield put({ type: GET_FREELANCER_FAIL, response });
        }
    } catch (error) {
        yield put({ type: GET_FREELANCER_FAIL, response });
    }
}

function* sendMailToFreelancer ({ payload: { data } }) {
    try {
        const response = yield call(sendCustomMailToFreelancer, data);
        // console.log(response);
        if (response.success) {
            yield put({ type: GET_FREELANCER_SUCCESS, response });
        } else {
            Swal.fire({
                title: "Oops!",
                text: response?.message || "Something went wrong.",
                icon: "error"
            });
            yield put({ type: GET_FREELANCER_FAIL, response });
        }
    } catch (error) {
        yield put({ type: GET_FREELANCER_FAIL, response });
    }
}

function* watchFreelancerSaga () {
    yield takeEvery(GET_FREELANCER_DATA, freelancerUpdate);
    yield takeEvery(DELETE_FREELANCER_DATA, freelancerDelete);
    yield takeEvery(SEND_MAIL_TO_FREELANCER, sendMailToFreelancer);
}

function* freelancerSaga () {
    yield all([fork(watchFreelancerSaga)]);
}

export default freelancerSaga;