import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
    Container,
    FormGroup, Label,
    Input as ReactInput
} from "reactstrap";
import {
    Button,
    Col as AntdCol,
    Form,
    Input,
    Row as AntdRow,
    Select,
    Radio,
    Space,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined, PlusCircleOutlined } from "@ant-design/icons"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import { useSelector, useDispatch } from "react-redux"
import { getCategory } from "../../store/actions"
import { getIndustry } from "../../store/actions"
import { addNewDataset } from "../../store/actions"



const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 5,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 19,
        },
    },
};

const formItemInnerLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 6,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 19,
        },
    },
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 24,
            offset: 5,
        },
    },
};

const children = [
    <Option key={1} value={"Hindi"}>{"Hindi"}</Option>,
    <Option key={2} value={"Gujarati"}>{"Gujarati"}</Option>,
    <Option key={3} value={"English"}>{"English"}</Option>,
    <Option key={4} value={"Marathi"}>{"Marathi"}</Option>,
    <Option key={5} value={"Telugu"}>{"Telugu"}</Option>,
    <Option key={6} value={"Tamil"}>{"Tamil"}</Option>,
    <Option key={7} value={"French"}>{"French"}</Option>,
    <Option key={8} value={"Urdu"}>{"Urdu"}</Option>,
    <Option key={9} value={"Sanskrit"}>{"Sanskrit"}</Option>,
];


const AddDataset = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const [readyToDeploy, setReadyToDeploy] = useState(true);
    const [higherPriority, setHigherPriority] = useState(true);
    const [higherPriorityIndustry, setHigherPriorityIndustry] = useState(true);
    const [datasetType, setDatasetType] = useState(1);
    const [audioType, setAudioType] = useState(1);
    const [category, setCategory] = useState([]);
    const [industry, setIndustry] = useState([]);
    const [fileBase64, setFileBase64] = useState([]);


    const { categoryList, addResponse } = useSelector(state => ({
        categoryList: state.Dataset.dataset,
        addResponse: state.addDataset.addDatasetResponse,
    }));

    const { industryList } = useSelector(state => ({
        industryList: state.Industry.industry,
    }))

    useEffect(() => {
        dispatch(getIndustry())
        dispatch(getCategory())
    }, [dispatch]);

    useEffect(() => {
        setCategory(categoryList?.result)
        setIndustry(industryList?.result)
    }, [categoryList, industryList]);


    const handleReadyToDeploy = (e) => {
        // console.log('radio checked', e.target.value);
        setReadyToDeploy(e.target.value);
    };

    const handleHigherPriority = (e) => {
        // console.log('radio checked', e.target.value);
        setHigherPriority(e.target.value);
    };

    const handleHigherPriorityIndustry = (e) => {
        // console.log('radio checked', e.target.value);
        setHigherPriorityIndustry(e.target.value);
    };

    const handleDatasetType = (e) => {
        // console.log('dataset-type checked', e.target.value);
        if (e.target.value ? e.target.value === "1" : false) {
            document.querySelector('.text').classList.remove('d-none');
            !document.querySelector('.audio-type').classList.contains('d-none') ? document.querySelector('.audio-type').classList.add('d-none') : null;
            !document.querySelector('.image-type').classList.contains('d-none') ? document.querySelector('.image-type').classList.add('d-none') : null;
            !document.querySelector('.video').classList.contains('d-none') ? document.querySelector('.video').classList.add('d-none') : null;
        } else if (e.target.value ? e.target.value === "2" : false) {
            document.querySelector('.audio-type').classList.remove('d-none');
            !document.querySelector('.text').classList.contains('d-none') ? document.querySelector('.text').classList.add('d-none') : null;
            !document.querySelector('.video').classList.contains('d-none') ? document.querySelector('.video').classList.add('d-none') : null;
            !document.querySelector('.image-type').classList.contains('d-none') ? document.querySelector('.image-type').classList.add('d-none') : null;

        } else if (e.target.value ? e.target.value === "3" : false) {
            document.querySelector('.image-type').classList.remove('d-none');
            !document.querySelector('.text').classList.contains('d-none') ? document.querySelector('.text').classList.add('d-none') : null;
            !document.querySelector('.video').classList.contains('d-none') ? document.querySelector('.video').classList.add('d-none') : null;
            !document.querySelector('.audio-type').classList.contains('d-none') ? document.querySelector('.audio-type').classList.add('d-none') : null;
        } else if (e.target.value ? e.target.value === "4" : false) {
            document.querySelector('.video').classList.remove('d-none');
            !document.querySelector('.text').classList.contains('d-none') ? document.querySelector('.text').classList.add('d-none') : null;
            !document.querySelector('.image-type').classList.contains('d-none') ? document.querySelector('.image-type').classList.add('d-none') : null;
            !document.querySelector('.audio-type').classList.contains('d-none') ? document.querySelector('.audio-type').classList.add('d-none') : null;
        }
        setDatasetType(parseInt(e.target.value));
    };

    const handleAudioType = (e) => {
        // console.log('audio-type checked', e.target.value);
        if (e.target.value === "1") {
            document.querySelector('.cc').classList.remove('d-none');
            !document.querySelector('.prompt').classList.contains('d-none') ? document.querySelector('.prompt').classList.add('d-none') : null;
        } else if (e.target.value === "2") {
            document.querySelector('.prompt').classList.remove('d-none');
            !document.querySelector('.cc').classList.contains('d-none') ? document.querySelector('.cc').classList.add('d-none') : null;
        }
        setAudioType(parseInt(e.target.value));
    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const FileUpload = async (e, fieldName) => {
        const b64 = await toBase64(e.target.files[0]);

        if (fieldName === 'card_image') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64 }]);
        } else if (fieldName === 'dataset_image') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64 }]);
        } else if (fieldName === 'cc_image') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64 }]);
        } else if (fieldName === 'prompt_image') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64 }]);
        } else if (fieldName === 'text_image') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64 }]);
        } else if (fieldName === 'text_tag_image') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64 }]);
        } else if (fieldName === 'image_type_file') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64, ['fileName']: e.target.value }]);
        } else if (fieldName === 'thumbnail_file') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64, ['fileName']: e.target.value }]);
        } else if (fieldName === 'video_file') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64, ['fileName']: e.target.value }]);
        } else if (fieldName === 'prompt_repeat_image') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64, ['fileName']: e.target.value }]);
        } else if (fieldName === 'text_repeat_file') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64, ['fileName']: e.target.value }]);
        } else if (fieldName === 'text_repeat_tag_file') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64, ['fileName']: e.target.value }]);
        } else if (fieldName === 'image_repeat_file') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64, ['fileName']: e.target.value }]);
        } else if (fieldName === 'video_repeat_image_file') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64, ['fileName']: e.target.value }]);
        } else if (fieldName === 'video_repeat_file') {
            setFileBase64(oldValue => [...oldValue, { [fieldName]: b64, ['fileName']: e.target.value }]);
        } else {
            alert("please select valid file!")
        }
    }

    const onFinish = (values) => {
        // console.log('Received values of form: ', values, readyToDeploy, higherPriority, datasetType, audioType);

        // Video Sample Array
        const thumbnailFile = fileBase64 ? fileBase64.map(elm => elm.thumbnail_file).filter(elm => elm ? elm : null) ? fileBase64.map(elm => elm.thumbnail_file).filter(elm => elm)[0] : null : null;

        const videoFile = fileBase64 ? fileBase64.map(elm => elm.video_file).filter(elm => elm ? elm : null) ? fileBase64.map(elm => elm.video_file).filter(elm => elm)[0] : null : null;

        const video_fields_modify_image = values.video_fields ? values.video_fields.filter(elm => elm.v_file = ((fileBase64 && fileBase64.length > 0) ? fileBase64.filter(elmt => elmt.fileName === elm.v_file).map(elm => elm.video_repeat_file)[0] : [])) : null;

        let video_fields_modify = video_fields_modify_image ? video_fields_modify_image.filter(elm => elm.image = ((fileBase64 && fileBase64.length > 0) ? fileBase64.filter(elmt => elmt.fileName === elm.image).map(elm => elm.video_repeat_image_file)[0] : [])) : null;

        video_fields_modify = video_fields_modify.map(elm => ({ "file": elm.v_file, "image": elm.image, "channel_1": elm.channel_1, "channel_2": elm.channel_2, "accent": elm.accent }));

        const video_sample = [
            {
                image: thumbnailFile ? thumbnailFile : null,
                file: videoFile ? videoFile : null,
                channel_1: values.video_channel1_initial ? values.video_channel1_initial : null,
                channel_2: values.video_channel2_initial ? values.video_channel2_initial : null,
                accent: values.video_accent_initial ? values.video_accent_initial : null,
            },
            ...(video_fields_modify ? video_fields_modify : [])
        ]

        // Image Sample Array
        const imageFile = fileBase64 ? fileBase64.map(elm => elm.image_type_file).filter(elm => elm ? elm : null) ? fileBase64.map(elm => elm.image_type_file).filter(elm => elm)[0] : null : null;

        let image_fields_modify = values.image_fields ? values.image_fields.filter(elm => elm.image_file = ((fileBase64 && fileBase64.length > 0) ? fileBase64.filter(elmt => elmt.fileName === elm.image_file).map(elm => elm.image_repeat_file)[0] : [])) : null;

        image_fields_modify = image_fields_modify ? image_fields_modify.map(elm => ({ "file": elm.image_file, "gender": elm.gender, "gesture": elm.gesture, "ethnicity": elm.ethnicity, "accessary": elm.accessary })) : [];

        const image_sample = [
            {
                gender: values.image_gender_initial ? values.image_gender_initial : null,
                gesture: values.image_gesture_initial ? values.image_gesture_initial : null,
                ethnicity: values.image_ethnicity_initial ? values.image_ethnicity_initial : null,
                accessary: values.image_accessary_initial ? values.image_accessary_initial : null,
                file: imageFile ? imageFile : null,
            },
            ...(image_fields_modify ? image_fields_modify : [])
        ]

        // Text Sample Array
        const textImage = fileBase64 ? fileBase64.map(elm => elm.text_image).filter(elm => elm ? elm : null) ? fileBase64.map(elm => elm.text_image).filter(elm => elm)[0] : null : null;

        const textTagFile = fileBase64 ? fileBase64.map(elm => elm.text_tag_image).filter(elm => elm ? elm : null) ? fileBase64.map(elm => elm.text_tag_image).filter(elm => elm)[0] : null : null;

        const text_fields_modify_image = values.text_fields ? values.text_fields.filter(elm => elm.text_sample = ((fileBase64 && fileBase64.length > 0) ? fileBase64.filter(elmt => elmt.fileName === elm.text_sample).map(elm => elm.text_repeat_file)[0] : [])) : null;

        const text_fields_modify = text_fields_modify_image ? text_fields_modify_image.filter(elm => elm.tagging = ((fileBase64 && fileBase64.length > 0) ? fileBase64.filter(elmt => elmt.fileName === elm.tagging).map(elm => elm.text_repeat_tag_file)[0] : [])) : null;

        const text_sample = [
            {
                heading: values.text_heading_initial ? values.text_heading_initial : null,
                text_sample: textImage ? textImage : null,
                tagging: textTagFile ? textTagFile : null,
            },
            ...(text_fields_modify ? text_fields_modify : [])
        ]

        // CC Sample Array
        const ccImage = fileBase64 ? fileBase64.map(elm => elm.cc_image).filter(elm => elm ? elm : null) ? fileBase64.map(elm => elm.cc_image).filter(elm => elm)[0] : null : null;

        const cc_sample = [{
            file: ccImage ? ccImage : null,
            channel1: values.cc_channel1_initial ? values.cc_channel1_initial : null,
            channel2: values.cc_channel2_initial ? values.cc_channel2_initial : null,
            format: values.cc_format_initial ? values.cc_format_initial : null,
            transcriptions: [...(values?.cc_transcript_initial ? JSON.parse(values?.cc_transcript_initial) : [])]
        },]

        // Prompt Sample Array
        const prompt_fields_modify = values.prompt_fields ? values.prompt_fields.filter(elm => elm.file = ((fileBase64 && fileBase64.length > 0) ? fileBase64.filter(elmt => elmt.fileName === elm.file).map(elm => elm.prompt_repeat_image)[0] : [])) : null;

        const promptImage = fileBase64 ? fileBase64.map(elm => elm.prompt_image).filter(elm => elm ? elm : null) ? fileBase64.map(elm => elm.prompt_image).filter(elm => elm)[0] : null : null;

        const prompt_sample = [
            {
                file: promptImage,
                speaker: values.prompt_speaker_initial,
                duration: values.prompt_duration_initial,
                transcript: values.prompt_transcript_initial,
            },
            ...(prompt_fields_modify ? prompt_fields_modify : [])
        ]

        // Demographics Key-Value Pair
        const array_of_obj_demographics = values.dataset_demographic_fields ? Object.values(values.dataset_demographic_fields?.map(elm => {
            return {
                [elm.demographic_key_repeat]: elm.demographic_value_repeat
            }
        })) : null;

        const demographic_pairs = array_of_obj_demographics ? Object.assign({}, ...array_of_obj_demographics) : null;

        const demographics_details = [
            {
                [values.demographic_key_initial]: values.demographic_value_initial,
                ...(demographic_pairs ? demographic_pairs : {})
            }
        ]
        //End demographics Key-value pair  

        // Files Key-Value Pair
        const array_of_obj_file = values.audio_file_fields ? Object.values(values.audio_file_fields.map(elm => {
            return {
                [elm.audio_key_repeat]: elm.audio_value_repeat
            }
        })) : null;

        const audio_file_pairs = array_of_obj_file ? Object.assign({}, ...array_of_obj_file) : null;

        const file_details = [
            {
                [values.audio_key_initial]: values.audio_value_initial,
                ...(audio_file_pairs ? audio_file_pairs : {})
            }
        ]
        // End fil key-value pair

        const cardImage = fileBase64 ? fileBase64.map(elm => elm.card_image).filter(elm => elm ? elm : null) ? fileBase64.map(elm => elm.card_image).filter(elm => elm)[0] : null : null;

        const datasetImage = fileBase64 ? fileBase64.map(elm => elm.dataset_image).filter(elm => elm ? elm : null) ? fileBase64.map(elm => elm.dataset_image).filter(elm => elm)[0] : null : null;

        const data = {
            title: values.dataset_title,
            description: values.dataset_description,
            card_title: values.card_title,
            card_description: values.card_description,
            card_language_country: values.card_language_country,
            sub_category: values.dataset_sub_category,
            category_id: values.dataset_category,
            industry: values.dataset_industry,
            language: values.language,
            sample_description: values.sample_description,
            usecase: values.dataset_usecase?.split(',').map(elm => elm.trim() != '' && elm.trim()).filter(elm => elm),
            is_higher_priority: higherPriority,
            is_higher_priority_for_industry: higherPriorityIndustry,
            ready_to_deploy: readyToDeploy,
            total_volume: values.total_volume,
            last_update: values.last_update,
            number_of_participants: values.number_of_participants,
            dataset_category: category ? category.filter(elm => elm._id === values.dataset_category)[0]?.category_name : null,
            tag: values.tag,
            get_dataset_button: values.get_dataset_button,
            about_set: values.dataset_about,
            card_image: cardImage,
            file: datasetImage,
            demographics_details: demographics_details,
            file_details: file_details,
            dataset_type: parseInt(datasetType),
            dataset_audio_type: parseInt(audioType),
            sample: datasetType === 1 ? text_sample : datasetType === 3 ? image_sample : datasetType === 4 ? video_sample : (datasetType === 2 && audioType === 1) ? cc_sample : prompt_sample,
        }

        dispatch(addNewDataset(data))
        // console.log(data)
    };



    return (
        <div className="page-content">
            <Container fluid={true}>
                <Breadcrumbs title="Dataset" breadcrumbItem="Add Dataset" />

                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="h3 mb-4">Dataset Details</CardTitle>
                                <Form
                                    {...formItemLayout}
                                    form={form}
                                    name="register"
                                    onFinish={onFinish}
                                    scrollToFirstError
                                >
                                    <Form.Item
                                        name="dataset_title"
                                        label="Dataset Title"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="dataset_description"
                                        label="Dataset Description"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="card_title"
                                        label="Card Title"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="card_description"
                                        label="Card Description"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="card_language_country"
                                        label="Card Language (Country)"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="dataset_category"
                                        label="Dataset Category"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Select placeholder="select dataset category" style={{ width: "40%" }}>
                                            {category?.filter(elm => elm.is_deleted === false)?.map(elm =>
                                                <Option value={elm._id} key={elm._id}>{elm.category_name}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="dataset_sub_category"
                                        label="Dataset Sub-Category"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="total_volume"
                                        label="Total Volume"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="last_update"
                                        label="Last Updated"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="number_of_participants"
                                        label="No Of Participents"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="dataset_industry"
                                        label="Dataset Industry"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Select placeholder="select dataset industry" style={{ width: "40%" }}>
                                            {industry?.filter(elm => elm.is_deleted === false)?.map(elm =>
                                                <Option value={elm._id} key={elm._id}>{elm.industry_name}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="ready_to_deploy"
                                        label="Ready To Deploy"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <FormGroup check inline onChange={handleReadyToDeploy}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="ready-to-deploy" value={true} /> True
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline onChange={handleReadyToDeploy}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="ready-to-deploy" value={false} /> False
                                            </Label>
                                        </FormGroup>
                                    </Form.Item>

                                    <Form.Item
                                        name="is_higher_priority"
                                        label="is Higher Priority"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <FormGroup check inline onChange={handleHigherPriority}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="higher-p" value={true} /> True
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline onChange={handleHigherPriority}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="higher-p" value={false} /> False
                                            </Label>
                                        </FormGroup>
                                    </Form.Item>

                                    <Form.Item
                                        name="is_higher_priority_for_industry"
                                        label="is Higher Priority For Industry"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <FormGroup check inline onChange={handleHigherPriorityIndustry}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="higher-priority-industry" value={true} /> True
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline onChange={handleHigherPriorityIndustry}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="higher-priority-industry" value={false} /> False
                                            </Label>
                                        </FormGroup>
                                    </Form.Item>

                                    <Form.Item
                                        name="tag"
                                        label="Tags"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input.TextArea rows={4} />
                                    </Form.Item>

                                    <Form.Item
                                        name="language"
                                        label="Language"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Please select languages"
                                            style={{
                                                width: '40%',
                                            }}
                                        >
                                            {children}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="dataset_usecase"
                                        label="Dataset Usecase"
                                        tooltip="Use comma ' , ' separated values"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input placeholder='AI, Speech Recognition, ASR' />
                                    </Form.Item>

                                    <Form.Item
                                        name="dataset_about"
                                        label="Dataset About"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input.TextArea rows={4} />
                                    </Form.Item>

                                    <Form.Item
                                        name="get_dataset_button"
                                        label="Get Dataset Button Name"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="card_image"
                                        label="Card Image"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            onChange={(e) => FileUpload(e, 'card_image')}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="dataset_image"
                                        label="Dataset Image"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <Input
                                            className="form-control"
                                            type="file"
                                            id="formFile"
                                            onChange={(e) => FileUpload(e, 'dataset_image')}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="dataset_type"
                                        label="Dataset Type"
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'This field is required!',
                                    //     },
                                    // ]}
                                    >
                                        <FormGroup check inline onChange={handleDatasetType}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="datasetType" value={1} /> Text
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline onChange={handleDatasetType}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="datasetType" value={2} /> Audio
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline onChange={handleDatasetType}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="datasetType" value={3} /> Image
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline onChange={handleDatasetType}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="datasetType" value={4} /> Video
                                            </Label>
                                        </FormGroup>
                                    </Form.Item>

                                    <Form.Item className='audio-type d-none'
                                        label="Audio Type"
                                    >
                                        <FormGroup check inline onChange={handleAudioType}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="audioType" value={1} /> Conversation
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check inline onChange={handleAudioType}>
                                            <Label check inline="true">
                                                <ReactInput type="radio" name="audioType" value={2} /> Prompt
                                            </Label>
                                        </FormGroup>
                                    </Form.Item>

                                    <Form.Item className='text d-none'>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="text_file_initial"
                                            label="Text Sample"
                                            validateTrigger={["onChange", "onBlur"]}
                                        >
                                            <Input
                                                className="form-control"
                                                type="file"
                                                id="textFile"
                                                // name='text-file'
                                                onChange={(e) => FileUpload(e, 'text_image')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="text_file_tagging_initial"
                                            label="Tagging File"
                                        >
                                            <Input
                                                className="form-control"
                                                type="file"
                                                id="textFileTag"
                                                // name='text-file-tag'
                                                onChange={(e) => FileUpload(e, 'text_tag_image')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="text_heading_initial"
                                            label="Heading"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.List name="text_fields">
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <div key={field.key}>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'text_sample']}
                                                                >
                                                                    <Input
                                                                        className="form-control"
                                                                        type="file"
                                                                        id="formtextFile"
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        onChange={(e) => FileUpload(e, 'text_repeat_file')}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'tagging']}
                                                                >
                                                                    <Input
                                                                        className="form-control"
                                                                        type="file"
                                                                        id="formtexttagFile"
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        onChange={(e) => FileUpload(e, 'text_repeat_tag_file')}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'heading']}
                                                                >
                                                                    <Input
                                                                        placeholder='Heading'
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(field.name)}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    ))}
                                                    <Form.Item
                                                        {...{
                                                            wrapperCol: {
                                                                span: 24,
                                                                offset: 6
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{
                                                                width: "60%",
                                                            }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add More Fields
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>

                                    <Form.Item className='cc d-none'>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="cc_file_initial"
                                            label="File"
                                        >
                                            <Input
                                                className="form-control"
                                                type="file"
                                                id="formFile"
                                                name='cc-file'
                                                onChange={(e) => FileUpload(e, 'cc_image')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="cc_channel1_initial"
                                            label="Channel 1"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="cc_channel2_initial"
                                            label="Channel 2"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="cc_format_initial"
                                            label="Format"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="cc_transcript_initial"
                                            label="Transcript"
                                        >
                                            <Input.TextArea rows={12} />
                                        </Form.Item>
                                        {/* <Form.List name="cc_transcript_fields">
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, index) => (
                                                        <Form.Item
                                                            {...{
                                                                wrapperCol: {
                                                                    span: 24,
                                                                    offset: 6
                                                                },
                                                            }}
                                                            required={false}
                                                            key={field.key}
                                                        >
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                {...field}
                                                                validateTrigger={["onChange", "onBlur"]}
                                                                noStyle
                                                            >
                                                                <Input.TextArea
                                                                    style={{
                                                                        width: "90%",
                                                                    }}
                                                                />
                                                            </Form.Item>

                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                onClick={() => remove(field.name)}
                                                            />
                                                        </Form.Item>
                                                    ))}
                                                    <Form.Item
                                                        {...{
                                                            wrapperCol: {
                                                                span: 24,
                                                                offset: 6
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{
                                                                width: "60%",
                                                            }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add Transcript
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List> */}
                                    </Form.Item>

                                    <Form.Item className='prompt d-none'>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="sample_description"
                                            label="Sample Description"
                                        >
                                            <Input.TextArea
                                                rows={4}
                                                className="form-control"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="prompt_file_initial"
                                            label="File"
                                        >
                                            <Input
                                                className="form-control"
                                                type="file"
                                                id="formFile"
                                                name='prompt-file'
                                                onChange={(e) => FileUpload(e, 'prompt_image')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="prompt_speaker_initial"
                                            label="Speaker"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="prompt_duration_initial"
                                            label="Duration"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="prompt_transcript_initial"
                                            label="Transcript"
                                        >
                                            <Input.TextArea />
                                        </Form.Item>

                                        <Form.List name="prompt_fields">
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <div key={field.key}>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'file']}
                                                                >
                                                                    <Input
                                                                        className="form-control"
                                                                        type="file"
                                                                        id="formFile"
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        onChange={(e) => FileUpload(e, 'prompt_repeat_image')}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'speaker']}
                                                                >
                                                                    <Input
                                                                        placeholder='Speaker'
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'duration']}
                                                                >
                                                                    <Input
                                                                        placeholder='Duration'
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'transcript']}
                                                                >
                                                                    <Input.TextArea
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        placeholder='Transcript'
                                                                    />
                                                                </Form.Item>

                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(field.name)}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    ))}
                                                    <Form.Item
                                                        {...{
                                                            wrapperCol: {
                                                                span: 24,
                                                                offset: 6
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{
                                                                width: "60%",
                                                            }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add More Fields
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>

                                    <Form.Item className='image-type d-none'>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="image_type_file_initial"
                                            label="Image File"
                                        >
                                            <Input
                                                className="form-control"
                                                type="file"
                                                id="formFileimagetype"
                                                // name='image-file'
                                                onChange={(e) => FileUpload(e, 'image_type_file')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="image_gender_initial"
                                            label="Gender"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="image_gesture_initial"
                                            label="Gesture"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="image_ethnicity_initial"
                                            label="Ethnicity"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="image_accessary_initial"
                                            label="Accessary"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.List name="image_fields">
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <div key={field.key}>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'image_file']}
                                                                >
                                                                    <Input
                                                                        className="form-control"
                                                                        type="file"
                                                                        id="formFilerepeatimage"
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        onChange={(e) => FileUpload(e, 'image_repeat_file')}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'gender']}
                                                                >
                                                                    <Input
                                                                        placeholder='Gender'
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'gesture']}
                                                                >
                                                                    <Input
                                                                        placeholder='Gesture'
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'ethnicity']}
                                                                >
                                                                    <Input
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        placeholder='Ethnicity'
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'accessary']}
                                                                >
                                                                    <Input
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        placeholder='Accessary'
                                                                    />
                                                                </Form.Item>

                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(field.name)}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    ))}
                                                    <Form.Item
                                                        {...{
                                                            wrapperCol: {
                                                                span: 24,
                                                                offset: 6
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{
                                                                width: "60%",
                                                            }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add More Fields
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>

                                    <Form.Item className='video d-none'>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="thumbnail_file_initial"
                                            label="Image File"
                                        >
                                            <Input
                                                className="form-control"
                                                type="file"
                                                id="formFileThumbnail"
                                                // name='image-file'
                                                onChange={(e) => FileUpload(e, 'thumbnail_file')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="video_file_initial"
                                            label="Video File"
                                        >
                                            <Input
                                                className="form-control"
                                                type="file"
                                                id="formFileVideo"
                                                // name='video-file'
                                                onChange={(e) => FileUpload(e, 'video_file')}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="video_channel1_initial"
                                            label="Channel 1"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="video_channel2_initial"
                                            label="Channel 2"
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...formItemInnerLayout}
                                            name="video_accent_initial"
                                            label="Accent"
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Form.List name="video_fields">
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <div key={field.key}>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'image']}
                                                                >
                                                                    <Input
                                                                        className="form-control"
                                                                        type="file"
                                                                        id="formVideoImageFile"
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        onChange={(e) => FileUpload(e, 'video_repeat_image_file')}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'v_file']}
                                                                >
                                                                    <Input
                                                                        className="form-control"
                                                                        type="file"
                                                                        id="formFilevideorepeat"
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        onChange={(e) => FileUpload(e, 'video_repeat_file')}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'channel_1']}
                                                                >
                                                                    <Input
                                                                        placeholder='Channel 1'
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'channel_2']}
                                                                >
                                                                    <Input
                                                                        placeholder='Channel 2'
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...{
                                                                    wrapperCol: {
                                                                        span: 24,
                                                                        offset: 6
                                                                    },
                                                                }}
                                                                required={false}
                                                            >
                                                                <Form.Item
                                                                    {...{
                                                                        wrapperCol: {
                                                                            span: 24,
                                                                            offset: 6
                                                                        },
                                                                    }}
                                                                    {...field}
                                                                    validateTrigger={["onChange", "onBlur"]}
                                                                    noStyle
                                                                    name={[name, 'accent']}
                                                                >
                                                                    <Input
                                                                        style={{
                                                                            width: "90%",
                                                                        }}
                                                                        placeholder='Accent'
                                                                    />
                                                                </Form.Item>
                                                                <MinusCircleOutlined
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => remove(field.name)}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    ))}
                                                    <Form.Item
                                                        {...{
                                                            wrapperCol: {
                                                                span: 24,
                                                                offset: 6
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{
                                                                width: "60%",
                                                            }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add More Fields
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>

                                    <Form.Item label='Audio File Details'></Form.Item>

                                    <Form.Item className='audio-file-details'>
                                        <AntdRow>
                                            <AntdCol sm={11}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {
                                                            span: 6,
                                                            offset: 7
                                                        },
                                                        wrapperCol: {
                                                            span: 24,

                                                        },
                                                    }}
                                                    name="audio_key_initial"
                                                    label="key"
                                                >
                                                    <Select placeholder="select key">
                                                        <Option value="Environment" key={1}>Environment</Option>
                                                        <Option value="Bit Depth" key={2}>Bit Depth</Option>
                                                        <Option value="Format" key={3}>Format</Option>
                                                        <Option value="Sample Rate" key={4}>Sample Rate</Option>
                                                        <Option value="channels" key={5}>channels</Option>
                                                        <Option value="Audio file duration" key={6}>Audio file duration</Option>
                                                    </Select>
                                                </Form.Item>
                                            </AntdCol>
                                            <AntdCol sm={11}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {
                                                            span: 5,
                                                            offset: 1
                                                        },
                                                        wrapperCol: {
                                                            span: 13,

                                                        },
                                                    }}
                                                    name="audio_value_initial"
                                                    label="Value"
                                                >
                                                    <Input
                                                        placeholder='value' />
                                                </Form.Item>
                                            </AntdCol>
                                        </AntdRow>
                                        <Form.List name="audio_file_fields">
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <div key={field.key}>
                                                            <AntdRow>
                                                                <AntdCol sm={11}>
                                                                    <Form.Item
                                                                        {...{
                                                                            labelCol: {
                                                                                span: 6,
                                                                                offset: 7
                                                                            },
                                                                            wrapperCol: {
                                                                                span: 24,

                                                                            },
                                                                        }}
                                                                        label='Key'
                                                                        required={false}
                                                                    >
                                                                        <Form.Item
                                                                            {...{
                                                                                labelCol: {
                                                                                    span: 7,
                                                                                    offset: 5
                                                                                },
                                                                                wrapperCol: {
                                                                                    span: 24,

                                                                                },
                                                                            }}
                                                                            {...field}
                                                                            validateTrigger={["onChange", "onBlur"]}
                                                                            noStyle
                                                                            name={[name, 'audio_key_repeat']}
                                                                        >
                                                                            <Select placeholder="select key">
                                                                                <Option value="Environment" key={1}>Environment</Option>
                                                                                <Option value="Bit Depth" key={2}>Bit Depth</Option>
                                                                                <Option value="Format" key={3}>Format</Option>
                                                                                <Option value="Sample Rate" key={4}>Sample Rate</Option>
                                                                                <Option value="channels" key={5}>channels</Option>
                                                                                <Option value="Audio file duration" key={6}>Audio file duration</Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Form.Item>
                                                                </AntdCol>
                                                                <AntdCol sm={11}>
                                                                    <Form.Item
                                                                        {...{
                                                                            labelCol: {
                                                                                span: 4,
                                                                                offset: 2
                                                                            },
                                                                            wrapperCol: {
                                                                                span: 13,

                                                                            },
                                                                        }}
                                                                        label='Value'
                                                                        required={false}
                                                                    >
                                                                        <Form.Item
                                                                            {...{
                                                                                labelCol: {
                                                                                    span: 4,
                                                                                    offset: 1
                                                                                },
                                                                                wrapperCol: {
                                                                                    span: 13,

                                                                                },
                                                                            }}
                                                                            {...field}
                                                                            name={[name, 'audio_value_repeat']}
                                                                            validateTrigger={["onChange", "onBlur"]}
                                                                            noStyle
                                                                        >
                                                                            <Input
                                                                                placeholder='value'
                                                                                className="form-control"
                                                                            />
                                                                        </Form.Item>
                                                                    </Form.Item>
                                                                </AntdCol>
                                                                <AntdCol sm={2} className="d-flex">
                                                                    <PlusCircleOutlined
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => add()}
                                                                    />
                                                                    <MinusCircleOutlined
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => {
                                                                            remove(field.name);
                                                                        }}
                                                                    /></AntdCol>
                                                            </AntdRow>
                                                        </div>
                                                    ))}
                                                    <Form.Item
                                                        {...{
                                                            wrapperCol: {
                                                                span: 24,
                                                                offset: 6
                                                            },
                                                        }}
                                                        className='first-add'
                                                    >
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => {
                                                                add();
                                                                // document.querySelector('.first-add').style.display = 'none';
                                                            }}
                                                            style={{
                                                                width: "60%",
                                                            }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add More Fields
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>

                                    <Form.Item label='Dataset Demographics'></Form.Item>

                                    <Form.Item className='dataset-demographics'>
                                        <AntdRow>
                                            <AntdCol sm={11}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {
                                                            span: 6,
                                                            offset: 7
                                                        },
                                                        wrapperCol: {
                                                            span: 24,

                                                        },
                                                    }}
                                                    name="demographic_key_initial"
                                                    label="key"
                                                >
                                                    <Select placeholder="select key">
                                                        <Option value="Language" key={1}>Language</Option>
                                                        <Option value="Language Code" key={2}>Language Code</Option>
                                                        <Option value="Country" key={3}>Country</Option>
                                                        <Option value="Age Group" key={4}>Age Group</Option>
                                                        <Option value="Gender Distribution" key={5}>Gender Distribution</Option>
                                                        <Option value="Accent" key={6}>Accent</Option>
                                                    </Select>
                                                </Form.Item>
                                            </AntdCol>
                                            <AntdCol sm={11}>
                                                <Form.Item
                                                    {...{
                                                        labelCol: {
                                                            span: 5,
                                                            offset: 1
                                                        },
                                                        wrapperCol: {
                                                            span: 13,

                                                        },
                                                    }}
                                                    name="demographic_value_initial"
                                                    label="Value"
                                                >
                                                    <Input
                                                        placeholder='value' />
                                                </Form.Item>
                                            </AntdCol>
                                        </AntdRow>
                                        <Form.List name="dataset_demographic_fields">
                                            {(fields, { add, remove }, { errors }) => (
                                                <>
                                                    {fields.map((field, name, index) => (
                                                        <div key={field.key}>
                                                            <AntdRow>
                                                                <AntdCol sm={11}>
                                                                    <Form.Item
                                                                        {...{
                                                                            labelCol: {
                                                                                span: 6,
                                                                                offset: 7
                                                                            },
                                                                            wrapperCol: {
                                                                                span: 24,

                                                                            },
                                                                        }}
                                                                        label='Key'
                                                                        required={false}
                                                                    >
                                                                        <Form.Item
                                                                            {...{
                                                                                labelCol: {
                                                                                    span: 7,
                                                                                    offset: 5
                                                                                },
                                                                                wrapperCol: {
                                                                                    span: 24,

                                                                                },
                                                                            }}
                                                                            {...field}
                                                                            validateTrigger={["onChange", "onBlur"]}
                                                                            noStyle
                                                                            name={[name, 'demographic_key_repeat']}
                                                                        >
                                                                            <Select placeholder="select key">
                                                                                <Option value="Language" key={1}>Language</Option>
                                                                                <Option value="Language Code" key={2}>Language Code</Option>
                                                                                <Option value="Country" key={3}>Country</Option>
                                                                                <Option value="Age Group" key={4}>Age Group</Option>
                                                                                <Option value="Gender Distribution" key={5}>Gender Distribution</Option>
                                                                                <Option value="Accent" key={6}>Accent</Option>
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Form.Item>
                                                                </AntdCol>
                                                                <AntdCol sm={11}>
                                                                    <Form.Item
                                                                        {...{
                                                                            labelCol: {
                                                                                span: 4,
                                                                                offset: 2
                                                                            },
                                                                            wrapperCol: {
                                                                                span: 13,

                                                                            },
                                                                        }}
                                                                        label='Value'
                                                                        required={false}
                                                                    >
                                                                        <Form.Item
                                                                            {...{
                                                                                labelCol: {
                                                                                    span: 4,
                                                                                    offset: 1
                                                                                },
                                                                                wrapperCol: {
                                                                                    span: 13,

                                                                                },
                                                                            }}
                                                                            {...field}
                                                                            validateTrigger={["onChange", "onBlur"]}
                                                                            noStyle
                                                                            name={[name, 'demographic_value_repeat']}
                                                                        >
                                                                            <Input
                                                                                placeholder='value'
                                                                            />
                                                                        </Form.Item>
                                                                    </Form.Item>
                                                                </AntdCol>
                                                                <AntdCol sm={2} className="d-flex">
                                                                    <PlusCircleOutlined
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => add()}
                                                                    />
                                                                    <MinusCircleOutlined
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => {
                                                                            remove(field.name);
                                                                        }}
                                                                    /></AntdCol>
                                                            </AntdRow>
                                                        </div>
                                                    ))}
                                                    <Form.Item
                                                        {...{
                                                            wrapperCol: {
                                                                span: 24,
                                                                offset: 6
                                                            },
                                                        }}
                                                        className='first-add'
                                                    >
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => {
                                                                add();
                                                                // document.querySelector('.first-add').style.display = 'none';
                                                            }}
                                                            style={{
                                                                width: "60%",
                                                            }}
                                                            icon={<PlusOutlined />}
                                                        >
                                                            Add More Fields
                                                        </Button>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </Form.List>
                                    </Form.Item>

                                    <Form.Item {...tailFormItemLayout}>
                                        <Button type="primary" htmlType="submit">
                                            Add Dataset
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AddDataset
