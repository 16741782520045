import React, { useState, useEffect } from "react"
import {
  Card,
  Col,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Badge,

  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux"
import { getAllBlogs } from "../../../store/actions"
import classnames from "classnames"
import img1 from "./BlogImage.jpg"

import { Link } from "react-router-dom"

const BlogGrid = (props) => {


  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [data1, setData1] = useState()
  const [data2, setData2] = useState()
  const [data3, setData3] = useState()
  const [data4, setData4] = useState()


  let changeData1 = (e) => {
    setData1(e.target.value)
  }
  let changeData2 = (e) => {
    setData2(e.target.value)
  }
  let changeData3 = (e) => {
    setData3(e.target.value)
  }
  let changeData4 = (e) => {
    setData4(e.target.value)
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      // setProject(null);
    } else {
      setModal(true);
    }
  };

  // get Data of Blog
  const dispatch = useDispatch()


  const { getBlogResponse, getBlogError } = useSelector(state => ({
    getBlogResponse: state.Blog.blogg,
    getBlogError: state.Blog
  }))


  useEffect(() => {
    dispatch(getAllBlogs())
  }, []);

  useEffect(() => {
    // console.log(getBlogResponse);
    // console.log(getBlogError);
  }, [getBlogResponse, getBlogError]);

  const handleProjectClick = arg => {
    const project = arg;

    setProject({
      // id: project.id,
      // img: project.img,
      // name: project.name,
      // description: project.description,
      // status: project.status,
      // color: project.color,
      // dueDate: project.dueDate,
      // team: project.team,
    });

    setIsEdit(true);

    toggle();
  };



  return (
    <React.Fragment>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} tag="h4">
            {!!isEdit ? "Edit Blog" : "Edit Blog"}
          </ModalHeader>
          <ModalBody>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                // validation.handleSubmit();
                return false;
              }}
            >
              <Row form>
                <Col xs={12}>
                  <div className="d-flex flex-column">
                    <label htmlFor="data1">data1</label>
                    <input
                      onChange={changeData1}
                      id="data1"
                      type="text"
                      value={data1}
                      name="team"
                    />
                    <label htmlFor="data2">data2</label>

                    <input
                      onChange={changeData2}
                      id="data2"
                      type="text"
                      value={data2}
                      name="img"
                    />
                    <label htmlFor="data3">data3</label>

                    <input
                      onChange={changeData3}
                      id="data3"
                      type="text"
                      value={data3}
                      name="img"
                    />
                    <label htmlFor="data4">data4</label>

                    <input
                      onChange={changeData4}
                      id="data4"
                      type="text"
                      value={data4}
                      name="img"
                    />
                  </div>
                  {/* <div className="mb-3">
                    <Label className="form-label">Name</Label>
                    <Input
                      name="name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={
                        validation.touched.name && validation.errors.name ? true : false
                      }
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Description</Label>
                    <Input
                      name="description"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.description || ""}
                      invalid={
                        validation.touched.description && validation.errors.description ? true : false
                      }
                    />
                    {validation.touched.description && validation.errors.description ? (
                      <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <Input
                      name="status"
                      id="status1"
                      type="select"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={
                        validation.values.status || ""
                      }
                    >
                      <option>Completed</option>
                      <option>Pending</option>
                      <option>Delay</option>
                    </Input>
                    {validation.touched.status && validation.errors.status ? (
                      <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Color</Label>
                    <Input
                      name="color"
                      type="select"
                      className="form-select"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.color || ""}
                      invalid={
                        validation.touched.color && validation.errors.color ? true : false
                      }
                    >
                      <option>success</option>
                      <option>warning</option>
                      <option>danger</option>
                    </Input>
                    {validation.touched.color && validation.errors.color ? (
                      <FormFeedback type="invalid">{validation.errors.color}</FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">dueDate</Label>
                    <Input
                      name="dueDate"
                      type="date"
                      format="YYYY/MM/DD"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.dueDate && validation.errors.dueDate ? true : false
                      }
                      value={validation.values.dueDate || ""}
                    ></Input>
                    {validation.touched.dueDate && validation.errors.dueDate ? (
                      <FormFeedback type="invalid">{validation.errors.dueDate}</FormFeedback>
                    ) : null}
                  </div> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-end">
                    <button
                      type="submit"
                      className="my-2  btn btn-success save-user"
                    >
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>

        {getBlogResponse.length > 0 && getBlogResponse?.map((x) => <Card key={x._id} className="p-1 border shadow-none" style={{ background: "#283593", color: "#F5EDDC", borderRadius: "15px", border: "10px solid black", width: "250px" }} >
          <div className="p-3">
            <h5 style={{ color: "#F5EDDC", }}>
              {x.heading}
            </h5>

          </div>

          <div className="position-relative" style={{ borderRadius: "8px" }}>
            <img style={{ borderRadius: "8px", }}
              src={x.image}
              alt=""
              className="img-thumbnail"
            />
          </div>
          <div className="p-3">
            <ul className="list-inline">
              {/* <li className="list-inline-item me-3">
                <Link to="#" className="text-muted">
                  <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                  Project
                </Link>
              </li>
              <li className="list-inline-item me-3">
                <Link to="#" className="text-muted">
                  <i className="bx bx-comment-dots align-middle text-muted me-1"></i>{" "}
                  12 Comments
                </Link>
              </li> */}
            </ul>
            <p>
              {x.title}
            </p>
            <p className=" mb-0"> {x.date}</p>

            <button type="button" style={{ color: "white", background: "black" }} onClick={toggle} className=" btn btn-light float-right  light my-2 ">Edit Data</button>
          </div>
        </Card>)}

      </div>

    </React.Fragment>
  )
}
export default BlogGrid
