import React from "react";
import { Redirect } from "react-router-dom";

// Profile
// import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import addDataset from "../pages/Dataset/Add_Dataset";
//import Dataset from "../pages/Dataset/adddataset"
import ContactsList from "../pages/Dataset/dataset_list";
// import Category from "../pages/Category/addCategory"
// import Industry from "../pages/Industry/addIndustry"

// This is used for blog ......................
import CreateBlog from "pages/Blog/CreateBlog";
import BlogList from "pages/Blog/BlogList";

// CRM Section
import CrmDashboard from "../pages/CRM/CrmDashboard";
import Deals from "../pages/CRM/Deals";
import ToDo from "../pages/CRM/ToDo";
import ViewDeal from "../pages/CRM/ViewDeal";

import datasetList from "../pages/Dataset/dataset_list";
import addDatasetWithJson from "../pages/Dataset/add-dataset-with-json";
import Dataset_Edit from "../pages/Dataset/editdataset";
// import Dataset from "store/datastore/reducer";
import GetDataById from "../pages/Dataset/GetDataById";

//section data
import AllDataTypeData from "../pages/Dataset/AllDataTypeData";
import NLP from "../pages/Dataset/Nlp";
import ComputerVision from "../pages/Dataset/ComputerVision";
import Ocr from "../pages/Dataset/Ocr";
import SpeechRecognition from "../pages/Dataset/SpeechRecognition";
import GenerativeAi from "../pages/Dataset/GenerativeAi";
// import DragDrop from "../pages/Dataset/DragDrop"

// Industries section..........................................
import All_Indisries from "pages/industries/all_Industries";
import RetailAndEcommerce from "pages/industries/RetailAndE-commerce";
import AR_VR from "pages/industries/AR_VR";
import AutonomousVehicle from "pages/industries/AutonomousVehicle";
import RealEstate from "pages/industries/RealEstate";
import HealthCare from "pages/industries/HealthCare";
import SafetyAndSurveillance from "pages/industries/SafetyAndSurveillance";
import Travel from "pages/industries/Travel";
import Telecom from "pages/industries/Telecom";
import BankingAndfinance from "pages/industries/BankingAndfinance";


// ContactUs , DatasetRequest , Subscriber
import ContactUs from "pages/Contact Us/ContactUs";
import Subscriber from "pages/Subscriber/Subscriber";
import DatasetRequest from "pages/Dataset Request/DatasetRequest";


//Freelancer and vendor
import FreelancerAndVendor from "pages/FreelancerAndVendor/FreelancerAndVendor";

//Get a call 
import GetCall from "pages/GetACall/GetCall";

// sell data 
import SellData from "pages/SellData/Selldata";

// case studies
import caseStudies from "pages/caseStudies/caseStudies";

// yugo
import yugo from "pages/yugo/yugo";

// get   all community request 
import CommunityReq from "../pages/Get_all_community_request/GetAllCommunity";
const authProtectedRoutes = [

  { path: "/contact-us", component: ContactUs },
  { path: "/subscriber", component: Subscriber },
  { path: "/dataset-request", component: DatasetRequest },

  // Industries  section ......................................
  { path: "/all-industries", component: All_Indisries },
  { path: "/retail-and-ecommerce", component: RetailAndEcommerce },
  { path: "/ar-vr", component: AR_VR },
  { path: "/autonomous-vehicle", component: AutonomousVehicle },
  { path: "/real-estate", component: RealEstate },
  { path: "/health-care", component: HealthCare },
  { path: "/safety-and-surveillance", component: SafetyAndSurveillance },
  { path: "/travel", component: Travel },
  { path: "/telecom", component: Telecom },
  { path: "/banking-and-finance", component: BankingAndfinance },

  // CRM section
  { path: "/crm/dashboard", component: CrmDashboard },
  { path: "/crm/deals", component: Deals },
  { path: "/crm/to-do", component: ToDo },
  { path: "/crm/view-deal/:id", component: ViewDeal },

  //Dataset
  { path: "/add_dataset", component: addDatasetWithJson },
  { path: "/list_dataset", component: datasetList },
  { path: "/dashboard", component: Dashboard },
  { path: "/edit-dataset", component: Dataset_Edit },

  //Blog
  { path: "/add_blog", component: CreateBlog },
  { path: "/blog_list", component: BlogList },

  //filter data 
  { path: "/all-types-data", component: AllDataTypeData },
  { path: "/speech-recognition", component: SpeechRecognition },
  { path: "/nlp", component: NLP },
  { path: "/computer-vision", component: ComputerVision },
  { path: "/ocr", component: Ocr },
  { path: "/generative-ai", component: GenerativeAi },

  //freelancer and vendor
  { path: "/freelancer-vendor", component: FreelancerAndVendor },

  // get a call 
  { path: "/get-a-call", component: GetCall },
  { path: "/sell-data", component: SellData },
  { path: "/case-studies", component: caseStudies },
  { path: "/yugo", component: yugo },
  { path: "/community-request", component: CommunityReq },

  { path: `/getDataSetById/:_id`, component: GetDataById },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/admin_login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  // { path: "/admin_login", component: Login },
];

export { publicRoutes, authProtectedRoutes };
