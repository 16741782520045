
import React from "react"
import {
  Container,
} from "reactstrap"

const CrmDashboard = () => {
  document.title = "CRM-Dashboard | FeatureBeeAi";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4></h4>
          <h2 className="main-heading ">
            <span>
              <span>
                Dashboard
              </span>
            </span>
          </h2>
        </Container>
        
      </div>
    </React.Fragment>
  );
}

export default CrmDashboard;
