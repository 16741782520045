import {
  GET_SUBSCRIBER,
  GET_SUBSCRIBER_SUCCESS,
  GET_SUBSCRIBER_FAIL
} from "./actionTypes"

export const getSubscriber = () => ({
  type: GET_SUBSCRIBER,
})

export const getSubscriberSuccess = users => (
  {
    type: GET_SUBSCRIBER_SUCCESS,
    payload: users,
  })


export const getSubscriberrofile = () => ({
  type: GET_SUBSCRIBER_FAIL,
})
