import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDeal, get_all_community } from 'store/actions';
import "./getallcommunity.css";
import "rsuite/dist/rsuite.css";
import { Drawer, ButtonToolbar } from 'rsuite';
import { Col, Container, Row, Table, Button } from 'reactstrap';
import { Modal, Loader } from 'rsuite';
import { initialLifeCycle } from 'pages/Dataset Request/DatasetRequest';
import { formatDDMMYYYY } from 'components/Common/UtilitiesFunctions';
import { message } from 'antd';


function GetAllCommunity () {
    const [data, setdata] = useState("");
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState('');
    const [Loaderr, setLoaderr] = useState(true);
    const [RecordsNotAvalable, setRecordsNotAvalable] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [messageSuccess, setMessageSuccess] = useState(false);

    const [openn, setOpenn] = React.useState(false);
    const [backdrop, setBackdrop] = React.useState('static');
    const [messagePara, setmessagePara] = useState("-");

    let { responseData, deals } = useSelector((state) => ({
        responseData: state.getCommunity.data,
        deals: state.DealsReducer.deals,
    }));

    useEffect(() => {
        if (deals?.success && messageSuccess) {
            message.success(deals?.message);
            selectedRows.forEach((id) => {
                let data = [{ url: id }, { move_to_deal: true }];
                // dispatch(addCommentDataSetReq(data));
                document.querySelector(`#row-${id}`).checked = false;
            });
            document.querySelector(`#all-row-select`).checked = false;
            setMessageSuccess(false);
            // Remove selection of all the rows
            setSelectedRows([]);
        }
    }, [deals]);

    const handleOpen = (e) => {
        console.log(responseData);
        for (let ii = 0; ii < responseData.result.length; ii++) {
            if (e.target.id == (ii + 2)) {
                setOpenn(true);
                setmessagePara(responseData.result[ii].message);
            }
        }
    };

    const handleClose = () => setOpenn(false);

    const dispatch = useDispatch();
    const styles = {
        radioGroupLabel: {
            padding: '8px 12px',
            display: 'inline-block',
            verticalAlign: 'middle'
        }
    };

    useEffect(() => {
        dispatch(get_all_community());
    }, []);

    useEffect(() => {
        if (responseData.success) {
            setLoaderr(false);
            setdata(responseData);
            setRecordsNotAvalable(false);
            localStorage.setItem("CommunityNewMessage", responseData.result?.length);
        }
        if (responseData.success == false) {
            setLoaderr(false);
            setRecordsNotAvalable(true);
            localStorage.setItem("CommunityNewMessage", responseData.result?.length);
        }
    }, [responseData]);

    let i = 1;

    let openModalFunc = (e) => {

        for (let ii = 0; ii < responseData.result.length; ii++) {
            if (e.target.id == (ii + 2)) {
                // console.log(responseData.result[ii].project_flow[0])
                setModalData(Object.entries(responseData.result[ii].project_flow[0]));
                setOpen(true);
            }
        }
    };

    const changeRowSelection = (e, id) => {
        if (e.target.checked) {
            if (id === 'all') {
                const selectedRowsList = data.result.map(elm => !elm?.move_to_deal ? elm._id : false).filter(elm => elm);
                setSelectedRows(selectedRowsList);
                selectedRowsList.forEach(elm => {
                    document.querySelector(`#row-${elm}`).checked = true;
                });
            } else {
                if ([...selectedRows, id].length === data.result.length) {
                    document.querySelector(`#all-row-select`).checked = true;
                }
                setSelectedRows(prev => [...prev, id]);
            }
        } else {
            if (id === 'all') {
                setSelectedRows([]);
                data.result.forEach(elm => {
                    document.querySelector(`#row-${elm._id}`).checked = false;
                });
            } else {
                if (selectedRows.filter(elm => elm !== id).length !== data.result.length && document.querySelector(`#all-row-select`).checked) {
                    document.querySelector(`#all-row-select`).checked = false;
                }
                setSelectedRows(prev => [...prev.filter(elm => elm !== id)]);
            }
        }
    };

    const MoveToDeal = () => {
        let dealsData = [];
        for (let index = 0; index < selectedRows.length; index++) {
            const id = selectedRows[index];
            dealsData.push(...data.result.map(elm => {
                if (elm._id === id) {
                    return {
                        "owner": JSON.parse(localStorage.getItem('authUser') || '{"result":[{"name":""}]}')?.result[0]?.name || '',
                        "client_name": elm.name,
                        "email": elm.email,
                        "request_date": new Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric'
                        }).format(new Date(elm?.createdAt)),
                        "from_where": elm.form_fill,
                        "message": elm.message,
                        "dataset_type": "",
                        "dataset_sub_type": '',
                        "language": [],
                        "industry": '',
                        "demography": [],
                        "type": [],
                        "sample_link": '',
                        "page_link": elm.page_url,
                        "dataset_name": elm.title,
                        "company_name": elm.company_name,
                        "volume": elm.volume,
                        "purpose": elm.purpose,
                        "budget": elm.tentative_budget,
                        "service": "",
                        "lifecycle": initialLifeCycle,
                        "client_type": "",
                        "last_activity": '',
                        "update_date": formatDDMMYYYY(new Date()),
                        "deal_status": 1,
                        "notes": elm?.comment ? elm.comment : "",
                        "self_created": false,
                        "deal_from": 4,
                        "deal_from_id": elm?._id,
                    };
                } else {
                    return false;
                }
            }).filter(elm => elm));
        }
        const deals = {
            deals: dealsData
        };
        dispatch(addDeal(deals));
        setMessageSuccess(true);
    };

    let ii = 1;

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box pb-2 d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18">Community</h4>
                                <Button color='primary' disabled={selectedRows.length <= 0} onClick={MoveToDeal}>Move to deal</Button>
                            </div>
                        </Col>
                    </Row>
                    <div className='get-all-community-container'>
                        <Table bordered responsive hover className='mb-0 table-primary' >
                            <thead>
                                <tr className='positionstick'>
                                    <th className="border-0 text-center font-medium  bgdark   text-nowrap"><input type="checkbox" className='form-check-input mt-1' id='all-row-select' onChange={(e) => changeRowSelection(e, 'all')} /></th>
                                    <th className='text-nowrap bgdark'>No.</th>
                                    <th className='text-nowrap bgdark'>Name</th>
                                    <th className='text-nowrap bgdark'>Email</th>
                                    <th className='text-nowrap bgdark'>Project Flow</th>
                                    <th className='text-nowrap bgdark'>Message</th>
                                    <th className='text-nowrap bgdark'>Date</th>
                                </tr>

                            </thead>
                            {
                                RecordsNotAvalable ? <tr className='border-0 w-100 records-not-class'>
                                    <td colSpan={7} className='text-center bg-transparent text-primary fs-4 border-0'>
                                        Records are not available!
                                    </td>
                                </tr> : null

                            }
                            <tbody>
                                <tr>
                                    {
                                        Loaderr ? <td colSpan={7}><div className="  loaderposition">
                                            <Loader size="lg" className='' />
                                        </div></td> : null
                                    }
                                </tr>

                                {data.success && data.result.map((x) => <tr key={i} className=' ' style={x?.move_to_deal ? { backgroundColor: "#a0e2c4" } : {}}>
                                    <td className={x?.move_to_deal ? 'bg-transparent text-center' : 'text-center'}>
                                        <input type="checkbox" id={`row-${x._id}`} className='form-check-input mt-1' onChange={(e) => changeRowSelection(e, x._id)} disabled={x?.move_to_deal} />
                                    </td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>
                                        {i++}
                                    </td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>
                                        {x.name ? x.name : '-'}
                                    </td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>
                                        {x.email ? x.email : "-"}
                                    </td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>
                                        {x.project_flow && <ButtonToolbar>
                                            <Button className="bg-primary text-light " id={i} onClick={openModalFunc}>Show</Button>
                                        </ButtonToolbar>}
                                    </td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>
                                        <div className='d-flex'>
                                            <span className=''>
                                                {x.message && x.message.length > 0 ? <ButtonToolbar>
                                                    <Button className='bg-primary text-light' id={i} onClick={handleOpen}> Read</Button>
                                                </ButtonToolbar> : '-'}
                                            </span>
                                        </div>
                                    </td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>
                                        {x.createdAt ? x.createdAt.split("T")[0].split("-").reverse().join("/") : '-'}
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>

                    <Modal backdrop={backdrop} keyboard={false} open={openn} onClose={handleClose}>
                        <Modal.Header>
                            <Modal.Title>Message </Modal.Title>
                        </Modal.Header>
                        <hr />
                        <Modal.Body>
                            <p>
                                {messagePara}
                            </p>
                        </Modal.Body>
                        <hr />
                        <Modal.Footer>
                            <Button onClick={handleClose} appearance="subtle" className='btn btn-primary'>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {
                        modalData && <Drawer id="modal" open={open} onClose={() => setOpen(false)}>
                            <Drawer.Body>
                                {/* {console.log(modalData)} */}
                                <div className="table-responsive">
                                    <table className="table
                        table-hover	
                        table-borderless
                        table-primary
                        align-middle">
                                        <thead className="table-light">
                                            <tr>
                                                <th className='bgdark'>No. </th>
                                                <th className='bgdark'>Project Flow </th>
                                                <th className='bgdark'>Detail</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-group-divider">
                                            {modalData.map((x) => <tr key={x[0]} className="table-primary" >
                                                <td scope="row">{ii++} </td>
                                                <td scope="row">{x[0] ? x[0] : "No Data "} </td>
                                                <td>{x[1] ? x[1].toString() : "No Data"} </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                            </Drawer.Body>
                        </Drawer>
                    }
                </Container>
            </div>
        </>
    );
}

export default GetAllCommunity;