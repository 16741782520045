import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  DELETE_CONTACT_US,
  DELETE_CONTACT_US_SUCCESS,
  DELETE_CONTACT_US_FAIL
} from "./actionTypes"

import {
  deleteContactUs, deleteContactUsSuccess, deleteContactUsrofile
} from "./actions"

//Include Both Helper File with needed methods
import { deleteContactUsHelper, getContactUsHelper } from "../../helpers/backend_helper"
import { getContactUs, getContactUsSuccess, getContactUsrofile } from "store/actions";

function* fetchContactUs({ payload: id }) {
  // console.log(id);
  try {
    const response = yield call(deleteContactUsHelper, id)
    // console.log(response);

    try {
      const response1 = yield call(getContactUsHelper)

      yield put(getContactUsSuccess(response1))
    } catch (error) {
      yield put(getContactUsrofile(error))
    }


    yield put(deleteContactUsSuccess(response))
  } catch (error) {
    yield put(deleteContactUsrofile(error))
  }
}


function* DeleteContactUsSaga() {
  yield takeEvery(DELETE_CONTACT_US, fetchContactUs)
}

export default DeleteContactUsSaga;
