import {
    API_SUCCESS,
    API_FAIL,
    GET_DASHBOARD_DATA
} from "./actionTypes";

export const apiSuccess = (actionType, data) => (

    {
        type: API_SUCCESS,
        payload: { actionType, data },
    });

export const apiFail = (actionType, error) => ({
    type: API_FAIL,
    payload: { actionType, error },
});

//charts data
export const getDashboardData = (periodType) => ({
    type: GET_DASHBOARD_DATA,
    payload: periodType
});
