//REGISTER
export const POST_REGISTER = "/register";

//LOGIN
export const POST_LOGIN = "/admin_login";
export const POST_PASSWORD_FORGET = "/forget-password";

//PROFILE
export const POST_EDIT_PROFILE = "/post-profile";

//DATASET
export const ADD_NEW_DATASET = "/add_dataset";
/////////////////////////
export const GET_DATASET = "/datasets";
export const GET_DATASET_MINIMAL_DETAILS = "/datasets_with_minimal_details";
export const ADD_NEW_CATEGORY = "/add_category";
export const GET_INDUSTRY = "/industrys";
export const GET_CATEGORY = "/categorys";
export const ADD_NEW_INDUSTRY = "/add_industry";


// This is use for gujarat  id  ......
export const UPDATE_DATASET = `/dataset`;
export const UPDATE_DATASET_TWO_SHOW = `/dataset`;
export const GET_DATASET_BY_ID = `/dataset`;
export const GET_DATASET_BY_ID_TWO_SHOW = `/dataset`;


// search bar 
//BLOG 
export const ADD_BLOG = "/create_blog";
export const GET_ALL_BLOG = "/blogs";

export const GET_DASHBOARD_DATA = "/admin_dash";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";


export const GET_TASKS = "/tasks";

// section 
export const GET_SECTION = `/section`;

export const PUT_UPDATE_PRIORITY_SECTION = `/section`;
export const GET_CONTACT_US_HELPER = `/contacts`;
export const DELETE_CONTACT_US_HELPER = `/contact`;
export const DELETE_DATASET_REQUEST_HELPER = `/dataset_request`;

export const GET_SUBSCRIBER_HELPER = `/subscribers`;
export const GET_DATASET_REQUEST_ALL = "/get_requested__datasets";


// freelancer_vendors
export const GET_FREELANCER_VENDOR = `/freelancer_vendors`;
export const DELETE_FREELANCER_VENDOR = `/freelancer_vendor`;
export const SEND_CUSTOM_MAIL_TO_FREELANCERS = '/custom_freelancer_vendors/email'

// get a call data
export const GET_A_CALL_API = "/calls";

// get all community 
export const GET_ALL_COMMUNITY = "/get_community";

// get case studies
export const GET_CASE_STUDIES = "/case_studies";

// get yugo
export const GET_YUGO = "/yugo";

//get all sell data 
export const GET_SELL_DATA = "/all_sell_data";
export const ADD_COMMENT_CONTACT_US = "/contact";
export const ADD_DATASET_REQUEST = "/dataset_request";

export const ADD_DEAL = "/deal";
export const GET_DEAL = "/deals";