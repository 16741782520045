import { number } from 'prop-types';
import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";
import "../../assets/DragAndDrop.css"

import { getDataset, updatePriority, sections } from "../../store/actions"

const SpeechRecognition = () => {
    const [data, setData] = useState(null);
    const [allData, setAllData] = useState(null);
    const [hide, setHide] = useState(true);
    const [hide2, setHide2] = useState(true);
    const [section, setSections] = useState(null);
    const [putLoading, setPutLoading] = useState(false)
    const [addData, setAddData] = useState(false)


    const dispatch = useDispatch();

    const { dataset, sectionss, putResponse } = useSelector(state => ({
        dataset: state.getDataset.datasetData.result,
        sectionss: state.section.data?.result,
        putResponse: state
    }));

    useEffect(() => {
    }, [putResponse]);
    useEffect(() => {
        dispatch(getDataset());
        dispatch(sections("home_category_sr"));

    }, []);

    useEffect(() => {
        setAllData(dataset)
        // setData(dataset);
        if (dataset) {
            setHide(false)
            setData(dataset.filter((x) => (x.category_name === "Speech Recognition")))
        }
    }, [dataset,]);


    useEffect(() => {
        if (sectionss) {
            setHide2(false)
            setSections(sectionss[0].datasets)
            if (sectionss[0].datasets.length == 0) {
                setAddData(true)
            } else {
                setAddData(false)
            }
        }
    }, [sectionss,]);


    function allowDrop(ev) {
        ev.preventDefault();
    }

    function drag(ev) {
        ev.dataTransfer.setData("text", ev.target.id);

        SaveData()
    }

    let SaveData = () => {
        let aa = document.getElementById("container2").children
        var bb = []
        for (let i = 0; i < aa.length; i++) {
            const element = { "card_title": aa[i].id.split("&!&")[0], "_id": aa[i].childNodes[4].id, "dataset_type": aa[i].childNodes[5].id, "file": aa[i].childNodes[6].id, "dataset_audio_type": aa[i].childNodes[7].id, "card_description": aa[i].childNodes[8].id, "url": aa[i].childNodes[9].id, "category_name": aa[i].childNodes[10].id }
            bb.push(element)
        }
        // console.log(bb)
        dispatch(updatePriority(
            { "datasets": bb }, "home_category_sr"
        ))
        setPutLoading(true)
    }
    function drop(ev) {
        ev.preventDefault();

        var data = ev.dataTransfer.getData("text");
        if (ev.target.parentElement.id == "container1") {
            ev.target.before(document.getElementById(data));
        } else if ((ev.target.parentElement.parentElement.id == "container1") || ev.target.id == "hr") {
            ev.target.parentElement.after(document.getElementById(data));
        } else if (ev.target.id == "container1") {
            ev.target.append(document.getElementById(data));
        }
        if (ev.target.parentElement.id == "container2") {
            ev.target.before(document.getElementById(data));
            setAddData(false)

        } else if ((ev.target.parentElement.parentElement.id == "container2") || ev.target.id == "hr") {
            ev.target.parentElement.after(document.getElementById(data));
            setAddData(false)

        } else if (ev.target.id == "container2") {
            ev.target.append(document.getElementById(data));
            setAddData(false)

        }
        setTimeout(() => {
            SaveData()
        }, 1000);
    }

    let filterData = () => {
        let value = document.getElementById("select").value
        for (let i = 0; i < data.length; i++) {
            if (data[i].dataset_type == value) {
                if (document.getElementById(data[i]._id).parentElement.parentElement.id == "container1") {
                    document.getElementById(data[i]._id).parentElement.style.display = "block"
                }
            } else if (value == "All") {
                document.getElementById(data[i]._id).parentElement.style.display = "block"
            } else {
                if (document.getElementById(data[i]._id).parentElement.parentElement.id == "container1") {
                    document.getElementById(data[i]._id).parentElement.style.display = "none"
                }
            }
        }
    }

    let FindData = (e) => {
        if (e.target.value == "") {
            for (let i = 0; i < data.length; i++) {
                document.getElementById(data[i]._id).parentElement.style.display = "block"
            }
        }
        if (e.keyCode === 13) {
            let value = document.getElementById("search").value
            e.preventDefault();
            for (let i = 0; i < data.length; i++) {
                if (data[i].dataset_code == value) {
                    if (document.getElementById(data[i]._id).parentElement.parentElement.id == "container1") {
                        document.getElementById(data[i]._id).parentElement.style.display = "block"
                    }
                } else {
                    if (document.getElementById(data[i]._id).parentElement.parentElement.id == "container1") {
                        document.getElementById(data[i]._id).parentElement.style.display = "none"
                    }
                }
            }
        }
    }

    return (
        <>
            <div className='d-flex' style={{ marginTop: "60px", marginLeft: "90px" }}>
                <select onChange={filterData} style={{ width: "150px", border: "2px solid black" }} id="select" className="form-select mx-1" aria-label="Default select example">
                    <option value="All">All</option>
                    <option value="Image">Image</option>
                    <option value="Audio">Audio</option>
                    <option value="Text">Text</option>
                </select>
                <input className="form-control me-2 mx-1" id="search" onKeyUp={FindData} style={{ width: "150px", border: "2px solid black" }} type="search" placeholder="Search" aria-label="Search" />
            </div>
            <div style={{ margin: "20px" }} className="d-flex  justify-content-around" >
                <div id="container1" onDrop={drop} onDragOver={allowDrop} className="mx-4" >
                    {hide && <div className="spinner-border" style={{ position: "relative", top: "200px", width: " 3rem", height: "3rem" }} role="status">

                    </div>}
                    {data !== null && data !== undefined && data.length == 0 && <div className="text-light h3" style={{ position: "relative", top: "200px", width: " ", height: "" }} role="status">
                        Records are not available !
                    </div>}
                    {
                        // console.log(data)
                    }
                    {data?.map((x) => <div className='m-3 card cardd' style={{ display: "block" }} key={x.card_title + "kjjkasd" + x._id} draggable="true" onDragStart={drag} id={x.card_title + "&!&" + x._id} >{x.card_title} <hr style={{ height: "25px", marginBottom: "0px" }} id='hr' />  <span style={{ display: "none" }} key={x._id + "1212"} id={x._id}>{x._id}</span>
                        <span key={x.dataset_type + "2112341"} id={x.dataset_type} />
                        <span key={x.file + "2232334"} id={x.file} />
                        <span key={x.dataset_audio_type + "343423"} id={x.dataset_audio_type} />
                        <span key={x.card_description + "4454"} id={x.card_description} />
                        <span key={x.url + "44asdsd54"} id={x.url} />
                        <span key={x.url + x.category_name} id={x.category_name} />
                    </div>)}

                </div>

                <div id="container2" onDrop={drop} onDragOver={allowDrop} className="mx-4">
                    {hide2 && <div className="spinner-border" style={{ position: "relative", top: "200px", width: " 3rem", height: "3rem" }} role="status">
                    </div>}
                    {addData && <div className="text-light h3" style={{ position: "relative", top: "200px", width: "", height: "" }} role="status">
                        Add data here!
                    </div>}
                    {
                        // console.log(section)
                    }
                    {section?.map((x) => <div className='m-3 card cardd' key={x.card_title + "jmnwerdf" + x._id} draggable="true" onDragStart={drag} id={x.card_title} >{x.card_title} <hr style={{ height: "25px", marginBottom: "0px" }} id='hr' />  <span key={x._id + "sdcvkjd"} style={{ display: "none" }} id={x._id}>{x._id}</span>
                        <span key={x._id + "qewdscsdcd"} id={x.dataset_type} />
                        <span key={x._id + "lkmksdecl"} id={x.file} />
                        <span key={x._id + "mvlkkjmkv"} id={x.dataset_audio_type} />
                        <span key={x._id + "smfvlkddkf"} id={x.card_description} />
                        <span key={x._id + "smfvlkddkfassasds"} id={x.url} />
                        <span key={x._id + x.category_name} id={x.category_name} />
                    </div>)}
                </div>
            </div>
        </>
    );
}

export default SpeechRecognition;






