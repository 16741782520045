import { useMemo } from "react";

import { Link, useHistory } from "react-router-dom";

import TableContainer from "components/Common/TableContainer";
import PropTypes from "prop-types";

import { map } from "lodash";
//Import Breadcrumb
import "./dataset.css";

import {
  Badge,
  Container,
  Button,
} from "reactstrap";
import { message } from 'antd';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";


import { getDataset, getDatasetByIdTWO, getDatasetById } from "../../store/actions";
import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

// XLSX for Excel file handling
import * as XLSX from "xlsx";

// File Saver
import * as FileSaver from "file-saver";
import { updateDataset, updateDatasetTWO } from "../../store/actions";
import { success } from "toastr";
import { Loader } from "rsuite";

const datasetList = props => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const [datasetData, setdatasetData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState(false);
  const [PageData, setPageData] = useState();
  const [PageDataAutoShow, setPageDataAutoShow] = useState();
  const [SaveID, setSaveID] = useState("0");
  // const [ShowHide, setShowHide] = useState(null);
  // const [ShowHideID, setShowHideID] = useState(null);
  // const [hhss, sethhss] = useState("hide");
  // const [messagee, setmessagee] = useState(false);
  const [RecordsNotAvalable, setRecordsNotAvalable] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const { dataset } = useSelector(state => ({
    dataset: state.getDataset.datasetData,
  }));

  const editDataset = (e) => {
    // console.log("id1", e?.target?.id)
    // console.log(e);
    // let text = this.getAttribute("id");
    // log("jkacshbc" + text)
    // console.log("this" + this)
    // console.log("id", e.target)

    if (!e.target.id) {
      localStorage.setItem("idForEditData", e?.target?.parentElement.id);
      history.push(`getDataSetById/${e.target.parentElement.id}`);
    } else {
      localStorage.setItem("idForEditData", e?.target?.id);
      history.push(`getDataSetById/${e.target.id}`);
      // console.log("id2", e?.target?.id)
    }
  };

  const { DataByID } = useSelector(state => ({
    DataByID: state.getDatasetByIdTWO.datasetDataID2,
  }));


  // const {
  //   match: { params },
  // } = props
  // useEffect(() => {
  //   if (params.id) {
  //     dispatch(getDatasetById())
  //   } else {
  //     dispatch(getDatasetById())
  //   }
  //   }
  // }, [dispatch, params.id])

  useEffect(() => {
    dispatch(getDataset());

  }, [dispatch]);

  useEffect(() => {
    if (dataset.success) {
      setdatasetData(dataset.result);
      console.log(dataset);
      setLoading(false)
      setRecordsNotAvalable(false);
      setPagination(true);
      let aaa = [];
      for (let i = 0; i < 100; i++) {
        aaa.push(dataset.result[i]);
      }
      setPageData(aaa);
    }
    if (dataset.success == false) {
      setLoading(false)
      setRecordsNotAvalable(true);
    }

  }, [dataset]);

  var Data = datasetData;

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    ws["!cols"] = [{ wch: 3 }, { wch: 20 }, { wch: 60 }, { wch: 70 }];

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const excelData = () => {
    let count = 1;
    let baseUrl = 'https://datastore.futurebeeai.com/dataset/';
    return Data?.map(item => {
      return {
        "Id": count++,
        "Dataset Code": item?.dataset_code ? item.dataset_code : '-',
        "Title": item?.title ? item.title : '-',
        "Link": item?.dataset_type === 'Text' ? baseUrl + 'text/' + item._id :
          item.dataset_type === 'Image' ? baseUrl + 'image/' + item._id :
            item.dataset_type === 'Video' ? baseUrl + 'video/' + item._id :
              item.dataset_type === 'Audio' ? item.dataset_audio_type === 'conversation' ? baseUrl + 'audio/cc/' + item._id : item.dataset_audio_type === 'prompt' ? baseUrl + 'audio/prompt/' + item._id : baseUrl : baseUrl,
      };
    });
  };

  if (pagination) {
    var totalPage = Math.ceil(datasetData.length / 100);
    var a = [];
    for (let i = 0; i < totalPage; i++) {
      a.push(i);

    }
    // console.log(a);
  }

  let paginationFunc = (e) => {

    document.getElementById(SaveID).parentElement.classList.remove("active");
    document.getElementById(e.target.id).parentElement.classList.add("active");
    setSaveID(e.target.id);

    if ((e.target.id * 100) + 100 < datasetData.length) {
      var dataOfPage = (e.target.id * 100);
      let aa = [];
      for (let i = dataOfPage; i < (dataOfPage + 100); i++) {
        aa.push(datasetData[i]);
      }
      setPageData(aa);
    } else {
      var dataOfPage = (e.target.id * 100);
      let bb = [];
      for (let ii = dataOfPage; ii < datasetData.length; ii++) {
        bb.push(datasetData[ii]);
      }
      setPageData(bb);
    }
  };

  let prevFunc = (e) => {
    if (Number(SaveID)) {
      document.getElementById(SaveID).parentElement.classList.remove("active");
      document.getElementById(SaveID - 1).parentElement.classList.add("active");
      setSaveID(SaveID - 1);
      if ((SaveID * 100) + 100 < datasetData.length) {
        var dataOfPage = (SaveID * 100);
        let aa = [];
        for (let i = dataOfPage; i < (dataOfPage + 100); i++) {
          aa.push(datasetData[i]);
        }
        setPageData(aa);
      } else {
        var dataOfPage = (SaveID * 100);
        let bb = [];
        for (let ii = dataOfPage; ii < datasetData.length; ii++) {
          bb.push(datasetData[ii]);
        }
        setPageData(bb);
      }
    }
  };

  let nextFunc = () => {

    // console.log(Number(SaveID) + 1);
    if ((SaveID * 100) + 100 < datasetData.length) {
      var dataOfPage = (SaveID * 100);
      let aa = [];
      setSaveID(Number(SaveID) + 1);
      document.getElementById(SaveID).parentElement.classList.remove("active");
      document.getElementById(Number(SaveID) + 1).parentElement.classList.add("active");
      for (let i = dataOfPage; i < (dataOfPage + 100); i++) {
        aa.push(datasetData[i]);
      }
      setPageData(aa);
    } else {
      var dataOfPage = (SaveID * 100);
      let bb = [];
      for (let ii = dataOfPage; ii < datasetData.length; ii++) {
        bb.push(datasetData[ii]);
      }
      setPageData(bb);
    }
  };

  useEffect(() => {
    if (DataByID) {
      setShowHide(DataByID.result[0].is_show);
      setShowHideID(DataByID.result[0]._id);
    }
  }, [DataByID, updateDatasetTWO]);

  let isShowFunc = (e) => {

    document.getElementById(e.target.id);
    let aa = e.target.id.split("&!&")[1];


    let ss = () => {
      let dd = datasetData.filter((x) => x._id == aa);
      dd = dd[0];
      let success = () => {
        message.success(`Successfully Update ${dd.is_show ? "hide" : "show"}`);
      };
      // console.log();
      if (true) {
        dd.is_show ? dispatch(updateDatasetTWO({
          "is_show": false, aa
        })) : dispatch(updateDatasetTWO({
          "is_show": true, aa
        }));
        success();

        dd.is_show ? setmessagee("Hide") : setmessagee("Show");
        setmessagee(dd.is_show ? false : true);
        dispatch(getDataset());
        sethhss("show");

        setTimeout(() => {
          sethhss("hide");

        }, 3000);
      }
      setShowHide(null);
    };
    ss();
  };

  let i = 1;

  let HideFuncAlert = () => {
    sethhss("hide");
  };

  let FindData = (e) => {

    if (e.target.value == false) {
      if ((SaveID * 100) + 100 < datasetData.length) {
        var dataOfPage = (SaveID * 100);
        let aa = [];
        for (let i = dataOfPage; i < (dataOfPage + 100); i++) {
          aa.push(datasetData[i]);
        }
        setPageData(aa);
      } else {
        var dataOfPage = (SaveID * 100);
        let bb = [];
        for (let ii = dataOfPage; ii < datasetData.length; ii++) {
          bb.push(datasetData[ii]);
        }
        setPageData(bb);
      }
    }



    if (e.target.value) {
      setPageDataAutoShow(PageData);
      let value = document.getElementById("search").value;
      setPageData(datasetData.filter((x) => x.dataset_code?.includes(value)));
      e.preventDefault();
    }

  };


  function backToTop () {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    document.querySelector(".table-responsive").scrollTop = 0;
  }

  useEffect(() => {
    document.getElementById("0")?.parentElement?.classList.add("active");
  }, [pagination]);

  useEffect(() => {
    document.querySelector(".table-responsive").style.height = "80vh";
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <div className="page-content">
          <div className="">
            <div className="">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex   ">
                    <h2 className="dataset-heading main-heading ">
                      <span>
                        <span>
                          Dataset
                        </span>
                      </span>
                    </h2>
                    <label htmlFor="search" className="mx-1 p-2">Search :</label>
                    <input className="form-control me-2  " id="search" onKeyUp={FindData} style={{ width: "150px", height: "38px", border: "2px solid black", }} type="search" placeholder="Search" aria-label="Search" />

                    <Button color="primary" style={{ marginLeft: "auto", height: "38px" }} className="" onClick={(e) => exportToCSV(excelData(), "DatasetSheet")}>Download Sheet</Button>
                  </div>
                  <div className="card">
                    <div className="table-responsive container-fluid" >
                      <table className="table no-wrap user-table mb-0  table-primary   ">
                        <thead>
                          <tr className="header  positionsticky bgdark">
                            <th className="border-0 text-uppercase font-medium col-1 bgdark">No.</th>
                            <th className="border-0 text-uppercase font-medium col-2 bgdark">Name</th>
                            <th className="border-0 text-uppercase font-medium col-2 bgdark">Dataset Code</th>
                            <th className="border-0 text-uppercase font-medium col-2 bgdark">Category </th>
                            <th className="border-0 text-uppercase font-medium col-2 bgdark">Sample</th>
                            <th className="border-0 text-uppercase font-medium col-2 bgdark">Type
                            </th>
                            <th className="border-0 text-uppercase font-medium col-2 bgdark">Edit/Delete</th>
                            <th className="border-0 text-uppercase font-medium col-2 bgdark">Show/Hide</th>
                          </tr>
                        </thead>
                        <tbody id="">
                          {
                            RecordsNotAvalable ?
                              <tr>
                                <td colSpan={8} className="text-center bg-transparent border-0" style={{ height: 'calc(80vh - 46px)', verticalAlign: 'middle' }}>
                                  <h3>Records are not available!</h3>
                                </td>
                              </tr>
                              : null
                          }
                          {
                            Loading ?
                              <tr><td colSpan={8} className="text-center bg-transparent border-0" style={{ height: 'calc(80vh - 46px)', verticalAlign: 'middle' }}>
                                <Loader size="lg" className='' />
                              </td>
                              </tr>
                              : null
                          }
                          {map(PageData, (project, _id) => (
                            // console.log(project),
                            <tr key={_id} id={"tr" + project._id} style={{ height: "20px" }} className="dataset-row-height">
                              <td className="dataset-row-height" >
                                <p className="text-muted mb-0">
                                  {Number(SaveID) * 100 + i++}
                                </p>
                              </td>
                              <td className="dataset-row-height" >

                                <p className="text-muted mb-0">
                                  {project.card_title}
                                </p>
                              </td>
                              {/* {
                                console.log(project.industries)
                              } */}
                              <td className="dataset-row-height">
                                <p className="text-muted mb-0">
                                  {project.dataset_code}
                                </p>
                              </td>
                              <td>
                                <p className="text-muted mb-0">
                                  {project.category_name}
                                </p>
                              </td>

                              <td className="dataset-row-height"  >
                                <p className="text-muted mb-0  " style={{ justifyItems: "center", }}>
                                  {/* {console.log(project.industries.length)} */}
                                  {
                                    project.sample == 0 ?
                                      <Badge className="bg-danger" id="badgee" key={i} style={{ background: "red" }}> {"Not Available"}
                                      </Badge>

                                      : project.sample == 1 ? <Badge className="bg-success" id="badgee" key={i} style={{ background: "green" }}> &nbsp;&nbsp;&nbsp;{"Available"}&nbsp;&nbsp;&nbsp;&nbsp;
                                      </Badge> : "-"
                                  }
                                </p>
                              </td>

                              <td className="dataset-row-height" >
                                <p className="text-muted  mb-0">
                                  <Badge>
                                    &nbsp;  {project.dataset_type} &nbsp;
                                  </Badge>
                                </p>
                              </td>
                              <td className="d-flex-column dataset-row-height  justify-content-center" style={{ height: "135px" }}>

                                <button type="button" id={project._id} key={project._id} onClick={editDataset} className="px-2 py-1  primary" style={{ borderRadius: "4px", background: "#0096FF", color: "white" }}><i className="far fa-edit primary" key={project._id + "aqesde"}> Edit</i> </button>

                                {/* <button type="button" style={{ borderRadius: "4px", background: "#fa4d69", color: "white", cursor: "not-allowed" }} className="p-1 m-2 danger" disabled="true" ><i className="fas fa-trash danger" > Delete</i> </button> */}

                              </td>
                              <td className="dataset-row-height">
                                <div className="form-check form-switch d-flex  justify-content-center ">
                                  {project.is_show ? <input className="form-check-input mmlkskdmsdmnd" onClick={isShowFunc} type="checkbox" role="switch" id={`flexSwitchCheckDefault&!&` + project._id} style={{
                                    // borderRadius: "100%",
                                    width: "50px",
                                    height: "30px",
                                    padding: "10px"
                                  }}
                                    defaultChecked
                                  />
                                    :
                                    <input className="form-check-input " onClick={isShowFunc} type="checkbox" role="switch" id={`flexSwitchCheckDefault&!&` + project._id} style={{
                                      // borderRadius: "100%",
                                      width: "50px",
                                      height: "30px",
                                      padding: "10px"
                                    }}
                                    />
                                  }

                                  {/* <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Default switch checkbox input</label> */}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerr">
              <button
                type="button"
                className="btn btn-danger btn-floating btn-lg"
                id="btn-back-to-top"
                onClick={backToTop}
                style={{ display: "flex", float: "right", position: "relative" }}
              >
                <i className="fas fa-arrow-up style-arrow"></i>
              </button>
              {a && <nav aria-label="..." className="d-flex justify-content-center" >
                <ul className="pagination" >
                  <li className="page-item " key={"jnasdkjcn"}>
                    <a className="page-link" onClick={prevFunc} key={"asnsaddckjnasdkjcn"} href="#" tabIndex="-1">Previous</a>
                  </li>
                  {a.map((x) => <li className="page-item " key={x + "kmdsfdkl"} ><a className="page-link " key={x + "asksd"} onClick={paginationFunc} id={x} >{x + 1}</a></li>)}
                  <li className="page-item" key={"asnsaddc"}>
                    <a className="page-link" onClick={nextFunc} key={"saddckjnasdkjcn"} href="#">Next</a>
                  </li>
                </ul>
              </nav>}

            </div>
          </div>
        </div>
      </Container>
    </React.Fragment >
  );
};

datasetList.propTypes = {
  match: PropTypes.any,
};
export default datasetList;