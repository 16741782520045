import { getCaseStudiesHelper } from "helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import {
    GET_CASE_STUDIES, GET_CASE_STUDIES_FAIL,
    GET_CASE_STUDIES_SUCCESS
} from "./actionTypes"



function* CaseStudiesSagaa() {

    try {
        const response = yield call(getCaseStudiesHelper)

        // console.log(response);
        yield put({ type: GET_CASE_STUDIES_SUCCESS, response })
    } catch (error) {
        yield put({ type: GET_CASE_STUDIES_FAIL, error })
    }
}


function* CaseStudiesSaga() {
    yield takeEvery(GET_CASE_STUDIES, CaseStudiesSagaa)

}





export default CaseStudiesSaga 