import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_DATASET, API_DATASETFAIL, API_DATASETSUCCESS, GET_CATEGORY, ADD_NEW_CATEGORY, GET_DATASET, GET_DATASET_BY_ID, UPDATE_DATASET, UPDATE_DATASET_TWO_HIDE, GET_DATASET_BY_ID_TWO_HIDE } from "./actionTypes"
import { apiDatasetSuccess, apiDatasetFail } from "./actions"

//Include Both Helper File with needed methods
import { addNewDataset, getCategory, addNewCategory, getDataset, getDatasetById, updateDataset, getDatasetByIdTwoHide, updateDatasetTwoHide, getDatasetMinimalDetails } from "../../helpers/backend_helper"

function* addDataset({ payload: dataset }) {
  try {
    const response = yield call(addNewDataset, dataset)
    yield put(apiDatasetSuccess(ADD_NEW_DATASET, response))
  } catch (error) {
    yield put(apiDatasetFail(ADD_NEW_DATASET, error))
  }
}
function* addCategory({ payload: category }) {
  try {
    const response = yield call(addNewCategory, category)
    // console.log(response)
    yield put(apiDatasetSuccess(API_DATASETSUCCESS, response))
  } catch (error) {
    yield put(apiDatasetFail(API_DATASETFAIL, error))
  }
}
function* getcategory() {
  try {
    const response = yield call(getCategory)
    yield put(apiDatasetSuccess(GET_CATEGORY, response))
  } catch (error) {
    yield put(apiDatasetFail(GET_CATEGORY, error))
  }
}
function* getdataset() {
  try {
    const response = yield call(getDatasetMinimalDetails)
    yield put(apiDatasetSuccess(GET_DATASET, response))
  } catch (error) {
    yield put(apiDatasetFail(GET_DATASET, error))
  }
}
function* getdatasetById(id) {
  // console.log(id);
  try {
    const response = yield call(getDatasetById, id.payload)
    // console.log(response);
    yield put(apiDatasetSuccess(GET_DATASET_BY_ID, response))
  } catch (error) {
    yield put(apiDatasetFail(GET_DATASET_BY_ID, error))
  }
}
function* getDatasetByIdTWOO(DATA) {
  // console.log(DATA);
  try {
    const response = yield call(getDatasetByIdTwoHide, DATA.payload)
    // console.log(response);
    yield put(apiDatasetSuccess(GET_DATASET_BY_ID_TWO_HIDE, response))
  } catch (error) {
    yield put(apiDatasetFail(GET_DATASET_BY_ID_TWO_HIDE, error))
  }
}

function* updatedataset({ payload: data }) {
  // console.log(data);
  // data = JSON.stringify(data)
  // console.log(data);
  try {
    const response = yield call(updateDataset, data)
    yield put(apiDatasetSuccess(UPDATE_DATASET, response))
  } catch (error) {
    yield put(apiDatasetFail(UPDATE_DATASET, error))
  }
}


function* updatedatasetTWOO({ payload: data }) {
  // console.log(data);
  // data = JSON.stringify(data)
  // console.log(data);
  try {
    const response = yield call(updateDatasetTwoHide, data.aa, { "is_show": data.is_show })
    // console.log(response);
    yield put(apiDatasetSuccess(UPDATE_DATASET_TWO_HIDE, response))
  } catch (error) {
    yield put(apiDatasetFail(UPDATE_DATASET_TWO_HIDE, error))
  }
}

export function* watchaddNewDataset() {
  yield takeEvery(ADD_NEW_DATASET, addDataset)
  yield takeEvery(GET_CATEGORY, getcategory)
  yield takeEvery(ADD_NEW_CATEGORY, addCategory)
  yield takeEvery(GET_DATASET, getdataset)
  yield takeEvery(UPDATE_DATASET, updatedataset)
  yield takeEvery(UPDATE_DATASET_TWO_HIDE, updatedatasetTWOO)
  yield takeEvery(GET_DATASET_BY_ID, getdatasetById)
  yield takeEvery(GET_DATASET_BY_ID_TWO_HIDE, getDatasetByIdTWOO)
}

function* datasetSaga() {
  yield all([fork(watchaddNewDataset)])
}

export default datasetSaga
