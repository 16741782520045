import {
  combineReducers
} from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//projects
import projects from "./projects/reducer"

//contacts
import contacts from "./contacts/reducer"

import Dataset, {
  addDataset
} from "./datastore/reducer"
import {
  addCategory
} from "./datastore/reducer"
import {
  getDataset, updateDataset, getDatasetById, getDatasetByIdTWO, updateDatasetTWO
} from "./datastore/reducer"
import Industry, {
  addIndustry
} from "./industry/reducer"
import Blog from "./blog/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

// section
import section from "./prioritySectionGetData/reducer"
import UpdateSection from "./prioritySectionUpdate/reducer"
import ContactsUs from "./contactus/reducer"
import Subscriber from "./subscriber/reducer"
import DatasetRequest from "./datasetrequest/reducer"
import freelancerReducer from "./freelancer-vendor/reducer"
import getCallReducer from "./get-a-call/reducer"
import getCommunity from "./get_all_community/reducer"
import getSelldata from "./sellData/reducer"
import caseStudies from "./caseStudies/reducer"
import yugo from "./yugo/reducer"
import datasetreqComment from './datasetrequest/reducerComment'
import DealsReducer from "./deals/reducer"


const rootReducer = combineReducers({
  datasetreqComment,
  getDatasetById,
  // public
  DatasetRequest,
  freelancerReducer,
  section,
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  projects,
  contacts,
  Dashboard,
  Dataset,
  addDataset,
  Industry,
  addIndustry,
  UpdateSection,
  addCategory,
  getDataset,
  getDataset,
  updateDataset,
  Blog,
  getDatasetByIdTWO,
  updateDatasetTWO,
  ContactsUs,
  Subscriber,
  getCallReducer,
  getCommunity,
  getSelldata,
  caseStudies,
  yugo,
  DealsReducer
})

export default rootReducer