import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import Swal from "sweetalert2";

export function addDaysToDateWithDayName (inputDate, numberOfDays) {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const [day, month, year] = inputDate.split('/').map(Number);
  const currentDate = new Date(year, month - 1, day);

  currentDate.setDate(currentDate.getDate() + numberOfDays);

  const resultDay = currentDate.getDate();
  const resultMonth = currentDate.getMonth() + 1;
  const resultYear = currentDate.getFullYear();

  const dayName = daysOfWeek[currentDate.getDay()];

  const formattedResult = `${resultDay.toString().padStart(2, '0')}/${resultMonth.toString().padStart(2, '0')}/${resultYear}`;

  return { date: formattedResult, day: dayName };
}

export function formatDDMMYYYY (date) {
  if (!new Date(date)) return '';
  return new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  }).format(new Date(date));
}

export const closeDealModal = async () => {
  const resp = await Swal.fire({
    title: "Are you sure to close the deal?",
    icon: 'question',
    input: "checkbox",
    inputValue: 0,
    inputPlaceholder: `
        Add as a potential client
        `,
    showCancelButton: true,
    confirmButtonText: `
        Continue&nbsp;<i class="fa fa-arrow-right"></i>
      `,
  });
  return resp;
};

export const addFollowUpConfirmationModal = async () => {
  const resp = await Swal.fire({
    title: "Do you want to add a new follow up?",
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Create it!"
  });
  return resp;
};

export const undoStageConfirmationModal = async () => {
  const resp = await Swal.fire({
    title: "Do you want to undo the current stage?",
    icon: "question",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Undo it!"
  });
  return resp;
};

export const moveDealConfirmationModal = async () => {
  const resp = await Swal.fire({
    title: "Are you sure to change the status?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: `
        Continue&nbsp;<i class="fa fa-arrow-right"></i>
      `,
  });
  return resp;
};

export const getBorderColor = (lifecycle, deal_status) => {
  const isEqual = lifecycle.some((elm, idx) => {

    if ((lifecycle.length - 1 - (lifecycle.slice().reverse().findIndex(elm => elm.status == deal_status))) < idx) {
      const taskDate = new Date(elm.date.date.split('/').reverse().join('-'));
      const taskDateFormatted = new Date(taskDate.getFullYear(), taskDate.getMonth(), taskDate.getDate()).toLocaleDateString();

      const today = new Date();
      const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()).toLocaleDateString();

      if (taskDateFormatted == todayDate && !['1', '6', '7'].includes(elm.status)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  if (isEqual) {
    return '#F46A6A';
  } else {
    return '#556ee6';
  }
};

export const columnsMapping = (status) => {
  const [stage, count] = status.split('.');
  if (count) {
    return `FollowUp-${count}`;
  }
  switch (stage) {
    case "1":
      return 'Initiated';
    case "2":
      return 'Contacted';
    case "4":
      return "Not Responding";
    case "5":
      return "In Conversation";
    case "6":
      return 'Close';
    case "7":
      return 'Lose';
    default:
      return 'N/A';
  }
};

export const getDays = (status, prevStatus) => {
  if (!status) {
    if (prevStatus.startsWith('4') || prevStatus.startsWith('5')) {
      return 10;
    } else if (prevStatus.startsWith('3') || prevStatus.startsWith('2')) {
      return 3;
    }
  }
  if (status == '1') {
    return 0;
  } else if (status.startsWith('3.1')) {
    return 3;
  } else if (status == '2' || status.startsWith('3')) {
    return 2;
  } else if (status.startsWith('4.1')) {
    return 7;
  } else if (status.startsWith('4.') || status.startsWith('5.')) {
    return 2;
  } else if (status.startsWith('4') || status.startsWith('5')) {
    return 3;
  } else if (status == '6' || status == '7') {
    return 10;
  }
};

export const LoseModalContent = () => {
  return (
    <>
      <Row className="mt-4">
        <Col sm="12" className="mx-auto">
          <div className="mb-2">
            <select id="lose-reason" className="form-select" onChange={(e) => {
              if (e.target?.value === "Other") {
                const customMessageRow = document.querySelector('.custom-message');
                if (customMessageRow.classList.contains('d-none')) {
                  customMessageRow.classList.remove('d-none');
                }
              } else {
                const customMessageRow = document.querySelector('.custom-message');
                if (!customMessageRow.classList.contains('d-none')) {
                  customMessageRow.classList.add('d-none');
                }
              }
            }}>
              <option defaultValue value="We don't have OTS data">
                We don't have OTS data
              </option>
              <option value="Budget Issue">Budget Issue</option>
              <option value="Deadline Issue">Deadline Issue</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </Col>
      </Row>
      <Row className="custom-message d-none">
        <Col sm="12" className="mx-auto">
          <div className="mb-3">
            <textarea
              className="form-control"
              id="lose-reason-custom"
              placeholder="Add your reason here"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="12" className="mx-auto">
          <div className="my-3">
            <FormGroup check className="d-flex gap-2 align-items-center">
              <Input type="checkbox" id="pot_client" />
              {' '}
              <Label check className="m-0">
                Add as a potential client
              </Label>
            </FormGroup>
          </div>
        </Col>
      </Row>
    </>
  );
};