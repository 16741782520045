import React, { useState, useEffect } from "react";

const initialValues = {
  dataset_code: "",
  title: "",
  description: "",
  about_set: "",
  card_d1: "",
  card_d2: "",
  card_description: "",
  card_image: "",
  card_title: "",
  category_name: "",
  category_name_2: "",
  createdAt: "",
  data_form: "",
  dataset_audio_type: "",
  dataset_page_priority: "",
  dataset_type: "",
  demographics_details: "",
  description: "",
  environment: "",
  file: "",
  file_details: "",
  filter_usecases: "",
  industries: "",
  is_deleted: "",
  is_higher_priority: "",
  is_higher_priority_for_industry: "",
  is_show: "",
  ready_to_deploy: "",
  sample: "",
  sample_link: "",
  section_icon: "",
  section_name: "",
  title: "",
  usecase: "",
};

const Dataset_Edit = () => {
  const [dataset, setDataset] = useState(initialValues);
  const {
    dataset_code,
    title,
    description,
    about_set,
    PoBox,
    City,
    Province,
    Country,
    Phone,
    Fax,
    Email,
    RegistrationNo,
    VatNo,
    PinNo,
    BranchNo,
    BranchHq,
    StartDate,
    EndDate,
    Current,
    RunDate,
    DateCreated,
    UserID,
    LocationID,
  } = dataset;

  const onValueChange = (e) => {
    setDataset({ ...dataset, [e.target.dataset_code]: e.target.value });
  };




  return (


    <form>
      <h3> Edit  Master</h3>
      <div className="form-class8">
        <div className="form-group">
          <label>Company Code:</label>
          <p onChange={(e) => onValueChange(e)} name="dataset_code"
            value={dataset_code}></p>

        </div>

        <div className="form-group">
          <label>Company Name:</label>
          <p onChange={(e) => onValueChange(e)}
            name="description"
            value={description}>m</p>
        </div>

        <div className="form-group">
          <label>description</label>
          <input
            type="text"
            className="form-control"
            placeholder="description"
            onChange={(e) => onValueChange(e)}
            name="description"
            value={description}
          />
        </div>

        <div className="form-group">
          <label>about_set</label>
          <input
            type="text"
            className="form-control"
            placeholder="about_set"
            onChange={(e) => onValueChange(e)}
            name="about_set"
            value={about_set}
          />
        </div>

        <div className="form-group">
          <label>PO Box</label>
          <input
            type="text"
            className="form-control"
            placeholder="PO Box"
            onChange={(e) => onValueChange(e)}
            name="PoBox"
            value={PoBox}
          />
        </div>

        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            className="form-control"
            placeholder="City"
            onChange={(e) => onValueChange(e)}
            name="City"
            value={City}
          />
        </div>

        <div className="form-group">
          <label>Province</label>
          <input
            type="text"
            className="form-control"
            placeholder="Province"
            onChange={(e) => onValueChange(e)}
            name="Province"
            value={Province}
          />
        </div>

        <div className="form-group">
          <label>Country</label>
          <input
            type="text"
            className="form-control"
            placeholder="Country"
            onChange={(e) => onValueChange(e)}
            name="Country"
            value={Country}
          />
        </div>
      </div>

      <div className="form-class8">
        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            className="form-control"
            placeholder="Phone"
            onChange={(e) => onValueChange(e)}
            name="Phone"
            value={Phone}
          />
        </div>

        <div className="form-group">
          <label>Fax</label>
          <input
            type="text"
            className="form-control"
            placeholder="Fax"
            onChange={(e) => onValueChange(e)}
            name="Fax"
            value={Fax}
          />
        </div>

        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            onChange={(e) => onValueChange(e)}
            name="Email"
            value={Email}
          />
        </div>

        <div className="form-group">
          <label>Registration No</label>
          <input
            type="text"
            className="form-control"
            placeholder="RegistrationNo"
            onChange={(e) => onValueChange(e)}
            name="RegistrationNo"
            value={RegistrationNo}
          />
        </div>

        <div className="form-group">
          <label>VAT No</label>
          <input
            type="text"
            className="form-control"
            placeholder="VAT No"
            onChange={(e) => onValueChange(e)}
            name="VatNo"
            value={VatNo}
          />
        </div>

        <div className="form-group">
          <label>Pin No</label>
          <input
            type="text"
            className="form-control"
            placeholder="Pin No"
            onChange={(e) => onValueChange(e)}
            name="PinNo"
            value={PinNo}
          />
        </div>

        <div className="form-group">
          <label>Branch No</label>
          <input
            type="text"
            className="form-control"
            placeholder="Branch No"
            onChange={(e) => onValueChange(e)}
            name="BranchNo"
            value={BranchNo}
          />
        </div>

        <div className="form-group">
          <label>Branch Hq</label>
          <input
            type="text"
            className="form-control"
            placeholder="Branch Hq"
            onChange={(e) => onValueChange(e)}
            name="BranchHq"
            value={BranchHq}
          />
        </div>
      </div>

      <div className="form-class8">
        <div className="form-group">
          <label>Start Date</label>
          <input
            type="text"
            className="form-control"
            placeholder="Start Date"
            onChange={(e) => onValueChange(e)}
            name="StartDate"
            value={StartDate}
          />
        </div>

        <div className="form-group">
          <label>End Date</label>
          <input
            type="text"
            className="form-control"
            placeholder="End Date"
            onChange={(e) => onValueChange(e)}
            name="EndDate"
            value={EndDate}
          />
        </div>

        <div className="form-group">
          <label>Current</label>
          <input
            type="text"
            className="form-control"
            placeholder="Current"
            onChange={(e) => onValueChange(e)}
            name="Current"
            value={Current}
          />
        </div>

        <div className="form-group">
          <label>Run Date</label>
          <input
            type="text"
            className="form-control"
            placeholder="Run Date"
            onChange={(e) => onValueChange(e)}
            name="RunDate"
            value={RunDate}
          />
        </div>

        <div className="form-group">
          <label>Date Created</label>
          <input
            type="text"
            className="form-control"
            placeholder="Date Created"
            onChange={(e) => onValueChange(e)}
            name="DateCreated"
            value={DateCreated}
          />
        </div>

        <div className="form-group">
          <label>User ID</label>
          <input
            type="text"
            className="form-control"
            placeholder="User ID"
            onChange={(e) => onValueChange(e)}
            name="UserID"
            value={UserID}
          />
        </div>

        <div className="form-group">
          <label>Location ID</label>
          <input
            type="text"
            className="form-control"
            placeholder="Location ID"
            onChange={(e) => onValueChange(e)}
            name="LocationID"
            value={LocationID}
          />
        </div>
      </div>

      <button className="btn btn-primary btn-block">Edit</button>
    </form>

  );
};

export default Dataset_Edit;