import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_DATASET_REQUEST,
  GET_DATASET_REQUEST_SUCCESS,
  GET_DATASET_REQUEST_FAIL,
  ADD_DATASET_REQ_COMMENT,
} from "./actionTypes"

import {
  getDatasetRequest, getDatasetRequestSuccess, getDatasetRequestrofile, addCommentSuccessDataSetReq
} from "./actions"
// import { , addCommentDataSetReq } from 'store/actions'
//Include Both Helper File with needed methods
import { getDatasetRequestHelper, putAddCommentDataSetReqApi } from "../../helpers/backend_helper"
import { useDispatch, useSelector } from 'react-redux'


function* fetchDatasetRequest() {
  try {
    const response = yield call(getDatasetRequestHelper)
    yield put(getDatasetRequestSuccess(response))
  } catch (error) {
    yield put(getDatasetRequestrofile(error))
  }
}


function* commentDatasetRequest({ payload: user }) {

  try {
    const response = yield call(putAddCommentDataSetReqApi, user[0].url, { comment: user[1].comment, move_to_deal: user[1].move_to_deal })

    yield put(addCommentSuccessDataSetReq(response))
  } catch (error) {

    yield put(getDatasetRequestrofile(error))
  }
}


function* DatasetSaga() {
  yield takeEvery(GET_DATASET_REQUEST, fetchDatasetRequest)
  yield takeEvery(ADD_DATASET_REQ_COMMENT, commentDatasetRequest)
}

export default DatasetSaga;
