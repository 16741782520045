import { DELETE_FREELANCER_DATA, GET_FREELANCER_DATA, SEND_MAIL_TO_FREELANCER } from "./actionTypes";

export const getFreelancerData = () => ({
    type: GET_FREELANCER_DATA,
});

export const deleteFreelancerData = (id) => ({
    type: DELETE_FREELANCER_DATA,
    payload: { id }
});

export const sendMailToFreelancer = (data) => ({
    type: SEND_MAIL_TO_FREELANCER,
    payload: { data }
});