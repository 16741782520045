import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import {
  postLogin,
} from "../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }) {
  var response
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      var response = yield call(postLogin, user)

      yield put(loginSuccess(response));
      if (response.success) {
        history.push("/dashboard");
      } else {

      }
    }
  } catch (error) {

    yield put(apiError(error.response.data.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history.push("/admin_login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
