import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { get_All_Sell_Data } from 'store/actions';
import "./selldata.css";
// import { Loader } from 'rsuite';

import { Drawer, ButtonToolbar, Button } from 'rsuite';
// import { Table } from 'reactstrap';
import { Modal, Loader, RadioGroup, Radio, Placeholder } from 'rsuite';

function Selldata () {
    const [dataa, setdataa] = useState("");
    const [Loaderr, setLoaderr] = useState(true);
    const [RecordsNotAvalable, setRecordsNotAvalable] = useState(false);

    const [openn, setOpenn] = React.useState(false);
    const [backdrop, setBackdrop] = React.useState('static');
    const [messagePara, setmessagePara] = useState("-");



    const dispatch = useDispatch();

    const data = useSelector((state) => state.getSelldata.data);

    useEffect(() => {
        dispatch(get_All_Sell_Data());
    }, []);
    useEffect(() => {
        // console.log(data);
        if (data.success) {
            setdataa(data.result);
            setLoaderr(false);
            setRecordsNotAvalable(false);
            localStorage.setItem("sellDataNewMessage", data.result?.length);
        }
        if (data.success == false) {
            setLoaderr(false);
            setRecordsNotAvalable(true);
            localStorage.setItem("sellDataNewMessage", data.result?.length);
        }

    }, [data]);


    let i = 1;
    ///////////// This is modal
    const handleOpen = (e) => {
        for (let ii = 0; ii < data.result.length; ii++) {
            if (e.target.id == (ii + 2)) {
                setOpenn(true);
                setmessagePara(data.result[ii].message);
            }
        }
    };

    const handleClose = () => setOpenn(false);



    return (
        <>
            <div className='page-content'  >
                <h2 className='sellDataHeading main-heading mb-2'>
                    <span>
                        <span>
                            Sell Data
                        </span>
                    </span>
                </h2>
                <div className='selldata-container'>
                    <Table bordered responsive hover className='table-primary mb-0' >
                        <thead className=''>
                            <tr className="bgdark  sticktopp  " >
                                <th className="bgdark " >
                                    No.
                                </th>
                                <th className="bgdark ">
                                    Name
                                </th>
                                <th className="bgdark " >
                                    Email
                                </th>
                                <th className="bgdark " >
                                    Company Name
                                </th>
                                <th className="bgdark " >
                                    Data Types
                                </th>
                                <th className="bgdark " >
                                    Message
                                </th>
                                <th className="bgdark " >
                                    Date
                                </th>
                            </tr>
                        </thead>
                        {
                            RecordsNotAvalable ? <div className=' records-not-class'  >
                                Records are not available!
                            </div> : null
                        }

                        <tbody >
                            <tr>
                                {
                                    Loaderr ? <div className="  loaderposition">
                                        <Loader size="lg" className='' />
                                    </div> : null
                                }
                            </tr>
                            {/* style={{ height: "100px" }} */}
                            {data.success && data.result.map((x) => <tr key={i} >
                                <td   >
                                    {i++}
                                </td>
                                <td >
                                    {x.name ? x.name : "-"}
                                </td>
                                <td >
                                    {x.email ? x.email : "-"}
                                </td>
                                <td >
                                    {x.company_name ? x.company_name : "-"}
                                </td>
                                <td >
                                    {x.select_your_data_types ? x.select_your_data_types.toString() : "-"}
                                </td>
                                <td >
                                    <div className='d-flex'>
                                        <div className=' m-auto'>
                                            {x.message && x.message.length > 0 ? <ButtonToolbar>
                                                <Button className='bg-primary text-light h-50' id={i} onClick={handleOpen}> Read</Button>
                                            </ButtonToolbar> :
                                                "-"
                                            }
                                        </div>
                                    </div>
                                </td>
                                <td >
                                    {x.createdAt ? x.createdAt.split("T")[0].split("-").reverse().join("/") : "-"}
                                </td>
                            </tr>
                            )}

                        </tbody>
                    </Table>
                </div>
            </div>

            <Modal backdrop={backdrop} keyboard={false} open={openn} onClose={handleClose}>
                <Modal.Header>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <p>
                        {messagePara}
                    </p>
                </Modal.Body>
                <hr />
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="subtle" className='btn btn-primary'>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default Selldata;