import React from "react";

import {
    Card,
    CardBody,
    Col,
    Row,
    Container,
    Input,
    Badge,
} from "reactstrap";

import { Editor } from '@tinymce/tinymce-react';

const FormEditors = ({ data, editorContent, setEditorContent, subject, setSubject }) => {

    return (
        <React.Fragment>
            <div className="page-content py-0" style={{ scrollBehavior: 'smooth' }}>
                <Container fluid={true}>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Recipients</h4>
                                    <p className="card-title-desc"></p>
                                    <div className="d-flex w-100 flex-nowrap overflow-x-auto pb-2 gap-2">
                                        {data?.map(elm => {
                                            return (
                                                <Badge
                                                    color="primary"
                                                    pill
                                                    key={elm.id}
                                                    className="px-2 py-1"
                                                >
                                                    {elm.email}
                                                </Badge>
                                            );
                                        })}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Subject</h4>
                                    <p className="card-title-desc"></p>
                                    <div>
                                        <Input
                                            bsSize="lg"
                                            onChange={(e) => setSubject(e.target?.value)}
                                            value={subject}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Mail Description</h4>
                                    <p className="card-title-desc"></p>
                                    <div>
                                        <Editor
                                            apiKey='1t53r4wh9dvnipzkurp1gxj9ujs0d175czegbbohemtiflj8'
                                            onInit={(evt, editor) => {
                                                editor.on('focus', () => editor.getBody()?.scrollIntoView());
                                            }}
                                            onDes
                                            initialValue=""
                                            onEditorChange={(content, editor) =>
                                                setEditorContent(content)}
                                            init={{
                                                height: 500,
                                                branding: false,
                                                menubar: true,
                                                icons: "thin",
                                                plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons accordion',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                menubar: 'file edit view insert format tools table help',
                                                toolbar: "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
                                                autosave_ask_before_unload: true,
                                                autosave_interval: '30s',
                                                autosave_prefix: 'freelancer-mail-',
                                                autosave_restore_when_empty: false,
                                                autosave_retention: '2m',
                                                image_advtab: true,
                                                image_caption: true,
                                                quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                                noneditable_class: 'mceNonEditable',
                                                toolbar_mode: 'sliding',
                                                contextmenu: 'link image table',
                                                skin: 'oxide',
                                                content_css: 'default',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                                            }}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default FormEditors;