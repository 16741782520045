import {
  API_INDUSTRYSUCCESS,
  API_INDUSTRYFAIL,
  GET_INDUSTRY,
  ADD_NEW_INDUSTRY,
  UPDATE_INDUSTRY

} from "./actionTypes"

const INIT_STATE = {
  industry: [],
  addIndustryResponse: [],
}

const Industry = (state = INIT_STATE, action) => {
  // console.log(action)
  switch (action.type) {
    case API_INDUSTRYSUCCESS:
      switch (action.payload.actionType) {
        case GET_INDUSTRY:
          return {
            ...state,
            industry: action?.payload?.data,
          }
        default:
          return state
      }
    case API_INDUSTRYFAIL:
      switch (action.payload.actionType) {
        case GET_INDUSTRY:
          return {
            ...state,
            industryError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
export const addIndustry = (state = INIT_STATE, action) => {
  // console.log(action)
  switch (action.type) {
    case API_INDUSTRYSUCCESS:
      switch (action.payload.actionType) {
        case ADD_NEW_INDUSTRY:
          return {
            ...state,
            addIndustryResponse: action?.payload?.data,
          }

        default:
          return state
      }
    case API_INDUSTRYFAIL:
      switch (action.payload.actionType) {
        case ADD_NEW_INDUSTRY:
          return {
            ...state,
            industryError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
export const updateIndustry = (state = INIT_STATE, action) => {
  // console.log(action)
  switch (action.type) {
    case API_INDUSTRYSUCCESS:
      switch (action.payload.actionType) {
        case UPDATE_INDUSTRY:
          return {
            ...state,
            users: state.users.map(user =>
              user.id.toString() === action.payload.id.toString()
                ? { user, ...action.payload }
                : user
            ),
          }
        default:
          return state
      }
    case API_INDUSTRYFAIL:
      switch (action.payload.actionType) {
        case UPDATE_INDUSTRY:
          return {
            ...state,
            industryError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
export default Industry
