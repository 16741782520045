import {
  GET_DATASET_REQUEST,
  GET_DATASET_REQUEST_SUCCESS,
  GET_DATASET_REQUEST_FAIL,
  ADD_DATASET_REQ_COMMENT,
  ADD_DATASET_REQ_COMMENT_SUCCESS,
  ADD_DATASET_REQ_COMMENT_FAIL
} from "./actionTypes"

export const getDatasetRequest = () => ({
  type: GET_DATASET_REQUEST,
})

export const getDatasetRequestSuccess = users => (
  {
    type: GET_DATASET_REQUEST_SUCCESS,
    payload: users,
  })


export const getDatasetRequestrofile = () => ({
  type: GET_DATASET_REQUEST_FAIL,
})

export const addCommentDataSetReq = data => ({
  type: ADD_DATASET_REQ_COMMENT,
  payload: data,
})
export const addCommentSuccessDataSetReq = data => (
  {
    type: ADD_DATASET_REQ_COMMENT_SUCCESS,
    payload: data,
  })
export const addCommentFailDataSetReq = data => ({
  type: ADD_DATASET_REQ_COMMENT_FAIL,
  payload: data,
})
