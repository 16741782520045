import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDatasetById, updateDataset } from "../../store/actions";
import { message } from 'antd';
import Editor from '@monaco-editor/react';
import { useParams, useHistory, Prompt } from "react-router-dom";
import "./getDataSetByID.css";

const GetDataById = () => {
    const [data, setData] = useState("");
    const [Loading, setLoading] = useState(false);
    const [messageee, setMessageee] = useState(false);
    const [newData, setNewData] = useState("");

    let { _id } = useParams();
    const history = useHistory();

    const { DataByID, UpdateDataa } = useSelector(state => ({
        DataByID: state.getDatasetById?.datasetDataID,
        UpdateDataa: state.updateDataset.addDatasetResponse
    }));

    const dispatch = useDispatch();
    useEffect(() => {
        if (_id) {
            dispatch(getDatasetById(_id));
        }
    }, [_id]);

    useEffect(() => {
        const unloadCallback = (event) => {
            if (JSON.stringify(newData) !== JSON.stringify(data)) {
                event.preventDefault();
                event.returnValue = "";
                return "";
            }
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => {
            window.removeEventListener("beforeunload", unloadCallback);
        };
    }, [newData]);

    useEffect(() => {
        if (DataByID != "" && DataByID != undefined && DataByID.success) {
            setData(JSON.stringify(DataByID.result[0]));
        }
    }, [DataByID]);

    useEffect(() => {
        if (UpdateDataa && UpdateDataa != "" && UpdateDataa != undefined) {
            { messageee && success(); }
            setTimeout(() => {
                setLoading(false);
                setMessageee(false);
            }, 1000);
        }
    }, [updateDataset, UpdateDataa]);

    function UpdateData () {

        function isValidJSONString (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                error();
                return false;
            }
            return true;
        }

        if (isValidJSONString(newData)) {
            setMessageee(true);
            const updatedData = JSON.parse(newData);
            setLoading(true);
            dispatch(updateDataset(updatedData));
        }
    }

    const success = () => {
        message.success("Data Updated SuccessFully.");
    };
    const error = () => {
        message.error('JSON Data is not Valid.');
    };


    const handleEditorChange = (value) => {
        const newValue = JSON.parse(value);
        setNewData(JSON.stringify({ ...newValue, ...(!newValue?._id ? { _id } : {}) }));
    };

    return (
        <React.Fragment  >
            <Prompt
                message={(location, action) => {
                    if (action === 'POP' && JSON.stringify(newData) !== JSON.stringify(data)) {
                        return `Are you sure you want to leave? You have unsaved changes.`;
                    }
                }}
            />
            <div className="page-content"  >
                {/* <Container  > */}
                <div className="container containerr-text" >
                    <div className="mini-container" >
                        <h2 id="heading" className="dataset-heading main-heading ">
                            <span>
                                <span>
                                    Edit Data
                                </span>
                            </span>
                        </h2>
                        <div className=" d-flex-column  text-center  ">
                            <Editor id="myTextArea" height="75vh" defaultLanguage="json" value={data} onChange={(value) => handleEditorChange(value)} onMount={(editor) => setTimeout(() => {
                                editor.setScrollTop(0);
                                editor.getAction('editor.action.formatDocument').run();
                            }, 200)} />
                            <button type="button" onClick={UpdateData} className="btn btn-primary mt-1 button-main" >Save Data</button>
                        </div>
                    </div>
                    {Loading && <span style={{ position: 'absolute', width: "100px", height: "100px", left: "45%", top: "40%", textAlign: "center", zIndex: "1" }} className="spinner-border center-horizontally" role="status" />}
                </div>
                {/* </Container> */}
            </div>

        </React.Fragment>
    );
};

export default GetDataById;
