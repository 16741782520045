import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Table, Button, Container, Col, Row } from 'reactstrap';
import { addDeal, getCall } from 'store/actions';
import "./getcall.css";
import { Loader } from 'rsuite';
import { initialLifeCycle } from 'pages/Dataset Request/DatasetRequest';

function GetCall () {
    const dispatch = useDispatch();
    const [Loaderr, setLoaderr] = useState(true);
    const [RecordsNotAvalable, setRecordsNotAvalable] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [messageSuccess, setMessageSuccess] = useState(false);

    const { getCallData, deals, comment } = useSelector((state) => ({
        getCallData: state.getCallReducer.data,
        deals: state.DealsReducer.deals,
        comment: { success: false, result: [], message: "Records are not available" }, //state.datasetreqComment.users,
    }));

    useEffect(() => {
        dispatch(getCall());
    }, []);

    useEffect(() => {
        if (deals?.success && messageSuccess) {
            message.success(deals?.message);
            selectedRows.forEach((id) => {
                // let data = [{ url: id }, { move_to_deal: true }];
                // dispatch(addCommentDataSetReq(data));
                document.querySelector(`#row-${id}`).checked = false;
            });
            setMessageSuccess(false);
            // Remove selection of all the rows
            setSelectedRows([]);
        }
    }, [deals]);

    useEffect(() => {
        if (comment.success && messageSuccess) {
            dispatch(getCall());
            message.success('Comment added successfully.');
        }
        if (comment.success && !messageSuccess) {
            dispatch(getCall());
        }
    }, [comment]);

    useEffect(() => {
        if (getCallData.success) {
            setLoaderr(false);
            setRecordsNotAvalable(false);
            localStorage.setItem("GetCallNewMessage", getCallData.result?.length);
        }
        if (getCallData.success == false) {
            setLoaderr(false);
            setRecordsNotAvalable(true);
            localStorage.setItem("GetCallNewMessage", getCallData.result?.length);
        }

    }, [getCallData]);

    const changeRowSelection = (e, id) => {
        if (e.target.checked) {
            if (id === 'all') {
                const selectedRowsList = getCallData.result.map(elm => !elm?.move_to_deal ? elm?._id : false).filter(elm => elm);
                console.log(getCallData.result);
                setSelectedRows(selectedRowsList);
                selectedRowsList.forEach(elm => {
                    document.querySelector(`#row-${elm}`).checked = true;
                });
            } else {
                setSelectedRows(prev => [...prev, id]);
            }
        } else {
            if (id === 'all') {
                setSelectedRows([]);
                getCallData.result.forEach(elm => {
                    document.querySelector(`#row-${elm._id}`).checked = false;
                });
            } else {
                setSelectedRows(prev => [...prev.filter(elm => elm !== id)]);
            }
        }
    };

    const MoveToDeal = () => {
        let dealsData = [];
        for (let index = 0; index < selectedRows.length; index++) {
            const id = selectedRows[index];
            dealsData.push(...getCallData.result.map(elm => {
                if (elm._id === id) {
                    return {
                        "owner": JSON.parse(localStorage.getItem('authUser') || '{"result":[{"name":""}]}')?.result[0]?.name || '',
                        "client_name": elm?.name,
                        "email": elm?.email,
                        "request_date": new Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric'
                        }).format(new Date(elm?.createdAt)),
                        "from_where": elm?.form_fill,
                        "message": elm?.number,
                        "dataset_type": "",
                        "dataset_sub_type": '',
                        "language": [],
                        "industry": '',
                        "demography": [],
                        "type": [],
                        "sample_link": '',
                        "page_link": elm?.page_url,
                        "dataset_name": elm?.title,
                        "company_name": elm?.company_name,
                        "volume": elm?.volume,
                        "purpose": elm?.purpose,
                        "budget": elm?.tentative_budget,
                        "service": "",
                        "lifecycle": initialLifeCycle,
                        "client_type": "",
                        "last_activity": '',
                        "deal_status": 1,
                        "notes": elm?.comment ? elm?.comment : "",
                        "self_created": false,
                        "deal_from": 3,
                        "deal_from_id": elm?._id,
                    };
                } else {
                    return false;
                }
            }).filter(elm => elm));
        }
        const deals = {
            deals: dealsData
        };
        dispatch(addDeal(deals));
        setMessageSuccess(true);
    };


    let i = 1;
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box pb-2 d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18">Get a call</h4>
                                <Button color='primary' disabled={selectedRows.length <= 0} onClick={MoveToDeal}>Move to deal</Button>
                            </div>
                        </Col>
                    </Row>
                    <div className='getacall-container'>
                        <Table bordered responsive hover className='mb-0 table-primary' >
                            <thead>
                                <tr className='bgdark positionstick'>
                                    <th className='bgdark'><input type="checkbox" className='form-check-input mt-1' onChange={(e) => changeRowSelection(e, 'all')} /></th>
                                    <th className='bgdark' >
                                        No.
                                    </th>
                                    <th className='bgdark' >Name</th>
                                    <th className='bgdark' >Email</th>
                                    <th className='bgdark' >Company</th>
                                    <th className='bgdark col-2' >Contact Number</th>
                                </tr>


                            </thead>

                            <tbody className=''>
                                {
                                    RecordsNotAvalable ? <tr className='border-0'  >
                                        <td colSpan={6} className='text-center fs-4 bg-transparent text-primary border-0'>
                                            Records are not available!
                                        </td>
                                    </tr> : null
                                }
                                <tr>
                                    {
                                        Loaderr ? <td colSpan={6} className="  loaderposition">
                                            <Loader size="lg" className='' />
                                        </td> : null
                                    }
                                </tr>
                                {getCallData.success && getCallData.result.map((x) => <tr key={i} style={x?.move_to_deal ? { backgroundColor: "#a0e2c4" } : {}}>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>
                                        <input type="checkbox" id={`row-${x._id}`} className='form-check-input mt-1' onChange={(e) => changeRowSelection(e, x._id)} disabled={x?.move_to_deal} />
                                    </td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>{i++}</td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>{x.name ? x.name : "-"}</td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>{x.email ? x.email : "-"}</td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>{x.company_name ? x.company_name : "-"}</td>
                                    <td className={x?.move_to_deal ? 'bg-transparent' : ''}>{x.number ? x.number : "-"}</td>
                                </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default GetCall;