import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { Button, message, Space } from 'antd';

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import logo from "assets/images/logo.png";
import { isUserAuthenticated } from "helpers/backend_helper";
import { useHistory } from "react-router-dom";

const Login = props => {

  const [errorCondition, seterrorCondition] = useState(false);
  const [errorConditiontrue, seterrorConditiontrue] = useState(false);
  const [successs, setsuccesss] = useState(false);

  //meta title
  document.title = "Login | FutureBeeAI - Data Store";

  const dispatch = useDispatch();
  const history = useHistory();

  if (isUserAuthenticated()) {
    history.replace('/dashboard');
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
      seterrorConditiontrue(true);
    }
  });

  const { errorr } = useSelector(state => ({
    errorr: state.Login,
  }));

  useEffect(() => {
    // console.log(errorr);
    if (errorConditiontrue && errorr.error == "Incorrect Email") {
      seterrorCondition(errorr.error);
    } else if (errorConditiontrue && errorr.error == "Incorrect Password") {
      seterrorCondition(errorr.error);
    }

    if (errorr.success) {
      setsuccesss(errorr.success);
    }
  }, [loginUser, errorr]);

  const [typeOfPass, settypeOfPass] = useState("password");
  const [typeOfIcon, settypeOfIcon] = useState("far fa-eye");

  let password = document.getElementById("password");

  let toggle = () => {
    password.getAttribute("type") === "password" ? settypeOfPass("text") : settypeOfPass("password");
    password.getAttribute("type") == "password" ? settypeOfIcon("far fa-eye-slash") : settypeOfIcon("far  fa-eye");
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden" style={{ width: "450px" }}>
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h3 className="text-primary">FutureBeeAI</h3>

                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="72"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      <div style={{ height: "50px" }}>
                        {errorConditiontrue && errorr.error && errorr ? <Alert color="danger" style={{ width: "350px" }}>{typeof (errorr?.error) === "object" ? errorr?.error?.message : errorr?.error || ''}</Alert> : null}
                        {successs ? <Alert color="success" style={{ width: "350px" }}>{"Login Successfully"}</Alert> : null}
                      </div>
                      {/* <div className={`alert alert-${false ? "success" : "success"}  alert-dismissible fade ${false ? "show" : "hide"}`} role="alert">
                        {errorr.error}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div> */}


                      <div className="mb-3" style={{ width: "350px" }}>
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3" style={{ width: "350px", }}>
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type={typeOfPass}
                          id="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        <i className={`${typeOfIcon} m-2`} id="togglePassword" style={{
                          width: "30px", color: "#556ee6", position: "absolute", right: "47px",
                          top: "350px"
                        }} onClick={toggle}  ></i>
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      {/* <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div> */}

                      <div className="mt-3 d-grid" style={{ width: "350px" }}>
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        // onClick={error}
                        >
                          Log In
                        </button>
                      </div>


                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Copyright  © {new Date().getFullYear()} FutureBeeAI   All rights reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
