import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { UPDATE_PRIORITY } from "./actionTypes"
import { updatePrioritySuccess, updatePriorityError } from "./actions"

import {
  putUpdatePrioritySection,
} from "../../helpers/backend_helper"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* sectionnUpdate(user) {
  // console.log(user);
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      // console.log(user.payload.user);
      const response = yield call(putUpdatePrioritySection,
        user.payload.url,

        user.payload.user

      )
      if (response) {
        yield put(
          updatePrioritySuccess(
            response
            // "Reset link are sended to y/our mailbox, check there first"
          )
        )
      }
    }
  } catch (error) {
    yield put(updatePriorityError(error))
  }
}

export function* watchUserSectionUpdate() {
  yield takeEvery(UPDATE_PRIORITY, sectionnUpdate)
}

function* SectionUpdateSaga() {
  yield all([fork(watchUserSectionUpdate)])
}

export default SectionUpdateSaga
