import {
  API_INDUSTRYSUCCESS,
  API_INDUSTRYFAIL,
  GET_INDUSTRY,
  ADD_NEW_INDUSTRY,
  UPDATE_INDUSTRY,
} from "./actionTypes"

export const apiIndustrySuccess = (actionType, data) => ({
  type: API_INDUSTRYSUCCESS,
  payload: { actionType, data },
})

export const apiIndustryFail = (actionType, error) => ({
  type: API_INDUSTRYFAIL,
  payload: { actionType, error },
})

export const addNewIndustry = periodType => ({
  type: ADD_NEW_INDUSTRY,
  payload: periodType,
})
// Industry data

export const getIndustry = periodType => ({
  type: GET_INDUSTRY,
  payload: periodType,
})
export const updateIndustry = user => ({
  type: UPDATE_INDUSTRY,
  payload: industry,
})