import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postRegister = data => {
  return axios
    .post(url.POST_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);

// postForgetPwd
const postForgetPwd = data => post(url.POST_PASSWORD_FORGET, data);

const postProfile = data => post(url.POST_EDIT_PROFILE, data);


// get contacts
export const getUsers = () => get(url.GET_USERS);

// add user
export const addNewUser = user => post(url.ADD_NEW_USER, user);

// update user
export const updateUser = user => put(url.UPDATE_USER, user);

// delete user
export const deleteUser = user => del(url.DELETE_USER, { headers: { user } });

/** PROJECT */

// get project
export const getProjects = () => get(url.GET_PROJECTS);

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } });

// add project
export const addNewProject = project => post(url.ADD_NEW_PROJECT, project);

// update project
export const updateProject = project => put(url.UPDATE_PROJECT, project);

// delete project
export const deleteProject = project =>
  del(url.DELETE_PROJECT, { headers: { project } });

export const getUserProfile = () => get(url.GET_USER_PROFILE);

export const addNewDataset = data => post(url.ADD_NEW_DATASET, data);
export const addNewCategory = data => post(url.ADD_NEW_CATEGORY, data);
export const getCategory = () => get(url.GET_CATEGORY);
export const getDataset = () => get(url.GET_DATASET);
export const getDatasetMinimalDetails = () => get(url.GET_DATASET_MINIMAL_DETAILS);
export const getIndustry = () => get(url.GET_INDUSTRY);
export const addNewIndustry = data => post(url.ADD_NEW_INDUSTRY, data);

export const updateDataset = (data) => put(`${url.UPDATE_DATASET}/${data._id}`, data);
export const updateDatasetTwoHide = (data, data1) => put(`${url.UPDATE_DATASET_TWO_SHOW}/${data}`, data1);
export const getDatasetById = (id) => get(`${url.GET_DATASET_BY_ID}/${id}`);

export const getDatasetByIdTwoHide = (ID) => get(`${url.GET_DATASET_BY_ID_TWO_SHOW}/${ID}`);

// blog
export const addNewBlog = data => post(url.ADD_BLOG, data);
export const getAllBlogs = () => get(url.GET_ALL_BLOG);


export const getDashboardData = () => get(url.GET_DASHBOARD_DATA);

// get tasks
export const getTasks = () => get(url.GET_TASKS);


export const getSection = (id) => get(`${url.GET_SECTION}/${id}`);


export const putUpdatePrioritySection = (urll, data) => put(`${url.PUT_UPDATE_PRIORITY_SECTION}/${urll}`, data);

export const getContactUsHelper = () => get(url.GET_CONTACT_US_HELPER);
export const deleteContactUsHelper = (id) => del(`${url.DELETE_CONTACT_US_HELPER}/${id}`);

export const getSubscriberHelper = () => get(url.GET_SUBSCRIBER_HELPER);
export const getDatasetRequestHelper = () => get(url.GET_DATASET_REQUEST_ALL);
export const deleteDatasetRequestHelper = (id) => del(`${url.DELETE_DATASET_REQUEST_HELPER}/${id}`);

export const addDealData = data => post(url.ADD_DEAL, data);
export const updateDealData = (id, data) => put(`${url.ADD_DEAL}/${id}`, data);
export const deleteDealData = id => del(`${url.ADD_DEAL}/${id}`);
export const getDealData = () => get(url.GET_DEAL);
export const getDealDataById = (id) => get(`${url.ADD_DEAL}/${id}`);


//freelancer and vendor  
export const getFreelancerVendors = () => get(url.GET_FREELANCER_VENDOR);
export const deleteFreelancerVendors = (id) => del(`${url.DELETE_FREELANCER_VENDOR}/${id}`);
export const sendCustomMailToFreelancer = (data) => post(`${url.SEND_CUSTOM_MAIL_TO_FREELANCERS}`, data)

//get a call 
export const getCallHelper = () => get(url.GET_A_CALL_API);
// get all  community request 
export const getCommunityHelper = () => get(url.GET_ALL_COMMUNITY);
// get all case studies
export const getCaseStudiesHelper = () => get(url.GET_CASE_STUDIES);
// get yugo
export const getYugoHelper = () => get(url.GET_YUGO);

// get sell all data 
export const getSellDataHelper = () => get(url.GET_SELL_DATA);
export const putAddCommentApi = (id, data) => put(`${url.ADD_COMMENT_CONTACT_US}/${id}`, data);
export const putAddCommentDataSetReqApi = (id, data) => put(`${url.ADD_DATASET_REQUEST}/${id}`, data);

export {
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  postProfile,
  postForgetPwd,
};
