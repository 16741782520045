import React, { useState, useRef, useCallback } from 'react';
import Select, { components } from 'react-select';

const Option = (props) => (
    <components.Option {...props} className='d-flex align-items-center gap-2'>
        {props.value === "*" ? (
            <input
                className="form-check-input clearfix m-0"
                key={props.value}
                type="checkbox"
                ref={(input) => {
                    if (input) input.indeterminate = props.getValue().length > 0 && props.getValue().length !== (props.options.length - 1);
                }}
                checked={props.getValue().length === (props.options.length - 1)}
                onChange={() => { }}
            />
        ) : (
            <input
                className="form-check-input clearfix m-0"
                key={props.value}
                type="checkbox"
                checked={props.isSelected || (props.getValue().length === props.options.length - 1)}
                onChange={() => { }}
            />
        )}
        <label style={{ margin: "0" }}>{props.label}</label>
    </components.Option>
);

const filterOptions = (options, input) => {
    return options?.length ? options?.filter((elm) => {
        return elm?.label?.toLowerCase()?.includes(input?.toLowerCase());
    }
    ) : [];
};

const comparator = (v1, v2) => {
    const a = v1.value;
    const b = v2.value;
    // Extract numbers from the strings
    let numA = a.match(/\d+/);
    let numB = b.match(/\d+/);

    // If both have numbers, compare them numerically
    if (numA && numB) {
        return parseInt(numA[0]) - parseInt(numB[0]);
    }
    // If only one has a number, it comes first
    if (numA) return -1;
    if (numB) return 1;

    // Otherwise, compare lexicographically
    return a.localeCompare(b);
};

const MultiSelect = (props) => {
    const [selectInput, setSelectInput] = useState("");
    const isAllSelected = useRef(false);
    const selectAllLabel = useRef("Select all");
    const allOption = { value: "*", label: selectAllLabel.current };

    let filteredOptions = filterOptions(props.options?.sort(comparator), selectInput);
    let filteredSelectedOptions = filterOptions(props.value, selectInput);

    const Input = (props) => (
        <>
            {selectInput.length === 0 ? (
                <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                    {props.children}
                </components.Input>
            ) : (
                <div style={{ border: "1px dotted gray" }}>
                    <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                        {props.children}
                    </components.Input>
                </div>
            )}
        </>
    );

    const customFilterOption = ({ value, label }, input) =>
        (value !== "*" && label.toLowerCase().startsWith(input.toLowerCase())) ||
        (value === "*" && filteredOptions?.length > 0);

    const onInputChange = (inputValue, event) => {
        if (event.action === "input-change") setSelectInput(inputValue);
        else if (event.action === "menu-close" && selectInput !== "")
            setSelectInput("");
    };

    const onKeyDown = (e) => {
        if ((e.key === " " || e.key === "Enter") && !selectInput)
            e.preventDefault();
    };

    const handleChange = (selected) => {
        if (
            selected.length > 0 &&
            !isAllSelected.current &&
            (selected[selected.length - 1].value === allOption.value ||
                JSON.stringify(filteredOptions) ===
                JSON.stringify(selected.sort(comparator)))
        )
            return props.onChange(
                [
                    ...(props.value ?? []),
                    ...props.options.filter(
                        ({ label }) =>
                            label.toLowerCase().includes(selectInput?.toLowerCase()) &&
                            (props.value ?? []).filter((opt) => opt.label === label)
                                .length === 0
                    ),
                ].sort(comparator)
            );
        else if (
            selected.length > 0 &&
            selected[selected.length - 1].value !== allOption.value &&
            JSON.stringify(selected.sort(comparator)) !==
            JSON.stringify(filteredOptions)
        )
            return props.onChange(selected);
        else
            return props.onChange([
                ...props.value?.filter(
                    ({ label }) =>
                        !label.toLowerCase().includes(selectInput?.toLowerCase())
                ),
            ]);
    };

    const customStyles = {
        multiValueLabel: (def) => ({
            ...def,
            backgroundColor: "lightgray",
        }),
        multiValueRemove: (def) => ({
            ...def,
            backgroundColor: "lightgray",
        }),
        valueContainer: (base) => ({
            ...base,
            maxHeight: "65px",
            overflow: "auto",
        }),
        option: (styles, { isSelected, isFocused }) => {
            return {
                ...styles,
                backgroundColor:
                    isSelected && !isFocused
                        ? null
                        : isFocused && !isSelected
                            ? styles.backgroundColor
                            : isFocused && isSelected
                                ? "#DEEBFF"
                                : null,
                color: isSelected ? null : null,
            };
        },
        menu: (def) => ({ ...def, zIndex: 9999 }),
    };

    if (props.isSelectAll && props.options.length !== 0) {
        isAllSelected.current =
            JSON.stringify(filteredSelectedOptions) ===
            JSON.stringify(filteredOptions);

        if (filteredSelectedOptions?.length > 0) {
            if (filteredSelectedOptions?.length === filteredOptions?.length)
                selectAllLabel.current = `All (${filteredOptions.length}) selected`;
            else
                selectAllLabel.current = `${filteredSelectedOptions?.length} / ${filteredOptions.length} selected`;
        } else selectAllLabel.current = "Select all";

        allOption.label = selectAllLabel.current;

        return (
            <Select
                {...props}
                inputValue={selectInput}
                onInputChange={onInputChange}
                onKeyDown={onKeyDown}
                options={[allOption, ...props.options?.sort(comparator)]}
                onChange={handleChange}
                components={{
                    Option: Option,
                    Input: Input,
                    ...props.components,
                }}
                filterOption={customFilterOption}
                menuPlacement={props.menuPlacement ?? "auto"}
                styles={customStyles}
                isMulti
                closeMenuOnSelect={false}
                tabSelectsValue={false}
                backspaceRemovesValue={false}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
            />
        );
    }

    return (
        <Select
            {...props}
            inputValue={selectInput}
            onInputChange={onInputChange}
            filterOption={customFilterOption}
            components={{
                Input: Input,
                ...props.components,
            }}
            isMulti
            menuPlacement={props.menuPlacement ?? "auto"}
            onKeyDown={onKeyDown}
            tabSelectsValue={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            backspaceRemovesValue={false}
            blurInputOnSelect={true}
        />
    );
};

export default MultiSelect;
