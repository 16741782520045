import { getCommunityHelper } from "helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { GET_COMMUNITY_REQUEST, GET_COMMUNITY_REQUEST_FAIL, GET_COMMUNITY_REQUEST_SUCCESS } from "./actionTypes"



function* communitySagaa() {

    try {
        const response = yield call(getCommunityHelper)

        // console.log(response);
        yield put({ type: GET_COMMUNITY_REQUEST_SUCCESS, response })
    } catch (error) {
        yield put({ type: GET_COMMUNITY_REQUEST_FAIL, error })
    }
}


function* communitySaga() {
    yield takeEvery(GET_COMMUNITY_REQUEST, communitySagaa)

}





export default communitySaga 