import {
  ADD_DEAL,
  UPDATE_DEAL,
  DELETE_DEAL,
  GET_DEAL,
  DEAL_SUCCESS,
  DEAL_FAIL,
  GET_DEAL_BY_ID,
} from "./actionTypes";

export const dealSuccess = (deals) => ({
  type: DEAL_SUCCESS,
  payload: deals
});

export const dealFail = (deals) => ({
  type: DEAL_FAIL,
  payload: deals
});

export const addDeal = (deals) => ({
  type: ADD_DEAL,
  payload: deals
});

export const updateDeal = (id, deals) => ({
  type: UPDATE_DEAL,
  payload: { id, deals },
});
export const deleteDeal = (id) => (
  {
    type: DELETE_DEAL,
    payload: id,
  });
export const getDeal = () => ({
  type: GET_DEAL,
});
export const getDealById = (id) => ({
  type: GET_DEAL_BY_ID,
  payload: id,
});