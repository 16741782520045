import { getYugoHelper } from "helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import {
    GET_YUGO, GET_YUGO_FAIL,
    GET_YUGO_SUCCESS
} from "./actionTypes"



function* YugoSagaa() {

    try {
        const response = yield call(getYugoHelper)

        // console.log(response);
        yield put({ type: GET_YUGO_SUCCESS, response })
    } catch (error) {
        yield put({ type: GET_YUGO_FAIL, error })
    }
}


function* YugoSaga() {
    yield takeEvery(GET_YUGO, YugoSagaa)

}





export default YugoSaga 