import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  DELETE_DATASET_REQUEST,
  DELETE_DATASET_REQUEST_SUCCESS,
  DELETE_DATASET_REQUEST_FAIL
} from "./actionTypes"

import {
  deleteDatasetRequest, deleteDatasetRequestSuccess, deleteDatasetRequestrofile
} from "./actions"

//Include Both Helper File with needed methods
import { deleteDatasetRequestHelper, getDatasetRequestHelper } from "../../helpers/backend_helper"
import { getDatasetRequest, getDatasetRequestSuccess, getDatasetRequestrofile } from "store/actions";

function* fetchDatasetRequest({ payload: id }) {
  // console.log(id);
  try {
    const response = yield call(deleteDatasetRequestHelper, id)
    // console.log(response);

    try {
      const response1 = yield call(getDatasetRequestHelper)

      yield put(getDatasetRequestSuccess(response1))
    } catch (error) {
      yield put(getDatasetRequestrofile(error))
    }


    yield put(deleteDatasetRequestSuccess(response))
  } catch (error) {
    yield put(deleteDatasetRequestrofile(error))
  }
}


function* DeleteDatasetRequestSaga() {
  yield takeEvery(DELETE_DATASET_REQUEST, fetchDatasetRequest)
}

export default DeleteDatasetRequestSaga;
