import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addNewDataset } from "../../store/actions";
import { message } from 'antd';
import Editor from '@monaco-editor/react';
import "./getDataSetByID.css";
import { useHistory, Prompt } from 'react-router-dom';

const AddDatasetWithJson = () => {
    const [data, setData] = useState("");

    const { addDataResponse } = useSelector(state => ({
        addDataResponse: state.addDataset?.addDatasetResponse,
    }));

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const unloadCallback = (event) => {
            if (data?.trim()?.length > 0) {
                event.preventDefault();
                event.returnValue = "";
                return "";
            }
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => {
            window.removeEventListener("beforeunload", unloadCallback);
        };
    }, [data]);

    useEffect(() => {
        if (addDataResponse) {
            if (addDataResponse?.success) {
                success(addDataResponse?.message);
            } else if (addDataResponse?.success === false) {
                message.error(addDataResponse?.message || 'Something went wrong!');
            }
        }
    }, [addDataResponse]);

    function addData () {

        function isValidJSONString (str) {
            try {
                const data = JSON.parse(str);
                if (!Array.isArray(data)) {
                    throw "Data must be array of objects";
                }
            } catch (e) {
                error(e);
                return false;
            }
            return true;
        }

        if (isValidJSONString(data)) {
            const updatedData = {
                dataset: JSON.parse(data)
            };
            message.loading("Adding new dataset...");
            // console.log(newData,'====newData====')
            dispatch(addNewDataset(updatedData));
        }
    }

    const success = (msg) => {
        message.success(msg || "Data Updated SuccessFully.");
    };
    const error = (err) => {
        message.error(err || 'JSON Data is not Valid.');
    };


    const handleEditorChange = (value) => {
        setData(value);
    };

    return (
        <React.Fragment  >
            <Prompt
                message={(location, action) => {
                    if (action === 'POP' && data?.trim()?.length) {
                        return `Are you sure you want to leave? You have unsaved changes.`;
                    }
                }}
            />
            <div className="page-content"  >
                <div className="container containerr-text" >
                    <div className="mini-container" >
                        <h2 id="heading" className="dataset-heading main-heading ">
                            <span>
                                <span>
                                    Add Dataset
                                </span>
                            </span>
                        </h2>
                        <div className=" d-flex-column  text-center  mt-3">
                            <Editor id="myTextArea" height="75vh" defaultLanguage="json" value={data} onChange={(value) => handleEditorChange(value)} onMount={(editor) => setTimeout(() => {
                                editor.setScrollTop(0);
                                editor.getAction('editor.action.formatDocument').run();
                            }, 200)} />
                            <button type="button" onClick={addData} className="btn btn-primary mt-3 button-main" >Add Dataset</button>
                        </div>
                    </div>
                    {/* {Loading && <span style={{ position: 'absolute', width: "100px", height: "100px", left: "45%", top: "40%", textAlign: "center", zIndex: "1" }} className="spinner-border center-horizontally" role="status" />} */}
                </div>
            </div>

        </React.Fragment>
    );
};

export default AddDatasetWithJson;
