import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import { ADD_BLOG, API_BLOG_FAIL, API_BLOG_SUCCESS, GET_ALL_BLOG } from "./actionTypes"
import { apiBlogSuccess, apiBlogFail } from "./actions"

//Include Both Helper File with needed methods
import { addNewBlog, getAllBlogs } from "../../helpers/backend_helper"

function* addBlog({ payload: blog }) {
  try {
    const response = yield call(addNewBlog, blog)
    // console.log(response)
    yield put(apiBlogSuccess(API_BLOG_SUCCESS, response))
  } catch (error) {
    yield put(apiBlogFail(API_BLOG_FAIL, error))
  }
}
function* getBlog() {
  try {
    const response = yield call(getAllBlogs)
    // console.log(response)
    yield put(apiBlogSuccess(GET_ALL_BLOG, response))
  } catch (error) {
    yield put(apiBlogFail(API_BLOG_FAIL, error))
  }
}

export function* watchaddNewBlog() {
  yield takeEvery(ADD_BLOG, addBlog)
  yield takeEvery(GET_ALL_BLOG, getBlog)
}

function* blogSaga() {
  yield all([fork(watchaddNewBlog)])

}

export default blogSaga
