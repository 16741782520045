import {
    GET_CASE_STUDIES, GET_CASE_STUDIES_FAIL,
    GET_CASE_STUDIES_SUCCESS
} from "./actionTypes";


const initialState = {
    data: []
}

export default (state = initialState, action) => {

    switch (action.type) {


        case GET_CASE_STUDIES_SUCCESS:
            return { ...state, data: action.response }


        default:
            return state
    }
}


