import {
    GET_YUGO, GET_YUGO_FAIL,
    GET_YUGO_SUCCESS
} from "./actionTypes";


const initialState = {
    data: []
}

export default (state = initialState, action) => {

    switch (action.type) {


        case GET_YUGO_SUCCESS:
            return { ...state, data: action.response }


        default:
            return state
    }
}


