// import { use } from "i18next";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteFreelancerData, getFreelancerData, sendMailToFreelancer } from "store/actions";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "./freelancer.css";
// import Item from "antd/lib/list/Item";
import { Badge, Table, Modal as RSModal, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, Spinner } from "reactstrap";
import "rsuite/dist/rsuite.css";
import { Loader } from "rsuite";
// import { split } from "lodash";
import { Button } from "rsuite";
// import { Modal } from "rsuite";
import { Pagination } from "rsuite";
import { Tooltip, message } from "antd";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import MultiSelect from "components/Common/MultiSelectWithCheckbox";
import Swal from "sweetalert2";
import { FaDownload } from "react-icons/fa6";
import * as XLSX from 'xlsx';
import { TbMailShare } from "react-icons/tb";
import FormEditors from "common/FormEditor";

const textCapitalize = (text) => {
    return text?.trim()?.split(' ')?.map(elm => elm.slice(0, 1)?.toUpperCase() + elm.slice(1))?.join(' ');
};

const birthCountryOptions = (data) => {
    const birthCountries = new Set();
    for (const elm of data || []) {
        if (elm.birth_country?.trim()) {
            birthCountries.add(elm.birth_country?.trim());
        }
    }
    return Array.from(birthCountries).sort().map(elm => ({ label: elm, value: elm }));
};

const currentCountryOptions = (data) => {
    const currentCountries = new Set();
    for (const elm of data || []) {
        if (elm.current_country?.trim()) {
            currentCountries.add(elm.current_country?.trim());
        }
    }
    return Array.from(currentCountries).sort().map(elm => ({ label: elm, value: elm }));
};

const teamCountryOptions = (data) => {
    const teamCountries = new Set();
    for (const elm of data || []) {
        if (elm.team_countries) {
            for (const country of elm.team_countries) {
                if (country?.trim()) {
                    teamCountries.add(country?.trim());
                }
            }
        } else if (elm.working_team_country) {
            for (const country of elm.working_team_country) {
                if (country?.trim()) {
                    teamCountries.add(country?.trim());
                }
            }
        }
    }
    return Array.from(teamCountries).sort().map(elm => ({ label: elm, value: elm }));
};

const countryOptions = (data) => {
    const allCountries = new Set();
    for (const elm of data || []) {
        if (elm.birth_country) {
            allCountries.add(textCapitalize(elm.birth_country));
        }
        if (elm.current_country) {
            allCountries.add(textCapitalize(elm.current_country));
        }
        if (elm.team_countries) {
            for (const country of elm.team_countries) {
                if (country) {
                    allCountries.add(textCapitalize(country));
                }
            }
        }
        if (elm.working_team_country) {
            for (const country of elm.working_team_country) {
                if (country) {
                    allCountries.add(textCapitalize(country));
                }
            }
        }
        if (elm.country) {
            allCountries.add(textCapitalize(elm.country));
        }
    }
    return Array.from(allCountries).sort().map(elm => ({ label: elm, value: elm }));
};

const birthStateOptions = (data) => {
    const birthStates = new Set();
    for (const elm of data || []) {
        if (elm.birth_state) {
            birthStates.add(elm.birth_state);
        }
    }
    return Array.from(birthStates).sort().map(elm => ({ label: elm, value: elm }));
};

const currentStateOptions = (data) => {
    const currentStates = new Set();
    for (const elm of data || []) {
        if (elm.current_state) {
            currentStates.add(elm.current_state);
        }
    }
    return Array.from(currentStates).sort().map(elm => ({ label: elm, value: elm }));
};

const birthCityOptions = (data) => {
    const birthCities = new Set();
    for (const elm of data || []) {
        if (elm.birth_city?.trim()) {
            birthCities.add(elm.birth_city?.trim());
        }
    }
    return Array.from(birthCities).sort().map(elm => ({ label: elm, value: elm }));
};

const currentCityOptions = (data) => {
    const currentCities = new Set();
    for (const elm of data || []) {
        if (elm.current_city?.trim()) {
            currentCities.add(elm.current_city?.trim());
        }
    }
    return Array.from(currentCities).sort().map(elm => ({ label: elm, value: elm }));
};

const nativeLanguageOptions = (data) => {
    const nativeLanguages = new Set();
    for (const elm of data || []) {
        if (elm.native_language?.trim()) {
            nativeLanguages.add(textCapitalize(elm.native_language?.trim()));
        }
    }
    return Array.from(nativeLanguages).sort().map(elm => ({ label: elm, value: elm }));
};

const otherLanguagesOptions = (data) => {
    const otherLanguages = new Set();
    for (const elm of data || []) {
        if (elm.other_languages) {
            for (const language of elm.other_languages) {
                if (language?.trim()) {
                    otherLanguages.add(textCapitalize(language?.trim()));
                }
            }
        }
    }
    return Array.from(otherLanguages).sort().map(elm => ({ label: elm, value: elm }));
};

const teamLanguagesOptions = (data) => {
    const teamLanguages = new Set();
    for (const elm of data || []) {
        if (elm.vendor_languages) {
            for (const language of elm.vendor_languages) {
                if (language?.trim()) {
                    teamLanguages.add(textCapitalize(language?.trim()));
                }
            }
        }
    }
    return Array.from(teamLanguages).sort().map(elm => ({ label: elm, value: elm }));
};

const languageOptions = (data) => {
    const allLanguages = new Set();
    for (const elm of data || []) {
        if (elm.native_language) {
            allLanguages.add(textCapitalize(elm.native_language?.trim()));
        }
        if (elm.other_languages) {
            for (const language of elm.other_languages) {
                if (language?.trim()) {
                    if (language.includes(',')) {
                        language.split(',').forEach(elm => allLanguages.add(textCapitalize(elm?.trim())));
                    } else {
                        allLanguages.add(textCapitalize(language?.trim()));
                    }
                }
            }
        }
        if (elm.vendor_languages) {
            for (const language of elm.vendor_languages) {
                if (language?.trim()) {
                    allLanguages.add(textCapitalize(language?.trim()));
                }
            }
        }
        if (elm.language) {
            for (const language of elm.language) {
                if (language?.trim()) {
                    allLanguages.add(textCapitalize(language?.trim()));
                }
            }
        }
    }
    return Array.from(allLanguages).flat().sort().map(elm => ({ label: elm, value: elm }));
};

const interestedInExperienceOptions = (types) => {
    const optionsMap = {
        "Transcription": [
            { label: 'Tr: No Experience', value: 'Tr: No Experience' },
            { label: 'Tr: 1 to 3 Years', value: 'Tr: 1 to 3 Years' },
            { label: 'Tr: 3+ Years', value: 'Tr: 3+ Years' },
        ],
        "Annotation": [
            { label: 'An: No Experience', value: 'An: No Experience' },
            { label: 'An: 1 to 3 Years', value: 'An: 1 to 3 Years' },
            { label: 'An: 3+ Years', value: 'An: 3+ Years' },
        ],
        "Translation": [
            { label: 'Ts: No Experience', value: 'Ts: No Experience' },
            { label: 'Ts: 1 to 3 Years', value: 'Ts: 1 to 3 Years' },
            { label: 'Ts: 3+ Years', value: 'Ts: 3+ Years' },
        ],
        "Audio Recording": [
            { label: 'Ar: No Experience', value: 'Ar: No Experience' },
            { label: 'Ar: 1 to 3 Years', value: 'Ar: 1 to 3 Years' },
            { label: 'Ar: 3+ Years', value: 'Ar: 3+ Years' },
        ],
        "Data Collection": [
            { label: 'Dc: No Experience', value: 'Dc: No Experience' },
            { label: 'Dc: 1 to 3 Years', value: 'Dc: 1 to 3 Years' },
            { label: 'Dc: 3+ Years', value: 'Dc: 3+ Years' },
        ]
    };
    return types.flatMap(type => optionsMap[type] || []);
};

const osOptions = (data) => {
    const osSet = new Set();
    for (const elm of data || []) {
        if (elm.os?.trim() && elm.os !== 'Android & iOS') {
            osSet.add(elm.os?.trim());
        }
    }
    return Array.from(osSet).sort().map(elm => ({ label: elm, value: elm }));
};

const mobileBrandOptions = (data, types) => {
    const brandSet = new Set();
    for (const elm of data || []) {
        if (types.includes(elm?.os) && elm?.brand?.length) {
            for (const brandName of elm.brand) {
                if (brandName?.trim()) {
                    brandSet.add(brandName?.trim());
                }
            }
        }
    }
    return Array.from(brandSet).sort().map(elm => ({ label: elm, value: elm }));
};

const educationOptions = (data) => {
    const educationSet = new Set();
    for (const elm of data || []) {
        if (elm.education?.trim()) {
            educationSet.add(elm.education?.trim()?.toLowerCase());
        }
    }
    return Array.from(educationSet).sort().map(elm => ({ label: elm?.toLowerCase(), value: elm?.toLowerCase() }));
};

const occupationOptions = (data) => {
    const occupationSet = new Set();
    for (const elm of data || []) {
        if (elm.occupation?.trim()?.trim()) {
            occupationSet.add(elm.occupation?.trim()?.toLowerCase());
        }
    }
    return Array.from(occupationSet).sort().map(elm => ({ label: elm?.toLowerCase(), value: elm?.toLowerCase() }));
};

const domainOptions = (data) => {
    const domainSet = new Set();
    for (const elm of data || []) {
        if (elm.domain?.trim()) {
            domainSet.add(elm.domain?.trim()?.toLowerCase());
        }
    }
    return Array.from(domainSet).sort().map(elm => ({ label: elm?.toLowerCase(), value: elm?.toLowerCase() }));
};

const hearAboutOptions = (data) => {
    const hearAboutSet = new Set();
    for (const elm of data || []) {
        if (elm.hear_about_us?.trim() && elm.hear_about_us !== "Other (Please Specify)") {
            hearAboutSet.add(elm.hear_about_us?.trim()?.toLowerCase());
        }
    }
    return Array.from(hearAboutSet).sort().map(elm => ({ label: elm?.toLowerCase(), value: elm?.toLowerCase() }));
};

const interestedInOptions = [
    { label: "Transcription", value: "transcription" },
    { label: "Annotation", value: "annotation" },
    { label: "Translation", value: "translation" },
    { label: "Audio Recording", value: "audioRecording" },
    { label: "Data Collection", value: "dataCollection" },
];

const domainExperienceOptions = [
    { label: "No Experience", value: "No Experience" },
    { label: "1 to 3 Years", value: "1 to 3 Years" },
    { label: "3+ Years", value: "3+ Years" },
];

const livingYearOptions = [
    { label: "1 to 5 Years", value: "1 to 5 Years" },
    { label: "5 to 15 Years", value: "5 to 15 Years" },
    { label: "15+ Years", value: "15+ Years" },
];

const isDateInRange = (date, start, end) => {
    const parseDate = (str) => {
        const [day, month, year] = str.split('/').map(Number);
        return new Date(year, month - 1, day);
    };

    const targetDate = parseDate(date);
    const startDateObj = parseDate(start);
    const endDateObj = parseDate(end);

    return targetDate >= startDateObj && targetDate <= endDateObj;
};

const copyToClipboard = (copyText) => {
    try {
        navigator.clipboard.writeText(copyText);
        message.success("Text Copied!");
    } catch (err) {
        message.error('Something went wrong!');
    }
};

let searchAndFilterSequence;
function FreelancerAndVendor () {
    const [Loaderr, setLoaderr] = useState(true);
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    // const [sortOrder, setSortOrder] = useState("asc"); // Add sorting state
    const [deleteDataId, setDeleteDataId] = useState();
    const [activePage, setActivePage] = React.useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchFilterData, setSearchFilterData] = useState([]);
    const [mainFilterData, setMainFilterData] = useState([]);
    // const [searchAndFilterSequence, setSearchAndFilterSequence] = useState();

    // const [modalData, setModalData] = useState("");
    // const [open, setOpen] = useState(false);
    // const [openn, setOpenn] = React.useState(false);
    // const [backdrop, setBackdrop] = React.useState("static");
    // const [messagePara, setmessagePara] = useState("-");
    // const [NameOfDrawer, setNameOfDrawer] = useState("");
    // const [openIntrustedin, setOpenIntrustedin] = React.useState(false);
    // const [openLanguage, setopenLanguage] = React.useState(false);
    // const [ListItemNumber, setListItemNumber] = React.useState(1);

    // Filter States
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [birthCountry, setBirthCountry] = useState([]);
    const [currentCountry, setCurrentCountry] = useState([]);
    const [teamCountry, setTeamCountry] = useState([]);
    const [country, setCountry] = useState([]);
    const [livingYears, setLivingYears] = useState([]);
    const [birthState, setBirthState] = useState([]);
    const [currentState, setCurrentState] = useState([]);
    const [birthCity, setBirthCity] = useState([]);
    const [currentCity, setCurrentCity] = useState([]);
    const [nativeLanguage, setNativeLanguage] = useState([]);
    const [otherLanguages, setOtherLanguages] = useState([]);
    const [teamLanguages, setTeamLanguages] = useState([]);
    const [language, setLanguage] = useState([]);
    const [interestedIn, setInterestedIn] = useState([]);
    const [interestedInExperience, setInterestedInExperience] = useState([]);
    const [os, setOS] = useState([]);
    const [brand, setBrand] = useState([]);
    const [education, setEducation] = useState([]);
    const [occupation, setOccupation] = useState([]);
    const [domain, setDomain] = useState([]);
    const [domainExperience, setDomainExperience] = useState([]);
    const [hearAbout, setHearAbout] = useState([]);
    const [date, setDate] = useState([]);
    const [birthYear, setBirthYear] = useState([]);
    const [birthYearData, setBirthYearData] = useState({ greater: 0, less: 0, from: 0, to: 0, min: 0, max: 0 });
    const [filterSequence, setFilterSequence] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [mailModal, setMailModal] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const [subject, setSubject] = useState("");
    const [mailLoading, setMailLoading] = useState(false);

    const dispatch = useDispatch();

    let freeLancerData = useSelector((state) => state.freelancerReducer.data);

    useEffect(() => {
        if (!freeLancerData?.success) {
            dispatch(getFreelancerData());
        }
        if (freeLancerData.message === "Records deleted successfully.") {
            setLoaderr(false);
            setDataAll(prev => prev.filter(elm => elm._id !== deleteDataId));
            setData(prev => prev.filter(elm => elm._id !== deleteDataId));
            setDeleteDataId();
        }
        if (freeLancerData.success && Loaderr && freeLancerData.message !== "Records deleted successfully.") {
            // console.log(freeLancerData);
            setCountryList(countryOptions(freeLancerData?.result));
            setLanguageList(languageOptions(freeLancerData?.result));
            setLoaderr(false);
            setDataAll(freeLancerData?.result || []);
            const temporaryData = freeLancerData?.result || [];
            setData(temporaryData.reverse());

            localStorage.setItem(
                "FreeLancerNewMessage",
                freeLancerData.result?.length
            );
        }
        if (freeLancerData.success == false) {
            setLoaderr(false);
            localStorage.setItem(
                "FreeLancerNewMessage",
                freeLancerData.result?.length
            );
        }
    }, [freeLancerData]);

    useEffect(() => {
        if (filterSequence?.length || (!filterSequence?.length && data?.length < dataAll?.length)) {
            const isDataFiltered = filterSequence?.filter(elm => elm.value?.length)?.length;

            if (isDataFiltered && searchAndFilterSequence === undefined) {
                searchAndFilterSequence = 1;
            } else if (!searchQuery?.trim()?.length && !isDataFiltered) {
                searchAndFilterSequence = undefined;
            } else if (searchQuery?.trim()?.length && !isDataFiltered) {
                searchAndFilterSequence = 0;
                handleSearch();
                return;
            }
            const filteredData = filterData();
            if (!searchQuery?.trim()?.length || searchAndFilterSequence === 0) {
                setData(filteredData);
            }
        }
    }, [filterSequence]);

    useEffect(() => {
        if (searchAndFilterSequence === 0 || (filterSequence?.length && !searchQuery?.trim()?.length)) {
            const filteredData = filterData();
            setData(filteredData);
        }
    }, [searchFilterData]);

    useEffect(() => {
        if (searchAndFilterSequence === 1 && searchQuery?.trim()?.length) {
            const filteredData = handleSearch();
            setData(filteredData);
        }
    }, [mainFilterData]);

    useEffect(() => {
        if (dataAll?.length) {
            if (Loaderr) setLoaderr(false);
            // setData([...data].slice(100 * activePage - 100, 100 * activePage));
        }
    }, [activePage]);

    useLayoutEffect(() => {
        setCheckboxStatus(country, "country");
    }, [country]);

    useLayoutEffect(() => {
        setCheckboxStatus(language, "language");
    }, [language]);

    const filterData = () => {
        let filteredData = searchAndFilterSequence === 0 ? [...searchFilterData] : [...dataAll];

        for (let index = 0; index < filterSequence.length; index++) {
            const { type, value } = filterSequence[index];

            if (!value.length) {
                setFilterSequence(prev => prev.filter(elm => elm.value.length));
            }

            if (type === 'userType') {
                const filterDate = new Date("2024-06-12T20:36:40.769Z");
                filteredData = filteredData.filter(elm => {
                    return (value.includes('new') && value.includes('old')) ? true : value.includes('new') ? new Date(elm?.createdAt) > filterDate : value.includes('old') ? new Date(elm?.createdAt) <= filterDate : true;
                });
            } else if (type === 'role') {
                filteredData = filteredData.filter(elm => {
                    return (value.includes('freelancer') && value.includes('vendor')) ? true : value.includes('freelancer') ? (elm?.is_vendor === false ? true : elm?.role == 1 ? true : false) : value.includes('vendor') ? (elm?.is_vendor ? true : elm?.is_vendor === false ? false : elm?.role == 2 ? true : false) : true;
                });
            } else if (type === 'gender') {
                filteredData = filteredData.filter(elm => {
                    return (value.includes('male') && value.includes('female')) ? value.includes(elm?.gender) : value.includes('male') ? elm?.gender === 'male' : value.includes('female') ? elm?.gender === 'female' : true;
                });
            } else if (type === 'birthCountry') {
                const selectedCountry = value.map(elm => elm.value);
                if (!selectedCountry.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedCountry.includes(elm?.birth_country);
                });
            } else if (type === 'currentCountry') {
                const selectedCountry = value.map(elm => elm.value);
                if (!selectedCountry.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedCountry.includes(elm?.current_country);
                });
            } else if (type === 'teamCountry') {
                const selectedCountries = value.map(elm => elm.value);
                if (!selectedCountries.length) continue;
                filteredData = filteredData.filter(elm => {
                    return (elm?.team_countries?.some(country => selectedCountries.includes(country)) || elm?.working_team_country?.some(country => selectedCountries.includes(country))) || (elm?.is_vendor === false || elm?.role === 1);
                });
            } else if (type === 'country') {
                const selectedCountries = value.map(elm => elm.value);
                if (!selectedCountries.length) continue;
                filteredData = filteredData.filter(elm => {
                    return elm?.team_countries?.some(country => selectedCountries.includes(textCapitalize(country))) || elm?.working_team_country?.some(country => selectedCountries.includes(textCapitalize(country))) || selectedCountries.includes(textCapitalize(elm?.birth_country)) || selectedCountries.includes(textCapitalize(elm?.current_country)) || selectedCountries.includes(textCapitalize(elm?.country));
                });
            } else if (type === 'livingYears') {
                const selectedValue = value.map(elm => elm.value);
                if (!selectedValue.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedValue.includes(elm?.living_year);
                });
            } else if (type === 'birthState') {
                const selectedValue = value.map(elm => elm.value);
                if (!selectedValue.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedValue.includes(elm?.birth_state);
                });
            } else if (type === 'currentState') {
                const selectedValue = value.map(elm => elm.value);
                if (!selectedValue.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedValue.includes(elm?.current_state);
                });
            } else if (type === 'birthCity') {
                const selectedValue = value.map(elm => elm.value);
                if (!selectedValue.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedValue.includes(elm?.birth_city?.trim());
                });
            } else if (type === 'currentCity') {
                const selectedValue = value.map(elm => elm.value);
                if (!selectedValue.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedValue.includes(elm?.current_city?.trim());
                });
            } else if (type === 'nativeLanguage') {
                const selectedValue = value.map(elm => elm.value);
                if (!selectedValue.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedValue.includes(textCapitalize(elm?.native_language?.trim()));
                });
            } else if (type === 'otherLanguages') {
                const selectedLanguages = value.map(elm => elm.value);
                if (!selectedLanguages.length) continue;
                filteredData = filteredData.filter(elm => {
                    return elm?.other_languages?.some(language => selectedLanguages.includes(textCapitalize(language?.trim())));
                });
            } else if (type === 'teamLanguages') {
                const selectedLanguages = value.map(elm => elm.value);
                if (!selectedLanguages.length) continue;
                filteredData = filteredData.filter(elm => {
                    return elm?.vendor_languages?.some(language => selectedLanguages.includes(textCapitalize(language?.trim()))) || elm?.is_vendor === false || elm?.role === 1;
                });
            } else if (type === 'language') {
                const selectedLanguages = value.map(elm => elm.value);
                if (!selectedLanguages.length) continue;
                filteredData = filteredData.filter(elm => {
                    return elm?.other_languages?.some(language => selectedLanguages.includes(textCapitalize(language?.trim()))) || elm?.vendor_languages?.some(language => selectedLanguages.includes(textCapitalize(language?.trim()))) || selectedLanguages.includes(textCapitalize(elm?.native_language?.trim())) || elm?.language?.some(language => selectedLanguages.includes(textCapitalize(language?.trim())));
                });
            } else if (type === 'interestedIn') {
                const selectedInterest = value.map(elm => elm.value);
                if (!selectedInterest.length) continue;
                filteredData = filteredData.filter(elm => {
                    let transcription, annotation, translation, audioRecording, dataCollection;
                    if (selectedInterest.includes('transcription')) {
                        transcription = elm?.project_participation?.find(elm => elm?.transcription) || elm.interested_in?.includes('Transcription');
                    }
                    if (selectedInterest.includes('annotation')) {
                        annotation = elm?.project_participation?.find(elm => elm?.annotation) || elm.interested_in?.includes('Data Annotation');
                    }
                    if (selectedInterest.includes('translation')) {
                        translation = elm?.project_participation?.find(elm => elm?.translation);
                    }
                    if (selectedInterest.includes('audioRecording')) {
                        audioRecording = elm?.project_participation?.find(elm => elm?.audioRecording);
                    }
                    if (selectedInterest.includes('dataCollection')) {
                        dataCollection = elm?.project_participation?.find(elm => elm?.dataCollection) || elm.interested_in?.includes('AI Data Collection');
                    }

                    if (transcription || annotation || translation || audioRecording || dataCollection) {
                        return true;
                    }
                    return false;
                });
            } else if (type === 'interestedInExperience') {
                const selectedInterest = interestedIn.map(elm => elm.value);
                if (!value.map(elm => elm.value).length) continue;
                filteredData = filteredData.filter(elm => {
                    let transcription, annotation, translation, audioRecording, dataCollection;
                    if (selectedInterest.includes('transcription')) {
                        const selectedExperience = value.map(elm => elm.value.startsWith('Tr: ') ? elm.value.slice(4) : false).filter(elm => elm);
                        transcription = elm?.project_participation?.find(elm => elm?.transcription && selectedExperience.includes(elm?.experience));
                    }
                    if (selectedInterest.includes('annotation')) {
                        const selectedExperience = value.map(elm => elm.value.startsWith('An: ') ? elm.value.slice(4) : false).filter(elm => elm);
                        annotation = elm?.project_participation?.find(elm => elm?.annotation && selectedExperience.includes(elm?.experience));
                    }
                    if (selectedInterest.includes('translation')) {
                        const selectedExperience = value.map(elm => elm.value.startsWith('Ts: ') ? elm.value.slice(4) : false).filter(elm => elm);
                        translation = elm?.project_participation?.find(elm => elm?.translation && selectedExperience.includes(elm?.experience));
                    }
                    if (selectedInterest.includes('audioRecording')) {
                        const selectedExperience = value.map(elm => elm.value.startsWith('Ar: ') ? elm.value.slice(4) : false).filter(elm => elm);
                        audioRecording = elm?.project_participation?.find(elm => elm?.audioRecording && selectedExperience.includes(elm?.experience));
                    }
                    if (selectedInterest.includes('dataCollection')) {
                        const selectedExperience = value.map(elm => elm.value.startsWith('Dc: ') ? elm.value.slice(4) : false).filter(elm => elm);
                        dataCollection = elm?.project_participation?.find(elm => elm?.dataCollection && selectedExperience.includes(elm?.experience));
                    }

                    if (transcription || annotation || translation || audioRecording || dataCollection) {
                        return true;
                    }
                    return false;
                });
            } else if (type === 'os') {
                let selectedOS = value.map(elm => elm.value);
                if (!selectedOS.length) continue;
                selectedOS = selectedOS.includes('Android') && selectedOS.includes('iOS') ? [...selectedOS, 'Android & iOS'] : selectedOS;

                filteredData = filteredData.filter(elm => {
                    return selectedOS.includes(elm?.os);
                });
            } else if (type === 'brand') {
                const selectedBrand = value.map(elm => elm.value);
                if (!selectedBrand.length) continue;
                filteredData = filteredData.filter(elm => {
                    return elm?.brand?.some(brand => selectedBrand.includes(brand?.trim()));
                });
            } else if (type === 'education') {
                const selectedEducation = value.map(elm => elm.value);
                if (!selectedEducation.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedEducation.includes(elm?.education?.trim()?.toLowerCase());
                });
            } else if (type === 'occupation') {
                const selectedOccupation = value.map(elm => elm.value);
                if (!selectedOccupation.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedOccupation.includes(elm?.occupation?.trim()?.toLowerCase());
                });
            } else if (type === 'domain') {
                const selectedDomain = value.map(elm => elm.value);
                if (!selectedDomain.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedDomain.includes(elm?.domain?.trim()?.toLowerCase());
                });
            } else if (type === 'domainExperience') {
                const selectedExperience = value.map(elm => elm.value);
                if (!selectedExperience.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedExperience.includes(elm?.experience);
                });
            } else if (type === 'hearAbout') {
                const selectedHearAbout = value.map(elm => elm.value);
                if (!selectedHearAbout.length) continue;
                filteredData = filteredData.filter(elm => {
                    return selectedHearAbout.includes(elm?.hear_about_us?.trim()?.toLowerCase());
                });
            } else if (type === 'ownLaptop') {
                filteredData = filteredData.filter(elm => {
                    return (value.includes('yes') && value.includes('no')) ? true : value.includes('yes') ? elm?.own_laptop : value.includes('no') ? !elm?.own_laptop : true;
                });
            } else if (type === 'dateRange') {
                if (!value.length) continue;
                const [startDate, endDate] = value;
                filteredData = filteredData.filter(elm => {
                    const date = elm?.createdAt?.split("T")[0]?.split("-")?.reverse()?.join("/");
                    return isDateInRange(date, startDate, endDate) ? true : false;
                });
            } else if (type === "birthYear") {
                if (!value.length) continue;
                const currentYear = new Date().getFullYear();
                filteredData = filteredData.filter(elm => {
                    return birthYear.includes(currentYear - (elm?.birth_year || currentYear));
                });
            }
        }

        setMainFilterData(filteredData);
        setActivePage(1);
        return filteredData;
    };

    const handleSearch = () => {
        const searchValue = searchQuery?.slice(0, 1) === '+' ? searchQuery?.slice(1)?.replace(/\s/g, "")?.trim()?.toLowerCase() : searchQuery?.replace(/\s/g, "")?.trim()?.toLowerCase();

        const isDataFiltered = filterSequence?.filter(elm => elm.value?.length)?.length;

        if (searchValue && searchAndFilterSequence === undefined) {
            searchAndFilterSequence = 0;
        } else if (!searchValue && !isDataFiltered) {
            searchAndFilterSequence = undefined;
        } else if (!searchValue && isDataFiltered) {
            searchAndFilterSequence = 1;
        }

        const searchData = searchAndFilterSequence === 1 ? [...mainFilterData] : [...dataAll];

        const filteredData = searchData.filter((data) => {
            const name = (data?.name || '')?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue);
            const fnameLname = ((data?.fname + data?.lname) || '')?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue);
            const email = (data?.email || '')?.toLowerCase()?.startsWith(searchValue);
            const phone = (data?.phone || '')?.slice(0, 1) === '+' ? (data?.phone || '')?.slice(1)?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue) : (data?.phone || '')?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue);
            const contact_number = (data?.contact_number || '')?.slice(0, 1) === '+' ? (data?.contact_number || '')?.slice(1)?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue) : (data?.contact_number || '')?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue);
            const whatsapp_number = (data?.whatsapp_number || '')?.slice(0, 1) === '+' ? (data?.whatsapp_number || '')?.slice(1)?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue) : (data?.whatsapp_number || '')?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue);
            const telegram_number = (data?.telegram_number || '')?.slice(0, 1) === '+' ? (data?.telegram_number || '')?.slice(1)?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue) : (data?.telegram_number || '')?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue);
            const linkedin_profile = (data?.linkedin_profile || '')?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue);
            const skype_profile = (data?.skype_profile || '')?.replace(/\s/g, "")?.toLowerCase()?.startsWith(searchValue);

            return (name || fnameLname || email || phone || contact_number || whatsapp_number || telegram_number || linkedin_profile || skype_profile);
        });
        setSearchFilterData(filteredData);
        setActivePage(1);
        return filteredData;
    };

    const toggleFilterModal = () => {
        setShowFilterModal(prev => !prev);
        document.body.classList.add("no_padding");
    };

    const toggleMailModal = () => {
        setMailModal(prev => !prev);
        setMailLoading(false);
        setSubject('');
        setEditorContent('');
        document.body.classList.add("no_padding");
    };

    const handleChange = (selected, type) => {
        if (type) {
            const dataExist = filterSequence.filter(elm => elm.type === type).length;
            setFilterSequence(prev => (dataExist ? prev.map(elm => elm.type === type ? { ...elm, value: selected } : elm) : [...prev, { type, value: selected }]));
        }
        switch (type) {
            case "birthCountry":
                setBirthCountry(selected);
                break;
            case "currentCountry":
                setCurrentCountry(selected);
                break;
            case "teamCountry":
                setTeamCountry(selected);
                break;
            case "country":
                setCountry(selected);
                !selected?.length ? setCheckboxStatus([], 'country') : null;
                break;
            case "livingYears":
                setLivingYears(selected);
                break;
            case "birthState":
                setBirthState(selected);
                break;
            case "currentState":
                setCurrentState(selected);
                break;
            case "birthCity":
                setBirthCity(selected);
                break;
            case "currentCity":
                setCurrentCity(selected);
                break;
            case "nativeLanguage":
                setNativeLanguage(selected);
                break;
            case "otherLanguages":
                setOtherLanguages(selected);
                break;
            case "teamLanguages":
                setTeamLanguages(selected);
                break;
            case "language":
                setLanguage(selected);
                !selected?.length ? setCheckboxStatus([], 'language') : null;
                break;
            case "interestedIn":
                setInterestedIn(selected);
                break;
            case "interestedInExperience":
                setInterestedInExperience(selected);
                break;
            case "os":
                setOS(selected);
                break;
            case "brand":
                setBrand(selected);
                break;
            case "education":
                setEducation(selected);
                break;
            case "occupation":
                setOccupation(selected);
                break;
            case "domain":
                setDomain(selected);
                break;
            case "domainExperience":
                setDomainExperience(selected);
                break;
            case "hearAbout":
                setHearAbout(selected);
                break;

            default:
                break;
        }
    };

    const checkboxFilter = (e, type) => {
        const value = e.target.value;
        const checked = e.target.checked;
        const id = e.target?.id || "";
        const isVendor = id?.includes('vendor');
        const isFreelancer = id?.includes('freelancer');
        const freelancerOutside = document.getElementById('freelancer-filter');
        const freelancerInside = document.getElementById('freelancer');
        const vendorOutside = document.getElementById('vendor-filter');
        const vendorInside = document.getElementById('vendor');
        if (checked) {
            if (isVendor) {
                if (vendorInside) vendorInside.checked = true;
                if (vendorOutside) vendorOutside.checked = true;
            }
            if (isFreelancer) {
                if (freelancerInside) freelancerInside.checked = true;
                if (freelancerOutside) freelancerOutside.checked = true;
            }
            const dataExist = filterSequence.filter(elm => elm.type === type).length;
            setFilterSequence(prev => (dataExist ? prev.map(elm => elm.type === type ? { ...elm, value: [...(elm['value'] || []), value] } : elm) : [...prev, { type, value: [value] }]));
        }
        else {
            if (isVendor) {
                if (vendorInside) vendorInside.checked = false;
                if (vendorOutside) vendorOutside.checked = false;
            }
            if (isFreelancer) {
                if (freelancerInside) freelancerInside.checked = false;
                if (freelancerOutside) freelancerOutside.checked = false;
            }
            setFilterSequence(prev => (prev.map(elm => elm.type === type ? { ...elm, value: elm.value.filter(el => el !== value) } : elm)));
        }
    };

    const handleDateChange = (dateRange) => {
        if (dateRange.length === 2) {
            let [startDate, endDate] = dateRange;
            startDate = new Date(startDate);
            startDate = startDate.toLocaleDateString('en-IN');
            endDate = new Date(endDate);
            endDate = endDate.toLocaleDateString('en-IN');
            const selected = [startDate, endDate];
            const type = "dateRange";
            setDate(selected);
            const dataExist = filterSequence.filter(elm => elm.type === type).length;
            setFilterSequence(prev => (dataExist ? prev.map(elm => elm.type === type ? { ...elm, value: selected } : elm) : [...prev, { type, value: selected }]));
        } else {
            setDate([]);
            const selected = [];
            const type = 'dateRange';
            const dataExist = filterSequence.filter(elm => elm.type === type).length;
            setFilterSequence(prev => (dataExist ? prev.map(elm => elm.type === type ? { ...elm, value: selected } : elm) : [...prev, { type, value: selected }]));
        }
    };

    const onChangeBirthYearData = (e, type) => {
        const currentValue = parseInt(e.target?.value || 0);
        const value = currentValue < 16 ? 16 : currentValue > 100 ? 100 : currentValue;
        let selectedYears;
        if (type !== "specific") {
            let min, max;
            if (type === 'greater') {
                min = (Math.min(value, (birthYearData.from || value)) || 16);
                max = (Math.max(birthYearData.less, birthYearData.to) || 100);
            } else if (type === 'less') {
                min = Math.max(Math.min((birthYearData.greater || birthYearData.from), (birthYearData.from || birthYearData.greater)), 16);
                max = Math.min(Math.max(value, (birthYearData.to || value)), 100);
            } else if (type === 'from') {
                min = (Math.min(value, (birthYearData.greater || value)) || 16);
                max = (Math.max(birthYearData.less, birthYearData.to) || 100);
            } else if (type === 'to') {
                min = Math.max(Math.min((birthYearData.greater || birthYearData.from), (birthYearData.from || birthYearData.greater)), 16);
                max = Math.min(Math.max((birthYearData.less || value), value), 100);
            }
            selectedYears = [...new Set(Array.from({ length: max - min + 1 }, (_, i) => i + min))].sort((a, b) => a - b);
            setBirthYear(selectedYears);
            setBirthYearData(prev => ({ ...prev, [type]: currentValue, min, max }));
        } else {
            if (e.target.checked) {
                selectedYears = [...birthYear, currentValue];
                setBirthYear([...new Set(selectedYears)].sort((a, b) => a - b));
            } else {
                selectedYears = birthYear.filter(elm => elm !== currentValue);
                setBirthYear([...new Set(selectedYears)].sort((a, b) => a - b));
            }
        }
        setBirthYearCheckboxStatus(selectedYears);
        const dataExist = filterSequence.filter(elm => elm.type === 'birthYear').length;
        setFilterSequence(prev => (dataExist ? prev.map(elm => elm.type === 'birthYear' ? { ...elm, value: selectedYears } : elm) : [...prev, { type: 'birthYear', value: selectedYears }]));
    };

    const removeBirthYear = (value) => {
        const selectedYears = birthYear.filter(elm => elm !== value);
        setBirthYear(selectedYears);
        setBirthYearCheckboxStatus(selectedYears);
        const dataExist = filterSequence.filter(elm => elm.type === 'birthYear').length;
        setFilterSequence(prev => (dataExist ? prev.map(elm => elm.type === 'birthYear' ? { ...elm, value: selectedYears } : elm) : [...prev, { type: 'birthYear', value: selectedYears }]));
    };

    const setBirthYearCheckboxStatus = (selectedYears) => {
        const totalYears = Array.from({ length: 100 - 16 + 1 }, (_, i) => i + 16);
        for (let i = 0; i < totalYears.length; i++) {
            const checkbox = document.querySelector(`#birth-year-${totalYears[i]}`);
            if (selectedYears.includes(totalYears[i])) {
                checkbox.checked = true;
            } else {
                checkbox.checked = false;
            }
        }
    };

    const setCheckboxStatus = (selected, type) => {
        const values = selected.map(elm => elm.value);
        const total = type === 'country' ? countryOptions(dataAll) : languageOptions(dataAll);
        for (let i = 0; i < total.length; i++) {
            const checkbox = document.querySelector(`#${type}-${i}`);
            if (values.includes(total[i]?.value)) {
                checkbox.checked = true;
            } else {
                checkbox.checked = false;
            }
        }
    };

    const resetFilters = () => {
        setBirthCountry([]);
        setCurrentCountry([]);
        setTeamCountry([]);
        setCountry([]);
        setLivingYears([]);
        setBirthState([]);
        setCurrentState([]);
        setBirthCity([]);
        setCurrentCity([]);
        setNativeLanguage([]);
        setOtherLanguages([]);
        setTeamLanguages([]);
        setLanguage([]);
        setInterestedIn([]);
        setInterestedInExperience([]);
        setOS([]);
        setBrand([]);
        setEducation([]);
        setOccupation([]);
        setDomain([]);
        setDomainExperience([]);
        setHearAbout([]);
        setDate([]);
        setBirthYear([]);
        setBirthYearData({ greater: 0, less: 0, from: 0, to: 0 });
        setFilterSequence([]);
        const allCheckbox = document.querySelectorAll('input[type="checkbox"]');
        Array.from(allCheckbox).forEach(elm => elm.checked = false);

        if (!searchQuery?.trim()?.length) {
            filterData();
        }
    };

    const onFilterChange = (e, type) => {
        let selected;
        const value = JSON.parse(e.target?.value);
        if (type === 'country') {
            if (e.target.checked) {
                selected = [...country, value];
            } else {
                selected = country?.filter(elm => elm.value !== value?.value);
            }
            const searchCountryInput = document.getElementById('search-country');
            if (searchCountryInput.value) {
                searchCountryInput.value = '';
                setCountryList(countryOptions(dataAll));
            }
            setCountry(selected);
        } else if (type === 'language') {
            if (e.target.checked) {
                selected = [...language, value];
            } else {
                selected = language?.filter(elm => elm.value !== value?.value);
            }
            const searchLanguageInput = document.getElementById('search-language');
            if (searchLanguageInput.value) {
                searchLanguageInput.value = '';
                setLanguageList(languageOptions(dataAll));
            }
            setLanguage(selected);
        }

        if (type) {
            const dataExist = filterSequence.filter(elm => elm.type === type).length;
            setFilterSequence(prev => (dataExist ? prev.map(elm => elm.type === type ? { ...elm, value: selected } : elm) : [...prev, { type, value: selected }]));
        }
    };

    const deleteFreelancerDetail = (id) => {
        Swal.fire({
            title: "Are you sure you want to delete this?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result.isConfirmed) {
                setDeleteDataId(id);
                setLoaderr(true);
                dispatch(deleteFreelancerData(id));
            }
        });
    };

    const filterCountryOptions = (e) => {
        const value = e.target?.value || "";
        const totalCountries = countryOptions(dataAll);
        const countries = totalCountries?.filter(elm => elm.label?.toLowerCase()?.startsWith(value?.toLowerCase()));
        setCountryList(countries);
    };

    const filterLanguageOptions = (e) => {
        const value = e.target?.value || "";
        const totalLanguages = languageOptions(dataAll);
        const languages = totalLanguages?.filter(elm => elm.label?.toLowerCase()?.startsWith(value?.toLowerCase()));
        setLanguageList(languages);
    };

    const downloadExcelSheet = () => {
        if (!data?.length) return;
        const header = ["Name", "Email", "Phone", "Role", "Gender", "Birth Year", "Education", "Occupation", "Domain", "Domain Experience", "Birth Country", "Birth State", "Birth City", "Current Country", "Current State", "Current City", "Living Years", "WhatsApp", "Telegram", "LinkedIn", "Skype", "Native Language", "Other Languages", "OS", "Mobile Brand", "Own Laptop", "Language Supported", "Team Countries", "Intrested In", "Transcription Exp.", "Annotation Exp.", "Translation Exp.", "Audio Recording Exp.", "Data Collection Exp.", "Online Presence", "Hear About Us", "Message", "Date"];
        const sheetData = data?.map(data => {
            const transcription = data?.project_participation?.find(elm => elm?.transcription);
            const annotation = data?.project_participation?.find(elm => elm?.annotation);
            const translation = data?.project_participation?.find(elm => elm?.translation);
            const audioRecording = data?.project_participation?.find(elm => elm?.audioRecording);
            const dataCollection = data?.project_participation?.find(elm => elm?.dataCollection);
            let intrestedIn = data?.project_participation?.map(elm => Object.keys(elm))?.flat()?.filter(elm => elm !== 'experience')?.map(elm => elm === 'audioRecording' ? 'audio recording' : elm === "dataCollection" ? "data collection" : elm);
            intrestedIn = [...new Set([...(intrestedIn || []), ...(data.interested_in?.map(elm => elm.toLowerCase() === "ai data collection" ? "data collection" : elm?.toLowerCase() === "data annotation" ? "annotation" : elm) || [])])];

            const trExp = transcription ? transcription?.experience : '';
            const anExp = annotation ? annotation?.experience : '';
            const tsExp = translation ? translation?.experience : '';
            const arExp = audioRecording ? audioRecording?.experience : '';
            const dcExp = dataCollection ? dataCollection?.experience : '';
            return [
                (data.fname && data.lname) ? data.fname + " " + data.lname : data.name ? data.name : '-',
                data.email ? data.email : '-',
                data?.contact_number ? data?.contact_number : data?.phone ? data.phone : '-',
                (data.is_vendor === false || data.role === 1) ? "Freelancer" : (data.is_vendor === true || data.role === 2) ? "Vendor" : '-',
                data?.gender ? data.gender : '-',
                data?.birth_year ? data.birth_year : '-',
                data?.education ? data.education : '-',
                data?.occupation ? data.occupation : '-',
                data?.domain ? data.domain : '-',
                data?.experience || '-',
                data?.birth_country ? data.birth_country : '-',
                data?.birth_state ? data.birth_state : '-',
                data?.birth_city ? data.birth_city : '-',
                data?.current_country ? data.current_country : data?.birth_country ? data.birth_country : data?.country ? data.country : '-',
                data?.current_state ? data.current_state : data?.birth_state ? data.birth_state : '-',
                data?.current_city ? data.current_city : data?.birth_city ? data.birth_city : '-',
                data?.living_year ? data.living_year : '-',
                data?.whatsapp_number ? data.whatsapp_number : '-',
                data?.telegram_number ? data.telegram_number : '-',
                data?.linkedin_profile ? data?.linkedin_profile : '-',
                data?.skype_profile ? data.skype_profile : "-",
                data?.native_language ? data.native_language : data?.language?.length ? data.language?.join(', ') : '-',
                data?.other_languages?.length ? data?.other_languages?.join(', ') : '-',
                data?.os ? data.os : '-',
                data?.brand?.length ? data.brand.join(', ') : '-',
                data?.own_laptop !== undefined ? data?.own_laptop ? "Yes" : "No" : '-',
                data?.vendor_languages?.length ? data?.vendor_languages?.join(', ') : '-',
                data?.team_countries?.length ? data?.team_countries?.join(', ') : '-',
                intrestedIn && intrestedIn.length >= 1 ? intrestedIn?.join(', ') : '-',
                trExp ? trExp : '-',
                anExp ? anExp : '-',
                tsExp ? tsExp : '-',
                arExp ? arExp : '-',
                dcExp ? dcExp : '-',
                data.online_presence && data.online_presence.length >= 1 ? data.online_presence.map(elm => (
                    `${elm.startsWith('http') ? elm : 'http://' + elm}`
                ))?.join(', ') : '-',
                data?.hear_about_us ? data.hear_about_us : '-',
                data?.extra_text ? data?.extra_text : '-',
                data.createdAt ? data.createdAt.split("T")[0].split("-").reverse().join("/") : '-',
            ];
        });
        const ws = XLSX.utils.aoa_to_sheet([header, ...sheetData]);
        ws["!cols"] = [{ wch: 30 }, { wch: 40 }, { wch: 30 }, { wch: 15 }, { wch: 15 }, { wch: 10 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 15 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 10 }, { wch: 15 }, { wch: 15 }, { wch: 35 }, { wch: 25 }, { wch: 15 }, { wch: 30 }, { wch: 10 }, { wch: 10 }, { wch: 10 }, { wch: 45 }, { wch: 45 }, { wch: 45 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 50 }, { wch: 15 }, { wch: 45 }, { wch: 20 }];
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'ExtractedAmounts');
        XLSX.writeFile(wb, 'Freelancer_vendor_data.xlsx');
    };

    const changeRowSelection = (e, id, name, email) => {
        if (e.target.checked) {
            if (id === 'all') {
                // const selectedRowsList = data.map(elm => !elm?.move_to_deal ? elm._id : false).filter(elm => elm);
                const selectedRowsList = data.map(elm => !elm?.move_to_deal ? { id: elm?._id, name: (elm?.fname && elm?.lname) ? elm.fname + ' ' + elm.lname : elm?.name ? elm.name : '', email: elm?.email || '' } : false).filter(elm => elm);
                setSelectedRows(selectedRowsList);
                selectedRowsList.forEach(elm => {
                    if (document.querySelector(`#row-${elm.id}`)) {
                        document.querySelector(`#row-${elm.id}`).checked = true;
                    }
                });
            } else {
                if ([...selectedRows.map(elm => elm.id), id].length === data.length) {
                    if (document.querySelector(`#all-row-select`)) {
                        document.querySelector(`#all-row-select`).checked = true;
                    }
                }
                setSelectedRows(prev => [...prev, { id, name, email }]);
            }
        } else {
            if (id === 'all') {
                setSelectedRows([]);
                data.forEach(elm => {
                    if (document.querySelector(`#row-${elm._id}`)) {
                        document.querySelector(`#row-${elm._id}`).checked = false;
                    }
                });
            } else {
                if (document.querySelector(`#all-row-select`).checked) {
                    document.querySelector(`#all-row-select`).checked = false;
                }
                setSelectedRows(prev => [...prev.filter(elm => elm.id !== id)]);
            }
        }
    };

    const sendEmailToFreelancer = () => {
        Swal.fire({
            icon: "question",
            title: "Are you sure you want to send this email?",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Send",
        }).then((result) => {
            if (result.isConfirmed) {
                let message = editorContent;
                message = message?.replaceAll(/"/g, "'");
                const recipients = selectedRows.map(elm => ({ name: elm.name, email: elm.email }));
                setMailLoading(true);
                dispatch(sendMailToFreelancer({ subject, message, data: recipients }));
                toggleMailModal();
                Swal.fire({
                    title: "Processing!",
                    text: "Email is on its way. Please hold on.",
                    icon: "info",
                    cancelButtonText: "Ok"
                });
            }
        });
    };

    return (
        <>
            <div className="margin-top freelancer-container freelancer-vendor-filter">
                <div className="d-flex justify-content-between pe-3 pb-2">
                    <div className="d-flex align-items-center gap-3">
                        {/* <h2 className="text-nowrap main-heading mx-2">
                            <span>
                                <span>Freelancer And Vendor</span>
                            </span>
                        </h2> */}

                        {/* <label htmlFor="search" className="mx-1 p-2">
                            Search :
                        </label> */}
                        <input
                            className="form-control me-2  "
                            id="search"
                            onKeyUp={() => {
                                const searchedData = handleSearch();
                                setData(searchedData);
                            }}
                            style={{
                                width: "250px",
                                height: "38px",
                                border: "2px solid black",
                            }}
                            type="search"
                            placeholder="Search by name, email, phone..."
                            aria-label="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div className="d-flex gap-3 align-items-center py-1">
                            <div className="form-check d-flex align-items-center gap-2 m-0">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="freelancer"
                                    id="freelancer-filter"
                                    defaultChecked={filterSequence.filter(elm => elm.type === 'role' && elm.value.includes('freelancer')).length}
                                    onClick={(e) => checkboxFilter(e, 'role')}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="freelancer-filter"
                                >
                                    Freelancer
                                </label>
                            </div>
                            <div className="form-check d-flex align-items-center gap-2 m-0">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value="vendor"
                                    id="vendor-filter"
                                    defaultChecked={filterSequence.filter(elm => elm.type === 'role' && elm.value.includes('vendor')).length}
                                    onClick={(e) => checkboxFilter(e, 'role')}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="vendor-filter"
                                >
                                    Vendor
                                </label>
                            </div>
                        </div>

                        <UncontrolledDropdown style={{ width: '250px' }}>
                            <DropdownToggle color="light" type="button" className="w-100 d-flex justify-content-between align-items-center fs-6" style={{ background: '#cbced2' }}>
                                Filter by Country
                                <div className="d-flex gap-3 align-items-center">
                                    {country?.length ?
                                        <>
                                            <span>({country?.length || 0})</span>
                                        </>
                                        : null}
                                    <i className="mdi mdi-chevron-down"></i>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu persist={true} className="w-100">
                                <div className="d-flex flex-column gap-2 px-3">
                                    <div className="dropdown-header noti-title px-0 pb-0 ps-1">
                                        <h5 className="font-size-13 text-muted text-truncate mn-0 d-flex justify-content-between align-items-center gap-2">
                                            <input
                                                className="form-control"
                                                id="search-country"
                                                onKeyUp={filterCountryOptions}
                                                type="search"
                                                placeholder="Search Country"
                                                aria-label="Search"
                                            />
                                            <span className="text-primary text-decoration-underline" onClick={() => {
                                                const selectedCountry = [];
                                                setCountry(selectedCountry);
                                                const dataExist = filterSequence.filter(elm => elm.type === "country").length;
                                                setFilterSequence(prev => (dataExist ? prev.map(elm => elm.type === "country" ? { ...elm, value: selectedCountry } : elm) : [...prev, { type: "country", value: selectedCountry }]));
                                                setCheckboxStatus(selectedCountry, 'country');
                                            }} role="button">{country.length ? 'clear all' : null}</span></h5>
                                    </div>
                                    <div className="d-flex flex-column gap-2 birth-year-specific ps-1">
                                        {countryList?.map((elm, index) =>
                                            <div key={elm?.value} className="form-check d-flex align-items-center gap-2 my-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={JSON.stringify(elm)}
                                                    id={`country-${index}`}
                                                    onChange={(e) => onFilterChange(e, 'country')}
                                                />
                                                <label
                                                    className="form-check-label w-100 text-truncate"
                                                    htmlFor={`country-${index}`}
                                                    title={elm?.label}
                                                >
                                                    {elm?.label}
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown style={{ width: '250px' }}>
                            <DropdownToggle color="light" type="button" className="w-100 d-flex justify-content-between align-items-center fs-6" style={{ background: '#cbced2' }}>
                                Filter by Language
                                <div className="d-flex gap-3 align-items-center">
                                    {language?.length ?
                                        <>
                                            <span>({language?.length || 0})</span>
                                        </>
                                        : null}
                                    <i className="mdi mdi-chevron-down"></i>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu persist={true} className="w-100">
                                <div className="d-flex flex-column gap-2 px-3">
                                    <div className="dropdown-header noti-title px-0 pb-0 ps-1">
                                        <h5 className="font-size-13 text-muted text-truncate mn-0 d-flex justify-content-between align-items-center gap-2">
                                            <input
                                                className="form-control"
                                                id="search-language"
                                                onKeyUp={filterLanguageOptions}
                                                type="search"
                                                placeholder="Search Language"
                                                aria-label="Search"
                                            />
                                            <span className="text-primary text-decoration-underline" onClick={() => {
                                                const selectedLanguage = [];
                                                setLanguage(selectedLanguage);
                                                const dataExist = filterSequence.filter(elm => elm.type === "language").length;
                                                setFilterSequence(prev => (dataExist ? prev.map(elm => elm.type === "language" ? { ...elm, value: selectedLanguage } : elm) : [...prev, { type: "language", value: selectedLanguage }]));
                                                setCheckboxStatus(selectedLanguage, 'language');
                                            }} role="button">{language.length ? 'clear all' : null}</span></h5>
                                    </div>
                                    <div className="d-flex flex-column gap-2 birth-year-specific ps-1">
                                        {languageList?.map((elm, index) =>
                                            <div key={elm?.value} className="form-check d-flex align-items-center gap-2 my-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={JSON.stringify(elm)}
                                                    id={`language-${index}`}
                                                    onChange={(e) => onFilterChange(e, 'language')}
                                                />
                                                <label
                                                    className="form-check-label w-100 text-truncate"
                                                    htmlFor={`language-${index}`}
                                                    title={elm?.label}
                                                >
                                                    {elm?.label}
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                    <div className="d-flex align-items-center">
                        <Tooltip placement="top" title={"Send mail to freelancers"}>
                            <Button
                                onClick={() => setMailModal(true)}
                                className=" text-center bg-primary text-light mx-2"
                                style={{ padding: '9px 16px' }}
                                disabled={!selectedRows?.length}>
                                <TbMailShare className="fs-5" />
                            </Button>
                        </Tooltip>
                        <Button
                            onClick={toggleFilterModal}
                            data-toggle="modal"
                            className=" text-center bg-primary text-light mx-2"
                        >
                            Filter {filterSequence?.length ? `(${data?.length})` : ''}
                        </Button>
                        <Button
                            onClick={resetFilters}
                            className=" text-center bg-danger text-light mx-2"
                            disabled={!filterSequence?.length}
                        >
                            Clear Filter
                        </Button>
                        <Tooltip placement="top" title={"Download Data in Excel"}>
                            <Button
                                onClick={downloadExcelSheet}
                                className=" text-center bg-primary text-light mx-2"
                                style={{ padding: '11px 16px' }}>
                                <FaDownload />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <Table
                    bordered
                    responsive
                    hover
                    className="table table-primary mb-0 f-table"
                >
                    <thead>
                        <tr className="t_head_row">
                            <th className="border-0  font-medium  bgdark   text-nowrap"><input type="checkbox" className='form-check-input mt-1' id='all-row-select' onChange={(e) => changeRowSelection(e, 'all')} /></th>
                            <th className="  bgdark  text-nowrap t_head_row">No.</th>
                            <th className="  bgdark  text-nowrap t_head_row">Name</th>
                            <th className="  bgdark  text-nowrap t_head_row">Email</th>
                            <th className="  bgdark  text-nowrap t_head_row">Phone</th>
                            <th className="  bgdark  text-nowrap t_head_row">Role</th>
                            <th className="  bgdark  text-nowrap t_head_row">Gender</th>
                            <th className="  bgdark  text-nowrap t_head_row">Birth Year</th>
                            <th className="  bgdark  text-nowrap t_head_row">Education</th>
                            <th className="  bgdark  text-nowrap t_head_row">Occupation</th>
                            <th className="  bgdark  text-nowrap t_head_row">Domain</th>
                            <th className="  bgdark  text-nowrap t_head_row">Domain Experience</th>
                            <th className="  bgdark  text-nowrap t_head_row">Birth Country</th>
                            <th className="  bgdark  text-nowrap t_head_row">Birth State</th>
                            <th className="  bgdark  text-nowrap t_head_row">Birth City</th>
                            <th className="  bgdark  text-nowrap t_head_row">Current Country</th>
                            <th className="  bgdark  text-nowrap t_head_row">Current State</th>
                            <th className="  bgdark  text-nowrap t_head_row">Current City</th>
                            <th className="  bgdark  text-nowrap t_head_row">Living Years</th>
                            <th className="  bgdark  text-nowrap t_head_row">WhatsApp</th>
                            <th className="  bgdark  text-nowrap t_head_row">Telegram</th>
                            <th className="  bgdark  text-nowrap t_head_row">LinkedIn</th>
                            <th className="  bgdark  text-nowrap t_head_row">Skype</th>
                            <th className="  bgdark  text-nowrap t_head_row">Native Language</th>
                            <th className="  bgdark  text-nowrap t_head_row">Other Languages</th>
                            <th className="  bgdark  text-nowrap t_head_row">OS</th>
                            <th className="  bgdark  text-nowrap t_head_row">Mobile Brand</th>
                            <th className="  bgdark  text-nowrap t_head_row">Own Laptop</th>
                            <th className="  bgdark  text-nowrap t_head_row">Language Supported</th>
                            <th className="  bgdark  text-nowrap t_head_row">Team Countries</th>
                            <th className=" bgdark   text-nowrap">Intrested In</th>
                            <th className="  bgdark  text-nowrap t_head_row">Transcription Exp.</th>
                            <th className="  bgdark  text-nowrap t_head_row">Annotation Exp.</th>
                            <th className="  bgdark  text-nowrap t_head_row">Translation Exp.</th>
                            <th className="  bgdark  text-nowrap t_head_row">Audio Recording Exp.</th>
                            <th className="  bgdark  text-nowrap t_head_row">Data Collection Exp.</th>
                            <th className=" bgdark   text-nowrap">Online Presence</th>
                            <th className=" bgdark   text-nowrap">Hear About Us</th>
                            <th className=" bgdark   text-nowrap">Message</th>
                            <th className=" bgdark   text-nowrap t_head_row">Date</th>
                            <th className=" bgdark   text-nowrap">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(!data?.length && freeLancerData?.success) ?
                            <tr className="no-bg-hover">
                                <td colSpan={39} className="text-center d-flex align-items-center bg-transparent border-0" style={{ height: 'calc(80vh - 46px)', left: '40%', width: 'max-content' }}>
                                    <h3>Records are not available!</h3>
                                </td>
                            </tr>
                            : null}
                        {Loaderr ?
                            <tr className="no-bg-hover">
                                <td colSpan={39} className="text-center d-flex align-items-center bg-transparent border-0 left-0" style={{ height: 'calc(80vh - 46px)', left: '50%' }}>
                                    <Loader size="lg" className="" />
                                </td>
                            </tr>
                            : null}
                        {dataAll?.length ?
                            [...data].slice(100 * activePage - 100, 100 * activePage).map((data, index) => {
                                const transcription = data?.project_participation?.find(elm => elm?.transcription);
                                const annotation = data?.project_participation?.find(elm => elm?.annotation);
                                const translation = data?.project_participation?.find(elm => elm?.translation);
                                const audioRecording = data?.project_participation?.find(elm => elm?.audioRecording);
                                const dataCollection = data?.project_participation?.find(elm => elm?.dataCollection);
                                let intrestedIn = data?.project_participation?.map(elm => Object.keys(elm))?.flat()?.filter(elm => elm !== 'experience')?.map(elm => elm === 'audioRecording' ? 'audio recording' : elm === "dataCollection" ? "data collection" : elm);
                                intrestedIn = [...new Set([...(intrestedIn || []), ...(data.interested_in?.map(elm => elm.toLowerCase() === "ai data collection" ? "data collection" : elm?.toLowerCase() === "data annotation" ? "annotation" : elm) || [])])];

                                const trExp = transcription ? transcription?.experience : '';
                                const anExp = annotation ? annotation?.experience : '';
                                const tsExp = translation ? translation?.experience : '';
                                const arExp = audioRecording ? audioRecording?.experience : '';
                                const dcExp = dataCollection ? dataCollection?.experience : '';
                                return (
                                    <tr key={data._id} id={"tr" + data._id} style={data?.move_to_deal ? { backgroundColor: "#a0e2c4" } : {}}>
                                        <td className={data?.move_to_deal ? 'bg-transparent' : ''}>
                                            <input type="checkbox" id={`row-${data._id}`} className='form-check-input mt-1' onChange={(e) => changeRowSelection(e, data._id, (data?.fname && data?.lname) ? data?.fname + ' ' + data?.lname : data?.name || '', data?.email || '')} disabled={data?.move_to_deal} defaultChecked={selectedRows.map(elm => elm.id).includes(data?._id)} />
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent' : 'text-nowrap'}>
                                            {(activePage - 1) * 100 + (index + 1)}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {(data.fname && data.lname) ? (
                                                data.fname + " " + data.lname
                                            ) : data.name ? (
                                                data.name
                                            ) : (
                                                <p className="text-center">-</p>
                                            )}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent' : 'text-nowrap'}>
                                            {data.email ? data.email : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent' : 'text-nowrap'} onClick={() => copyToClipboard(data?.contact_number ? data?.contact_number : data?.phone ? data.phone : 'No Data')}>
                                            {data?.contact_number ? data?.contact_number : data?.phone ? data.phone : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent' : 'text-nowrap'}>
                                            {(data.is_vendor === false || data.role === 1) ? (
                                                <Badge bg="success" className="bg-success   text-center">
                                                    Freelancer
                                                </Badge>
                                            ) : (data.is_vendor === true || data.role === 2) ? (
                                                <Badge
                                                    bg="danger"
                                                    className="bg-danger  text-center vendor-badge-class"
                                                >
                                                    Vendor
                                                </Badge>
                                            ) : (
                                                <p className="text-center">-</p>
                                            )}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.gender ? data.gender : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.birth_year ? data.birth_year : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.education ? data.education : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.occupation ? data.occupation : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.domain ? data.domain : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent' : 'text-nowrap'}>
                                            {data?.experience ? data?.experience?.length > 30 ? data?.experience?.slice(0, 30) : data?.experience : <p className="text-center">-</p>}
                                            {data?.experience ? data?.experience?.length > 30 ? (
                                                <Tooltip placement="top" title={data?.experience} overlayStyle={{ maxWidth: "70%" }}>
                                                    <span role="button" className="text-primary" onClick={() => copyToClipboard(data?.experience || '')}>...more</span>
                                                </Tooltip>
                                            ) : null : null}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.birth_country ? data.birth_country : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.birth_state ? data.birth_state : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.birth_city ? data.birth_city : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.current_country ? data.current_country : data?.birth_country ? data.birth_country : data?.country ? data.country : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.current_state ? data.current_state : data?.birth_state ? data.birth_state : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.current_city ? data.current_city : data?.birth_city ? data.birth_city : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.living_year ? data.living_year : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'} onClick={() => copyToClipboard(data?.whatsapp_number ? data.whatsapp_number : "No Data")}>
                                            {data?.whatsapp_number ? data.whatsapp_number : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'} onClick={() => copyToClipboard(data?.telegram_number ? data.telegram_number : "No Data")}>
                                            {data?.telegram_number ? data.telegram_number : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'} onClick={() => copyToClipboard(data?.linkedin_profile ? data.linkedin_profile : "No Data")}>
                                            {data?.linkedin_profile ? <Tooltip placement="top" title={data?.linkedin_profile}><Button className="bg-transparent">Copy</Button></Tooltip> : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'} onClick={() => copyToClipboard(data?.skype_profile ? data.skype_profile : "No Data")}>
                                            {data?.skype_profile ? <Tooltip placement="top" title={data?.skype_profile}><Button className="bg-transparent">Copy</Button></Tooltip> : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.native_language ? data.native_language : data?.language?.length ? data.language?.length > 4 ? data.language?.slice(0, 4)?.join(', ') : data.language?.join(', ') : <p className="text-center">-</p>}
                                            {data?.language?.length ? data?.language?.length > 4 ? (<Tooltip placement="top" title={data?.language?.join(', ')} overlayStyle={{ maxWidth: "70%" }}>
                                                <span role="button" className="text-primary" onClick={() => copyToClipboard(data?.nativeLanguage || data?.language)}>...more</span>
                                            </Tooltip>) : null : null}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.other_languages?.length ? data?.other_languages?.length > 4 ? data.other_languages?.slice(0, 4).join(', ') : data?.other_languages?.join(', ') : <p className="text-center">-</p>}
                                            {data?.other_languages?.length ? data?.other_languages?.length > 4 ? (<Tooltip placement="top" title={data?.other_languages?.join(', ')} overlayStyle={{ maxWidth: "70%" }}>
                                                <span role="button" className="text-primary" onClick={() => copyToClipboard(data?.other_languages?.length ? data?.other_languages : '')}>...more</span>
                                            </Tooltip>) : null : null}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.os ? data.os : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.brand?.length ? data.brand.join(', ') : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-center text-nowrap text-capitalize bg-transparent' : 'text-center text-nowrap text-capitalize'}>
                                            {data?.own_laptop !== undefined ? data?.own_laptop ? "Yes" : "No" : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.vendor_languages?.length ? data?.vendor_languages?.length > 4 ? data.vendor_languages?.slice(0, 4).join(', ') : data?.vendor_languages?.join(', ') : <p className="text-center">-</p>}
                                            {data?.vendor_languages?.length ? data?.vendor_languages?.length > 4 ? (<Tooltip placement="top" title={data?.vendor_languages?.join(', ')} overlayStyle={{ maxWidth: "70%" }}>
                                                <span role="button" className="text-primary" onClick={() => copyToClipboard(data?.vendor_languages?.length ? data?.vendor_languages : '')}>...more</span>
                                            </Tooltip>) : null : null}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.team_countries?.length ? data?.team_countries?.length > 4 ? data.team_countries?.slice(0, 4).join(', ') : data?.team_countries?.join(', ') : <p className="text-center">-</p>}
                                            {data?.team_countries?.length ? data?.team_countries?.length > 4 ? (<Tooltip placement="top" title={data?.team_countries?.join(', ')} overlayStyle={{ maxWidth: "70%" }}>
                                                <span role="button" className="text-primary" onClick={() => copyToClipboard(data?.team_countries?.length ? data?.team_countries : '')}>...more</span>
                                            </Tooltip>) : null : null}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {intrestedIn && intrestedIn.length >= 1 ? (
                                                intrestedIn?.join(', ')
                                            ) : (
                                                <p className="text-center">-</p>
                                            )}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {trExp ? trExp : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {anExp ? anExp : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {tsExp ? tsExp : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {arExp ? arExp : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {dcExp ? dcExp : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent' : 'text-nowrap'}>
                                            <div className="m-auto text-center">
                                                {data.online_presence && data.online_presence.length >= 1 ? (
                                                    <Tooltip placement="top" title={data.online_presence.map(elm => (
                                                        <span
                                                            key={elm}
                                                            href={`${elm.startsWith('http') ? elm : 'http://' + elm}`}
                                                            className="d-block text-info"
                                                            role="button"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                copyToClipboard(`${elm.startsWith('http') ? elm : 'http://' + elm}`);
                                                            }}>
                                                            {elm}
                                                        </span>
                                                    ))} overlayStyle={{ maxWidth: "70%" }}>
                                                        <span role="button" className="bg-transparent text-primary">View</span>
                                                    </Tooltip>
                                                ) : (
                                                    <p className="text-center">-</p>
                                                )}
                                            </div>
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.hear_about_us ? data.hear_about_us : <p className="text-center">-</p>}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent text-capitalize' : 'text-nowrap text-capitalize'}>
                                            {data?.extra_text ? data?.extra_text?.length > 30 ? data?.extra_text?.slice(0, 30) : data?.extra_text : <p className="text-center">-</p>}
                                            {data?.extra_text ? data?.extra_text?.length > 30 ? (<Tooltip placement="top" title={data?.extra_text} overlayStyle={{ maxWidth: "70%" }}>
                                                <span role="button" className="text-primary" onClick={() => copyToClipboard(data?.extra_text || '')}>...more</span>
                                            </Tooltip>) : null : null}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent' : 'text-nowrap'}>
                                            {data.createdAt ? (
                                                data.createdAt.split("T")[0].split("-").reverse().join("/")
                                            ) : (
                                                <p className="text-center">-</p>
                                            )}
                                        </td>
                                        <td className={data?.move_to_deal ? 'text-nowrap bg-transparent' : 'text-nowrap'}>
                                            <Button color="red" className="btn btn-danger bg-danger text-white" type="button" onClick={() => deleteFreelancerDetail(data?._id)}>
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            }
                            ) : null}
                    </tbody>
                </Table>
            </div>
            <div className="pagination-container">
                {dataAll?.length && (
                    <Pagination
                        prev
                        last
                        next
                        first
                        size="lg"
                        total={data?.length}
                        limit={Math.min(data?.length, 100)}
                        activePage={activePage}
                        onChangePage={(value) => {
                            if (value === activePage) return;
                            setLoaderr(true);
                            setActivePage(value);
                        }}
                    />
                )}
            </div>

            <RSModal
                isOpen={mailModal}
                toggle={toggleMailModal}
                scrollable={true}
                backdrop={'static'}
                keyboard={false}
                fullscreen={true}
            >
                <div className="modal-header">
                    <h3 className="modal-title mt-0">
                        Send Mail to Freelancers
                    </h3>
                    <button
                        type="button"
                        onClick={toggleMailModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body freelancer-vendor-filter d-flex gap-5 flex-column">
                    <FormEditors data={selectedRows} editorContent={editorContent} setEditorContent={setEditorContent} subject={subject} setSubject={setSubject} />
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={toggleMailModal}
                    >
                        Close
                    </button>
                    <button type="button" className="btn btn-primary" id="send-mail-btn" onClick={sendEmailToFreelancer} disabled={!subject || !editorContent}>
                        {mailLoading ?
                            <div className="d-flex justify-content-between align-items-center">
                                <Spinner
                                    color="light"
                                    size="sm"
                                >
                                </Spinner>
                                <span className="ms-2">Sending...</span>
                            </div>
                            :
                            "Send Mail"
                        }
                    </button>
                </div>
            </RSModal>

            <RSModal
                isOpen={showFilterModal}
                toggle={toggleFilterModal}
                scrollable={true}
                backdrop={'static'}
                keyboard={false}
                fullscreen={true}
            >
                <div className="modal-header">
                    <h3 className="modal-title mt-0">
                        Filter Data ({data?.length} of {dataAll?.length})
                    </h3>
                    <button
                        type="button"
                        onClick={toggleFilterModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body freelancer-vendor-filter d-flex gap-5 flex-column">
                    <Row className="row-gap-5">
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Old/New</h4>
                            <div className="d-flex gap-3 flex-column py-1">
                                <div className="form-check d-flex align-items-center gap-2 m-0">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="old"
                                        id="old"
                                        defaultChecked={filterSequence.filter(elm => elm.type === 'userType' && elm.value.includes('old')).length}
                                        onClick={(e) => checkboxFilter(e, 'userType')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="old"
                                    >
                                        Old User
                                    </label>
                                </div>
                                <div className="form-check d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="new"
                                        id="new"
                                        defaultChecked={filterSequence.filter(elm => elm.type === 'userType' && elm.value.includes('new')).length}
                                        onClick={(e) => checkboxFilter(e, 'userType')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="new"
                                    >
                                        New User
                                    </label>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Role</h4>
                            <div className="d-flex gap-3 flex-column py-1">
                                <div className="form-check d-flex align-items-center gap-2 m-0">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="freelancer"
                                        id="freelancer"
                                        defaultChecked={filterSequence.filter(elm => elm.type === 'role' && elm.value.includes('freelancer')).length}
                                        onClick={(e) => checkboxFilter(e, 'role')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="freelancer"
                                    >
                                        Freelancer
                                    </label>
                                </div>
                                <div className="form-check d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="vendor"
                                        id="vendor"
                                        defaultChecked={filterSequence.filter(elm => elm.type === 'role' && elm.value.includes('vendor')).length}
                                        onClick={(e) => checkboxFilter(e, 'role')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="vendor"
                                    >
                                        Vendor
                                    </label>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Gender</h4>
                            <div className="d-flex gap-3 flex-column py-1">
                                <div className="form-check d-flex align-items-center gap-2 m-0">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="male"
                                        id="male"
                                        defaultChecked={filterSequence.filter(elm => elm.type === 'gender' && elm.value.includes('male')).length}
                                        onClick={(e) => checkboxFilter(e, 'gender')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="male"
                                    >
                                        Male
                                    </label>
                                </div>
                                <div className="form-check d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="female"
                                        id="female"
                                        defaultChecked={filterSequence.filter(elm => elm.type === 'gender' && elm.value.includes('female')).length}
                                        onClick={(e) => checkboxFilter(e, 'gender')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="female"
                                    >
                                        Female
                                    </label>
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Birth Year</h4>
                            <UncontrolledDropdown>
                                <DropdownToggle color="light" type="button" className="w-100 d-flex justify-content-between fs-6">
                                    Select Birth Year <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu persist={true} className="w-100">
                                    <div className="form-check d-flex align-items-center gap-4 px-3 mb-2">
                                        <input
                                            className="form-control w-50"
                                            type="number"
                                            value={birthYearData.greater}
                                            id="greater"
                                            min={16}
                                            max={Math.min(Math.max(birthYearData.to, birthYearData.less), 100) || 100}
                                            onChange={(e) => onChangeBirthYearData(e, 'greater')}
                                        />
                                        <label
                                            className="form-check-label flex-shrink-0 w-50"
                                            htmlFor="greater"
                                        >
                                            Greater Than
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center gap-4 px-3 mb-2">
                                        <input
                                            className="form-control w-50"
                                            type="number"
                                            value={birthYearData.less}
                                            id="less"
                                            min={Math.max(Math.min(birthYearData.greater, birthYearData.from), 16) || 16}
                                            max={100}
                                            onChange={(e) => onChangeBirthYearData(e, 'less')}
                                        />
                                        <label
                                            className="form-check-label flex-shrink-0 w-50"
                                            htmlFor="less"
                                        >
                                            Less Than
                                        </label>
                                    </div>
                                    <div className="form-check d-flex align-items-center gap-3 px-3 mb-2">
                                        <input
                                            className="form-control"
                                            type="number"
                                            value={birthYearData.from}
                                            id="from"
                                            min={16}
                                            max={Math.min(Math.max(birthYearData.to, birthYearData.less), 100)}
                                            onChange={(e) => onChangeBirthYearData(e, 'from')}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="from"
                                        >
                                            To
                                        </label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            value={birthYearData.to}
                                            id="to"
                                            min={Math.max(Math.min(birthYearData.greater, birthYearData.from), 16)}
                                            max={100}
                                            onChange={(e) => onChangeBirthYearData(e, 'to')}
                                        />
                                    </div>

                                    <div className="dropdown-divider"></div>

                                    <div className="d-flex flex-column gap-2 px-3">
                                        <div className="dropdown-header noti-title px-0 pb-0">
                                            <h5 className="font-size-13 text-muted text-truncate mn-0 d-flex justify-content-between align-items-center">Select Specific Year <span className="text-primary text-decoration-underline" onClick={() => {
                                                setBirthYearData({ greater: 0, less: 0, from: 0, to: 0 });
                                                const selectedYears = birthYear.length < 85 ? Array.from({ length: 100 - 16 + 1 }, (_, i) => i + 16) : [];
                                                setBirthYear(selectedYears);
                                                setBirthYearCheckboxStatus(selectedYears);
                                            }} role="button">{birthYear.length < 85 ? 'select all' : 'clear all'}</span></h5>
                                        </div>
                                        <div className="d-flex flex-column gap-2 birth-year-specific">
                                            {Array.from({ length: 100 - 16 + 1 }, (_, i) => i + 16)?.map(elm =>
                                                <div key={elm} className="form-check d-flex align-items-center gap-2 my-1">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={elm}
                                                        id={`birth-year-${elm}`}
                                                        onChange={(e) => onChangeBirthYearData(e, 'specific')}
                                                    />
                                                    <label
                                                        className="form-check-label w-100"
                                                        htmlFor={`birth-year-${elm}`}
                                                    >
                                                        {elm}
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <div className="mt-2 d-flex gap-2 flex-nowrap overflow-x-auto pb-2" role="button">
                                {birthYear.map(elm =>
                                    <Badge color="primary" className="px-2 py-1" key={elm} onClick={() => removeBirthYear(elm)}>
                                        <span className="fs-6">{elm}</span><span className="btn-close btn-close-white ms-2"></span>
                                    </Badge>
                                )}
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Date</h4>
                            <div className="form-check d-flex align-items-center p-0">
                                <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select a date range"
                                    options={{
                                        mode: "range",
                                        dateFormat: "d-m-Y"
                                    }}
                                    value={date}
                                    onClose={handleDateChange}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Birth Country</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="birthCountry"
                                    options={birthCountryOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'birthCountry')}
                                    value={birthCountry}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Current Country</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="currentCountry"
                                    options={currentCountryOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'currentCountry')}
                                    value={currentCountry}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Team Country</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="teamCountry"
                                    options={teamCountryOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'teamCountry')}
                                    value={teamCountry}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Country</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="country"
                                    options={countryOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'country')}
                                    value={country}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Living Years</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="livingYears"
                                    options={livingYearOptions}
                                    onChange={(selected) => handleChange(selected, 'livingYears')}
                                    value={livingYears}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Birth State</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="birthState"
                                    options={birthStateOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'birthState')}
                                    value={birthState}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Current State</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="currentState"
                                    options={currentStateOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'currentState')}
                                    value={currentState}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Birth City</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="birthCity"
                                    options={birthCityOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'birthCity')}
                                    value={birthCity}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Current City</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="currentCity"
                                    options={currentCityOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'currentCity')}
                                    value={currentCity}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Native Language</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="nativeLanguage"
                                    options={nativeLanguageOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'nativeLanguage')}
                                    value={nativeLanguage}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Other Languages</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="otherLanguages"
                                    options={otherLanguagesOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'otherLanguages')}
                                    value={otherLanguages}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Team Languages</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="teamLanguages"
                                    options={teamLanguagesOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'teamLanguages')}
                                    value={teamLanguages}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Language</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="language"
                                    options={languageOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'language')}
                                    value={language}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Project Participation</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="interestedIn"
                                    options={interestedInOptions}
                                    onChange={(selected) => handleChange(selected, 'interestedIn')}
                                    value={interestedIn}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Project Experience</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="interestedInExperience"
                                    options={interestedInExperienceOptions(interestedIn?.map(elm => elm.label))}
                                    onChange={(selected) => handleChange(selected, 'interestedInExperience')}
                                    value={interestedInExperience}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Mobile OS</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="os"
                                    options={osOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'os')}
                                    value={os}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Mobile Brand</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="brand"
                                    options={mobileBrandOptions(data, os?.map(elm => elm.label))}
                                    onChange={(selected) => handleChange(selected, 'brand')}
                                    value={brand}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Education</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="education"
                                    options={educationOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'education')}
                                    value={education}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Occupation</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="occupation"
                                    options={occupationOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'occupation')}
                                    value={occupation}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Domain</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="domain"
                                    options={domainOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'domain')}
                                    value={domain}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Domain Experience</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="domainExperience"
                                    options={domainExperienceOptions}
                                    onChange={(selected) => handleChange(selected, 'domainExperience')}
                                    value={domainExperience}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Hear About Us</h4>
                            <div className="form-check p-0">
                                <MultiSelect
                                    key="hearAbout"
                                    options={hearAboutOptions(dataAll)}
                                    onChange={(selected) => handleChange(selected, 'hearAbout')}
                                    value={hearAbout}
                                    isSelectAll={true}
                                />
                            </div>
                        </Col>
                        <Col sm={6} md={4} lg={3}>
                            <h4 className="mb-3">Own Laptop</h4>
                            <div className="d-flex gap-5 align-items-center py-1">
                                <div className="form-check d-flex align-items-center gap-2 m-0">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="yes"
                                        id="hasLaptop"
                                        defaultChecked={filterSequence.filter(elm => elm.type === 'ownLaptop' && elm.value.includes('yes')).length}
                                        onClick={(e) => checkboxFilter(e, 'ownLaptop')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="hasLaptop"
                                    >
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check d-flex align-items-center gap-2">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="no"
                                        id="noLaptop"
                                        defaultChecked={filterSequence.filter(elm => elm.type === 'ownLaptop' && elm.value.includes('no')).length}
                                        onClick={(e) => checkboxFilter(e, 'ownLaptop')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="noLaptop"
                                    >
                                        No
                                    </label>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    {/* <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={toggleFilterModal}
                    >
                        Close
                    </button> */}
                    <button className="btn btn-danger d-flex gap-2 align-items-center" onClick={resetFilters}>
                        <span>Clear Filter</span>
                    </button>
                    <button type="button" className="btn btn-primary" onClick={toggleFilterModal}>
                        Filter
                    </button>
                </div>
            </RSModal>
        </>
    );
}

export default FreelancerAndVendor;
