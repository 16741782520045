import { getSellDataHelper } from "helpers/backend_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import {
    GET_SELL_DATA, GET_SELL_DATA_FAIL,
    GET_SELL_DATA_SUCCESS
} from "./actionTypes"



function* sellDataSagaa() {

    try {
        const response = yield call(getSellDataHelper)

        // console.log(response);
        yield put({ type: GET_SELL_DATA_SUCCESS, response })
    } catch (error) {
        yield put({ type: GET_SELL_DATA_FAIL, error })
    }
}


function* sellDataSaga() {
    yield takeEvery(GET_SELL_DATA, sellDataSagaa)

}





export default sellDataSaga 