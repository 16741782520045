import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const CardInvoice = ({ data }) => {
  const name = data.founder
  const nameIcon = name.charAt(0)
}

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);
  let obj = JSON.parse(localStorage.getItem("authUser"));
  let name = obj.result[0].name
  let company_name = obj.result[0].company_name
  let email = obj.result[0].email

  // let obj = JSON.parse(localStorage.getItem("authUser"));
  // const avatar = obj.company_name.charAt(0)
  // const avatar = obj.result[0].company_name.charAt(0).toUpperCase()
  // const avatar = obj.company_name.charAt(0)
  const avatar = obj.result[0].company_name.charAt(0).toUpperCase()
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item h-100"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="rounded-circle header-profile-user" style={{ background: "#ABD9FF", fontSize: "20px", }}>
            {avatar}
          </div>
          {/* <img
            className="rounded-circle header-profile-user"
            src={user1}

            alt="Header Avatar"
          /> */}

          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block" /> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem >
            {" "}
            <i className="	fas fa-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
            <br />
            <span><strong>Name: </strong> {name}</span> <br />
            <span><strong>Company Name: </strong> {company_name}</span> <br />
            <span><strong>Email: </strong> {email}</span>
          </DropdownItem>

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="fa fa-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
