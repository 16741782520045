import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { SECTION } from "./actionTypes"
import { sectionsSuccess, sectionsError } from "./actions"

import {
  getSection,
} from "../../helpers/backend_helper"

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* sectionn({ payload: user }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(getSection,
        // console.log(user),
        user,
      )
      if (response) {
        yield put(
          sectionsSuccess(
            response
            // "Reset link are sended to y/our mailbox, check there first"
          )
        )
      }
    }
  } catch (error) {
    yield put(sectionsError(error))
  }
}

export function* watchUserSection() {
  yield takeEvery(SECTION, sectionn)
}

function* SectionSaga() {
  yield all([fork(watchUserSection)])
}

export default SectionSaga
