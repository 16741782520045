import {
  DELETE_DATASET_REQUEST,
  DELETE_DATASET_REQUEST_SUCCESS,
  DELETE_DATASET_REQUEST_FAIL
} from "./actionTypes"

export const deleteDatasetRequest = id => ({
  type: DELETE_DATASET_REQUEST,
  payload: id,
})

export const deleteDatasetRequestSuccess = id => (
  {
    type: DELETE_DATASET_REQUEST_SUCCESS,
    payload: id,
  })


export const deleteDatasetRequestrofile = () => ({
  type: DELETE_DATASET_REQUEST_FAIL,
})
