import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CONTACT_US,
  GET_CONTACT_US_SUCCESS,
  GET_CONTACT_US_FAIL
} from "./actionTypes"

import {
  getContactUs, getContactUsSuccess, getContactUsrofile
} from "./actions"

//Include Both Helper File with needed methods
import { getContactUsHelper } from "../../helpers/backend_helper"

function* fetchContactUs() {
  try {
    const response = yield call(getContactUsHelper)
    // console.log(response);
    yield put(getContactUsSuccess(response))
  } catch (error) {
    yield put(getContactUsrofile(error))
  }
}


function* ContactUsSaga() {
  yield takeEvery(GET_CONTACT_US, fetchContactUs)
}

export default ContactUsSaga;
