
import {
  API_DATASETSUCCESS,
  API_DATASETFAIL,
  ADD_NEW_DATASET,
  ADD_NEW_CATEGORY,
  GET_CATEGORY,
  GET_DATASET,
  GET_DATASET_BY_ID,
  GET_DATASET_BY_ID_TWO_HIDE,
  UPDATE_DATASET,
  UPDATE_DATASET_TWO_HIDE,
  // UPDATE_DATASET_TWO
} from "./actionTypes"

export const apiDatasetSuccess = (actionType, data) => ({
  type: API_DATASETSUCCESS,
  payload: { actionType, data },
})

export const apiDatasetFail = (actionType, error) => ({
  type: API_DATASETFAIL,
  payload: { actionType, error },
})

// charts data
export const getCategory = periodType => ({
  type: GET_CATEGORY,
  payload: periodType,
})

export const addNewDataset = periodType => ({
  type: ADD_NEW_DATASET,
  payload: periodType,
})

export const getDataset = () => ({
  type: GET_DATASET,
})

export const addNewCategory = periodType => ({
  type: ADD_NEW_CATEGORY,
  payload: periodType,
})
// export const getDatasetById = periodType => ({
//   type: GET_DATASET_BY_ID,
//   payload: periodType,
// })
export const updateDataset = periodType => (
  // console.log(periodType),
  {
    type: UPDATE_DATASET,
    payload: periodType,
  })

export const updateDatasetTWO = periodType => (
  // console.log(periodType),
  {
    type: UPDATE_DATASET_TWO_HIDE,
    payload: periodType,
  })


export const getDatasetById = Id => (
  // console.log(Id),
  {
    type: GET_DATASET_BY_ID,
    payload: Id,
  })
export const getDatasetByIdTWO = Id => (
  // console.log(Id),
  {
    type: GET_DATASET_BY_ID_TWO_HIDE,
    payload: Id,
  })