import React, { useState, useEffect } from "react"

import {
    Card,
    CardBody,
    Col,
    Row,
    CardTitle,
    Container,
    Form,
    Label,
    Input,
    Button,
} from "reactstrap"

import Select from "react-select";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useSelector, useDispatch } from "react-redux"
import { addNewBlog } from "../../store/actions"
import { getCategory } from "../../store/actions"
import '../../assets/custom-style.css';

const CreateBlog = () => {
    const dispatch = useDispatch();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [category, setCategory] = useState(null);
    const [editorData, setEditorData] = useState(null);
    const [base64Image, setBase64Image] = useState(null);

    const { addBlogResponse, addBlogError } = useSelector(state => ({
        addBlogResponse: state.Blog.blog,
        addBlogError: state.Blog.blogError
    }))

    const { categoryList } = useSelector(state => ({
        categoryList: state.Dataset.dataset,
    }));

    useEffect(() => {
        dispatch(getCategory())
    }, [dispatch]);

    useEffect(() => {
        setCategory(categoryList?.result)
    }, [categoryList]);

    // console.log(addBlogResponse, addBlogError, "add response")
    const categoryOptions = (category && category.length > 0) ? category.map(elm => ({ label: elm.category_name, value: elm._id })) : [{}]

    const selectCategory = (selectedOption) => {
        setSelectedCategory(selectedOption);
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleBlogImage = async (e) => {
        e.preventDefault();
        const b64 = await toBase64(e.target.files[0]);
        setBase64Image(b64);
    }

    const addBlog = (e) => {
        e.preventDefault();
        const title = e.target.title.value;
        const heading = e.target.heading.value;
        const date = new Date();

        const data = {
            title,
            heading,
            date: date.toLocaleDateString(),
            category: selectedCategory.value,
            image: base64Image,
            content: editorData,
        }

        dispatch(addNewBlog(data));
        // console.log(data);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Blog" breadcrumbItem="ADD BLOG" />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle className="h3 mb-5">Blog Details</CardTitle>
                                    <Form onSubmit={addBlog}>
                                        <div className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-title-Input"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Title
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    name="title"
                                                    type="text"
                                                    className="form-control"
                                                    id="horizontal-title-Input"
                                                    placeholder="Blog Title"
                                                />
                                            </Col>
                                        </div>
                                        <div className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-heading-Input"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Heading
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    name="heading"
                                                    type="text"
                                                    className="form-control"
                                                    id="horizontal-heading-Input"
                                                    placeholder="Blog Heading"
                                                />
                                            </Col>
                                        </div>
                                        <div className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-image-Input"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Image
                                            </Label>
                                            <Col sm={9}>
                                                <Input
                                                    name="image"
                                                    className="form-control"
                                                    type="file"
                                                    onChange={handleBlogImage}
                                                    id="formFile" />
                                            </Col>
                                        </div>
                                        <div className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-image-Input"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Category
                                            </Label>
                                            <Col sm={9}>
                                                <Select
                                                    value={selectedCategory}
                                                    onChange={selectCategory}
                                                    options={categoryOptions}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </Col>
                                        </div>
                                        <div className="row mb-4">
                                            <Label
                                                htmlFor="horizontal-image-Input"
                                                className="col-sm-2 col-form-label"
                                            >
                                                Content
                                            </Label>
                                            <Col sm={9}>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={editorData}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setEditorData(data);
                                                    }}
                                                />
                                            </Col>
                                        </div>

                                        <div className="row justify-content-end">
                                            <Col sm={12}>
                                                <div>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="w-md mt-3"
                                                    >
                                                        Add Blog
                                                    </Button>
                                                </div>
                                            </Col>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CreateBlog