// import BreadCrumb from '../../components/Breadcrumb';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addComment, addCommentDataSetReq, deleteDeal, getDealById, updateDeal, getDeal } from 'store/actions';
import { message as AntdMessage } from "antd";
import { addDaysToDateWithDayName, formatDDMMYYYY, addFollowUpConfirmationModal, closeDealModal, columnsMapping, getDays, undoStageConfirmationModal } from 'components/Common/UtilitiesFunctions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Swal from "sweetalert2";
import { getDatasetTypeOptions, getDatasetSubTypeOptions, demographyOptions, typeOptions } from './Deals';
import { languages } from "common/data/language";
import Select from "react-select";

AntdMessage.config({
    maxCount: 1
});

const dealFromMap = {
    1: "Dataset Request",
    2: "Contact Us",
    3: "Get A Call",
    4: "Community Request"
};

export const getCurrentStatusIndexFromLifecycle = (lifecycle, dealStatus) => {
    return (lifecycle.length - 1 - (lifecycle.slice().reverse().findIndex(elm => elm.status == dealStatus)));
};

const ViewDeal = () => {
    document.title = "View Deal | FuturebeeAI - CRM";

    const params = useParams();
    const dispatch = useDispatch();
    const router = useHistory();
    const [currentDeal, setCurrentDeal] = useState();
    const [clientDetailModal, setClientDetailModal] = useState(false);
    const [stageNotesModal, setStageNotesModal] = useState(false);
    const [addStageModal, setAddStageModal] = useState(false);
    const [urlModal, setUrlModal] = useState(false);
    const [lifeCycle, setLifeCycle] = useState([]);
    const [loading, setLoading] = useState(true);

    // step1
    const [clientType, setClientType] = useState('');
    const [requestDate, setRequestDate] = useState('');
    const [dealStatus, setDealStatus] = useState('');
    const [lastActivity, setLastActivity] = useState('');
    const [loseReason, setLoseReason] = useState('');

    // step2
    const [service, setService] = useState('');
    const [dataForm, setDataForm] = useState('');
    const [datasetSubType, setDatasetSubType] = useState('');
    const [language, setLanguage] = useState([]);
    const [industry, setIndustry] = useState('');
    const [demography, setDemography] = useState([]);
    const [type, setType] = useState([]);

    // Step 3
    const [datasetName, setDatasetName] = useState('');
    const [volume, setVolume] = useState('');
    const [purpose, setPurpose] = useState('');
    const [budget, setBudget] = useState('');
    const [message, setMessage] = useState('');

    const { deal, messageText } = useSelector((state) => {
        return {
            deal: state.DealsReducer.deals?.result?.length == 1 ? state.DealsReducer.deals?.result[0] : {},
            messageText: state.DealsReducer.deals?.message,
        };
    });

    useEffect(() => {
        if (Object.keys(deal ? deal : {})?.length) {
            // Set Fields
            setCurrentDeal(deal);
            sortLifeCycle(deal?.lifecycle ? deal?.lifecycle : []);
            setService(deal?.service ? deal?.service : '');
            setDataForm(deal?.dataset_type ? deal?.dataset_type : '');
            setDatasetSubType(deal?.dataset_sub_type ? deal?.dataset_sub_type : '');
            setIndustry(deal?.industry ? deal?.industry : '');
            setLanguage(deal?.language?.length ? deal?.language.map(elm => ({ value: elm, label: elm })) : []);
            setDemography(deal?.demography?.length ? deal?.demography.map(elm => ({ value: elm, label: elm })) : []);
            setType(deal?.type?.length ? deal?.type.map(elm => ({ value: elm, label: elm })) : []);
            setClientType(deal?.client_type ? deal?.client_type : '');
            setVolume(deal?.volume ? deal?.volume : '');
            setPurpose(deal?.purpose ? deal?.purpose : '');
            setBudget(deal?.budget ? deal?.budget : '');
            setDealStatus(deal?.deal_status ? deal?.deal_status : '');
            setMessage(deal?.message ? deal?.message : '');
            setRequestDate(deal?.request_date ? deal?.request_date.split('/').reverse().join('-') : '');
            setLastActivity(deal?.last_activity ? deal?.last_activity : '');
            setDatasetName(deal?.dataset_name ? deal?.dataset_name : '');
            setLoseReason(deal?.lose_reason ? deal?.lose_reason : '');

            setLoading(false);
        }
    }, [deal]);

    useEffect(() => {
        dispatch(getDealById(params?.id));
    }, []);

    useEffect(() => {
        if (["Records updated successfully.", "Records deleted successfully."].includes(messageText)) {
            AntdMessage.success(messageText);
        }
        if (messageText === "Records deleted successfully.") {
            dispatch(getDeal());
            setLoading(true);
            if (!currentDeal?.self_created) {
                let data = [{ url: currentDeal?.deal_from_id }, { move_to_deal: false }];
                if (currentDeal?.deal_from === 1) {
                    dispatch(addCommentDataSetReq(data));
                    router.push('/crm/deals');
                } else if (currentDeal?.deal_from === 2) {
                    dispatch(addComment(data));
                    router.push('/crm/deals');
                } else {
                    router.push('/crm/deals');
                }
            } else {
                router.push('/crm/deals');
            }
        }
    }, [messageText]);

    // Sort Deal Lifecycle
    const sortLifeCycle = (lifecycle) => {
        lifecycle.sort((a, b) => {
            let A, B;
            // if (a?.complete_date && b?.complete_date) {
            //     A = new Date(a.complete_date.split('/').reverse().join('-'));
            //     B = new Date(b.complete_date.split('/').reverse().join('-'));
            // } else {
            A = new Date(a.date.date.split('/').reverse().join('-'));
            B = new Date(b.date.date.split('/').reverse().join('-'));
            // }
            const date1 = new Date(B.getFullYear(), B.getMonth(), B.getDate()).getTime();

            const date2 = new Date(A.getFullYear(), A.getMonth(), A.getDate()).getTime();

            // if (a.complete_date && b.complete_date && (date1 == date2)) {
            //     return a.status > b.status ? 1 : a.status < b.status ? -1 : 0;
            // }

            return (date1 < date2) ? 1 : (date1 > date2) ? -1 : (date1 == date2) ? 1 : 1;
        });

        setLifeCycle(lifecycle);
    };

    // Update Deal Details
    const handleSubmit = () => {
        let dealData = {
            service,
            dataset_type: dataForm,
            dataset_sub_type: datasetSubType,
            dataset_name: datasetName,
            language: language.map(elm => elm.value),
            industry,
            demography: demography.map(elm => elm.value),
            type: type.map(elm => elm.value),
            client_type: clientType,
            volume,
            purpose,
            budget,
            message,
        };
        dispatch(updateDeal(params?.id, dealData));
        console.log(dealData);
    };

    // Save Client Details
    const saveClientDetails = (e) => {
        e.preventDefault();
        const client_name = e.target.clientName.value?.trim();
        const company_name = e.target.companyName.value?.trim();
        const owner = e.target.owner.value?.trim();
        const email = e.target.email.value?.trim();
        const request_date = requestDate.split('-').reverse().join('/');
        const inbound_outbound_place = e.target.fromWhere.value?.trim();
        dispatch(updateDeal(params?.id, {
            client_name, company_name, owner, email, request_date, inbound_outbound_place
        }));
        setClientDetailModal(false);
    };

    // Save Dataset URLs
    const saveUrls = (e) => {
        e.preventDefault();
        const page_link = e.target.pageLink.value?.trim();
        const sample_link = e.target.sampleLink.value?.trim();
        dispatch(updateDeal(params?.id, { page_link, sample_link }));
        setUrlModal(false);
    };

    // Handle Deal Status Update
    const saveStatusDetails = () => {
        if (!dealStatus) {
            AntdMessage.error('Please select deal status.');
            return false;
        }
        if (dealStatus == '7' && !loseReason) {
            AntdMessage.error('Please select lose reason.');
            return false;
        }
        const sourceLineId = deal?.deal_status.split('.')[0];
        const destinationLineId = dealStatus.split('.')[0];
        const lose_reason = dealStatus == 7 ? loseReason.trim() : '';
        const lifecycle = deal?.lifecycle.slice();

        if (dealStatus != deal?.deal_status) {
            const currentStatusIndex = getCurrentStatusIndexFromLifecycle(lifecycle, deal?.deal_status);
            let stageIndex = getCurrentStatusIndexFromLifecycle(lifecycle, dealStatus);

            if (dealStatus.includes('.') && (stageIndex - currentStatusIndex > 1)) {
                for (let idx in lifecycle) {
                    if (idx > currentStatusIndex && idx < stageIndex) {
                        lifecycle[idx]['skipped'] = true;
                    }
                    if (idx == stageIndex) {
                        lifecycle[idx]['complete_date'] = formatDDMMYYYY(new Date());
                    }
                };
                dispatch(updateDeal(params.id, { deal_status: dealStatus, last_activity: deal?.deal_status, update_date: formatDDMMYYYY(new Date()), lose_reason: null, lifecycle }));
            }
            else {
                updateDealStatus(sourceLineId, destinationLineId, JSON.parse(JSON.stringify(deal)), lose_reason);
            }
        }
    };

    // Handle Deal Status Update
    const updateDealStatus = async (sourceLineId, destinationLineId, movedCard, lose_reason) => {
        // Not Resp., In Conv. Close, Lose  => Followup
        if (sourceLineId > '3' && dealStatus.includes('.')) {
            const newStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, dealStatus);
            movedCard.lifecycle[newStatusIndex]['complete_date'] = formatDDMMYYYY(new Date());
            dispatch(updateDeal(movedCard._id, { deal_status: dealStatus, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
        // Inititated, contacted => followup
        else if (destinationLineId == '3') {
            if (sourceLineId == '1') {
                Swal.fire({
                    title: "Can not move from Initiated to FollowUp.",
                    icon: "error",
                });
            }
            movedCard.lifecycle.forEach((elmt, index) => {
                if (elmt.status == '3.1') {
                    movedCard.lifecycle[index].complete_date = formatDDMMYYYY(new Date());
                }
            });
            dispatch(updateDeal(movedCard._id, { deal_status: '3.1', lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
        // can not move back to initiated or contacted
        else if (sourceLineId > '1' && destinationLineId < '3') {
            Swal.fire({
                title: "Can not move back",
                icon: "error",
            });
        }
        // Initiated => Contacted, Not Responding, In Conversation, Close, Lose
        else if (sourceLineId == '1' && ['2', '4', '5', '6', '7'].includes(destinationLineId)) {
            if (destinationLineId == '2') {
                movedCard.lifecycle.forEach((elmt, index) => {
                    if (elmt.status == '2') {
                        movedCard.lifecycle[index].complete_date = formatDDMMYYYY(new Date());
                    }
                });
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            }
            else {
                Swal.fire({
                    title: `Can not move from Initiated to ${columnsMapping(destinationLineId)}.`,
                    icon: "error",
                });
            }
        }
        // Contacted => Not Responding, In Conversation, Close, Lose
        else if (sourceLineId == '2' && ['4', '5', '6', '7'].includes(destinationLineId)) {
            const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

            if (destinationLineId == '4') {
                movedCard.lifecycle.splice(-1, 1, {
                    name: `Not Responding`,
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '4',
                    notes: ''
                }, {
                    name: `FollowUp-1`,
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                    status: '4.1',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 20),
                    status: '',
                    notes: ''
                });
                const destinationStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, destinationLineId);
                movedCard.lifecycle.forEach((elm, idx) => {
                    if (idx > currentStatusIndex && idx < destinationStatusIndex) {
                        movedCard.lifecycle[idx].skipped = true;
                    }
                });
                sortLifeCycle(movedCard.lifecycle);
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            }
            else if (destinationLineId == '5') {
                movedCard.lifecycle.splice(-1, 1, {
                    name: `In Conversation`,
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '5',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 13),
                    status: '',
                    notes: ''
                });
                const destinationStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, destinationLineId);
                movedCard.lifecycle.forEach((elm, idx) => {
                    if (idx > currentStatusIndex && idx < destinationStatusIndex) {
                        movedCard.lifecycle[idx].skipped = true;
                    }
                });
                sortLifeCycle(movedCard.lifecycle);
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            }
            else if (['6', '7'].includes(destinationLineId)) {
                const statusName = destinationLineId == '6' ? 'Close' : 'Lose';
                movedCard.lifecycle.splice(-1, 1, {
                    name: statusName,
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: destinationLineId,
                    notes: ''
                });
                const destinationStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, destinationLineId);
                movedCard.lifecycle.forEach((elm, idx) => {
                    if (idx > currentStatusIndex && idx < destinationStatusIndex) {
                        movedCard.lifecycle[idx].skipped = true;
                    }
                });
                sortLifeCycle(movedCard.lifecycle);
                if (destinationLineId == '6') {
                    const { isConfirmed, value } = await closeDealModal();

                    if (isConfirmed) {
                        console.log(movedCard.lifecycle);

                        dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle, is_potential_client: value ? true : false, }));
                    } else {
                        setLifeCycle(deal?.lifecycle);
                    }
                }
                else if (destinationLineId == '7') {
                    dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
                }
            }
        }
        // Not Responding => In Conversation, Close, Lose
        else if (sourceLineId == '4' && ['5', '6', '7'].includes(destinationLineId)) {
            let lifecycle = movedCard.lifecycle.slice();
            let count = 0;
            let index;
            if (movedCard.deal_status == '4') {
                //remove not resp. and its followup which is not done and add inConvo. tabs in lifecycle
                index = lifecycle.reverse().findIndex(elm => elm.notes && elm.status.startsWith('4'));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('4.')) {
                            lifecycle[idx].skipped = true;
                        }
                    });
                } else {
                    lifecycle = lifecycle.filter(elm => !elm.status.startsWith('4'));
                    lifecycle.reverse();
                    count = 1;
                }
            }
            else if (movedCard.deal_status.startsWith('4.')) {
                // add inconvo tab under not resp. tab and its followup in life cycle
                index = lifecycle.reverse().findIndex(elm => (elm.status == movedCard.deal_status) || (elm.notes && elm.status.startsWith('4')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('4.') && elm.status !== movedCard.deal_status) {
                            lifecycle[idx].skipped = true;
                        }
                    });
                } else {
                    lifecycle.reverse();
                    count = 1;
                }
            }

            // Go back to prev stage by undoing the current stage
            const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

            if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLineId)) {
                const { isConfirmed } = await undoStageConfirmationModal();
                if (isConfirmed) {
                    const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLineId) && elm.complete_date)));
                    lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('5.') && elm.skipped && idx > prevIndexOfNewStatus) {
                            delete elm.skipped;
                            delete elm.complete_date;
                        }
                        if (idx > prevIndexOfNewStatus) {
                            elm.date = addDaysToDateWithDayName(lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, lifecycle[idx - 1].status));
                        }
                    });
                    sortLifeCycle(lifecycle);
                    console.log(lifecycle);
                    dispatch(updateDeal(movedCard._id, { deal_status: lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
                    return;
                } else {
                    setLifeCycle(movedCard.lifecycle);
                    return;
                }
            }

            if (destinationLineId == '5') {
                lifecycle.splice(-1, count, {
                    name: `In Conversation`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '5',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 13),
                    status: '',
                    notes: ''
                });
                sortLifeCycle(lifecycle);
                console.log(lifecycle);
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
            }
            else if (['6', '7'].includes(destinationLineId)) {
                const statusName = destinationLineId == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, count, {
                    name: statusName,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: destinationLineId,
                    notes: ''
                });
                const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, dealStatus);
                if ((((lifecycle.length - 1) - currentStatusIndex) > 1) && ['6', '7'].includes(dealStatus)) {
                    for (let idx in lifecycle) {
                        if (idx > currentStatusIndex && idx < (lifecycle.length - 1)) {
                            lifecycle[idx]['skipped'] = true;
                        }
                    }
                }
                sortLifeCycle(lifecycle);
                if (destinationLineId == '6') {
                    const { isConfirmed, value } = await closeDealModal();

                    if (isConfirmed) {
                        console.log(lifecycle);
                        dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle, is_potential_client: value ? true : false }));
                    } else {
                        setLifeCycle(deal?.lifecycle);
                    }
                }
                else if (destinationLineId == '7') {
                    dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
                }
            }
        }
        // In Conversation => Not Responding, Close, Lose
        else if (sourceLineId == '5' && ['4', '6', '7'].includes(destinationLineId)) {
            let lifecycle = movedCard.lifecycle.slice();
            let count = 0;
            let index;

            if (movedCard.deal_status == '5') {
                //remove in Conv. and add not resp. tabs in lifecycle
                index = lifecycle.reverse().findIndex(elm => elm.notes && elm.status.startsWith('5'));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('5.')) {
                            lifecycle[idx].skipped = true;
                        }
                    });
                } else {
                    lifecycle = lifecycle.filter(elm => !elm.status.startsWith('5'));
                    lifecycle.reverse();
                    count = 1;
                }
            }
            else if (movedCard.deal_status.startsWith('5.')) {
                // add not resp. tab under in conv. tab and its followup in life cycle
                index = lifecycle.reverse().findIndex(elm => (elm.status == movedCard.deal_status) || (elm.notes && elm.status.startsWith('5')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('5.') && elm.status !== movedCard.deal_status) {
                            lifecycle[idx].skipped = true;
                        }
                    });
                } else {
                    lifecycle.reverse();
                    count = 1;
                }
            }

            // Go back to prev stage by undoing the current stage
            const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

            if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLineId)) {
                const { isConfirmed } = await undoStageConfirmationModal();
                if (isConfirmed) {
                    const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLineId) && elm.complete_date)));
                    lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('4.') && elm.skipped && idx > prevIndexOfNewStatus) {
                            delete elm.skipped;
                            delete elm.complete_date;
                        }
                        if (idx > prevIndexOfNewStatus) {
                            elm.date = addDaysToDateWithDayName(lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, lifecycle[idx - 1].status));
                        }
                    });
                    sortLifeCycle(lifecycle);
                    console.log(lifecycle);
                    dispatch(updateDeal(movedCard._id, { deal_status: lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
                    return;
                } else {
                    setLifeCycle(movedCard.lifecycle);
                    return;
                }
            }

            if (destinationLineId == '4') {
                lifecycle.splice(-1, count, {
                    name: `Not Responding`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '4',
                    notes: ''
                }, {
                    name: `FollowUp-1`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
                    status: '4.1',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 20),
                    status: '',
                    notes: ''
                });
                sortLifeCycle(lifecycle);
                console.log(lifecycle);
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
            }
            else if (['6', '7'].includes(destinationLineId)) {
                const statusName = destinationLineId == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, count, {
                    name: statusName,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: destinationLineId,
                    notes: ''
                });
                const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, dealStatus);
                if ((((lifecycle.length - 1) - currentStatusIndex) > 1) && ['6', '7'].includes(dealStatus)) {
                    for (let idx in lifecycle) {
                        if (idx > currentStatusIndex && idx < (lifecycle.length - 1)) {
                            lifecycle[idx]['skipped'] = true;
                        }
                    }
                }
                sortLifeCycle(lifecycle);
                if (destinationLineId == '6') {
                    const { isConfirmed, value } = await closeDealModal();

                    if (isConfirmed) {
                        console.log(lifecycle);
                        dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle, is_potential_client: value ? true : false }));
                    } else {
                        setLifeCycle(deal?.lifecycle);
                    }
                }
                else if (destinationLineId == '7') {
                    dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
                }
            }
        }
        // Close => Lose
        else if (sourceLineId == '6' && destinationLineId == '7') {
            // replace close to lose in lifecycle and add lose reasone modal
            movedCard.lifecycle.splice(-1, 1, {
                name: "Lose",
                date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                complete_date: formatDDMMYYYY(new Date()),
                status: '7',
                notes: ''
            });
            sortLifeCycle(movedCard.lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
        }
        // Lose => Close
        else if (sourceLineId == '7' && destinationLineId == '6') {
            // replace lose to close in lifecycle and open close confirm modal
            movedCard.lifecycle.splice(-1, 1, {
                name: "Close",
                date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                complete_date: formatDDMMYYYY(new Date()),
                status: '6',
                notes: ''
            });
            sortLifeCycle(movedCard.lifecycle);
            const { isConfirmed, value } = await closeDealModal();

            if (isConfirmed) {
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), is_potential_client: value ? true : false, lifecycle: movedCard.lifecycle }));
            } else {
                setLifeCycle(deal?.lifecycle);
            }
        }
        // Close => Not Responding
        else if (sourceLineId == '6' && destinationLineId == '4') {
            const { isConfirmed } = await undoStageConfirmationModal();

            if (isConfirmed) {
                // Go back to prev stage by undoing the current stage
                const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

                if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLineId)) {
                    const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLineId) && elm.complete_date)));
                    movedCard.lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('4.') && elm.skipped && idx > prevIndexOfNewStatus) {
                            delete elm.skipped;
                            delete elm.complete_date;
                        }
                        if (idx > prevIndexOfNewStatus) {
                            elm.date = addDaysToDateWithDayName(movedCard.lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, movedCard.lifecycle[idx - 1].status));
                        }
                    });
                    movedCard.lifecycle.splice(-1, 1, {
                        name: 'Update Stage',
                        date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                        status: '',
                        notes: ''
                    });
                    sortLifeCycle(movedCard.lifecycle);
                    console.log(movedCard.lifecycle);
                    dispatch(updateDeal(movedCard._id, { deal_status: movedCard.lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
                    return;
                }
                // remove "close" and add "not responding" in place of that and add auto followup in it.
                movedCard.lifecycle.splice(-1, 1, {
                    name: 'Not Responding',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '4',
                    notes: ''
                }, {
                    name: 'FollowUp-1',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                    status: '4.1',
                    notes: ''
                }, {
                    name: 'Update Stage',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 20),
                    status: '',
                    notes: ''
                });
                sortLifeCycle(movedCard.lifecycle);
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            }
        }
        // Close => In Conversation
        else if (sourceLineId == '6' && destinationLineId == '5') {
            const { isConfirmed } = await undoStageConfirmationModal();

            if (isConfirmed) {
                // Go back to prev stage by undoing the current stage
                const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

                if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLineId)) {
                    const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLineId) && elm.complete_date)));
                    movedCard.lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('5.') && elm.skipped && idx > prevIndexOfNewStatus) {
                            delete elm.skipped;
                            delete elm.complete_date;
                        }
                        if (idx > prevIndexOfNewStatus) {
                            elm.date = addDaysToDateWithDayName(movedCard.lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, movedCard.lifecycle[idx - 1].status));
                        }
                    });
                    movedCard.lifecycle.splice(-1, 1, {
                        name: 'Update Stage',
                        date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                        status: '',
                        notes: ''
                    });
                    sortLifeCycle(movedCard.lifecycle);
                    console.log(movedCard.lifecycle);
                    dispatch(updateDeal(movedCard._id, { deal_status: movedCard.lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
                    return;
                }
                // remove "close" and add "In Conversation".
                movedCard.lifecycle.splice(-1, 1, {
                    name: 'In Conversation',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '5',
                    notes: ''
                }, {
                    name: 'Update Stage',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 13),
                    status: '',
                    notes: ''
                });
                sortLifeCycle(movedCard.lifecycle);
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            }
        }
        // Lose => Not Responding
        else if (sourceLineId == '7' && destinationLineId == '4') {
            const { isConfirmed } = await undoStageConfirmationModal();

            if (isConfirmed) {
                // Go back to prev stage by undoing the current stage
                const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

                if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLineId)) {
                    const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLineId) && elm.complete_date)));
                    movedCard.lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('4.') && elm.skipped && idx > prevIndexOfNewStatus) {
                            delete elm.skipped;
                            delete elm.complete_date;
                        }
                        if (idx > prevIndexOfNewStatus) {
                            elm.date = addDaysToDateWithDayName(movedCard.lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, movedCard.lifecycle[idx - 1].status));
                        }
                    });
                    movedCard.lifecycle.splice(-1, 1, {
                        name: 'Update Stage',
                        date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                        status: '',
                        notes: ''
                    });
                    sortLifeCycle(movedCard.lifecycle);
                    console.log(movedCard.lifecycle);
                    dispatch(updateDeal(movedCard._id, { deal_status: movedCard.lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
                    return;
                }
                // remove "lose" and add "not responding" in place of that and add auto followup in it.
                movedCard.lifecycle.splice(-1, 1, {
                    name: 'Not Responding',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '4',
                    notes: ''
                }, {
                    name: 'FollowUp-1',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                    status: '4.1',
                    notes: ''
                }, {
                    name: 'Update Stage',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 20),
                    status: '',
                    notes: ''
                });
                sortLifeCycle(movedCard.lifecycle);
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            }
        }
        // Lose => In Conversation
        else if (sourceLineId == '7' && destinationLineId == '5') {
            const { isConfirmed } = await undoStageConfirmationModal();

            if (isConfirmed) {
                // Go back to prev stage by undoing the current stage
                const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, movedCard.deal_status);

                if (movedCard.lifecycle[currentStatusIndex - 1].status.startsWith(destinationLineId)) {
                    const prevIndexOfNewStatus = movedCard.lifecycle.length - 1 - (movedCard.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(destinationLineId) && elm.complete_date)));
                    movedCard.lifecycle.forEach((elm, idx) => {
                        if (elm.status.startsWith('5.') && elm.skipped && idx > prevIndexOfNewStatus) {
                            delete elm.skipped;
                            delete elm.complete_date;
                        }
                        if (idx > prevIndexOfNewStatus) {
                            elm.date = addDaysToDateWithDayName(movedCard.lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, movedCard.lifecycle[idx - 1].status));
                        }
                    });
                    movedCard.lifecycle.splice(-1, 1, {
                        name: 'Update Stage',
                        date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 10),
                        status: '',
                        notes: ''
                    });
                    sortLifeCycle(movedCard.lifecycle);
                    console.log(movedCard.lifecycle);
                    dispatch(updateDeal(movedCard._id, { deal_status: movedCard.lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
                    return;
                }
                // remove "lose" and add "In Conversation" in place of that and add auto followup in it.
                movedCard.lifecycle.splice(-1, 1, {
                    name: 'In Conversation',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '5',
                    notes: ''
                }, {
                    name: 'Update Stage',
                    date: addDaysToDateWithDayName(movedCard.lifecycle[movedCard.lifecycle.length - 2].date.date, 13),
                    status: '',
                    notes: ''
                });
                sortLifeCycle(movedCard.lifecycle);
                dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: movedCard.lifecycle }));
            }
        }
        // Followup => Not Responding, In Conversation, Close, Lose
        else if (sourceLineId == '3' && ['4', '5', '6', '7']) {
            let lifecycle = movedCard.lifecycle.slice();
            let count = 0;
            let index;

            index = lifecycle.reverse().findIndex(elm => (elm.status == movedCard.deal_status) || (elm.notes && elm.status.startsWith('3')));
            if (index !== -1) {
                lifecycle.splice(0, index);
                lifecycle.reverse();
                lifecycle.forEach((elm, idx) => {
                    if (elm.status.startsWith('3') && elm.status !== movedCard.deal_status) {
                        lifecycle[idx].skipped = true;
                    }
                });
            } else {
                lifecycle.reverse();
                count = 1;
            }

            if (destinationLineId == '4') {
                lifecycle.splice(-1, count, {
                    name: `Not Responding`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '4',
                    notes: ''
                }, {
                    name: `FollowUp-1`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
                    status: '4.1',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 20),
                    status: '',
                    notes: ''
                });
            }
            else if (destinationLineId == '5') {
                lifecycle.splice(-1, count, {
                    name: `In Conversation`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 3),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: '5',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 13),
                    status: '',
                    notes: ''
                });
            }
            else if (['6', '7'].includes(destinationLineId)) {
                const statusName = destinationLineId == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, count, {
                    name: statusName,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - (count ? 2 : 1)].date.date, 10),
                    complete_date: formatDDMMYYYY(new Date()),
                    status: destinationLineId,
                    notes: ''
                });
                const currentStatusIndex = getCurrentStatusIndexFromLifecycle(movedCard.lifecycle, dealStatus);
                if ((((lifecycle.length - 1) - currentStatusIndex) > 1) && ['6', '7'].includes(dealStatus)) {
                    for (let idx in lifecycle) {
                        if (idx > currentStatusIndex && idx < (lifecycle.length - 1)) {
                            lifecycle[idx]['skipped'] = true;
                        }
                    }
                }
            }
            sortLifeCycle(lifecycle);
            console.log(lifecycle);
            dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
        }
        // Otherwise
        else {
            // dispatch(updateDeal(movedCard._id, { deal_status: destinationLineId, lose_reason: null, last_activity: movedCard.deal_status, update_date: formatDDMMYYYY(new Date()) }));
            Swal.fire({
                title: "Updated status is invalid.",
                icon: "error",
            });
        }
    };

    // Update All Notes
    const updateStageNotes = (e) => {
        e.preventDefault();
        const general_notes = e.target.general_notes?.value?.trim();
        const lifecycle = lifeCycle.map(elm => ({
            ...elm,
            notes: e.target[`${elm.name}-${elm.status}`]?.value ? e.target[`${elm.name}-${elm.status}`]?.value?.trim() : '',
        }));
        dispatch(updateDeal(params.id, { notes: general_notes, lifecycle }));
        setStageNotesModal(false);
    };

    // Copy Data On Click
    const clickToCopy = (value) => {
        if (value) {
            navigator.clipboard.writeText(value);
            AntdMessage.success("Copied");
        } else {
            AntdMessage.error("Data not available");
        }
    };

    // Toggle Potential Client Flag
    const activeBtn = (ele) => {
        if (!['6', '7'].includes(dealStatus) && !deal?.is_potential_client) {
            return;
        }
        if (ele.closest("i").classList.contains("bxs-bookmark-plus")) {
            ele.closest("i").classList.remove("bxs-bookmark-plus");
            ele.closest("i").classList.add("bx-bookmark-plus");
            dispatch(updateDeal(params.id, { is_potential_client: false }));
        } else {
            ele.closest("i").classList.add("bxs-bookmark-plus");
            ele.closest("i").classList.remove("bx-bookmark-plus");
            dispatch(updateDeal(params.id, { is_potential_client: true }));
        }
    };

    // Add New Stage to Lifecycle
    const addStageToLifeCycle = async (e) => {
        e.preventDefault();
        const stage = e.target.stage.value;
        const prevStage = lifeCycle[lifeCycle.length - 2];
        const notes = e.target.notes.value;
        let date = e.target.date?.value ? e.target.date.value.split('-').reverse().join('/') : '';
        date = date ? addDaysToDateWithDayName(date, 0) : '';
        const currentStatusIndex = getCurrentStatusIndexFromLifecycle(lifeCycle, deal.deal_status);

        let newStatus = 1;
        let name = '';
        let lifecycle = JSON.parse(JSON.stringify(lifeCycle));
        if (stage == '3') {
            const [stageStatus, followupStatus] = prevStage.status.split('.');
            if (followupStatus) {
                newStatus = Number(followupStatus) + 1;
                name = `FollowUp-${newStatus}`;
                newStatus = stageStatus + '.' + newStatus;
            } else {
                name = `FollowUp-${newStatus}`;
                newStatus = stageStatus + '.' + newStatus;
            }
            lifecycle.splice(-1, 1, {
                name: name,
                date: date,
                status: newStatus,
                notes
            }, {
                name: `Update Stage`,
                date: addDaysToDateWithDayName(date.date, getDays('', newStatus)),
                status: '',
                notes: ''
            });
        }
        else if (stage == '4') {
            let count = 0;
            let index;
            if (deal.deal_status == '2') {
                lifecycle.splice(-1, 1, {
                    name: `Not Responding`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 3),
                    status: '4',
                    notes: ''
                }, {
                    name: `FollowUp-1`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 10),
                    status: '4.1',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 20),
                    status: '',
                    notes: ''
                });
            }
            else if (prevStage.status.startsWith('3')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('3')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('3') && elm.status !== deal?.deal_status) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle.reverse();
                    count = 1;
                }
                lifecycle.splice(-1, count, {
                    name: `Not Responding`,
                    date: date,
                    status: '4',
                    notes
                }, {
                    name: `FollowUp-1`,
                    date: addDaysToDateWithDayName(date.date, 7),
                    status: '4.1',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(date.date, 17),
                    status: '',
                    notes: ''
                });
            }
            else if (prevStage.status == stage) {
                lifecycle.splice(-2, 2, {
                    name: `Not Responding`,
                    date: prevStage.date,
                    complete_date: prevStage.complete_date,
                    status: '4',
                    notes
                }, {
                    name: `FollowUp-1`,
                    date: addDaysToDateWithDayName(prevStage.date.date, 7),
                    status: '4.1',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(prevStage.date.date, 17),
                    status: '',
                    notes: ''
                });
            }
            else if (prevStage.status.startsWith('5.')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('5')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('5.') && elm.status !== deal?.deal_status) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle.reverse();
                    count = 1;
                }
                lifecycle.splice(-1, count, {
                    name: `Not Responding`,
                    date: date,
                    status: '4',
                    notes
                }, {
                    name: `FollowUp-1`,
                    date: addDaysToDateWithDayName(date.date, 7),
                    status: '4.1',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(date.date, 17),
                    status: '',
                    notes: ''
                });
            }
            else if (prevStage.status.startsWith('5')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('5')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('5.')) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle = lifecycle.filter(elm => !elm.status.startsWith('5'));
                    lifecycle.reverse();
                    count = 1;
                }
                lifecycle.splice(-1, count, {
                    name: `Not Responding`,
                    date: date,
                    status: '4',
                    notes
                }, {
                    name: `FollowUp-1`,
                    date: addDaysToDateWithDayName(date.date, 7),
                    status: '4.1',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(date.date, 17),
                    status: '',
                    notes: ''
                });
            }
            else if (['6', '7'].includes(deal.deal_status)) {
                lifecycle.splice(-1, 1, {
                    name: 'Not Responding',
                    date: date,
                    status: '4',
                    notes
                }, {
                    name: 'FollowUp-1',
                    date: addDaysToDateWithDayName(date.date, 7),
                    status: '4.1',
                    notes: ''
                }, {
                    name: 'Update Stage',
                    date: addDaysToDateWithDayName(date.date, 17),
                    status: '',
                    notes: ''
                });
            }
        }
        else if (stage == '5') {
            let count = 0;
            let index;
            if (prevStage.status == '2') {
                lifecycle.splice(-1, 1, {
                    name: `In Conversation`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 3),
                    status: '5',
                    notes: ''
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 13),
                    status: '',
                    notes: ''
                });
            }
            else if (prevStage.status.startsWith('3')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('3')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('3') && elm.status !== deal?.deal_status) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle.reverse();
                    count = 1;
                }
                lifecycle.splice(-1, count, {
                    name: `In Conversation`,
                    date: date,
                    status: '5',
                    notes
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(date.date, 10),
                    status: '',
                    notes: ''
                });
            }
            else if (prevStage.status.startsWith('4.')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('4')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('4.') && elm.status !== deal?.deal_status) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle.reverse();
                    count = 1;
                }
                lifecycle.splice(-1, count, {
                    name: `In Conversation`,
                    date: date,
                    status: '5',
                    notes
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(date.date, 10),
                    status: '',
                    notes: ''
                });
            }
            else if (prevStage.status.startsWith('4')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('4')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('4.')) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle = lifecycle.filter(elm => !elm.status.startsWith('4'));
                    lifecycle.reverse();
                    count = 1;
                }
                lifecycle.splice(-1, count, {
                    name: `In Conversation`,
                    date: date,
                    status: '5',
                    notes
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(date.date, 10),
                    status: '',
                    notes: ''
                });
            }
            else if (prevStage.status == stage) {
                lifecycle.splice(-2, 2, {
                    name: `In Conversation`,
                    date: prevStage.date,
                    complete_date: prevStage.complete_date,
                    status: '5',
                    notes
                }, {
                    name: `Update Stage`,
                    date: addDaysToDateWithDayName(prevStage.date.date, 10),
                    status: '',
                    notes: ''
                });
            }
            else if (['6', '7'].includes(prevStage.status)) {
                lifecycle.splice(-1, 1, {
                    name: 'In Conversation',
                    date: date,
                    status: '5',
                    notes
                }, {
                    name: 'Update Stage',
                    date: addDaysToDateWithDayName(date.date, 10),
                    status: '',
                    notes: ''
                });
            }
        }
        else if (['6', '7'].includes(stage)) {
            let count = 0;
            let index;
            if (prevStage.status == '2') {
                const statusName = stage == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, 1, {
                    name: statusName,
                    date: addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 10),
                    status: stage,
                    notes: ''
                });
            }
            else if (prevStage.status.startsWith('3')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('3')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('3') && elm.status !== deal?.deal_status) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle.reverse();
                    count = 1;
                }

                const statusName = stage == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, count, {
                    name: statusName,
                    date: date,
                    status: stage,
                    notes
                });
            }
            else if (prevStage.status.startsWith('4.')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('4')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('4.') && elm.status !== deal?.deal_status) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle.reverse();
                    count = 1;
                }
                const statusName = stage == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, count, {
                    name: statusName,
                    date: date,
                    status: stage,
                    notes
                });
            }
            else if (prevStage.status.startsWith('4')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('4')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('4.')) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle = lifecycle.filter(elm => !elm.status.startsWith('4'));
                    lifecycle.reverse();
                    count = 1;
                }
                const statusName = stage == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, count, {
                    name: statusName,
                    date: date,
                    status: stage,
                    notes
                });
            }
            else if (prevStage.status.startsWith('5.')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage.status) || (elm.notes && elm.status.startsWith('5')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('5.') && elm.status !== deal?.deal_status) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle.reverse();
                    count = 1;
                }
                const statusName = stage == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, count, {
                    name: statusName,
                    date: date,
                    status: stage,
                    notes
                });
            }
            else if (prevStage.status.startsWith('5')) {
                index = lifecycle.reverse().findIndex(elm => (elm.status == prevStage?.status) || (elm.notes && elm.status.startsWith('5')));
                if (index !== -1) {
                    lifecycle.splice(0, index);
                    lifecycle.reverse();
                    // lifecycle.forEach((elm, idx) => {
                    //     if (elm.status.startsWith('5.')) {
                    //         lifecycle[idx].skipped = true;
                    //     }
                    // });
                } else {
                    lifecycle = lifecycle.filter(elm => !elm.status.startsWith('5'));
                    lifecycle.reverse();
                    count = 1;
                }
                const statusName = stage == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, count, {
                    name: statusName,
                    date: date,
                    status: stage,
                    notes
                });
            }
            else if (prevStage.status.startsWith('7')) {
                const statusName = stage == '6' ? 'Close' : 'Lose';
                lifecycle.splice(-1, 1, {
                    name: statusName,
                    date: date,
                    status: stage,
                    notes
                });
            }
        }
        sortLifeCycle(lifecycle);
        if (!prevStage.status.startsWith(stage == '3' ? newStatus : stage)) {
            if (['6', '7'].includes(deal.deal_status)) {
                const prevStatusIndex = getCurrentStatusIndexFromLifecycle(lifecycle, deal.last_activity);
                dispatch(updateDeal(params.id, { lifecycle, deal_status: deal.last_activity, last_activity: deal.deal_status, update_date: lifecycle[prevStatusIndex].complete_date }));
            } else {
                dispatch(updateDeal(params.id, { lifecycle }));
            }
        }
        // if ((stage == prevStage.status && deal.deal_status == stage) || (lifecycle[currentStatusIndex].status !== deal.deal_status)) {
        //     dispatch(updateDeal(params.id, { deal_status: stage, last_activity: deal.deal_status, lifecycle, update_date: formatDDMMYYYY(new Date()), lose_reason: null }));
        // }
        setAddStageModal(false);
        console.log(stage, notes, date, lifecycle);
    };

    // Add Days to Lifecycle
    const addDays = (status, index) => {
        let days = document.querySelector(`#day-count-${status}`)?.innerText;
        days = Number(days ? days : 0);
        days = days + 1;

        let lifecycle = JSON.parse(JSON.stringify(lifeCycle));
        lifecycle[index].date = addDaysToDateWithDayName(lifecycle[index].date.date, 1);

        document.querySelector(`#day-count-${status}`).innerText = days;

        for (let idx = index + 1; idx < lifecycle.length; idx++) {
            lifecycle[idx].date = addDaysToDateWithDayName(lifecycle[idx - 1].date.date, getDays(lifecycle[idx].status, lifecycle[idx - 1].status));
        }

        sortLifeCycle(lifecycle);
    };

    // Remove Days to Lifecycle
    const removeDays = (status, index) => {
        let days = document.querySelector(`#day-count-${status}`)?.innerText;
        days = Number(days ? days : 0);
        days = days - 1;

        let lifecycle = JSON.parse(JSON.stringify(lifeCycle));
        lifecycle[index].date = addDaysToDateWithDayName(lifecycle[index].date.date, -1);

        if (new Date(lifecycle[index - 1].date.date.split('/').reverse().join('-')).getTime() > new Date(lifecycle[index].date.date.split('/').reverse().join('-')).getTime()) return;

        document.querySelector(`#day-count-${status}`).innerText = days;

        for (let idx = index + 1; idx < lifecycle.length; idx++) {
            lifecycle[idx].date = addDaysToDateWithDayName(lifecycle[idx - 1].date.date, getDays(lifecycle[idx].status, lifecycle[idx - 1].status));
        }

        sortLifeCycle(lifecycle);
    };

    // Update Lifecycle With New Day Count
    const updateLifeCycle = () => {
        document.querySelectorAll('.day-count').forEach(elm => elm.innerText = 0);
        dispatch(updateDeal(params.id, { lifecycle: lifeCycle }));
    };

    // Delete Deal
    const removeDeal = async (id) => {
        const { isConfirmed } = await Swal.fire({
            title: "Are you sure to delete the deal?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#CF5A5A',
            confirmButtonText: `
                Delete&nbsp;<i class="fas fa-trash-alt"></i>
              `,
        });
        if (isConfirmed) {
            dispatch(deleteDeal(id));
        }
    };

    // Show/Hide Fields When Deal Status Field Change
    const handleStatusChange = (e) => {
        const dateEl = document.querySelector('#date');

        if (e.target?.value) {
            const dateValue = addDaysToDateWithDayName(lifeCycle[lifeCycle.length - 2]?.date?.date, getDays(e.target?.value));
            // dateEl.removeAttribute('disabled');
            dateEl.value = dateValue?.date?.split('/').reverse().join('-');
        }
        // else {
        //     dateEl.value = formatDDMMYYYY(new Date())?.split('/').reverse().join('-');
        //     dateEl.setAttribute('disabled', true);
        // }
    };

    return (
        <React.Fragment>
            <div className="pt-3 mx-2">
                <Container fluid>
                    <Card className="mb-3">
                        <CardBody className='p-2'>
                            <div className="page-title-box pb-0 d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18 d-flex align-items-center gap-3 ms-2">
                                    <Button color="dark" outline className="px-3 py-0" onClick={() => router.goBack()}>
                                        <i className="mdi mdi-arrow-left font-size-20" />
                                    </Button>
                                    Deal Details</h4>
                                <div className="page-title-right">
                                    <Button color='danger' onClick={() => removeDeal(params.id)}>Delete</Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col md={3} style={{ height: 'calc(100vh - 92px)' }}>
                            <Card className='mb-2'>
                                <CardBody className='py-2'>
                                    <h5 id="from_where" className='text-center font-size-15 m-0'>{Object.keys(deal ? deal : {}).includes('deal_from') ? dealFromMap[deal?.deal_from] : 'Self Created'}</h5>
                                </CardBody>
                            </Card>
                            <Card className='mini-stats-wid'>
                                <CardBody style={{ minHeight: '279px' }}>
                                    {!loading ?
                                        <>
                                            <div className="float-end ms-2">
                                                <button type="button" className="btn btn-light btn-sm" onClick={() => setClientDetailModal(true)}>
                                                    <i className="bx bx-edit font-size-15"></i>
                                                </button>
                                            </div>
                                            <div className="d-flex position-relative">
                                                <div className="flex-shrink-0 me-3 avatar-sm rounded-circle bg-primary mx-auto mini-stat-icon">
                                                    <span className="avatar-title rounded-circle bg-primary font-size-20">
                                                        {deal?.client_name?.slice(0, 1)?.toUpperCase()}
                                                    </span>
                                                </div>
                                                <div className="flex-grow-1 align-self-center">
                                                    <div className="text-muted">
                                                        <h5 className="mb-1" onClick={() => clickToCopy(deal?.client_name)}>{deal?.client_name ? deal?.client_name : 'New Client'}</h5>
                                                        <p className="mb-2" onClick={() => clickToCopy(deal?.company_name)}>{deal?.company_name ? deal?.company_name : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between gap-2 mb-2">
                                                <p className="d-flex align-items-center m-0" onClick={() => clickToCopy(deal?.email)}><i className="mdi mdi-email me-1 text-info font-size-17"></i>{deal?.email}</p>
                                                <i className={`bx ${deal?.is_potential_client ? 'bxs-bookmark-plus' : 'bx-bookmark-plus'} ${!['6', '7'].includes(dealStatus) && !deal?.is_potential_client ? 'text-muted' : 'text-warning'} font-size-22 float-end`} title='Mark As Potential Client' role='button' onClick={(e) => activeBtn(e.target)}></i>
                                            </div>
                                            <div className="d-flex justify-content-between gap-2 text-muted flex-wrap align-items-center">
                                                <div className="hstack gap-2 justify-content-center">
                                                    <p className="d-flex align-items-center text-center m-0" onClick={() => clickToCopy(deal?.owner)}><i className="bx bx-user me-1 text-info font-size-17"></i><span className="badge bg-primary font-size-11 m-1">{deal?.owner ? deal?.owner : 'N/A'}</span></p>
                                                </div>
                                                <div className="hstack gap-2 justify-content-center">
                                                    <p className="d-flex align-items-center text-center m-0" onClick={() => clickToCopy(deal?.request_date)}><i className="bx bx-calendar me-1 text-info font-size-17"></i><span className="badge bg-primary font-size-11 m-1">{deal?.request_date ? deal?.request_date : 'N/A'}</span></p>
                                                </div>
                                            </div>
                                            <hr />
                                            <h5>Notes</h5>
                                            <Row>
                                                <Col sm="12">
                                                    <p className="text-wrap text-truncate-three-line m-0" onClick={() => setStageNotesModal(true)} title={deal?.notes}>
                                                        {deal?.notes ? deal?.notes : 'Click to add note'}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        !Object.keys(deal ? deal : {}).length && deal !== undefined ?
                                            <Spinner color="primary" className='position-absolute top-50 start-50' />
                                            :
                                            <h4 className="position-absolute top-50 start-50 translate-middle w-100 text-center text-muted opacity-25">No Data Available</h4>
                                    }
                                </CardBody>
                            </Card>
                            <Card style={{ height: 'calc(100% - 347px)' }}>
                                <CardBody className="pb-0 overflow-y-auto">
                                    {!loading ?
                                        <Row>
                                            <Col lg={dealStatus == '7' ? 6 : 12}>
                                                <div className="mb-3">
                                                    <Label>Deal Status</Label>
                                                    <select className="form-select" value={dealStatus} onChange={(e) => {
                                                        setDealStatus(e.target.value);
                                                        setLoseReason(deal?.lose_reason);
                                                        if (deal?.deal_status) {
                                                            setLastActivity(e.target.value != deal?.deal_status ? deal?.deal_status : deal?.last_activity);
                                                        }
                                                    }}>
                                                        <option value="">Select Deal Status</option>
                                                        {(deal?.deal_status == '1' || dealStatus == '2') &&
                                                            <option value="2">Contacted</option>
                                                        }
                                                        {(!['1', '2'].includes(deal?.deal_status) && dealStatus.includes('.')) &&
                                                            (<option value={deal?.deal_status}>{(deal?.deal_status ? deal?.deal_status?.startsWith('4.') : false) ? `${columnsMapping(deal?.deal_status)} (Not Responding Followup)` : (deal?.deal_status ? deal?.deal_status.startsWith('5.') : false) ? `${columnsMapping(deal?.deal_status)} (In Conversation Followup)` : ''}</option>)}
                                                        {[...new Set(lifeCycle.map((elm, index) => (
                                                            (elm.status && !['1', '2', '4', '5', '6', '7'].includes(elm.status) && (getCurrentStatusIndexFromLifecycle(lifeCycle, deal?.deal_status) < index)) &&
                                                            <option value={elm.status} key={index}>{elm.status.startsWith('4.') ? `${elm.name} (Not Responding Followup)` : elm.status.startsWith('5.') ? `${elm.name} (In Conversation Followup)` : elm.name}</option>
                                                        )))]}
                                                        <option value="4">Not Responding</option>
                                                        <option value="5">In Conversation</option>
                                                        <option value="6">Close</option>
                                                        <option value="7">Lose</option>
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg={dealStatus == '7' ? 6 : 12}>
                                                <div className="mb-3">
                                                    <Label for="lastActivity">
                                                        Last Activity
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        value={lastActivity ? columnsMapping(lastActivity) : ''}
                                                        onChange={(e) => setLastActivity(e.target.value)}
                                                        className="form-control"
                                                        id="lastActivity"
                                                        readOnly
                                                        disabled
                                                        placeholder="Last Activity"
                                                    />
                                                </div>
                                            </Col>
                                            {dealStatus == 7 ?
                                                <>
                                                    <Col lg="12">
                                                        <div className="mb-3">
                                                            <Label for="lastActivity">
                                                                Lose Reason
                                                            </Label>
                                                            <select id="lose-reason" className="form-select" onChange={(e) => {
                                                                setLoseReason(e.target.value);
                                                                if (e.target?.value === "Other") {
                                                                    const customMessageRow = document.querySelector('.custom-message');
                                                                    if (customMessageRow) {
                                                                        if (customMessageRow.classList.contains('d-none')) {
                                                                            customMessageRow.classList.remove('d-none');
                                                                        }
                                                                    }
                                                                } else {
                                                                    const customMessageRow = document.querySelector('.custom-message');
                                                                    if (customMessageRow) {
                                                                        if (!customMessageRow.classList.contains('d-none')) {
                                                                            customMessageRow.classList.add('d-none');
                                                                        }
                                                                    }
                                                                }
                                                            }}>
                                                                <option defaultValue value="">
                                                                    Select Reason
                                                                </option>
                                                                <option value="We don't have OTS data">
                                                                    We don't have OTS data
                                                                </option>
                                                                <option value="Budget Issue">Budget Issue</option>
                                                                <option value="Deadline Issue">Deadline Issue</option>
                                                                <option value="Other">Other</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    {(!["We don't have OTS data", "Budget Issue", "Deadline Issue"].includes(loseReason) && loseReason) ?
                                                        <Col lg="12">
                                                            <div className="mb-3">
                                                                <Label for="lose-reason-custom">
                                                                    Specify Reason
                                                                </Label>
                                                                <Input
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    id="lose-reason-custom"
                                                                    placeholder="Add your reason here"
                                                                    value={loseReason}
                                                                    onChange={(e) => setLoseReason(e.target.value)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        : null}
                                                </>
                                                : null}
                                        </Row>
                                        :
                                        !Object.keys(deal ? deal : {}).length && deal !== undefined ?
                                            <Spinner color="primary" className='position-absolute top-50 start-50' />
                                            :
                                            <h4 className="position-absolute top-50 start-50 translate-middle w-100 text-center text-muted opacity-25">No Data Available</h4>
                                    }
                                </CardBody>
                                <div style={{ padding: '0px 20px 20px' }}>
                                    <Button color="primary" block onClick={() => { saveStatusDetails(); }}>Save</Button>
                                </div>
                            </Card>
                        </Col>
                        <Col md={5} style={{ height: 'calc(100vh - 92px)' }}>
                            <Card className="h-100">
                                <CardBody className='overflow-y-auto pb-0'>
                                    {!loading ?
                                        <Form>
                                            <Row>
                                                <Col lg="4" className="mx-auto">
                                                    <div className="mb-3">
                                                        <Label for="service">
                                                            Service
                                                        </Label>
                                                        <select value={service} id="service" className="form-select" onChange={(e) => {
                                                            setService(e.target.value); setDataForm(''); setDatasetSubType(''); setLanguage(''); setIndustry(''); setDemography(''); setType('');
                                                        }}>
                                                            <option value=''>Select Service</option>
                                                            <option value="OTS Data">OTS Data</option>
                                                            <option value="Custom Collection">Custom Collection</option>
                                                            <option value="Transcription">Transcription</option>
                                                            <option value="Data Annotation">Data Annotation</option>
                                                            <option value="RLHF">RLHF</option>
                                                            <option value="Yugo (SAAS)">Yugo (SAAS)</option>
                                                        </select>
                                                    </div>
                                                </Col>

                                                <Col lg="3" className="mx-auto">
                                                    <div className="mb-3">
                                                        <Label for="data-form">
                                                            Dataset Type
                                                        </Label>
                                                        <select value={dataForm} id="data-form" className="form-select" onChange={(e) => setDataForm(e.target.value)}>
                                                            <option value=''>Select Dataset Type</option>
                                                            {getDatasetTypeOptions(service).map(elm => (<option value={elm} key={elm}>{elm}</option>))}
                                                        </select>
                                                    </div>
                                                </Col>

                                                <Col lg="5" className="mx-auto">
                                                    <div className="mb-3">
                                                        <Label for="datasetSubType">
                                                            Dataset Sub Type
                                                        </Label>
                                                        <select value={datasetSubType} id="datasetSubType" className="form-select" onChange={(e) => setDatasetSubType(e.target.value)}>
                                                            <option value=''>Select Dataset Sub Type</option>
                                                            {getDatasetSubTypeOptions(dataForm).map(elm => (<option value={elm} key={elm}>{elm}</option>))}
                                                        </select>
                                                    </div>
                                                </Col>

                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="datasetName">
                                                            Dataset Title
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            value={datasetName}
                                                            onChange={(e) => setDatasetName(e.target.value)}
                                                            className="form-control"
                                                            id="datasetName"
                                                            placeholder="Enter Dataset Name"
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg="6" className="mx-auto">
                                                    <div className="mb-3">
                                                        <Label className="control-label" for='language'>
                                                            Language
                                                        </Label>
                                                        <Select
                                                            value={language}
                                                            isMulti={true}
                                                            onChange={setLanguage}
                                                            options={languages}
                                                            className="select2-selection"
                                                            classNamePrefix="crm"
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg="6" className="mx-auto">
                                                    <div className="mb-3">
                                                        <Label for="industry">
                                                            Industry
                                                        </Label>
                                                        <select value={industry} id="industry" className="form-select" onChange={(e) => setIndustry(e.target.value)}>
                                                            <option value=''>Select Industry</option>
                                                            <option value="General">General</option>
                                                            <option value="Healthcare">Healthcare</option>
                                                            <option value="Retain and E-Commerce">Retain and E-Commerce</option>
                                                            <option value="Manufacturing">Manufacturing</option>
                                                            <option value="BFSI">BFSI</option>
                                                            <option value="Automobile">Automobile</option>
                                                            <option value="Telecom">Telecom</option>
                                                            <option value="Real-Estate">Real-Estate</option>
                                                        </select>
                                                    </div>
                                                </Col>

                                                <Col lg="6" className="mx-auto">
                                                    <div className="mb-3">
                                                        <Label for="demography">
                                                            Demography
                                                        </Label>
                                                        <Select
                                                            value={demography}
                                                            id="demography"
                                                            isMulti={true}
                                                            onChange={setDemography}
                                                            options={demographyOptions}
                                                            className="select2-selection"
                                                            classNamePrefix="crm"
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="type">
                                                            Type
                                                        </Label>
                                                        <Select
                                                            value={type}
                                                            id="type"
                                                            isMulti={true}
                                                            onChange={setType}
                                                            options={typeOptions}
                                                            className="select2-selection"
                                                            classNamePrefix="crm"
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <Label for="clientType">
                                                            Client Type
                                                        </Label>
                                                        <select value={clientType} id="clientType" className="form-select" onChange={(e) => setClientType(e.target.value)}>
                                                            <option value=''>
                                                                Select Client Type
                                                            </option>
                                                            <option value="New">
                                                                New
                                                            </option>
                                                            <option value="Recurring">Recurring</option>
                                                        </select>
                                                    </div>
                                                </Col>

                                                <Col lg="6">
                                                    <Label for="clientType" className="d-flex align-items-center">
                                                        Dataset URLs <i className="bx bx-edit font-size-17 ms-2" role="button" onClick={() => setUrlModal(true)}></i>
                                                    </Label>
                                                    <div className="mb-3 d-flex align-items-center gap-3">
                                                        <button className="btn btn-outline-primary d-flex align-items-center" disabled={!deal?.page_link} onClick={(e) => { e.preventDefault(); clickToCopy(deal?.page_link); }} title={deal?.page_link ? 'Click to copy' : 'Page link is not available'}>
                                                            Page Link <i className="bx bx-copy font-size-17 ms-2"></i>
                                                        </button>
                                                        <button className="btn btn-outline-primary d-flex align-items-center" disabled={!deal?.sample_link} onClick={(e) => { e.preventDefault(); clickToCopy(deal?.sample_link); }} title={deal.sample_link ? 'Click to copy' : 'Sample link is not available'}>
                                                            Sample Link <i className="bx bx-copy font-size-17 ms-2"></i>
                                                        </button>
                                                    </div>
                                                </Col>

                                                <Col lg="4">
                                                    <div className="mb-3">
                                                        <Label for="volume">
                                                            Volume
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            value={volume}
                                                            onChange={(e) => setVolume(e.target.value)}
                                                            className="form-control"
                                                            id="volume"
                                                            placeholder="Enter Volume"
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg="4">
                                                    <div className="mb-3">
                                                        <Label for="purpose">
                                                            Purpose
                                                        </Label>
                                                        <select value={purpose} id="purpose" className="form-select" onChange={(e) => setPurpose(e.target.value)}>
                                                            <option value=''>Select Purpose</option>
                                                            <option value="Training">Training</option>
                                                            <option value="Testing">Testing</option>
                                                            <option value="Fine Tuning">Fine Tuning</option>
                                                            <option value="POC">POC</option>
                                                        </select>
                                                    </div>
                                                </Col>

                                                <Col lg="4">
                                                    <div className="mb-3">
                                                        <Label for="budget">
                                                            Budget
                                                        </Label>
                                                        <select value={budget} id="budget" className="form-select" onChange={(e) => setBudget(e.target.value)}>
                                                            <option value=''>Select Budget</option>
                                                            <option value="Upto $10k">Upto $10k</option>
                                                            <option value="$10k - $50k">$10k - $50k</option>
                                                            <option value="Above $50k">Above $50k</option>
                                                            <option value="Not sure">Not sure</option>
                                                        </select>
                                                    </div>
                                                </Col>

                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <Label for="message">
                                                            Message
                                                        </Label>
                                                        <Input
                                                            type='textarea'
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                            className="form-control"
                                                            id="message"
                                                            placeholder="Enter Message"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        :
                                        !Object.keys(deal ? deal : {}).length && deal !== undefined ?
                                            <Spinner color="primary" className='position-absolute top-50 start-50' />
                                            :
                                            <h4 className="position-absolute top-50 start-50 translate-middle w-100 text-center text-muted opacity-25">No Data Available</h4>
                                    }
                                </CardBody>
                                <div style={{ padding: '20px' }}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="w-md"
                                        onClick={handleSubmit}
                                        block
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                        <Col md={4} style={{ height: 'calc(100vh - 92px)' }}>
                            <Card className='h-100'>
                                <CardTitle className="d-flex justify-content-between" style={{ padding: '20px' }}>
                                    <span>Life Cycle</span>
                                    <Button size="sm" color="primary" className="d-flex justify-content-center align-items-center gap-1" onClick={() => setAddStageModal(true)}>
                                        <i className="bx bx-plus text-white" />Stage
                                    </Button>
                                </CardTitle>
                                <CardBody className="overflow-y-auto py-0">
                                    {!loading ?
                                        <div>
                                            <ul className="verti-timeline list-unstyled">
                                                {(lifeCycle || [])?.map((cycle, i) => (
                                                    <li
                                                        className={
                                                            cycle.status === deal?.deal_status
                                                                ? "event-list active pb-2"
                                                                : "event-list pb-2"
                                                        }
                                                        key={"_cycle_" + i}
                                                    >
                                                        <div className="event-timeline-dot">
                                                            <i
                                                                className={
                                                                    getCurrentStatusIndexFromLifecycle(lifeCycle, deal?.deal_status) === i
                                                                        ? "bx bxs-right-arrow-circle bx-fade-right font-size-18"
                                                                        :
                                                                        (cycle.status && (getCurrentStatusIndexFromLifecycle(lifeCycle, deal?.deal_status) > i)) ? "bx bx-check-circle font-size-18 text-success"
                                                                            :
                                                                            "bx bx-right-arrow-circle font-size-18"
                                                                }
                                                            />
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="flex-grow-1">
                                                                <div className="">
                                                                    <div>
                                                                        <h5 className="font-size-15"
                                                                            style={{ width: '125px' }}>
                                                                            <span
                                                                                className="text-dark w-100 d-inline-block"
                                                                            >
                                                                                {cycle.name}
                                                                            </span>
                                                                        </h5>
                                                                    </div>
                                                                    <div className="mb-2 d-flex flex-wrap gap-3 align-items-center justify-content-between">
                                                                        <div className="d-flex gap-3">
                                                                            {getCurrentStatusIndexFromLifecycle(lifeCycle, deal?.deal_status) >= i ?
                                                                                <span className='badge badge-soft-info text-info font-size-12 m-0'>
                                                                                    {cycle.date.date} - {cycle.date.day?.slice(0, 3)}
                                                                                </span> : <span className='badge badge-soft-primary text-primary font-size-12 m-0'>
                                                                                    {cycle.date.date} - {cycle.date.day?.slice(0, 3)}
                                                                                </span>}
                                                                            {cycle.name !== 'Initiated' ?
                                                                                cycle.complete_date ?
                                                                                    <span className='badge badge-soft-success text-success font-size-12 m-0'>
                                                                                        {cycle.complete_date} - {new Date(cycle.complete_date.split('/').reverse().join('-')).toLocaleDateString('en-US', { weekday: 'short' })}
                                                                                    </span>
                                                                                    :
                                                                                    <span className='badge bg-warning font-size-12 m-0'>
                                                                                        {cycle.skipped ? "Skipped" : ""}
                                                                                    </span>
                                                                                : null
                                                                            }
                                                                        </div>
                                                                        {(getCurrentStatusIndexFromLifecycle(lifeCycle, deal?.deal_status) < i) &&
                                                                            (<div className="d-flex align-items-center gap-3">
                                                                                <span className="bx bx-minus" onClick={() => removeDays(cycle.status.replace('.', ''), i)}></span>

                                                                                <span className="day-count badge bg-primary text-white font-size-12" id={`day-count-${cycle.status.replace('.', '')}`}>0</span>

                                                                                <span className="bx bx-plus" onClick={() => addDays(cycle.status.replace('.', ''), i)}></span>
                                                                            </div>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex gap-2 align-items-start">
                                                                    <p className="m-0 text-wrap text-truncate-three-line" style={{ cursor: 'default' }} onClick={() => setStageNotesModal(true)} title={cycle?.notes}>
                                                                        {cycle?.notes ? cycle?.notes : 'Add Notes'}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        :
                                        !Object.keys(deal ? deal : {}).length && deal !== undefined ?
                                            <Spinner color="primary" className='position-absolute top-50 start-50' />
                                            :
                                            <h4 className="position-absolute top-50 start-50 translate-middle w-100 text-center text-muted opacity-25">No Data Available</h4>
                                    }
                                </CardBody>
                                <div style={{ padding: '20px' }}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="w-md"
                                        onClick={updateLifeCycle}
                                        block
                                    >
                                        Update
                                    </Button>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Update Client Details Modal */}
            <Modal
                isOpen={clientDetailModal}
                role="dialog"
                size=""
                autoFocus={true}
                id="clientDetailModal"
                toggle={() => setClientDetailModal(!clientDetailModal)}
            >
                <div className="modal-content">
                    <ModalHeader toggle={() => setClientDetailModal(!clientDetailModal)}>
                        Client Details
                    </ModalHeader>
                    <ModalBody>
                        <div className="content clearfix mx-3">
                            <Form onSubmit={saveClientDetails}>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="clientName">
                                                Client Name
                                            </Label>
                                            <Input
                                                type="text"
                                                name="clientName"
                                                defaultValue={deal?.client_name ? deal?.client_name : ''}
                                                className="form-control"
                                                id="clientName"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="companyName">
                                                Company Name
                                            </Label>
                                            <Input
                                                type="text"
                                                defaultValue={deal?.company_name ? deal?.company_name : ''}
                                                name="companyName"
                                                className="form-control"
                                                id="companyName"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="email">
                                                Email
                                            </Label>
                                            <Input
                                                type="text"
                                                defaultValue={deal?.email ? deal?.email : ''}
                                                name="email"
                                                className="form-control"
                                                id="email"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="deal-owner">
                                                Deal Owner
                                            </Label>
                                            <select id="deal-owner" defaultValue={deal?.owner ? deal?.owner : ''} className="form-select" name="owner">
                                                <option value=''>
                                                    Select Deal Owner
                                                </option>
                                                <option value="Jesal">
                                                    Jesal
                                                </option>
                                                <option value="Praveen">Praveen</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="requestDate">
                                                Request Date
                                            </Label>
                                            <Input
                                                type="date"
                                                value={requestDate}
                                                onChange={(e) => setRequestDate(e.target.value)}
                                                className="form-control"
                                                id="requestDate"
                                                placeholder="Enter Request Date"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="fromWhere">
                                                Inbound/Outbound Place
                                            </Label>
                                            <select defaultValue={deal?.inbound_outbound_place ? deal?.inbound_outbound_place : ''} id="fromWhere" className="form-select" name="fromWhere">
                                                <option value=''>Select Inbound/Outbound Place</option>
                                                <option value="Dataset Request">Dataset Request</option>
                                                <option value="Contact Us">Contact Us</option>
                                                <option value="Get a Call">Get a Call</option>
                                                <option value="Community Request">Community Request</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className="text-end">
                                        <Button type="submit" color="primary">Save</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
            {/* Update URLs Modal */}
            <Modal
                isOpen={urlModal}
                role="dialog"
                size="lg"
                autoFocus={true}
                id="urlModal"
                toggle={() => setUrlModal(!urlModal)}
            >
                <div className="modal-content">
                    <ModalHeader toggle={() => setUrlModal(!urlModal)}>
                        Dataset URLs
                    </ModalHeader>
                    <ModalBody>
                        <div className="content clearfix mx-3">
                            <Form onSubmit={saveUrls}>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="pageLink">
                                                Page Link
                                            </Label>
                                            <Input
                                                type="text"
                                                name="pageLink"
                                                defaultValue={deal?.page_link ? deal?.page_link : ''}
                                                className="form-control"
                                                id="pageLink"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="sampleLink">
                                                Sample Link
                                            </Label>
                                            <Input
                                                type="text"
                                                name="sampleLink"
                                                defaultValue={deal?.sample_link ? deal?.sample_link : ''}
                                                className="form-control"
                                                id="sampleLink"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className="text-end">
                                        <Button type="submit" color="primary" className="px-5">Save</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
            {/* Update Notes Modal */}
            <Modal
                isOpen={stageNotesModal}
                role="dialog"
                size="xl"
                autoFocus={true}
                id="stageNotesModal"
                toggle={() => setStageNotesModal(!stageNotesModal)}
            >
                <div className="modal-content">
                    <ModalHeader toggle={() => setStageNotesModal(!stageNotesModal)}>
                        Deal Notes
                    </ModalHeader>
                    <ModalBody>
                        <div className="content clearfix mx-3">
                            <Form onSubmit={updateStageNotes}>
                                <div className='mb-3'>
                                    <Row>
                                        <Col lg="12">
                                            <div className="mb-3">
                                                <Label for="clientName">
                                                    General Notes
                                                </Label>
                                                <Input
                                                    type="textarea"
                                                    name="general_notes"
                                                    defaultValue={deal?.notes ? deal?.notes : ''}
                                                    className="form-control"
                                                    id="general_notes"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mb-4">
                                    <Label for="stage" className="mb-4">
                                        Stage Notes
                                    </Label>
                                    <ul id="stage" className="verti-timeline list-unstyled">
                                        {(deal?.lifecycle || [])?.map((cycle, i) => (
                                            <li
                                                className={
                                                    cycle.status === deal?.deal_status
                                                        ? "event-list active"
                                                        : "event-list"
                                                }
                                                key={"_cycle_" + i}
                                            >
                                                <div className="event-timeline-dot">
                                                    <i
                                                        className={
                                                            getCurrentStatusIndexFromLifecycle(lifeCycle, deal?.deal_status) === i
                                                                ? "bx bxs-right-arrow-circle bx-fade-right font-size-18"
                                                                :
                                                                (cycle.status && (getCurrentStatusIndexFromLifecycle(lifeCycle, deal?.deal_status) > i)) ? "bx bx-check-circle font-size-18 text-success"
                                                                    :
                                                                    "bx bx-right-arrow-circle font-size-18"
                                                        }
                                                    />
                                                </div>
                                                <div className="d-flex">
                                                    <div className="d-flex gap-3 flex-grow-1">
                                                        <div>
                                                            <div>
                                                                <h5 className="font-size-15"
                                                                    style={{ width: '120px' }}>
                                                                    <span
                                                                        className="text-dark"
                                                                    >
                                                                        {cycle.name}
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                            <div className="mb-2 d-flex flex-wrap gap-3 align-items-center justify-content-between">
                                                                <span className="text-primary font-size-13 m-0">
                                                                    {cycle.date.date} - {cycle.date.day?.slice(0, 3)}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='w-100'>
                                                            <Input
                                                                type="textarea"
                                                                name={`${cycle.name}-${cycle.status}`}
                                                                defaultValue={cycle.notes}
                                                                className="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <Row>
                                    <Col sm={12} className="text-end">
                                        <Button type="submit" color="primary">Save</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
            {/* Add Stage Modal */}
            <Modal
                isOpen={addStageModal}
                role="dialog"
                size=""
                autoFocus={true}
                id="addStageModal"
                toggle={() => setAddStageModal(!addStageModal)}
            >
                <div className="modal-content">
                    <ModalHeader toggle={() => setAddStageModal(!addStageModal)}>
                        Add Stage
                    </ModalHeader>
                    <ModalBody>
                        <div className="content clearfix mx-3">
                            <Form onSubmit={addStageToLifeCycle}>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label>Deal Status</Label>
                                            <select disabled className="form-select" defaultValue="3" name="stage" onChange={handleStatusChange} required>
                                                <option value="">Select Stage</option>
                                                <option value="3">Follow Up</option>
                                                <option disabled={deal?.deal_status == '4'} value="4">Not Responding</option>
                                                <option disabled={deal?.deal_status == '5'} value="5">In Conversation</option>
                                                <option disabled={deal?.deal_status == '6'} value="6">Close</option>
                                                <option disabled={deal?.deal_status == '7'} value="7">Lose</option>
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="notes">
                                                Notes
                                            </Label>
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                className="form-control"
                                                id="notes"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label for="date">
                                                Date
                                            </Label>
                                            <Input
                                                type="date"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                defaultValue={lifeCycle.length ? addDaysToDateWithDayName(lifeCycle[lifeCycle.length - 2]?.date?.date, 2)?.date?.split('/')?.reverse()?.join('-') : ''}
                                                required
                                                min={lifeCycle[lifeCycle.length - 2]?.date?.date?.split('/')?.reverse()?.join('-')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className="text-end">
                                        <Button type="submit" color="primary">Add</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
};

export default ViewDeal;