import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { useLocation, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SiCrowdsource, SiFreelancer } from "react-icons/si";
import { TbDatabaseExport, TbClipboardList, TbFileSearch, TbDeviceMobile, TbLayoutGridAdd } from "react-icons/tb";
import { MdPhoneCallback, MdSubscriptions } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { AiOutlineDatabase } from "react-icons/ai";
import { GoHome } from "react-icons/go";
import { Badge } from 'rsuite';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCall, getContactUs, getDatasetRequest, getFreelancerData, getSubscriber, get_all_community, get_All_Sell_Data, getDeal } from "store/actions";

const SidebarContent = props => {
  const [dataSetReq, setdataSetReq] = useState(0);
  const [dealsData, setdealsData] = useState(0);
  const [subscriber, setSubscriber] = useState(0);
  const [contactus, setContactUs] = useState(0);
  const [freelancer, setFreelancer] = useState(0);
  const [getAcall, setGetAcall] = useState(0);
  const [sellData, setSellData] = useState(0);
  const [community, setCommunity] = useState(0);
  const [caseStudies, setCaseStudies] = useState(0);
  const [yugo, setYugo] = useState(0);
  const email = JSON.parse(localStorage.getItem("authUser"))?.result[0]?.email
  const ref = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const { datasetReq, subscriberr, contactss, freeLancerData, getCallData, selldata, communityData, deals } = useSelector(state => ({
    datasetReq: state.DatasetRequest.users,
    subscriberr: state.Subscriber.users,
    contactss: state.ContactsUs.users,
    freeLancerData: state.freelancerReducer.data,
    getCallData: state.getCallReducer.data,
    selldata: state.getSelldata.data,
    communityData: state.getCommunity.data,
    deals: state.DealsReducer.deals?.success ? state.DealsReducer.deals?.result : []

    // casestudies: state.getcaseStudies.data
  }));

  useEffect(() => {
    dispatch(getDatasetRequest());
    dispatch(getSubscriber());
    dispatch(getContactUs());
    dispatch(getFreelancerData());
    dispatch(getCall());
    dispatch(get_All_Sell_Data());
    dispatch(get_all_community());
    dispatch(getDeal());
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("DatasetReqNewMessage")) {
      localStorage.setItem("DatasetReqNewMessage", "First");
    }
  }, []);

  useEffect(() => {
    let oldMessage = localStorage.getItem("DatasetReqNewMessage");

    if (oldMessage == datasetReq.result?.length) {
      setdataSetReq(0);
    } else if (Number(oldMessage) < datasetReq.result?.length) {
      setdataSetReq(1);
    } else if (Number(oldMessage) > datasetReq.result?.length) {
      setdataSetReq(0);
      localStorage.setItem("DatasetReqNewMessage", datasetReq.result?.length);
    } else if (oldMessage == "First") {
      setdataSetReq(1);
    } else if (location.pathname == "/dataset-request") {
      setdataSetReq(0);
    } else if (oldMessage == undefined) {
      setdataSetReq(1);
    }
  }, [datasetReq]);

  useEffect(() => {
    let oldMessage = localStorage.getItem("newDealsData") || "First";

    if (oldMessage == deals?.length) {
      setdataSetReq(0);
    } else if (Number(oldMessage) < deals?.length) {
      setdataSetReq(1);
    } else if (Number(oldMessage) > deals?.length) {
      setdataSetReq(0);
      localStorage.setItem("newDealsData", deals?.length);
    } else if (oldMessage == "First") {
      setdataSetReq(1);
    } else if (location.pathname == "/crm/deals") {
      setdataSetReq(0);
    } else if (oldMessage == undefined) {
      setdataSetReq(1);
    }
  }, [deals]);

  useEffect(() => {
    let oldMessage = localStorage.getItem("SubscriberNewMessage") || "First";
    if (oldMessage === subscriberr.result?.length) {
      setSubscriber(0);
    } else if (Number(oldMessage) < subscriberr.result?.length) {
      setSubscriber(1);
    } else if (Number(oldMessage) > subscriberr.result?.length) {
      setSubscriber(0);
      localStorage.setItem("SubscriberNewMessage", subscriberr.result?.length);
    } else if (oldMessage === "First") {
      setSubscriber(1);
    } else if (location.pathname === "/subscriber") {
      setSubscriber(0);
    } else if (oldMessage === undefined) {
      setSubscriber(1);
    }
  }, [subscriberr]);

  useEffect(() => {
    let oldMessage = localStorage.getItem("ContactUsNewMessage") || "First";
    if (oldMessage == contactss.result?.length) {
      setContactUs(0);
    } else if (Number(oldMessage) < contactss.result?.length) {
      setContactUs(1);
    } else if (Number(oldMessage) > contactss.result?.length) {
      setContactUs(0);
      localStorage.setItem("ContactUsNewMessage", contactss.result?.length);
    } else if (oldMessage === "First") {
      setContactUs(1);
    } else if (location.pathname === "/contact-us") {
      setContactUs(0);
    } else if (oldMessage === undefined) {
      setContactUs(1);
    }
  }, [contactss]);

  useEffect(() => {
    let oldMessage = localStorage.getItem("FreeLancerNewMessage") || "First";
    if (oldMessage == freeLancerData.result?.length) {
      setFreelancer(0);
    } else if (Number(oldMessage) < freeLancerData.result?.length) {
      setFreelancer(1);
    } else if (Number(oldMessage) > freeLancerData.result?.length) {
      setFreelancer(0);
      localStorage.setItem("FreeLancerNewMessage", freeLancerData.result?.length);
    } else if (oldMessage === "First") {
      setFreelancer(1);
    } else if (location.pathname === "/freelancer-vendor") {
      setFreelancer(0);
    }
  }, [freeLancerData]);

  useEffect(() => {
    let oldMessage = localStorage.getItem("GetCallNewMessage") || "First";
    if (oldMessage == getCallData.result?.length) {
      setGetAcall(0);
    } else if (Number(oldMessage) < getCallData.result?.length) {
      setGetAcall(1);
    } else if (Number(oldMessage) > getCallData.result?.length) {
      setGetAcall(0);
      localStorage.setItem("GetCallNewMessage", getCallData.result?.length);
    } else if (oldMessage == "First") {
      setGetAcall(1);
    } else if (location.pathname == "/get-a-call") {
      setGetAcall(0);
    } else if (oldMessage == undefined) {
      setGetAcall(0);
    }
  }, [getCallData]);

  useEffect(() => {
    let oldMessage = localStorage.getItem("sellDataNewMessage") || "First";
    if (oldMessage == selldata.result?.length) {
      setSellData(0);
    } else if (Number(oldMessage) < selldata.result?.length) {
      setSellData(1);
    } else if (Number(oldMessage) > selldata.result?.length) {
      setSellData(0);
      localStorage.setItem("sellDataNewMessage", selldata.result?.length);
    } else if (oldMessage == "First") {
      setSellData(1);
    } else if (location.pathname == "/sell-data") {
      setSellData(0);
    }
  }, [selldata]);

  useEffect(() => {
    let oldMessage = localStorage.getItem("CommunityNewMessage") || "First";
    if (oldMessage == communityData.result?.length) {
      setCommunity(0);
    } else if (Number(oldMessage) < communityData.result?.length) {
      setCommunity(1);
    } else if (Number(oldMessage) > communityData.result?.length) {
      setCommunity(0);
      localStorage.setItem("CommunityNewMessage", communityData.result?.length);
    } else if (oldMessage == "First") {
      setCommunity(1);
    } else if (location.pathname == "/community-request") {
      setCommunity(0);
    }
  }, [communityData]);

  let badgeClickDatasetReq = () => {
    setdataSetReq(0);
  };
  let badgeClickSubscriber = () => {
    setSubscriber(0);
  };
  let badgeClickContactUs = () => {
    setContactUs(0);
  };
  let badgeClickFreelancer = () => {
    setFreelancer(0);
  };
  let badgeClickGetCall = () => {
    setGetAcall(0);
  };
  let badgeClickSellData = () => {
    setSellData(0);
  };
  let badgeClickCommunityData = () => {
    setCommunity(0);
  };
  let badgeClickCaseStudies = () => {
    setCaseStudies(0);
  };
  let badgeClickYugo = () => {
    setYugo(0);
  };

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/#" className="has-arrow fw-bolder">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Dataset")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">
                    <i className="" >
                      <GoHome /> </i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/list_dataset">
                    <i className="">
                      <TbClipboardList />
                    </i>
                    <span>{props.t("Dataset List")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/add_dataset">
                    <i className="">
                      <TbLayoutGridAdd />
                    </i>
                    <span>{props.t("Add Dataset")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="" style={{ fontSize: "18px" }}> <BiCategory /> </i>
                    <span>{props.t("Category Section")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/all-types-data">{props.t("All Categories")}</Link>
                    </li>
                    <li>
                      <Link to="/nlp">{props.t("NLP")}</Link>
                    </li>
                    <li>
                      <Link to="/computer-vision">{props.t("Computer Vision")}</Link>
                    </li>
                    <li>
                      <Link to="/ocr">{props.t("OCR")}</Link>
                    </li>
                    <li>
                      <Link to="/generative-ai">{props.t("Generative AI")}</Link>
                    </li>
                    <li>
                      <Link to="/speech-recognition">{props.t("Speech Recognition")}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="fa fa-industry" style={{ fontSize: "18px" }}></i>
                    <span>{props.t("Industries Section")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/all-industries">{props.t("All Industries")}</Link>
                    </li>
                    <li>
                      <Link to="/retail-and-ecommerce">{props.t("Retail And E-commerce")}</Link>
                    </li>
                    <li>
                      <Link to="/ar-vr">{props.t("AR/VR")}</Link>
                    </li>
                    <li>
                      <Link to="/autonomous-vehicle">{props.t("Autonomous Vehicle")}</Link>
                    </li>
                    <li>
                      <Link to="/real-estate">{props.t("Real Estate")}</Link>
                    </li>
                    <li>
                      <Link to="/health-care">{props.t("Health Care")}</Link>
                    </li>
                    <li>
                      <Link to="/safety-and-surveillance">{props.t("Safety And Surveillance")}</Link>
                    </li>
                    <li>
                      <Link to="/travel">{props.t("Travel")}</Link>
                    </li>
                    <li>
                      <Link to="/telecom">{props.t("Telecom")}</Link>
                    </li>
                    <li>
                      <Link to="/banking-and-finance">{props.t("Banking And Finance")}</Link>
                    </li>

                  </ul>
                </li>
              </ul>
            </li>
            {(email == "futurbeeai@dev.com" || email == "jesal@futurebeeai.com" || email == "praveenkumar@futurebeeai.com") && (
              <>
                <li>
                  <Link to="/#" className="has-arrow fw-bolder">
                    <i className="bx bx-git-pull-request"></i>
                    <span>{props.t("Inbound Requests")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/dataset-request" onClick={badgeClickDatasetReq} >
                        <i className="" ><AiOutlineDatabase /> </i>
                        {dataSetReq ? <Badge ><span  >{props.t("Dataset Request")}</span></Badge> : <span>{props.t("Dataset Request")}</span>}
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact-us" onClick={badgeClickContactUs} >
                        <i className="bx bxs-contact"></i>
                        {contactus ? <Badge >  <span>{props.t("Contact Us")}</span> </Badge> : <span>{props.t("Contact Us")}</span>}
                      </Link>
                    </li>
                    <li>
                      <Link to="/get-a-call" onClick={badgeClickGetCall} >
                        <i className=""> <MdPhoneCallback /> </i>
                        {getAcall ? <Badge >  <span>{props.t("Get A Call")}</span> </Badge> : <span>{props.t("Get A Call")}</span>}
                      </Link>
                    </li>
                    <li>
                      <Link to="/community-request" onClick={badgeClickCommunityData} >
                        <i>  <SiCrowdsource /> </i>
                        {community ? <Badge >  <span>{props.t("Community Req.")}</span> </Badge> : <span>{props.t("Community Req.")}</span>}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow fw-bolder">
                    <i className="bx bxs-component"></i>
                    <span>{props.t("CRM")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/crm/dashboard" onClick={badgeClickDatasetReq} >
                        <i className="" ><AiOutlineDatabase /> </i>
                        {dataSetReq ? <Badge ><span  >{props.t("Dashboard")}</span></Badge> : <span>{props.t("Dashboard")}</span>}
                      </Link>
                    </li>
                    <li>
                      <Link to="/crm/deals" onClick={badgeClickContactUs} >
                        <i className="bx bx-mail-send"></i>
                        {dealsData ? <Badge >  <span>{props.t("Deals")}</span> </Badge> : <span>{props.t("Deals")}</span>}
                      </Link>
                    </li>
                    <li>
                      <Link to="/crm/to-do" onClick={badgeClickGetCall} >
                        <i className="bx bx-task"></i>
                        {getAcall ? <Badge >  <span>{props.t("To-Do")}</span> </Badge> : <span>{props.t("To-Do")}</span>}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )}

            <li>
              <Link to="/#" className="has-arrow fw-bolder">
                <i className="bx bx-group"></i>
                <span>{props.t("Community")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/freelancer-vendor" onClick={badgeClickFreelancer} >
                    <i className=""> <SiFreelancer /></i>
                    {freelancer ? <Badge >    <span>{props.t("Freelancer/Vendor")}</span>   </Badge> : <span>{props.t("Freelancer/Vendor")}</span>}
                  </Link>
                </li>
                <li>
                  <Link to="/subscriber" onClick={badgeClickSubscriber} >
                    <i className=""> <MdSubscriptions />  </i>
                    {subscriber ? <Badge >    <span>{props.t("Subscriber")}</span> </Badge> : <span>{props.t("Subscriber")}</span>}
                  </Link>
                </li>
                <li>
                  <Link to="/sell-data" onClick={badgeClickSellData}  >
                    <i className=""> <TbDatabaseExport /> </i>
                    {sellData ? <Badge ><span>{props.t("Sell Data")}</span> </Badge> : <span>{props.t("Sell Data")}</span>}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow fw-bolder">
                <i className="bx bx-download"></i>
                <span>{props.t("Other Requests")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/case-studies" onClick={badgeClickCaseStudies}  >
                    <i className=""> <TbFileSearch /> </i>
                    {caseStudies ? <Badge ><span>{props.t("Case Studies")}</span> </Badge> : <span>{props.t("Case Studies")}</span>}
                  </Link>
                </li>
                <li>
                  <Link to="/yugo" onClick={badgeClickYugo}  >
                    <i className=""> <TbDeviceMobile /> </i>
                    {yugo ? <Badge ><span>{props.t("Yugo")}</span> </Badge> : <span>{props.t("Yugo")}</span>}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
