import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import projectsSaga from "./projects/saga"
import contactsSaga from "./contacts/saga"
import dashboardSaga from "./dashboard/saga"
import datasetSaga from "./datastore/saga"
import industrySaga from "./industry/saga"
import blogSaga from "./blog/saga"
import SectionSaga from "./prioritySectionGetData/saga"
import SectionUpdateSaga from "./prioritySectionUpdate/saga"
import ContactUsSaga from "./contactus/saga"
import SubscriberSaga from "./subscriber/saga"
import DatasetSaga from "./datasetrequest/saga"
import freelancerSaga from "./freelancer-vendor/saga"
import getCallSaga from "./get-a-call/saga"
import getCommunity from "./get_all_community/saga"
import getSellData from "./sellData/saga"
import caseStudies from "./caseStudies/saga"
import yugo from "./yugo/saga"
import DeleteContactUsSaga from "./contactusdelete/saga"
import DeleteDatasetRequestSaga from "./datasetrequestdelete/saga"
import DealsSaga from "./deals/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(ContactUsSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(SectionUpdateSaga),
    fork(projectsSaga),
    fork(contactsSaga),
    fork(SectionSaga),
    fork(dashboardSaga),
    fork(DatasetSaga),
    fork(datasetSaga),
    fork(industrySaga),
    fork(blogSaga),
    fork(SubscriberSaga),
    fork(freelancerSaga),
    fork(getCallSaga),
    fork(getCommunity),
    fork(getSellData),
    fork(caseStudies),
    fork(yugo),
    fork(DeleteContactUsSaga),
    fork(DeleteDatasetRequestSaga),
    fork(DealsSaga),
  ])
}
