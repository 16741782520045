import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SUBSCRIBER,
  GET_SUBSCRIBER_SUCCESS,
  GET_SUBSCRIBER_FAIL
} from "./actionTypes"

import {
  getSubscriber, getSubscriberSuccess, getSubscriberrofile
} from "./actions"

//Include Both Helper File with needed methods
import { getSubscriberHelper } from "../../helpers/backend_helper"

function* fetchSubscriber() {
  try {
    const response = yield call(getSubscriberHelper)
    // console.log(response);
    yield put(getSubscriberSuccess(response))
  } catch (error) {
    yield put(getSubscriberrofile(error))
  }
}


function* SubscriberSaga() {
  yield takeEvery(GET_SUBSCRIBER, fetchSubscriber)
}

export default SubscriberSaga;
