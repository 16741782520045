import { getCallHelper } from "helpers/backend_helper"
import { call, takeEvery, put } from "redux-saga/effects"
import { GET_A_CALL, GET_A_CALL_FAIL, GET_A_CALL_SUCCESS } from "./actionTypes"



function* getAcallFunc() {

    try {
        const response = yield call(getCallHelper)
        // console.log(response);
        yield put({ type: GET_A_CALL_SUCCESS, response })
    } catch (error) {
        yield put({ type: GET_A_CALL_FAIL, error })
    }
}





function* getCallSaga() {
    yield takeEvery(GET_A_CALL, getAcallFunc)
}




export default getCallSaga 