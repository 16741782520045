import {
  GET_CONTACT_US,
  GET_CONTACT_US_SUCCESS,
  GET_CONTACT_US_FAIL
} from "./actionTypes"

export const getContactUs = () => ({
  type: GET_CONTACT_US,
})

export const getContactUsSuccess = users => (
  {
    type: GET_CONTACT_US_SUCCESS,
    payload: users,
  })


export const getContactUsrofile = () => ({
  type: GET_CONTACT_US_FAIL,
})
