import {
  SECTION,
  SECTION_SUCCESS,
  SECTION_ERROR
} from "./actionTypes"

const initialState = {
  data: null,
  error: null,
}

const section = (state = initialState, action) => {
  // console.log(JSON.stringify(action.payload))
  switch (action.type) {
    case SECTION:
      state = {
        ...state,
        data: action.payload,
        error: null,
      }
      break
    case SECTION_SUCCESS:
      state = {
        ...state,
        data: action.payload,
      }
      break
    case SECTION_ERROR:
      state = { ...state, error: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default section
