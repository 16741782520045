import {
  GET_DATASET_REQUEST_SUCCESS,
  GET_DATASET_REQUEST_FAIL,
  ADD_DATASET_REQ_COMMENT,
  ADD_DATASET_REQ_COMMENT_SUCCESS,
  ADD_DATASET_REQ_COMMENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: {},
}

const DatasetRequest = (state = INIT_STATE, action) => {
  // console.log(action.payload);
  // console.log(action.type);
  switch (action.type) {
    // case ADD_DATASET_REQ_COMMENT:
    //   return {
    //     ...state,
    //     data: action.payload,
    //   }
    // case ADD_DATASET_REQ_COMMENT_SUCCESS:
    //   return {
    //     ...state,
    //     users: action.payload,
    //   }
    // case ADD_DATASET_REQ_COMMENT_FAIL:
    //   return {
    //     ...state,
    //     users: action.payload,
    //   }
    case GET_DATASET_REQUEST_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_DATASET_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    default:
      return state
  }
}

export default DatasetRequest
