/** Get Dashboard Chart data */
export const API_DATASETSUCCESS = "API_DATASETSUCCESS"
export const API_DATASETFAIL = "API_DATASETFAIL"
export const ADD_NEW_DATASET = "ADD_NEW_DATASET"
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY"
export const GET_CATEGORY = "GET_CATEGORY"
export const GET_DATASET = "GET_DATASET"
export const GET_DATASET_BY_ID = "GET_DATASET_BY_ID"
export const GET_DATASET_BY_ID_TWO_HIDE = "GET_DATASET_BY_ID_TWO_HIDE"
export const UPDATE_DATASET = "UPDATE_DATASET"
export const UPDATE_DATASET_TWO_HIDE = "UPDATE_DATASET_TWO_HIDE"
