import { all, call, fork, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {
  ADD_DEAL,
  UPDATE_DEAL,
  DELETE_DEAL,
  GET_DEAL,
  GET_DEAL_BY_ID
} from "./actionTypes";

import {
  dealSuccess, dealFail
} from "./actions";

//Include Both Helper File with needed methods
import { addDealData, deleteDealData, getDealData, getDealDataById, updateDealData } from "../../helpers/backend_helper";


function* addDeals ({ payload }) {

  try {
    const response = yield call(addDealData, payload);
    if (response.success) {
      yield put(dealSuccess(response));
    } else {
      yield put(dealFail(response));
    }
  } catch (error) {

    yield put(dealFail(error));
  }
}

function* updateDeals ({ payload: { id, deals } }) {

  try {
    const response = yield call(updateDealData, id, deals);

    if (response.success) {
      yield put(dealSuccess(response));
    } else {
      yield put(dealFail(response));
    }
  } catch (error) {

    yield put(dealFail(error));
  }
}

function* deleteDeals ({ payload }) {
  try {
    const response = yield call(deleteDealData, payload);

    if (response.success) {
      yield put(dealSuccess(response));
    } else {
      yield put(dealFail(response));
    }
  } catch (error) {

    yield put(dealFail(error));
  }
}

function* getDeals () {

  try {
    const response = yield call(getDealData);
    if (response.success) {
      yield put(dealSuccess(response));
    } else {
      yield put(dealFail(response));
    }
  } catch (error) {
    yield put(dealFail(error));
  }
}

function* getDealById ({ payload }) {

  try {
    const response = yield call(getDealDataById, payload);
    if (response.success) {
      yield put(dealSuccess(response));
    } else {
      yield put(dealFail(response));
    }
  } catch (error) {
    yield put(dealFail(error));
  }
}

export function* watchDeals () {
  yield takeEvery(ADD_DEAL, addDeals);
  yield takeEvery(GET_DEAL, getDeals);
  yield takeEvery(GET_DEAL_BY_ID, getDealById);
  yield takeEvery(UPDATE_DEAL, updateDeals);
  yield takeEvery(DELETE_DEAL, deleteDeals);
}

function* DealsSaga () {
  yield all([fork(watchDeals)]);
}

export default DealsSaga;
