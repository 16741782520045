import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from "reactstrap"

import MiniWidget from "./mini-widget"
import { useDispatch, useSelector } from "react-redux";


import { getDashboardData } from "../../store/actions"


const WelcomeComp = props => {


  const [dashboardData, setDashboardData] = useState([]);

  const dispatch = useDispatch();

  const { Dashboard } = useSelector(state => ({
    Dashboard: state.Dashboard.dashboardData,
  }))


  useEffect(() => {

    dispatch(getDashboardData());
  }, [dispatch]);

  useEffect(() => {

    setDashboardData(Dashboard?.result);
  }, [Dashboard]);

  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "Total Dataset",
      value: dashboardData?.total_dataset,

    },
    {
      icon: "bx bx-copy-alt",
      title: "Text Dataset",
      value: dashboardData?.total_dataset_text,
    },
    {
      icon: "bx bx-copy-alt",
      title: "Image Dataset",
      value: dashboardData?.total_dataset_image,
    },
    {
      icon: "bx bx-copy-alt",
      title: "Audio Dataset-cc",
      value: dashboardData?.total_conversation_audio,
    },
    {
      icon: "bx bx-copy-alt",
      title: "Audio Dataset-promt",
      value: dashboardData?.total_prompt_audio,
    },
    {
      icon: "bx bx-copy-alt",
      title: "Video Dataset",
      value: dashboardData?.total_dataset_video,
    },

  ];
  return (

    <Col xl="12">
      <Row>
        {/*mimi widgets */}
        <MiniWidget reports={reports} />
      </Row>
    </Col>



  )
}

export default WelcomeComp