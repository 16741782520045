
import { addDaysToDateWithDayName, formatDDMMYYYY, getDays, columnsMapping, LoseModalContent, undoStageConfirmationModal } from "components/Common/UtilitiesFunctions";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row,
} from "reactstrap";
import { getDeal, updateDeal } from "store/actions";
import Swal from "sweetalert2";
import { getCurrentStatusIndexFromLifecycle } from "./ViewDeal";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal as AntdModal } from "antd";
const { confirm } = AntdModal;


const ToDo = () => {
  document.title = "CRM-Tasks Board | FeatureBeeAi";

  const dispatch = useDispatch();
  const router = useHistory();
  const [columns, setColumns] = useState([]);
  const [addStageModal, setAddStageModal] = useState(false);
  const [currentDeal, setCurrentDeal] = useState();
  const [updatedLines, setUpdatedLines] = useState();

  const { deals, messageText } = useSelector((state) => {
    return {
      deals: state.DealsReducer.deals?.result,
      messageText: state.DealsReducer.deals?.message,
    };
  });

  useEffect(() => {
    dispatch(getDeal());
  }, []);

  useEffect(() => {
    if (messageText === "Records updated successfully.") {
      dispatch(getDeal());
    } else if (["Records are available.", "Records are not available."].includes(messageText)) {
      adjustTasksToColumns(deals);
    }
  }, [deals]);

  // Set Data to Columns
  const adjustTasksToColumns = (data) => {
    const today = new Date();
    const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const upcomingTasks = [];
    const todayTasks = [];
    const completedTasks = [];

    data.forEach((task) => {
      (task?.lifecycle || []).forEach((cycle, index) => {
        let taskDate = new Date(cycle.date.date.split('/').reverse().join('-'));
        taskDate = new Date(taskDate.getFullYear(), taskDate.getMonth(), taskDate.getDate());
        const id = Math.random().toString(36).slice(2);

        if ((taskDate > todayDate) && task?.lifecycle?.findIndex(elm => elm.status == task.deal_status) < index) {
          upcomingTasks.push({
            ...cycle,
            dealId: task._id,
            id,
            deal_status: task.deal_status,
            company_name: task.company_name,
            client_name: task.client_name
          });
        }
        else if (task?.lifecycle?.findIndex(elm => elm.status == task.deal_status) >= index) {
          completedTasks.push({
            ...cycle,
            dealId: task._id,
            id,
            deal_status: task.deal_status,
            company_name: task.company_name,
            client_name: task.client_name
          });
        }
        else if ((taskDate == todayDate) || ((taskDate <= todayDate) && (task?.lifecycle?.findIndex(elm => elm.status == task.deal_status) < index))) {
          todayTasks.push({
            ...cycle,
            dealId: task._id,
            id,
            deal_status: task.deal_status,
            company_name: task.company_name,
            client_name: task.client_name
          });
        }
      });
    });

    const initialColumns = [
      {
        id: "1",
        name: "Upcoming",
        cards: upcomingTasks,
      },
      {
        id: "2",
        name: "Today's Task",
        cards: todayTasks,
      },
      {
        id: "3",
        name: "Completed",
        cards: completedTasks,
      },
    ];

    sortTasks(initialColumns);
  };

  // Sort Data by Complete_date/Update_date
  const sortTasks = (initialColumns) => {
    initialColumns.forEach(elm => {
      if (elm.id == '1' || elm.id == '2') {
        elm.cards.sort((a, b) => {
          return (new Date(b.date.date.split('/').reverse().join('-')).getTime() < new Date(a.date.date.split('/').reverse().join('-')).getTime()) ? 1 : -1;
        });
      } else if (elm.id == 3) {
        elm.cards.sort((a, b) => {
          let A, B;
          if (a?.complete_date && b?.complete_date) {
            A = new Date(a.complete_date.split('/').reverse().join('-'));
            B = new Date(b.complete_date.split('/').reverse().join('-'));
            const date1 = new Date(B.getFullYear(), B.getMonth(), B.getDate()).getTime();

            const date2 = new Date(A.getFullYear(), A.getMonth(), A.getDate()).getTime();
            return date1 < date2 ? -1 : 1;
          } else {
            A = new Date(a.date.date.split('/').reverse().join('-'));
            B = new Date(b.date.date.split('/').reverse().join('-'));
            const date1 = new Date(B.getFullYear(), B.getMonth(), B.getDate()).getTime();

            const date2 = new Date(A.getFullYear(), A.getMonth(), A.getDate()).getTime();
            return date1 < date2 ? 1 : -1;
          }
        });
      }
    });

    setColumns(initialColumns);
  };

  // Sort deal lifecycle
  const sortLifeCycle = (lifecycle) => {
    lifecycle.sort((a, b) => {
      let A, B;
      if (a?.complete_date && b?.complete_date) {
        A = new Date(a.complete_date.split('/').reverse().join('-'));
        B = new Date(b.complete_date.split('/').reverse().join('-'));
      } else {
        A = new Date(a.date.date.split('/').reverse().join('-'));
        B = new Date(b.date.date.split('/').reverse().join('-'));
      }
      const date1 = new Date(B.getFullYear(), B.getMonth(), B.getDate()).getTime();

      const date2 = new Date(A.getFullYear(), A.getMonth(), A.getDate()).getTime();

      if (a.complete_date && b.complete_date && (date1 == date2)) {
        return a.status > b.status ? 1 : a.status < b.status ? -1 : 0;
      }

      return (date1 < date2) ? 1 : (date1 > date2) ? -1 : (date1 == date2) ? 1 : 1;
    });

    return lifecycle;
  };

  // Handle Drag and Drop
  const handleDragEnd = async (result) => {
    if (!result.destination) return; // If dropped outside a valid drop area, do nothing

    const { source, destination } = result;
    // Reorder cards within the same card line
    if (['1', '2'].includes(source.droppableId) && destination.droppableId == '3') {
      // Move card between different card lines
      const sourceLine = columns.find(line => line.id === source.droppableId);
      const destinationLine = columns.find(
        line => line.id === destination.droppableId
      );
      const sourceCards = Array.from(sourceLine.cards);
      const destinationCards = Array.from(destinationLine.cards);
      const [movedCard] = sourceCards.splice(source.index, 1);
      destinationCards.splice(destination.index, 0, movedCard);

      const updatedLines = columns.map(line => {
        if (line.id === source.droppableId) {
          return { ...line, cards: sourceCards };
        } else if (line.id === destination.droppableId) {
          return { ...line, cards: destinationCards };
        }
        return line;
      });

      const deal = deals.filter(elm => elm._id === movedCard.dealId)[0];
      setCurrentDeal(deal);

      deal.lifecycle.forEach(async (elm, index) => {
        if (elm.status == movedCard.status) {
          if (deal.lifecycle[index - 1]?.status != deal.deal_status) {
            const { isConfirmed } = await Swal.fire({
              title: "Are you sure to move?",
              text: "There are pending tasks on list.",
              showCancelButton: true,
              icon: 'warning',
              confirmButtonText: `Continue&nbsp;<i class="fa fa-arrow-right"></i>`,
            });

            if (isConfirmed) {
              if (elm.status) {
                // update db with new status
                if (elm.status == '6') {
                  const { isConfirmed, value } = await closeDealModal();
                  if (isConfirmed) {
                    deal.lifecycle.forEach((elmt, idx) => {
                      const currentIndex = getCurrentStatusIndexFromLifecycle(deal.lifecycle, deal.deal_status);
                      if (idx > currentIndex && elmt.status.includes('.') && !elmt.notes) {
                        deal.lifecycle[idx].skipped = true;
                      }
                      if (elmt.status == '6') {
                        deal.lifecycle[idx].complete_date = formatDDMMYYYY(new Date());
                      }
                    });
                    dispatch(updateDeal(movedCard._id, { deal_status: '6', last_activity: deal.deal_status, update_date: formatDDMMYYYY(new Date()), lose_reason: null, is_Potential_client: value ? true : false, lifecycle: deal.lifecycle }));
                  }
                } else if (elm.status == '7') {
                  deal.lifecycle.forEach((elmt, idx) => {
                    const currentIndex = getCurrentStatusIndexFromLifecycle(deal.lifecycle, deal.deal_status);
                    if (idx > currentIndex && elmt.status.includes('.') && !elmt.notes) {
                      deal.lifecycle[idx].skipped = true;
                    }
                    if (elmt.status == '7') {
                      deal.lifecycle[idx].complete_date = formatDDMMYYYY(new Date());
                    }
                  });
                  toggleLoseModal(deal._id, deal.deal_status, undefined, deal.lifecycle, undefined);
                } else {
                  console.log(deal.lifecycle);
                  deal.lifecycle.forEach((elmt, idx) => {
                    const currentIndex = getCurrentStatusIndexFromLifecycle(deal.lifecycle, deal.deal_status);
                    const movedCardIndex = getCurrentStatusIndexFromLifecycle(deal.lifecycle, movedCard.status);
                    console.log(idx, currentIndex, movedCardIndex);
                    if (idx > currentIndex && idx < movedCardIndex && elmt.status.includes('.')) {
                      deal.lifecycle[idx].skipped = true;
                    }
                    if (idx > currentIndex && elmt.status == movedCard.status) {
                      deal.lifecycle[idx].complete_date = formatDDMMYYYY(new Date());
                    }
                  });
                  dispatch(updateDeal(deal._id, { deal_status: elm.status, last_activity: deal.deal_status, update_date: formatDDMMYYYY(new Date()), lose_reason: null, lifecycle: deal.lifecycle }));
                }
                sortTasks(updatedLines);
              } else {
                setAddStageModal(true);
                setUpdatedLines(updatedLines);
              }
            }
          }
          else {
            if (elm.status) {
              // update db with new status
              if (elm.status == '6') {
                const { isConfirmed, value } = await closeDealModal();
                if (isConfirmed) {
                  deal.lifecycle.forEach((elmt, idx) => {
                    if (elmt.status == '6') {
                      deal.lifecycle[idx].complete_date = formatDDMMYYYY(new Date());
                    }
                  });
                  dispatch(updateDeal(deal._id, { deal_status: '6', last_activity: deal.deal_status, update_date: formatDDMMYYYY(new Date()), lose_reason: null, is_Potential_client: value ? true : false, lifecycle: deal.lifecycle }));
                }
              } else if (elm.status == '7') {
                deal.lifecycle.forEach((elmt, idx) => {
                  if (elmt.status == '7') {
                    deal.lifecycle[idx].complete_date = formatDDMMYYYY(new Date());
                  }
                });
                toggleLoseModal(deal._id, deal.deal_status, undefined, deal.lifecycle, undefined);
              } else {
                deal.lifecycle.forEach((elmt, idx) => {
                  if (elmt.status == elm.status) {
                    deal.lifecycle[idx].complete_date = formatDDMMYYYY(new Date());
                  }
                });
                dispatch(updateDeal(deal._id, { deal_status: elm.status, last_activity: deal.deal_status, update_date: formatDDMMYYYY(new Date()), lose_reason: null, lifecycle: deal.lifecycle }));
              }
              sortTasks(updatedLines);
            } else {
              setAddStageModal(true);
              setUpdatedLines(updatedLines);
            }
          }
        }
      });
    }
  };

  // Add Next Stage to Lifecycle
  const addStageToLifeCycle = async (e) => {
    e.preventDefault();
    const stage = e.target.stage.value;
    const prevStage = currentDeal.lifecycle[currentDeal.lifecycle.length - 2];
    const notes = e.target.notes.value;
    const is_potential_client = e.target.potential_client.checked;
    const loseReason = e.target.loseReason.value;
    const customReason = e.target.customReason.value;
    let lose_reason = customReason ? customReason.trim() : loseReason;
    let date = e.target.date?.value ? e.target.date.value.split('-').reverse().join('/') : '';
    date = date ? addDaysToDateWithDayName(date, 0) : '';

    let newStatus = 1;
    let name = '';
    let lifecycle = JSON.parse(JSON.stringify(currentDeal.lifecycle));

    if (stage == 3) {
      const [stageStatus, followupStatus] = prevStage.status.split('.');
      console.log(stageStatus, prevStage.status, currentDeal);
      if (followupStatus) {
        newStatus = Number(followupStatus) + 1;
        name = `FollowUp-${newStatus}`;
        newStatus = stageStatus + '.' + newStatus;
      } else {
        name = `FollowUp-${newStatus}`;
        newStatus = stageStatus + '.' + newStatus;
      }
      lifecycle.splice(-1, 1, {
        name: name,
        date: date,
        status: newStatus,
        notes
      }, {
        name: `Update Stage`,
        date: addDaysToDateWithDayName(date.date, getDays('', newStatus)),
        status: '',
        notes: ''
      });
    }
    else if (stage == 4) {
      let count = 0;
      let index;
      if (prevStage.status == '2') {
        lifecycle.splice(-1, 1, {
          name: `Not Responding`,
          date,
          status: '4',
          notes
        }, {
          name: `FollowUp-1`,
          date: addDaysToDateWithDayName(date.date, 7),
          status: '4.1',
          notes: ''
        }, {
          name: `Update Stage`,
          date: addDaysToDateWithDayName(date.date, 17),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('3')) {
        index = lifecycle.reverse().findIndex(elm => (elm.status == currentDeal.deal_status) || (elm.notes && elm.status.startsWith('3')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('3') && elm.status !== currentDeal.deal_status) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle.reverse();
          count = 1;
        }
        lifecycle.splice(-1, count, {
          name: `Not Responding`,
          date: date,
          status: '4',
          notes
        }, {
          name: `FollowUp-1`,
          date: addDaysToDateWithDayName(date.date, 7),
          status: '4.1',
          notes: ''
        }, {
          name: `Update Stage`,
          date: addDaysToDateWithDayName(date.date, 17),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('5.')) {
        index = lifecycle.reverse().findIndex(elm => (elm.status == currentDeal.deal_status) || (elm.notes && elm.status.startsWith('5')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('5.') && elm.status !== currentDeal.deal_status) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle.reverse();
          count = 1;
        }
        lifecycle.splice(-1, count, {
          name: `Not Responding`,
          date: date,
          status: '4',
          notes
        }, {
          name: `FollowUp-1`,
          date: addDaysToDateWithDayName(date.date, 7),
          status: '4.1',
          notes: ''
        }, {
          name: `Update Stage`,
          date: addDaysToDateWithDayName(date.date, 17),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('5')) {
        index = lifecycle.reverse().findIndex(elm => elm.notes && elm.status.startsWith('5'));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('5.')) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle = lifecycle.filter(elm => !elm.status.startsWith('5'));
          lifecycle.reverse();
          count = 1;
        }

        // Go back to prev stage by undoing the current stage
        const currentStatusIndex = getCurrentStatusIndexFromLifecycle(currentDeal.lifecycle, currentDeal.deal_status);

        if (currentDeal.lifecycle[currentStatusIndex - 1].status.startsWith(stage)) {
          const { isConfirmed } = await undoStageConfirmationModal();
          if (isConfirmed) {
            const prevIndexOfNewStatus = currentDeal.lifecycle.length - 1 - (currentDeal.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(stage) && elm.complete_date)));
            lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('4.') && elm.skipped && idx > prevIndexOfNewStatus) {
                delete elm.skipped;
                delete elm.complete_date;
              }
              if (idx > prevIndexOfNewStatus) {
                elm.date = addDaysToDateWithDayName(lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, lifecycle[idx - 1].status));
              }
            });
            sortLifeCycle(lifecycle);
            console.log(lifecycle);
            dispatch(updateDeal(currentDeal._id, { deal_status: lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: currentDeal.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
            setAddStageModal(false);
            sortTasks(updatedLines);
            return;
          }
          return;
        }

        lifecycle.splice(-1, count, {
          name: `Not Responding`,
          date: date,
          status: '4',
          notes
        }, {
          name: `FollowUp-1`,
          date: addDaysToDateWithDayName(date.date, 7),
          status: '4.1',
          notes: ''
        }, {
          name: `Update Stage`,
          date: addDaysToDateWithDayName(date.date, 17),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('6')) {
        lifecycle.splice(-1, 1, {
          name: 'Not Responding',
          date: date,
          status: '4',
          notes
        }, {
          name: 'FollowUp-1',
          date: addDaysToDateWithDayName(date.date, 7),
          status: '4.1',
          notes: ''
        }, {
          name: 'Update Stage',
          date: addDaysToDateWithDayName(date.date, 17),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('7')) {
        lifecycle.splice(-1, 1, {
          name: 'Not Responding',
          date: date,
          status: '4',
          notes
        }, {
          name: 'FollowUp-1',
          date: addDaysToDateWithDayName(date.date, 7),
          status: '4.1',
          notes: ''
        }, {
          name: 'Update Stage',
          date: addDaysToDateWithDayName(date.date, 17),
          status: '',
          notes: ''
        });
      }
    }
    else if (stage == 5) {
      let count = 0;
      let index;
      if (prevStage.status == '2') {
        lifecycle.splice(-1, 1, {
          name: `In Conversation`,
          date: addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 3),
          status: '5',
          notes: ''
        }, {
          name: `Update Stage`,
          date: addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 13),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('3')) {
        index = lifecycle.reverse().findIndex(elm => (elm.status == currentDeal.deal_status) || (elm.notes && elm.status.startsWith('3')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('3') && elm.status !== currentDeal.deal_status) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle.reverse();
          count = 1;
        }
        lifecycle.splice(-1, count, {
          name: `In Conversation`,
          date: date,
          status: '5',
          notes
        }, {
          name: `Update Stage`,
          date: addDaysToDateWithDayName(date.date, 10),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('4.')) {
        index = lifecycle.reverse().findIndex(elm => (elm.status == currentDeal.deal_status) || (elm.notes && elm.status.startsWith('4')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('4.') && elm.status !== currentDeal.deal_status) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle.reverse();
          count = 1;
        }
        lifecycle.splice(-1, count, {
          name: `In Conversation`,
          date: date,
          status: '5',
          notes
        }, {
          name: `Update Stage`,
          date: addDaysToDateWithDayName(date.date, 10),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('4')) {
        index = lifecycle.reverse().findIndex(elm => elm.notes && elm.status.startsWith('4'));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('4.')) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle = lifecycle.filter(elm => !elm.status.startsWith('4'));
          lifecycle.reverse();
          count = 1;
        }

        // Go back to prev stage by undoing the current stage
        const currentStatusIndex = getCurrentStatusIndexFromLifecycle(currentDeal.lifecycle, currentDeal.deal_status);

        if (currentDeal.lifecycle[currentStatusIndex - 1].status.startsWith(stage)) {
          const { isConfirmed } = await undoStageConfirmationModal();
          if (isConfirmed) {
            const prevIndexOfNewStatus = currentDeal.lifecycle.length - 1 - (currentDeal.lifecycle.slice().reverse().findIndex(elm => (elm.status.startsWith(stage) && elm.complete_date)));
            lifecycle.forEach((elm, idx) => {
              if (elm.status.startsWith('5.') && elm.skipped && idx > prevIndexOfNewStatus) {
                delete elm.skipped;
                delete elm.complete_date;
              }
              if (idx > prevIndexOfNewStatus) {
                elm.date = addDaysToDateWithDayName(lifecycle[prevIndexOfNewStatus].date.date, getDays(elm.status, lifecycle[idx - 1].status));
              }
            });
            sortLifeCycle(lifecycle);
            console.log(lifecycle);
            dispatch(updateDeal(currentDeal._id, { deal_status: lifecycle[prevIndexOfNewStatus].status, lose_reason: null, last_activity: currentDeal.deal_status, update_date: formatDDMMYYYY(new Date()), lifecycle: lifecycle }));
            setAddStageModal(false);
            sortTasks(updatedLines);
            return;
          }
          return;
        }

        lifecycle.splice(-1, count, {
          name: `In Conversation`,
          date: date,
          status: '5',
          notes
        }, {
          name: `Update Stage`,
          date: addDaysToDateWithDayName(date.date, 10),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('6')) {
        lifecycle.splice(-1, 1, {
          name: 'In Conversation',
          date: date,
          status: '5',
          notes
        }, {
          name: 'Update Stage',
          date: addDaysToDateWithDayName(date.date, 10),
          status: '',
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('7')) {
        lifecycle.splice(-1, 1, {
          name: 'In Conversation',
          date: date,
          status: '5',
          notes
        }, {
          name: 'Update Stage',
          date: addDaysToDateWithDayName(date.date, 10),
          status: '',
          notes: ''
        });
      }
    }
    else if (['6', '7'].includes(stage)) {
      let count = 0;
      let index;
      if (prevStage.status == '2') {
        const statusName = stage == '6' ? 'Close' : 'Lose';
        lifecycle.splice(-1, 1, {
          name: statusName,
          date: addDaysToDateWithDayName(lifecycle[lifecycle.length - 2].date.date, 10),
          complete_date: formatDDMMYYYY(new Date()),
          status: stage,
          notes: ''
        });
      }
      else if (prevStage.status.startsWith('3')) {
        index = lifecycle.reverse().findIndex(elm => (elm.status == currentDeal.deal_status) || (elm.notes && elm.status.startsWith('3')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('3') && elm.status !== currentDeal.deal_status) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle.reverse();
          count = 1;
        }

        const statusName = stage == '6' ? 'Close' : 'Lose';
        lifecycle.splice(-1, count, {
          name: statusName,
          date: date,
          complete_date: formatDDMMYYYY(new Date()),
          status: stage,
          notes
        });
      }
      else if (prevStage.status.startsWith('4.')) {
        index = lifecycle.reverse().findIndex(elm => (elm.status == currentDeal.deal_status) || (elm.notes && elm.status.startsWith('4')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('4.') && elm.status !== currentDeal.deal_status) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle.reverse();
          count = 1;
        }
        const statusName = stage == '6' ? 'Close' : 'Lose';
        lifecycle.splice(-1, count, {
          name: statusName,
          date: date,
          complete_date: formatDDMMYYYY(new Date()),
          status: stage,
          notes
        });
      }
      else if (prevStage.status.startsWith('4')) {
        index = lifecycle.reverse().findIndex(elm => (elm.status == currentDeal.deal_status) || (elm.notes && elm.status.startsWith('4')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('4.')) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle = lifecycle.filter(elm => !elm.status.startsWith('4'));
          lifecycle.reverse();
          count = 1;
        }
        const statusName = stage == '6' ? 'Close' : 'Lose';
        lifecycle.splice(-1, count, {
          name: statusName,
          date: date,
          complete_date: formatDDMMYYYY(new Date()),
          status: stage,
          notes
        });
      }
      else if (prevStage.status.startsWith('5.')) {
        index = lifecycle.reverse().findIndex(elm => (elm.status == currentDeal.deal_status) || (elm.notes && elm.status.startsWith('5')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('5.') && elm.status !== currentDeal.deal_status) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle.reverse();
          count = 1;
        }
        const statusName = stage == '6' ? 'Close' : 'Lose';
        lifecycle.splice(-1, count, {
          name: statusName,
          date: date,
          complete_date: formatDDMMYYYY(new Date()),
          status: stage,
          notes
        });
      }
      else if (prevStage.status.startsWith('5')) {
        index = lifecycle.reverse().findIndex(elm => (elm.status == currentDeal.deal_status) || (elm.notes && elm.status.startsWith('5')));
        if (index !== -1) {
          lifecycle.splice(0, index);
          lifecycle.reverse();
          lifecycle.forEach((elm, idx) => {
            if (elm.status.startsWith('5.')) {
              lifecycle[idx].skipped = true;
            }
          });
        } else {
          lifecycle = lifecycle.filter(elm => !elm.status.startsWith('5'));
          lifecycle.reverse();
          count = 1;
        }

        const statusName = stage == '6' ? 'Close' : 'Lose';
        lifecycle.splice(-1, count, {
          name: statusName,
          date: date,
          complete_date: formatDDMMYYYY(new Date()),
          status: stage,
          notes
        });
      }
      else if (prevStage.status.startsWith('7')) {
        const statusName = stage == '6' ? 'Close' : 'Lose';
        lifecycle.splice(-1, 1, {
          name: statusName,
          date: date,
          complete_date: formatDDMMYYYY(new Date()),
          status: stage,
          notes
        });
      }
    }

    if (!prevStage.status.startsWith(stage)) {
      sortLifeCycle(lifecycle);
      const currentStatusIndex = lifecycle.findIndex(elm => elm.status == currentDeal.deal_status);
      let stageIndex = lifecycle.slice().reverse().findIndex(elm => elm.status == (stage == '3' ? newStatus : stage));
      stageIndex = stageIndex !== -1 ? lifecycle.length - 1 - stageIndex : -1;

      for (let idx in lifecycle) {
        if (idx > currentStatusIndex && idx <= stageIndex) {
          lifecycle[idx]['complete_date'] = formatDDMMYYYY(new Date());
        }
      };

      if (['3', '4', '5'].includes(stage)) {
        dispatch(updateDeal(currentDeal._id, { lifecycle }));
      }
      else if (['6', '7'].includes(stage)) {
        dispatch(updateDeal(currentDeal._id, { lifecycle, is_potential_client, deal_status: stage, last_activity: currentDeal.deal_status, lose_reason, update_date: formatDDMMYYYY(new Date()) }));
      }
    } else {
      Swal.fire({
        title: `You are already in ${columnsMapping(stage)} stage.`,
        icon: "error",
      });
      setAddStageModal(false);
      return;
    }
    console.log(stage, prevStage.status, lifecycle);
    setAddStageModal(false);
    sortTasks(updatedLines);
  };

  // Handle Show/Hide Fields When Status Fields Change
  const handleStatusChange = (e) => {
    const dateEl = document.querySelector('#date');
    const pot_client = document.querySelector('#pot_client');
    const loseReason = document.querySelector('.lose-reason');
    const customMessageRow = document.querySelector('.custom-message');

    if (e.target?.value && !['6', '7'].includes(e.target?.value)) {
      const dateValue = addDaysToDateWithDayName(currentDeal?.lifecycle[currentDeal?.lifecycle.length - 2]?.date?.date, getDays(e.target?.value));
      dateEl.removeAttribute('disabled');
      dateEl.value = dateValue?.date?.split('/').reverse().join('-');
    } else {
      dateEl.value = formatDDMMYYYY(new Date())?.split('/').reverse().join('-');
      dateEl.setAttribute('disabled', true);
    }

    if (['6', '7'].includes(e.target?.value)) {
      if (pot_client.querySelector('input').type == 'hidden') {
        pot_client.querySelector('input').type = 'checkbox';
        pot_client.classList.remove('visually-hidden');
      }
      if (loseReason.classList.contains('visually-hidden') && e.target?.value == '7') {
        loseReason.classList.remove('visually-hidden');
        if (loseReason.querySelector('select').value !== "Other") {
          customMessageRow.querySelector('#lose-reason-custom').removeAttribute('required');
        }
      } else {
        loseReason.classList.add('visually-hidden');
        loseReason.querySelector('select').selectedIndex = 0;
        loseReason.querySelector('select').removeAttribute('required');
        customMessageRow.querySelector('#lose-reason-custom').removeAttribute('required');
        customMessageRow.classList.add('visually-hidden');
      }
    } else {
      if (pot_client.querySelector('input').type != "hidden") {
        pot_client.querySelector('input').type = 'hidden';
        pot_client.classList.add('visually-hidden');
      }
      if (!loseReason.classList.contains('visually-hidden') && e.target?.value != '7') {
        loseReason.classList.add('visually-hidden');
        loseReason.querySelector('select').selectedIndex = 0;
        loseReason.querySelector('select').removeAttribute('required');
        customMessageRow.querySelector('#lose-reason-custom').removeAttribute('required');
        customMessageRow.classList.add('visually-hidden');
      }
    }
  };

  const toggleLoseModal = (draggingTaskId, prevColumn, lifecycle) => {
    confirm({
      title: 'Describe a Reason:',
      icon: <ExclamationCircleFilled />,
      content: <LoseModalContent />,
      okText: 'Submit',
      okType: 'primary',
      cancelText: 'Cancel',
      onOk () {
        const loseReasonDropdown = document.querySelector('#lose-reason');
        const loseReasonTextArea = document.querySelector('#lose-reason-custom');
        const potClient = document.querySelector('#pot_client');

        const reason = loseReasonDropdown?.value === "Other" ? loseReasonTextArea?.value : loseReasonDropdown?.value;

        if (prevColumn) {
          dispatch(updateDeal(draggingTaskId, { deal_status: '7', lose_reason: reason, last_activity: prevColumn, update_date: formatDDMMYYYY(new Date()), is_potential_client: potClient.checked ? true : false, lifecycle }));
        } else {
          dispatch(updateDeal(draggingTaskId, { is_potential_client: potClient.checked ? true : false, lifecycle }));
        }
      },
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between pb-3">
                <h4 className="mb-0 font-size-18">Tasks Board</h4>
              </div>
            </Col>
          </Row>
          <Row className="flex-nowrap overflow-x-auto">
            <DragDropContext onDragEnd={handleDragEnd}>
              {(columns || []).map(line => (
                <Col lg={4} key={line.id} style={{ height: 'calc(100vh - 130px)' }}>
                  <Card className="h-100">
                    <div className="position-sticky top-0 bg-white z-2 d-flex justify-content-between gap-3 align-items-center border-bottom" style={{ padding: '8px 20px' }}>
                      <h4 className="card-title mb-0">{line.name}</h4>
                      <span className="deal-count">{line.cards.length}</span>
                    </div>
                    <CardBody className="overflow-y-auto h-100 pt-3">
                      <Droppable droppableId={line.id}>
                        {provided => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={!deals?.length ? "opacity-25 h-100" : 'h-100'}
                          >
                            {line.cards.map((card, index) => {
                              const badgeColor = 'success';//getBadgeColor(card.deal_status);
                              return (
                                <Draggable
                                  key={index}
                                  draggableId={card.id}
                                  index={index}
                                >
                                  {provided => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      // className="card task-list"
                                      className="pb-1 task-list"
                                      id={line.name + "-task"}
                                    >
                                      <div className="card task-box mb-2" id="uptask-1" onClick={() => router.push(`/crm/view-deal/${card.dealId}`)}>
                                        <CardBody className="py-3">
                                          <div className="float-end ms-2">
                                            <span
                                              className={`font-monospace text-capitalize badge rounded-pill badge-soft-${badgeColor} font-size-12`}
                                              id="task-status"
                                            >
                                              {columnsMapping(card.deal_status)}
                                            </span>
                                          </div>
                                          <div>
                                            <strong className="text-capitalize">{card.client_name}</strong>
                                            <p className="text-muted mb-2 text-capitalize">
                                              {card.company_name}
                                            </p>
                                            <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
                                              <h5 className="font-size-12 text-capitalize badge badge-soft-primary m-0 font-monospace rounded-pill">
                                                {`${card.name}`}
                                              </h5>
                                              <p className={`font-size-12 badge ${line.id == '3' ? card.complete_date ? 'badge-soft-pink' : 'bg-warning' : 'badge-soft-danger'} m-0 font-monospace rounded-pill`}>
                                                {`${line.id == '3' ? card?.complete_date ? `Done on: ${card.complete_date}` : 'Skipped' : `Due by: ${card.date.date}`}`}
                                              </p>
                                            </div>
                                          </div>
                                        </CardBody>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                            {!line.cards.length && (
                              <div className="text-center d-grid h-100">
                                <h5
                                  className="d-flex justify-content-center align-items-center font-size-20 text-muted p-2"
                                >
                                  {line.id == '1' ? "No Upcoming Tasks" : line.id == '2' ? "No Tasks For Today" : line.id == '3' ? "Haven't Done Any Tasks" : "No Tasks To Show"}
                                </h5>
                              </div>
                            )}
                          </div>
                        )}
                      </Droppable>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </DragDropContext>
          </Row>
        </Container>
        {/* Add Stage Modal */}
        <Modal
          isOpen={addStageModal}
          role="dialog"
          size=""
          autoFocus={true}
          id="addStageModal"
          backdrop="static"
          toggle={() => setAddStageModal(!addStageModal)}
        >
          <div className="modal-content">
            <ModalHeader toggle={() => setAddStageModal(!addStageModal)}>
              Update Next Stage
            </ModalHeader>
            <ModalBody>
              <div className="content clearfix mx-3">
                <Form onSubmit={addStageToLifeCycle}>
                  <Row>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label>Deal Status</Label>
                        <select className="form-select" name="stage" onChange={(e) => handleStatusChange(e)} required>
                          <option value="">Select Status</option>
                          <option value="3">Follow Up</option>
                          <option disabled={currentDeal?.deal_status == '4'} value="4">Not Responding</option>
                          <option disabled={currentDeal?.deal_status == '5'} value="5">In Conversation</option>
                          <option disabled={currentDeal?.deal_status == '6'} value="6">Close</option>
                          <option disabled={currentDeal?.deal_status == '7'} value="7">Lose</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="lose-reason visually-hidden">
                    <Col sm="12" className="mx-auto">
                      <div className="mb-2">
                        <Label>Select Lose Reason</Label>
                        <select id="lose-reason" name="loseReason" className="form-select" onChange={(e) => {
                          if (e.target?.value === "Other") {
                            const customMessageRow = document.querySelector('.custom-message');
                            if (customMessageRow.classList.contains('visually-hidden')) {
                              customMessageRow.classList.remove('visually-hidden');
                            }
                          } else {
                            const customMessageRow = document.querySelector('.custom-message');
                            if (!customMessageRow.classList.contains('visually-hidden')) {
                              customMessageRow.classList.add('visually-hidden');
                              customMessageRow.querySelector('#lose-reason-custom').removeAttribute('required');
                            }
                          }
                        }} required>
                          <option defaultValue value="We don't have OTS data">
                            We don't have OTS data
                          </option>
                          <option value="Budget Issue">Budget Issue</option>
                          <option value="Deadline Issue">Deadline Issue</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-message visually-hidden">
                    <Col sm="12" className="mx-auto">
                      <div className="mb-3">
                        <Input
                          type="textarea"
                          required
                          defaultValue={"Other"}
                          className="form-control"
                          id="lose-reason-custom"
                          name="customReason"
                          placeholder="Add your reason here"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label for="notes">
                          Notes
                        </Label>
                        <Input
                          type="textarea"
                          name="notes"
                          className="form-control"
                          id="notes"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <div className="mb-3">
                        <Label for="date">
                          Date
                        </Label>
                        <Input
                          type="date"
                          name="date"
                          className="form-control"
                          id="date"
                          defaultValue={currentDeal ? addDaysToDateWithDayName(currentDeal?.lifecycle[currentDeal?.lifecycle.length - 2]?.date?.date, getDays('3.1'))?.date?.split('/')?.reverse()?.join('-') : ''}
                          required
                          min={currentDeal?.lifecycle[currentDeal?.lifecycle.length - 2]?.date?.date?.split('/')?.reverse()?.join('-')}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row id="pot_client" className="visually-hidden">
                    <Col sm="12" className="mx-auto">
                      <div className="my-3">
                        <FormGroup className="d-flex gap-2 align-items-center">
                          <Input type="hidden" className="form-check-input" name="potential_client" />
                          {' '}
                          <Label className="m-0">
                            Add as a potential client
                          </Label>
                        </FormGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} className="text-end">
                      <Button type="submit" color="primary">Add</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default ToDo;
