import { GET_COMMUNITY_REQUEST, GET_COMMUNITY_REQUEST_FAIL, GET_COMMUNITY_REQUEST_SUCCESS } from "./actionTypes";


const initialState = {
    data: []
}

export default (state = initialState, action) => {
    // console.log(action);
    switch (action.type) {

        // case GET_COMMUNITY_REQUEST:
        //     return { ...state }

        case GET_COMMUNITY_REQUEST_SUCCESS:
            return { ...state, data: action.response }

        // case GET_COMMUNITY_REQUEST_FAIL:
        //     return { ...state }

        default:
            return state
    }
}


