import {
  DELETE_CONTACT_US,
  DELETE_CONTACT_US_SUCCESS,
  DELETE_CONTACT_US_FAIL
} from "./actionTypes"

export const deleteContactUs = id => ({
  type: DELETE_CONTACT_US,
  payload: id,
})

export const deleteContactUsSuccess = id => (
  {
    type: DELETE_CONTACT_US_SUCCESS,
    payload: id,
  })


export const deleteContactUsrofile = () => ({
  type: DELETE_CONTACT_US_FAIL,
})
