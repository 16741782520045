import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_DASHBOARD_DATA } from "./actionTypes";
import { apiSuccess, apiFail } from "./actions";

//Include Both Helper File with needed methods
import {
    getDashboardData
}
    from "../../helpers/backend_helper";

function* getDashboard({ payload: periodType }) {
    try {
        const response = yield call(getDashboardData)

        yield put(apiSuccess(GET_DASHBOARD_DATA, response));
    } catch (error) {
        yield put(apiFail(GET_DASHBOARD_DATA, error));
    }
}

export function* watchGetDashboardData() {
    yield takeEvery(GET_DASHBOARD_DATA, getDashboard);
}

function* dashboardSaga() {
    yield all([fork(watchGetDashboardData)]);
}

export default dashboardSaga;
