import {
    GET_SELL_DATA, GET_SELL_DATA_FAIL,
    GET_SELL_DATA_SUCCESS
} from "./actionTypes";


const initialState = {
    data: []
}

export default (state = initialState, action) => {

    switch (action.type) {


        case GET_SELL_DATA_SUCCESS:
            return { ...state, data: action.response }


        default:
            return state
    }
}


