import React, { useEffect, useState } from 'react';
import { getDatasetRequest, addCommentDataSetReq, addDeal } from 'store/actions';
import { deleteDatasetRequest } from 'store/actions';

import { useDispatch, useSelector } from 'react-redux';
import "./datasetRequest.css";
import { Table, Button, Container, Row, Col } from 'reactstrap';
import { ButtonToolbar, Loader, Modal, Input, Badge } from 'rsuite';
import { message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import "rsuite/dist/rsuite.css";
// import { Modal, RadioGroup, Radio, Placeholder } from 'rsuite';
import { Pagination } from 'rsuite';
import { addDaysToDateWithDayName, formatDDMMYYYY } from 'components/Common/UtilitiesFunctions';

export const initialLifeCycle = [
    {
        name: 'Initiated',
        date: addDaysToDateWithDayName(formatDDMMYYYY(new Date()), 0),
        complete_date: formatDDMMYYYY(new Date()),
        status: '1',
        notes: ''
    },
    {
        name: 'Contacted',
        date: addDaysToDateWithDayName(formatDDMMYYYY(new Date()), 2),
        status: '2',
        notes: ''
    },
    {
        name: 'FollowUp-1',
        date: addDaysToDateWithDayName(formatDDMMYYYY(new Date()), 5),
        status: '3.1',
        notes: ''
    },
    {
        name: 'Update Stage',
        date: addDaysToDateWithDayName(formatDDMMYYYY(new Date()), 8),
        status: '',
        notes: ''
    }
];

function DatasetRequest() {
    let dispatch = useDispatch();
    const [Loaderr, setLoaderr] = useState(true);
    const [RecordsNotAvalable, setRecordsNotAvalable] = useState(false);

    const [data, setdata] = useState('');
    const [dataAll, setdataAll] = useState('');

    const [openn, setOpenn] = React.useState(false);
    const [backdrop, setBackdrop] = React.useState('static');
    const [messagePara, setmessagePara] = useState("-");
    const [commentText, setCommentText] = useState(null);
    const [CommentOpen, setCommentOpen] = React.useState(false);
    const [messageSuccess, setMessageSuccess] = useState(false);
    const [contactUsPutId, setContactUsPutId] = useState(null);
    const [activePage, setActivePage] = React.useState(1);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [contactToDelete, setContactToDelete] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [user, setUser] = useState();

    const handleDeleteClick = (contactId) => {
        setContactToDelete(contactId);
        setDeleteConfirmation(true);

    };

    const handleConfirmDelete = () => {
        if (contactToDelete) {
            dispatch(deleteDatasetRequest(contactToDelete));
            setDeleteConfirmation(false);
            setContactToDelete(null);
        }
    };

    const { datasetreq, comment, deals } = useSelector(state => ({
        datasetreq: state.DatasetRequest.users,
        comment: state.datasetreqComment.users,
        deals: state.DealsReducer.deals,
    }));

    useEffect(() => {
        if (deals?.success && messageSuccess) {
            message.success(deals?.message);
            selectedRows.forEach((id) => {
                let data = [{ url: id }, { move_to_deal: true }];
                dispatch(addCommentDataSetReq(data));
                document.querySelector(`#row-${id}`).checked = false;
            });
            document.querySelector(`#all-row-select`).checked = false;
            setMessageSuccess(false);
            // Remove selection of all the rows
            setSelectedRows([]);
        }
    }, [deals]);

    useEffect(() => {
        if (comment.success && messageSuccess) {
            dispatch(getDatasetRequest());
            message.success('Comment added successfully.');
        }
        if (comment.success && !messageSuccess) {
            dispatch(getDatasetRequest());
        }
    }, [comment]);

    useEffect(() => {
        if (datasetreq.success) {
            setLoaderr(false);
            setRecordsNotAvalable(false);
            localStorage.setItem("DatasetReqNewMessage", datasetreq.result?.length);
        }
        if (datasetreq.success == false) {
            setLoaderr(false);
            setRecordsNotAvalable(true);
            localStorage.setItem("DatasetReqNewMessage", datasetreq.result?.length);
        }

    }, [datasetreq]);

    useEffect(() => {
        if (datasetreq.success) {
            setLoaderr(false);
            setRecordsNotAvalable(false);
            setdataAll(datasetreq);
            let TemporaryData = datasetreq;
            setdata(TemporaryData.result.slice(0, 100));
            // setdata(datasetreq.result)

            localStorage.setItem("DatasetReqNewMessage", datasetreq.result?.length);

        }
        if (datasetreq.success == false) {
            setLoaderr(false);
            setRecordsNotAvalable(true);
            localStorage.setItem("DatasetReqNewMessage", datasetreq.result?.length);
        }
    }, [datasetreq]);

    useEffect(() => {
        if (dataAll) {
            setdata(dataAll.result.slice(100 * activePage - 100, 100 * activePage));
        }

    }, [activePage]);

    useEffect(() => {
        dispatch(getDatasetRequest());
        const userDeails = localStorage.getItem('authUser') || '{"result":[{"name":""}]}';
        if (userDeails) {
            setUser(JSON.parse(userDeails)?.result[0]);
        }
    }, []);

    let i = 1;

    let linkCopyFunc = (e) => {
        if (e.target.id) {
            message.success("Copied")
            navigator.clipboard.writeText(e.target.id);
        } else {
            message.error("Data not available");
        }
    };

    const handleOpen = (e) => {

        // for (let ii = 0; ii < datasetreq.result.length; ii++) {
            // if (e.target.id == (ii + 2)) {
                setmessagePara(e.target.id);
                setOpenn(true);
        //     }
        // }
    };

    const handleClose = () => setOpenn(false);

    let openModalFuncComment = (e) => {
        setCommentOpen(true);
        for (let ii = 0; ii < datasetreq.result.length; ii++) {
            if (e.target.id == (ii + 2)) {
                setContactUsPutId(datasetreq.result[ii]._id);
                setCommentText(datasetreq.result[ii].comment);
            }
        }
    };

    const handleCloseComment = () => {
        setCommentOpen(false);
    };

    const handleSaveComment = () => {
        let data = [{ url: contactUsPutId }, { comment: commentText }];
        dispatch(addCommentDataSetReq(data));
        setCommentOpen(false);
        setMessageSuccess(true);
    };

    let commentChange = (e) => {
        setCommentText(e);
    };

    const changeRowSelection = (e, id) => {
        if (e.target.checked) {
            if (id === 'all') {
                const selectedRowsList = data.map(elm => !elm?.move_to_deal ? elm._id : false).filter(elm => elm);
                setSelectedRows(selectedRowsList);
                selectedRowsList.forEach(elm => {
                    document.querySelector(`#row-${elm}`).checked = true;
                });
            } else {
                if ([...selectedRows, id].length === data.length) {
                    document.querySelector(`#all-row-select`).checked = true;
                }
                setSelectedRows(prev => [...prev, id]);
            }
        } else {
            if (id === 'all') {
                setSelectedRows([]);
                data.forEach(elm => {
                    document.querySelector(`#row-${elm._id}`).checked = false;
                });
            } else {
                if (document.querySelector(`#all-row-select`).checked) {
                    document.querySelector(`#all-row-select`).checked = false;
                }
                setSelectedRows(prev => [...prev.filter(elm => elm !== id)]);
            }
        }
    };

    const MoveToDeal = () => {
        let dealsData = [];
        for (let index = 0; index < selectedRows.length; index++) {
            const id = selectedRows[index];
            dealsData.push(...data.map(elm => {
                if (elm._id === id) {
                    return {
                        "owner": user?.name || "",
                        "client_name": elm.name,
                        "email": elm.email,
                        "request_date": new Intl.DateTimeFormat('en-GB', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric'
                        }).format(new Date(elm?.createdAt)),
                        "from_where": elm.form_fill,
                        "message": elm.message,
                        "dataset_type": "",
                        "dataset_sub_type": '',
                        "language": [],
                        "industry": '',
                        "demography": [],
                        "type": [],
                        "sample_link": elm.sample_link,
                        "page_link": elm.page_url,
                        "dataset_name": elm.title,
                        "company_name": elm.company_name,
                        "volume": elm.volume,
                        "purpose": elm.purpose,
                        "budget": elm.tentative_budget,
                        "service": "",
                        "lifecycle": initialLifeCycle,
                        "client_type": "",
                        "last_activity": '',
                        "update_date": formatDDMMYYYY(new Date()),
                        "deal_status": 1,
                        "notes": elm?.comment ? elm.comment : "",
                        "self_created": false,
                        "deal_from": 1,
                        "deal_from_id": elm?._id,
                    };
                } else {
                    return false;
                }
            }).filter(elm => elm));
        }
        const deals = {
            deals: dealsData
        };
        dispatch(addDeal(deals));
        setMessageSuccess(true);
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box pb-2 d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18">Dataset  Request</h4>
                                <Button color='primary' disabled={selectedRows.length <= 0} onClick={MoveToDeal}>Move to deal</Button>
                            </div>
                        </Col>
                    </Row>
                    <Table bordered responsive hover className='table-primary main-table'>
                        <thead className=' '>
                            <tr className="bgdark z-indexx">
                                <th className="border-0  font-medium  bgdark   text-nowrap"><input type="checkbox" className='form-check-input mt-1' id='all-row-select' onChange={(e) => changeRowSelection(e, 'all')} /></th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">No.</th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">Name</th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">Email</th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">Form For</th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">  Comment  </th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">Dataset Code </th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">Page url </th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">  Sample Link</th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">Form Fill</th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">  Company Name </th>
                                <th className="border-0  font-medium  bgdark   text-nowrap"> Title  </th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">  Volume </th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">  Purpose </th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">  Tentative Budget </th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">  Message</th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">  Date</th>
                                <th className="border-0  font-medium  bgdark   text-nowrap">  Delete</th>

                                {/* <th className="border-0  font-medium  bgdark   text-nowrap">  DownLoad  Sample</th> */}
                            </tr>


                        </thead>
                        {
                            RecordsNotAvalable ? <tr className='border-0 w-100 records-not-class'>
                                <td colSpan={18} className='text-center bg-transparent text-primary fs-4 border-0'>
                                    Records are not available!
                                </td>
                            </tr> : null

                        }
                        <tbody>
                            <tr>
                                {
                                    Loaderr ? <td colSpan={18}><div className="  loaderposition">
                                        <Loader size="lg" className='' />
                                    </div></td> : null
                                }
                            </tr>
                            {/* {
                            datasetreq.success && datasetreq.result.map((project) => */}
                            {dataAll.success && data.slice(0, 100).map((project) =>
                                <tr key={project._id} id={"tr" + project._id} style={project?.move_to_deal ? { backgroundColor: "#a0e2c4" } : {}}>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        <input type="checkbox" id={`row-${project._id}`} className='form-check-input mt-1' onChange={(e) => changeRowSelection(e, project._id)} disabled={project?.move_to_deal} />
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        <p className=' m-0 text-nowrap'>
                                            {(activePage - 1) * 100 + (i++)}
                                        </p>
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>

                                        {project.name ? <p className=' m-0 text-nowrap'>
                                            {project.name}
                                        </p> :
                                            <p className=' m-0 text-center'>
                                                -
                                            </p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.email ? <p className=' m-0 text-nowrap'>
                                            {project.email}
                                        </p>
                                            : <p className=' m-0 text-center'>
                                                -
                                            </p>}
                                    </td>


                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.form_for == 1 ? <div className=' m-0 text-nowrap text-center'>
                                            <Badge color="blue" content=" Custom  Datasets Request" className='p-2' />
                                        </div> : project.form_for == 2 ? <div className=' m-0'>
                                            <Badge color="green" content={"Sample Request"} className='p-2' />
                                        </div> : project.form_for == 3 ?
                                            <div className=' m-0'>
                                                <Badge color="red" content={`Sample`} className='p-2' />
                                            </div> : project.form_for == 4 ?
                                                <div className=' m-0'>
                                                    <Badge color="violet" content={`Get This Dataset`} className='p-2' />
                                                </div> :
                                                <div className=' m-0 text-center'>
                                                    -
                                                </div>
                                        }
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>

                                        <div className='d-flex'>
                                            <div className='mx-2' style={{ width: "150px" }}>
                                                {project.comment ? project.comment : "-"}
                                            </div>
                                            <div className=''>
                                                <ButtonToolbar>
                                                    <Button className="bg-primary text-light " id={i} onClick={openModalFuncComment}>{<EditOutlined />}
                                                    </Button>
                                                </ButtonToolbar>
                                            </div>
                                        </div>

                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.dataset_code ? <p className=' m-0 text-nowrap'>
                                            {project.dataset_code}  </p> : <p className=' m-0 text-center'>
                                            - </p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.page_url ? <p className=' m-0 text-primary  linkclass  text-center' onClick={linkCopyFunc}>
                                            <Button id={project.page_url} className=' text-center bg-primary text-light'> Copy </Button>
                                        </p> : <p className=' m-0 text-center'> - </p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.sample_link ? <p className=' m-0 text-primary  linkclass  text-center' onClick={linkCopyFunc}>
                                            <Button id={project.sample_link} className=' text-center bg-primary text-light'> Copy </Button>
                                        </p>
                                            :
                                            <p className=' m-0  text-center'>
                                                -
                                            </p>
                                        }
                                    </td>

                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.form_fill ? <p className=' m-0 text-nowrap'>
                                            {project.form_fill}  </p> :
                                            <p className="text-center">
                                                -
                                            </p>
                                        }

                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.company_name ? <p className=' m-0 text-nowrap'>
                                            {project.company_name}  </p> :
                                            <p>
                                                -
                                            </p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.title ? <p className=' m-0 text-nowrap'>
                                            {project.title}  </p> :
                                            <p>
                                                -
                                            </p>}
                                    </td>

                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.volume ? <p className=' m-0 '>
                                            {project.volume}  </p> :
                                            <p>
                                                -
                                            </p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.purpose ? <p className=' m-0 text-nowrap'>
                                            {project.purpose}  </p> :
                                            <p>
                                                -
                                            </p>}
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.tentative_budget ? <p className=' m-0 text-nowrap'>
                                            {project.tentative_budget}  </p> :
                                            <p>
                                                -
                                            </p>}
                                    </td>

                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        <div className=''>
                                            {project.message ? <ButtonToolbar>
                                                <Button className='bg-primary text-light' id={project.message} onClick={handleOpen}> Read</Button>
                                            </ButtonToolbar>
                                                :
                                                <p className=' m-0 text-center'>
                                                    -
                                                </p>
                                            }
                                        </div>
                                    </td>
                                    <td className={project?.move_to_deal ? 'bg-transparent' : ''}>
                                        {project.createdAt ? <p className=' m-0 text-nowrap'>
                                            {project.createdAt.split("T")[0].split("-").reverse().join("/")}  </p> :
                                            <p className=' m-0 text-center'>
                                                -
                                            </p>}

                                    </td>
                                    <td className='text-nowrap'>
                                        <Button className='bg-danger text-light' onClick={() => handleDeleteClick(project._id)}>Delete</Button>
                                    </td>

                                </tr>
                            )}
                        </tbody>
                    </Table>
                    <Modal backdrop={backdrop} keyboard={false} open={deleteConfirmation}>
                        <Modal.Header>
                            <Modal.Title>Confirm Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to delete this Dataset Request?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={handleConfirmDelete} color='red'>
                                Yes
                            </Button>
                            <Button onClick={() => setDeleteConfirmation(false)}>No</Button>
                        </Modal.Footer>
                    </Modal>
                    <div className='pagination-container py-2'>
                        {dataAll.success && <Pagination
                            prev
                            last
                            next
                            first
                            size="lg"
                            total={dataAll.result.length}
                            limit={100}
                            activePage={activePage}
                            onChangePage={setActivePage}
                        // onClick={clickPage}
                        />}
                    </div>
                    <Modal backdrop={backdrop} keyboard={false} open={openn} onClose={handleClose}>
                        <Modal.Header>
                            <Modal.Title>Message</Modal.Title>
                        </Modal.Header>
                        <hr />
                        <Modal.Body>
                            <p>
                                {messagePara}
                            </p>
                        </Modal.Body>
                        <hr />
                        <Modal.Footer>
                            <Button onClick={handleClose} appearance="subtle" className='btn btn-primary'>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal backdrop={backdrop} keyboard={false} open={CommentOpen} onClose={handleCloseComment}>
                        <Modal.Header>
                            <Modal.Title>Comment </Modal.Title>
                        </Modal.Header>
                        <hr />
                        <Modal.Body>
                            <p>
                                <Input as="textarea" value={commentText} rows={3} placeholder="Textarea" className="text-area-size" onChange={commentChange} />
                            </p>
                        </Modal.Body>
                        <hr />
                        <Modal.Footer>
                            <Button onClick={handleCloseComment} appearance="subtle" className='btn btn-secondary'>
                                Cancel
                            </Button>
                            <Button onClick={handleSaveComment} appearance="subtle" className='btn btn-primary'>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Container>
            </div>
        </>
    );
}

export default DatasetRequest;