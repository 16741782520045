import {
  SECTION,
  SECTION_SUCCESS,
  SECTION_ERROR
} from "./actionTypes"

export const sections = (user) => {
  return {
    type: SECTION,
    payload: user,
  }
}

export const sectionsSuccess = message => {
  // console.log(JSON.stringify(message));
  return {
    type: SECTION_SUCCESS,
    payload: message,
  }
}

export const sectionsError = message => {
  return {
    type: SECTION_ERROR,
    payload: message,
  }
}
