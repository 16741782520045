import {
  UPDATE_PRIORITY,
  UPDATE_PRIORITY_SUCCESS,
  UPDATE_PRIORITY_ERROR
} from "./actionTypes"

const initialState = {
  data: null,
  error: null,
}

const section = (state = initialState, action) => {
  // console.log(JSON.stringify(action))
  switch (action.type) {
    case UPDATE_PRIORITY:
      // console.log(action.payload);
      state = {
        data: action.payload,
      }
      break
    case UPDATE_PRIORITY_SUCCESS:
      state = {
        ...state,
        data: action.payload,
      }
      break
    case UPDATE_PRIORITY_ERROR:
      state = { ...state, error: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default section
