import { call, put, takeEvery, all, fork } from "redux-saga/effects"

// Crypto Redux States
import { GET_INDUSTRY, ADD_NEW_INDUSTRY, API_INDUSTRYSUCCESS, API_INDUSTRYFAIL, UPDATE_INDUSTRY } from "./actionTypes"
import { apiIndustrySuccess, apiIndustryFail } from "./action"

//Include Both Helper File with needed methods
import { getIndustry, addNewIndustry } from "../../helpers/backend_helper"

function* getindustry() {
  try {
    const response = yield call(getIndustry)

    yield put(apiIndustrySuccess(GET_INDUSTRY, response))
  } catch (error) {
    yield put(apiIndustryFail(GET_INDUSTRY, error))
  }
}
function* addIndustry({ payload: industry }) {
  try {
    const response = yield call(addNewIndustry, industry)
    // console.log(response)
    yield put(apiIndustrySuccess(API_INDUSTRYSUCCESS, response))
  } catch (error) {
    yield put(apiIndustryFail(API_INDUSTRYFAIL, error))
  }
}

export function* watchIndustry() {
  yield takeEvery(ADD_NEW_INDUSTRY, addIndustry)

  yield takeEvery(GET_INDUSTRY, getindustry)

}

function* industrySaga() {
  yield all([fork(watchIndustry)])

}

export default industrySaga
