import {
  API_DATASETSUCCESS,
  API_DATASETFAIL,
  GET_CATEGORY,
  ADD_NEW_DATASET,
  ADD_NEW_CATEGORY,
  GET_DATASET,
  GET_DATASET_BY_ID,
  UPDATE_DATASET,
  UPDATE_DATASET_TWO,
  GET_DATASET_BY_ID_TWO_HIDE,
  UPDATE_DATASET_TWO_HIDE,
} from "./actionTypes"

const INIT_STATE = {
  dataset: [],
  addDatasetResponse: [],
  addCategoryResponse: [],
  datasetData: [],
  datasetDataID: [],
  datasetDataID2: ""

}

const Dataset = (state = INIT_STATE, action) => {
  // console.log(action)
  switch (action.type) {
    case API_DATASETSUCCESS:
      switch (action.payload.actionType) {
        case GET_CATEGORY:
          return {
            ...state,
            dataset: action?.payload?.data,
          }

        default:
          return state
      }
    case API_DATASETFAIL:
      switch (action.payload.actionType) {
        case GET_CATEGORY:
          return {
            ...state,
            datasetError: action.payload.error,
          }

        default:
          return state
      }

    default:
      return state
  }
}

export const addDataset = (state = INIT_STATE, action) => {
  // console.log(action)
  switch (action.type) {
    case API_DATASETSUCCESS:
      switch (action.payload.actionType) {
        case ADD_NEW_DATASET:
          return {
            ...state,
            addDatasetResponse: action?.payload?.data,
          }

        default:
          return state
      }
    case API_DATASETFAIL:
      switch (action.payload.actionType) {
        case ADD_NEW_DATASET:
          return {
            ...state,
            datasetError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
export const addCategory = (state = INIT_STATE, action) => {

  switch (action.type) {
    case API_DATASETSUCCESS:
      switch (action.payload.actionType) {
        case ADD_NEW_CATEGORY:
          return {
            ...state,
            addCategoryResponse: action?.payload?.data,
          }

        default:
          return state
      }
    case API_DATASETFAIL:
      switch (action.payload.actionType) {
        case ADD_NEW_CATEGORY:
          return {
            ...state,
            datasetError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
export const getDataset = (state = INIT_STATE, action) => {
  // console.log(action)
  switch (action.type) {
    case API_DATASETSUCCESS:
      switch (action.payload.actionType) {
        case GET_DATASET:
          return {
            ...state,
            datasetData: action?.payload?.data,
          }
        default:
          return state
      }
    case API_DATASETFAIL:
      switch (action.payload.actionType) {
        case GET_DATASET:
          return {
            ...state,
            datasetError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
// export const getDatasetById = (state = INIT_STATE, action) => {

//   switch (action.payload) {
//     case GET_DATASET_BY_ID:
//       // console.log(action.payload)
//       return {
//         ...state,
//         datasetData: action.payload,
//       }
//     default:
//       return state
//   }

// }
export const getDatasetById = (state = INIT_STATE, action) => {

  switch (action.type) {
    case API_DATASETSUCCESS:
      switch (action.payload.actionType) {
        case GET_DATASET_BY_ID:
          return {
            ...state,
            datasetDataID: action?.payload?.data,
          }
        default:
          return state
      }
    case API_DATASETFAIL:
      switch (action.payload.actionType) {
        case GET_DATASET_BY_ID:
          return {
            ...state,
            datasetErrorID: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
export const getDatasetByIdTWO = (state = INIT_STATE, action) => {

  switch (action.type) {
    case API_DATASETSUCCESS:
      switch (action.payload.actionType) {
        case GET_DATASET_BY_ID_TWO_HIDE:
          return {
            ...state,
            datasetDataID2: action?.payload?.data,
          }
        default:
          return state
      }
    case API_DATASETFAIL:
      switch (action.payload.actionType) {
        case GET_DATASET_BY_ID_TWO_HIDE:
          return {
            ...state,
            datasetErrorID2: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
export const updateDataset = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_DATASETSUCCESS:
      switch (action.payload.actionType) {

        case UPDATE_DATASET:
          return {
            ...state,
            addDatasetResponse: action?.payload?.data,
          }

        default:
          return state
      }
    case API_DATASETFAIL:
      switch (action.payload.actionType) {
        case UPDATE_DATASET:
          return {
            ...state,
            datasetError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
export const updateDatasetTWO = (state = INIT_STATE, action) => {
  // console.log(action)
  // console.log(action)
  switch (action.type) {
    case API_DATASETSUCCESS:
      switch (action.payload.actionType) {

        case UPDATE_DATASET_TWO_HIDE:
          return {
            ...state,
            addDatasetResponse: action?.payload?.data,
          }

        default:
          return state
      }
    case API_DATASETFAIL:
      switch (action.payload.actionType) {
        case UPDATE_DATASET_TWO_HIDE:
          return {
            ...state,
            datasetError: action.payload.error,
          }

        default:
          return state
      }
    default:
      return state
  }
}
export default Dataset
