import {
  API_BLOG_SUCCESS,
  API_BLOG_FAIL,
  ADD_BLOG,
  GET_ALL_BLOG,
} from "./actionTypes"

export const apiBlogSuccess = (actionType, data) => (
  // console.log(actionType, data),
  {
    type: API_BLOG_SUCCESS,
    payload: { actionType, data },
  })

export const apiBlogFail = (actionType, error) => ({
  type: API_BLOG_FAIL,
  payload: { actionType, error },
})

// add blog
export const addNewBlog = periodType => ({
  type: ADD_BLOG,
  payload: periodType,
})

export const getAllBlogs = response => ({
  type: GET_ALL_BLOG,
  payload: response
})
