import { GET_A_CALL_SUCCESS } from "./actionTypes";

const InitialState = {
    data: []
}
const getCallReducer = (state = InitialState, action) => {
    switch (action.type) {
        case GET_A_CALL_SUCCESS:
            // console.log(action);
            return {
                ...state,
                data: action.response
            }
            break;

        default:
            return state
            break;
    }


}



export default getCallReducer