import {
  UPDATE_PRIORITY,
  UPDATE_PRIORITY_SUCCESS,
  UPDATE_PRIORITY_ERROR
} from "./actionTypes"

export const updatePriority = (user, url) => {
  // console.log(user)
  return {
    type: UPDATE_PRIORITY,
    payload: { user, url },
  }
}

export const updatePrioritySuccess = message => {
  // console.log(JSON.stringify(message));
  return {
    type: UPDATE_PRIORITY_SUCCESS,
    payload: message,
  }
}

export const updatePriorityError = message => {
  return {
    type: UPDATE_PRIORITY_ERROR,
    payload: message,
  }
}
