import React, { useEffect, useState } from 'react';
import { getSubscriber } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import "./subscriber.css";
import { Badge } from 'rsuite';
import { Table } from 'reactstrap';
import { Modal, Loader, RadioGroup, Radio, Placeholder } from 'rsuite';

function Subscriber () {
    const [Loaderr, setLoaderr] = useState(true);
    const [RecordsNotAvalable, setRecordsNotAvalable] = useState(false);

    const { subscriber } = useSelector(state => ({
        subscriber: state.Subscriber.users
    }));

    let dispatch = useDispatch();

    useEffect(() => {
        if (subscriber.success) {
            setLoaderr(false);
            localStorage.setItem("SubscriberNewMessage", subscriber.result?.length);
        }
        if (subscriber.success == false) {
            setLoaderr(false);
            setRecordsNotAvalable(true);
            localStorage.setItem("SubscriberNewMessage", subscriber.result?.length);
        }

    }, [subscriber]);
    useEffect(() => {
        dispatch(getSubscriber());
    }, []);


    let i = 1;
    return (

        <>
            <div className='page-content subscriber-container'>
                <h2 className="main-heading ">
                    <span>
                        <span>
                            Subscriber
                        </span>
                    </span>
                </h2>
                <Table bordered responsive hover className='table table-primary mb-0' >
                    <thead>
                        <tr className="bgdark   " >
                            <th className="bgdark "  > No.</th>
                            <th className="bgdark "  >Email</th>
                            <th className="bgdark "  >Form For</th>
                            <th className="bgdark "  >Date</th>
                        </tr>
                    </thead>
                    {
                        RecordsNotAvalable ? <div className=' records-not-class'  >
                            Records are not available!
                        </div> : null
                    }
                    <tbody>
                        <tr>
                            {
                                Loaderr ? <div className="  loaderposition">
                                    <Loader size="lg" className='' />
                                </div> : null
                            }
                        </tr>
                        {
                            subscriber.success && subscriber.result.map((x) =>
                                <tr key={x._id}>
                                    <td>{i++} </td>
                                    <td>  {x.email ? <p>{x.email}</p> : <p className='text-center'>-</p>} </td>
                                    <td>  {x.form_for == 1 ? <Badge content="Footer" /> : x.form_for == 2 ? <Badge color="blue" content="Blog" /> : x.form_for == 3 ? <Badge color="green" content="FAQ" /> : <p className='text-center'>-</p>} </td>
                                    <td>  {x.createdAt ? x.createdAt.split("T")[0].split("-").reverse().join("/") : <p> - </p>} </td>
                                </tr>
                            )}
                    </tbody>
                </Table>
            </div>
        </>
    );
}

export default Subscriber;